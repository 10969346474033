import { useEffect, useRef, useState } from "react";

export const useHasValueChanged = <T = any>(value: T): boolean => {
  const prevValue = useRef<T>();
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setHasChanged(prevValue.current !== value);
    prevValue.current = value;
  }, [value]);

  return hasChanged;
};
