import React from "react";
import { makeStyles } from "@mui/styles";
import Stack from "@mui/material/Stack";
import Header from "../common/header/Header";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.info.dark,
  },
}));

const H1Header: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Stack spacing={2}>
        <Header
          regulation="Regulation 27(2)"
          title="Section 58(3)(a) — certificate of consent to treatment"
        />
      </Stack>
    </div>
  );
};

export default H1Header;
