import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useDeviceType } from "hooks";
import Header from "../common/header/Header";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.info.dark,
  },
}));

const CTO12Header: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useDeviceType();

  return (
    <div className={classes.container}>
      <Stack spacing={2}>
        <Header
          regulation="Regulation 28(1A)"
          title="Section 64C(4A) – Certificate that community patient has capacity to consent"
        />
        <Typography
          variant="h3"
          style={{
            fontWeight: 600,
            margin: isMobile ? "0 0" : "0 auto",
            textAlign: isMobile ? "left" : "center",
          }}
        >
          (or if under 16 is competent to consent) to treatment and has done so
        </Typography>
        <Typography variant="h3" style={{ fontWeight: 600, margin: "0 auto" }}>
          (Part 4A consent certificate)
        </Typography>
        <Typography
          variant="h4"
          style={{
            alignSelf: "center",
            fontStyle: "italic",
            maxWidth: "80%",
            textAlign: "center",
          }}
        >
          (To be completed on behalf of the responsible hospital)
        </Typography>
      </Stack>
    </div>
  );
};

export default CTO12Header;
