import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography, TypographyProps } from "@mui/material";
import { useForm } from "hooks";
import { FormErrors } from "store/forms/types";

const useStyles = makeStyles((theme) => ({
  errorText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.error.dark,
    fontWeight: "bold",
  },
}));

const displayText = (
  errorKey: string | undefined,
  formErrors: FormErrors,
  error: boolean | undefined,
  errorText: string | undefined
) => (errorKey ? formErrors[errorKey] : error && errorText ? errorText : "");

type ErrorLabelType = {
  error?: boolean;
  errorText?: string;
  errorKey?: string;
  align?: TypographyProps["align"];
};
const ErrorLabel: React.FC<ErrorLabelType> = ({
  error,
  errorText,
  errorKey,
  align = "right",
}) => {
  const classes = useStyles();
  const { formErrors } = useForm();

  const shouldDisplayError = (errorKey && formErrors[errorKey]) || error;

  if (!shouldDisplayError) return <></>;

  return (
    <div className={classes.errorText}>
      <Typography variant="h5" align={align}>
        {displayText(errorKey, formErrors, error, errorText)}
      </Typography>
    </div>
  );
};

export default React.memo(ErrorLabel);
