import React from "react";

// Hooks
import { usePopups } from "hooks";

// Material UI
import MuiSnackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { AlertColor } from "@mui/material";

interface SnackbarProps {
  open: boolean;
  close: () => void;
  type?: AlertColor;
  message: string;
}

// Extracted for ease of testing
export const SnackbarContent: React.FC<SnackbarProps> = ({
  open,
  close,
  type,
  message,
}) => (
  <MuiSnackbar
    data-test-id="snackbar"
    open={open}
    autoHideDuration={6000}
    onClose={close}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
  >
    <Alert
      onClose={close}
      severity={type}
      elevation={6}
      variant="filled"
      sx={{ width: "100%", color: "#fff" }}
    >
      {message}
    </Alert>
  </MuiSnackbar>
);

const Snackbar: React.FC = () => {
  const {
    snackbar: { type, message },
    closeSnackbar,
  } = usePopups();
  const isOpen = type && message ? true : false;

  if (!isOpen) return <></>;

  return (
    <SnackbarContent
      open={isOpen}
      close={closeSnackbar}
      type={type}
      message={message}
    />
  );
};

export default React.memo(Snackbar);
