import { FC, memo, useCallback, useEffect, useState } from "react";

// Material UI
import { Button, ButtonGroup, Container, Typography } from "@mui/material";

// Custom Imports
import { useUI, useAuth, usePopups } from "hooks";
import { FormType } from "common/types/pdf";

import { FormState } from "common/types/statForms";
import generateFormProps from "utils/pdf/generateFormProps";
import { RenderedPDF } from "components/pdf";
import { ReviewingType } from "store/ui/uiContext";
import FormList from "components/dashboard/formList";
import EmailList from "components/dashboard/emailList/EmailList";

enum DashboardMode {
  FORMS = "FORMS",
  SENT_EMAILS = "SENT_EMAILS",
}

const Dashboard: FC = () => {
  const { setReviewing } = useUI();
  const { actor, clearActor } = useAuth();
  const { closeDialog } = usePopups();

  const [dashboardMode, setDashboardMode] = useState<DashboardMode>(
    DashboardMode.FORMS
  );

  const handleViewForm = useCallback(
    (formId: string, formType: FormType, formData: FormState["data"]) => {
      // generate form props format
      const formProps = generateFormProps(formId, formType, formData);
      // trigger pdf drawer via ui context
      setReviewing(<RenderedPDF {...formProps} />, {
        type: ReviewingType.VIEW,
      });
    },
    [setReviewing]
  );

  const DashboardView = {
    [DashboardMode.FORMS]: FormList,
    [DashboardMode.SENT_EMAILS]: EmailList,
  }[dashboardMode];

  /**
   * ? Extra Security
   * If the user decides to “cancel” a hospital form in progress you can’t clear the actor at that point
   * because the `useUnsavedChanges` hook will kick in and ask if the user really wants to go back and
   * clear the form progress. Of which they can cancel, stay on the form and then the actor has been cleared - IS
   */
  useEffect(() => {
    if (actor) clearActor();
    closeDialog();
  }, [actor, clearActor, closeDialog]);

  return (
    <Container maxWidth="lg" sx={{ p: 3 }}>
      <Typography variant="h1" mb={3}>
        Dashboard
      </Typography>
      <ButtonGroup sx={{ mb: 4 }}>
        <Button
          data-test-id="dashboard-view-forms"
          variant={
            dashboardMode === DashboardMode.FORMS ? "contained" : "outlined"
          }
          size="large"
          onClick={() => setDashboardMode(DashboardMode.FORMS)}
        >
          Forms
        </Button>
        <Button
          data-test-id="dashboard-view-sent-emails"
          variant={
            dashboardMode === DashboardMode.SENT_EMAILS
              ? "contained"
              : "outlined"
          }
          size="large"
          onClick={() => setDashboardMode(DashboardMode.SENT_EMAILS)}
        >
          Sent Emails
        </Button>
      </ButtonGroup>
      <DashboardView handleView={handleViewForm} />
    </Container>
  );
};

export default memo(Dashboard);
