import { CTO12Form } from "common/types/cto12";
import { formatInitialFormState } from "../helpers";

export const data: CTO12Form = {
  clinicianDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
    email: "",
  },
  patientDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
    capacity: undefined,
    description: "",
  },
  clinicianSignature: {
    signature: "",
    date: "",
    trackingId: "",
  },
};

export const cto12InitialState = formatInitialFormState(data);
