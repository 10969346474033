import React from "react";

// Form Components
import FormContainer from "components/forms/formContainer";
import T2Header from "components/formSections/t2/T2Header";
import ClinicianDetails from "components/formSections/t2/clinicianDetails/ClinicianDetails";
import PatientDetails from "components/formSections/t2/patientDetails/PatientDetails";
import FormLoading from "components/forms/formLoading";

// Custom Imports
import {
  useForm,
  useUI,
  useFormValidation,
  useFormAccess,
  useAuth,
} from "hooks";
import {
  generateFormItems,
  shouldDisablePrev,
  shouldEnableDownload,
  validationSchema,
} from "./helpers";
import { useStyles } from "common/styles/form";
import { isWard } from "utils/auth";
import { LoadingCircle } from "@s12solutions/ui";

/**
 * ------------------------------------------------------------
 * * WORKFLOW FOR T2 FORM
 * ------------------------------------------------------------
 * Stages
 * 1/ Started by doctor (who belongs to mht with feature enabled for T2)
 * 2/ Emailed to mha admin
 * ------------------------------------------------------------
 */

const T2Form: React.FC = () => {
  const classes = useStyles();
  const { actor, user } = useAuth();
  const { loading, formState, dispatchFormState, actorType } = useForm();
  const { currentSectionIndex } = useUI();

  // Destructure
  const { clinicianDetails, patientDetails, clinicianSignature } =
    formState.data;

  const { hasAccess } = useFormAccess("Treatment");

  useFormValidation(validationSchema, [
    { clinicianDetails },
    { patientDetails },
  ]);

  const formItems = generateFormItems();

  // Render
  if (loading || !hasAccess) {
    return <LoadingCircle />;
  }
  if (isWard(user) && !actor) {
    return <FormLoading />;
  }

  return (
    <FormContainer
      sections={[
        <>
          <div className={classes.sectionContainer}>
            <T2Header />
          </div>
          <div className={classes.sectionContainer}>
            <ClinicianDetails
              testIdKey="t2-clinician"
              clinicianDetails={clinicianDetails}
              dispatchFormState={dispatchFormState}
              disabled={false}
            />
          </div>
        </>,
        <div className={classes.sectionContainer}>
          <PatientDetails
            testIdKey="t2-patient"
            patientDetails={patientDetails}
            clinicianSignature={clinicianSignature}
            dispatchFormState={dispatchFormState}
          />
        </div>,
      ]}
      formPartSelectorProps={{
        formItems,
        enableDownload: shouldEnableDownload(formState.data),
      }}
      formNavButtonProps={{
        formItems,
        lastSectionIndex: 1,
        disablePrev: shouldDisablePrev(actorType, currentSectionIndex),
      }}
    />
  );
};

export default React.memo(T2Form);
