import * as Yup from "yup";

import {
  Actors,
  FormState,
  HospitalDetailsType,
  FormItemType,
  FormStatus,
} from "common/types/statForms";
import {
  H1Form,
  YourDetailsType,
  H1PatientDetailsType,
  FurnishingReportType,
  WardSignatureType,
} from "common/types/h1";
import { hospitalDetailsValidation } from "common/libs/validation";
import { CognitoUserExt } from "common/types/auth";
import { isWard } from "utils/auth";

/**
 * getActor
 * - if the user is a doctor and part 2 has not been signed -> stage 1 index 0
 * - if the user is a ward -> stage 2 index 3
 */
export const getActor = (
  formState: FormState<H1Form>,
  users: CognitoUserExt
): Actors | false => {
  const { furnishingReport } = formState.data;

  if (!furnishingReport.signature) {
    return Actors.Originator;
  }

  if (isWard(users)) {
    return Actors.MHAAdmin;
  }

  return false;
};

export const generateFormItems = (actor: Actors): FormItemType[] => {
  return [
    /** TO BE COMPLETED BY: S12Doctor, RC, AC or Doctor (and belongs to an mht with feature enabled) */
    {
      index: 0,
      itemText: "1. Hospital Details",
      disabled: actor !== Actors.Originator,
      errorKey: "hospitalDetails",
    },
    {
      index: 1,
      itemText: "2. Your Details",
      disabled: actor !== Actors.Originator,
      errorKey: "yourDetails",
    },
    {
      index: 2,
      itemText: "3. Patient Details",
      disabled: actor !== Actors.Originator,
      errorKey: "patientDetails",
    },
    /** TO BE SIGNED BY: Ward, once signed the email will be triggered and sent to MHA Admin */
    {
      index: 3,
      itemText: "4. Hospital Manager Signature",
      disabled: actor !== Actors.MHAAdmin,
      errorKey: "wardSignature",
    },
  ];
};

export const handleStatus = (formState: FormState<H1Form>): FormStatus => {
  if (
    (formState.status === "" || formState.status === FormStatus.s10_draft) &&
    !formState.data.furnishingReport.signature
  ) {
    return FormStatus.s10_draft;
  }

  return FormStatus.s40_signed;
};

export const validationSchema = [
  Yup.object().shape(hospitalDetailsValidation),
  Yup.object().shape({
    practitionerName: Yup.string()
      .trim()
      .required("Practitioner name is required"),
    isNominee: Yup.string().trim().required("This field is required"),
    isMedicalPractitioner: Yup.string()
      .trim()
      .required("This field is required"),
  }),
  Yup.object().shape({
    name: Yup.string().trim().required("Patient name is required"),
    detailedReasons: Yup.string()
      .trim()
      .required("Detailed reasons is required")
      .min(60, "Detailed reasons needs to be greater than 60 characters"),
  }),
];

// Section 1 will be disabled if there is no hospital name and address filled manually or using lookup
const hasErrorsSection1 = (props: HospitalDetailsType): boolean => {
  const { name, address, manualAddress1, manualAddress2, manualPostcode } =
    props;

  let error = false;
  if (!name) error = true;
  if (!address && !manualAddress1 && !manualAddress2 && !manualPostcode)
    error = true;

  return error;
};
const hasErrorsSection2 = (props: YourDetailsType): boolean => {
  const { practitionerName, isNominee, isMedicalPractitioner } = props;

  let error = false;
  if (!practitionerName) error = true;
  if (isNominee === undefined) error = true;
  if (isMedicalPractitioner === undefined) error = true;

  return error;
};
const hasErrorsSection3 = (
  patientDetails: H1PatientDetailsType,
  furnishingReport: FurnishingReportType
): boolean => {
  const { name: patientName, detailedReasons } = patientDetails;
  const { signature, date } = furnishingReport;

  let error = false;
  if (!patientName) error = true;
  if (!detailedReasons) error = true;
  if (!signature) error = true;
  if (!date) error = true;

  return error;
};
const hasErrorsSection4 = (props: WardSignatureType): boolean => {
  const { signature, date } = props;

  let error = false;
  if (!signature) error = true;
  if (!date) error = true;

  return error;
};

export const shouldDisableNext = (
  currentIndex: number,
  data: H1Form
): boolean => {
  const {
    hospitalDetails,
    yourDetails,
    patientDetails,
    furnishingReport,
    wardSignature,
  } = data;

  switch (currentIndex) {
    case 0:
      return hasErrorsSection1(hospitalDetails);
    case 1:
      return hasErrorsSection2(yourDetails);
    case 2:
      return hasErrorsSection3(patientDetails, furnishingReport);
    case 3:
      return hasErrorsSection4(wardSignature);
    default:
      return false;
  }
};

export const shouldDisablePrev = (
  actor: Actors,
  currentIndex: number
): boolean => {
  if (actor === Actors.MHAAdmin && currentIndex === 3) {
    return true;
  }

  return false;
};

export const shouldEnableDownload = (data: H1Form): boolean => {
  const {
    hospitalDetails,
    yourDetails,
    patientDetails,
    furnishingReport,
    wardSignature,
  } = data;

  if (
    hasErrorsSection1(hospitalDetails) ||
    hasErrorsSection2(yourDetails) ||
    hasErrorsSection3(patientDetails, furnishingReport) ||
    hasErrorsSection4(wardSignature)
  ) {
    return false;
  }

  return true;
};
