import React, { useCallback, useState } from "react";

// Material UI Imports
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Custom Imports
import { SignatureType } from "common/types/statForms";
import { FormStateActions, PatientDetailsType } from "common/types/t2";
import { useAuth, useForm } from "hooks";
import { formatUserName } from "common/libs/formatUserName";
import GenericPatientDetails, {
  PatientDetailsProps as GenericPatientDetailsProps,
} from "components/formSections/common/patientDetails";
import Paper from "components/paper";
import { StyledTextArea } from "components/uiElements";
import SignatureSection from "components/formSections/common/signatureSection";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

const MedicalDetailsTemplate = () => (
  <Stack spacing={2}>
    <Typography variant="body2">
      Record the class of drug and route of administration, and  either:
    </Typography>
    <Typography variant="body2" pl={2}>
      1. State that the dose (when calculated together with frequency) is within
      the BNF guidelines about maximum dose limits for that route, or state a
      maximum dose limit referenced to BNF guidelines such as, for example, 50%
      or 120%.
    </Typography>
    <Typography variant="body2" className="bold">
      OR
    </Typography>
    <Typography variant="body2" pl={2}>
      1. State a named drug and its route & dose maximum. In some circumstances
      it may be useful, indeed necessary, to specify a named drug and also its
      purpose, especially when it is being used for a non-licensed indication,
      e.g. clonazepam when used for agitation.
    </Typography>
    <Typography variant="body2" mt={1}>
      For example
    </Typography>
    <Typography variant="body2" pl={2}>
      1. One oral antidepressant drug within BNF advisory maximum dose limits.
    </Typography>
    <Typography variant="body2" pl={2}>
      1. Olanzapine, oral antipsychotic, maximum 15mg daily.
    </Typography>
    <Typography variant="body2" pl={2}>
      1. Clonazepam 1mg orally as required, maximum 4mg daily, for adjunct
      management of agitation.
    </Typography>
    <Typography variant="body2">
      There may be reasons to exclude a specific drug e.g. due to an advance
      decision to refuse it, and this can be stated on the form.
    </Typography>
    <Typography variant="body2" mt={1}>
      BNF can be accessed{" "}
      <Link href="https://bnf.nice.org.uk/" target="_blank" variant="body2">
        here
      </Link>
    </Typography>
  </Stack>
);

type PatientDetailsProps<T> = GenericPatientDetailsProps<T> & {
  clinicianSignature: SignatureType;
};

const PatientDetails: React.FC<PatientDetailsProps<PatientDetailsType>> = ({
  testIdKey,
  patientDetails,
  patientDetails: { description },
  clinicianSignature: { signature, date },
  dispatchFormState,
  disabled,
}) => {
  const { user } = useAuth();
  const { handleEmail } = useForm();
  const [signedStatus, setSignedStatus] = useState<boolean>(!!signature);
  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed ? formatUserName(user) : "";

      dispatchFormState({
        type: FormStateActions.SET_CLINICIAN_SIGNATURE,
        payload: signedValue,
      });
    },
    [dispatchFormState, user]
  );

  return (
    <>
      <GenericPatientDetails
        testIdKey={testIdKey}
        patientDetails={patientDetails}
        dispatchFormState={dispatchFormState}
        disabled={disabled}
      />
      <Paper>
        <Stack spacing={3} mt={3} mb={3}>
          <Typography variant="h4" display="inline">
            (a) is capable of understanding the nature, purpose and likely
            effects of:{" "}
            <span className="italic">
              (Give description of treatment or plan of treatment. Indicate
              clearly if the certificate is only to apply to any or all of the
              treatment for a specific period.)
            </span>
          </Typography>
          <StyledTextArea
            testId={`${testIdKey}-description`}
            label=""
            value={description}
            onChange={(value: any) =>
              dispatchFormState({
                type: FormStateActions.SET_PATIENT_TREATMENT_DESCRIPTION,
                payload: value,
              })
            }
            disabled={signedStatus}
            errorKey="patientDetails.description"
            templateProps={{
              name: "Medication Details Template",
              template: MedicalDetailsTemplate,
            }}
          />
          <Typography variant="h4" display="inline">
            AND
          </Typography>
          <Typography variant="h4" display="inline">
            (b) has consented to that treatment.
          </Typography>
        </Stack>
        <SignatureSection
          name={signature}
          date={date}
          signedStatus={signedStatus}
          setSignedStatus={handleSetSignedStatus}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          mt={2}
        >
          <Button
            data-test-id="t2-email-final"
            variant="contained"
            onClick={() => handleEmail({ includePDFAttachment: true })}
            disabled={!signedStatus}
            size="large"
            sx={{ mr: 1 }}
          >
            Send to MHA Admin
          </Button>
          <PdfDownloadButton disabled={!signedStatus} />
        </Stack>
      </Paper>
    </>
  );
};

export default React.memo(PatientDetails);
