import { CTO1Form } from "common/types/cto1";
import { formatInitialFormState } from "../helpers";

export const data: CTO1Form = {
  clinicianDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
    email: "",
  },
  patientDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
  },
  opinion: {
    for: [],
    details: "",
    furtherConditions: "",
    signature: "",
    trackingId: "",
    date: "",
  },
  amhpDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
    authorityName: "",
    otherAuthority: undefined,
    onBehalfOf: undefined,
    signature: "",
    trackingId: "",
    date: "",
  },
  ctoDetails: {
    effectiveFromDate: "",
    effectiveFromTime: "",
    signature: "",
    trackingId: "",
    date: "",
  },
};

export const cto1InitialState = formatInitialFormState(data);
