import { AlertColor } from "@mui/material";

/**
 * Confirmation Types
 */
export interface Confirmation {
  message: string;
  successCallback: () => any;
  title: string;
}

/**
 * Snackbar Types
 */
export interface Snackbar {
  type?: AlertColor;
  message: string;
}

/**
 * Dialog Types
 */
export enum DialogType {
  Success = "success",
  Error = "error",
  Warning = "warning",
  Info = "info",
  Standard = "standard",
}

export type DialogOptions = {
  /**
   * @default {undefined}
   * @param {string | undefined} key
   * key of the dialog to be rendered, this can stop duplicate dialogs from being rendered
   * if this is undefined, the dialog will be rendered regardless of whether it is a duplicate or not
   * (this is useful when using dialogs in a use effect hook)
   */
  key?: string | undefined;
  /**
   * @default {""}
   * @param {string} title
   * title of the dialog to be rendered within the DialogTitle mui component
   * if this is empty, DialogTitle component within the Dialog will not render.
   */
  title?: string;
  /**
   * @default {"md"}
   * @param {"sm" | "md" | "lg" | "xl"} size of mui dialog
   */
  size?: "sm" | "md" | "lg" | "xl";
  /**
   * @default {"standard"}
   * @param {DialogType} type
   * type of dialog to be rendered (this will change the colour of the dialog heading background color and
   * display a relevant icon
   */
  type?: DialogType;
  /**
   * @default {false}
   * @param {boolean} disableExit
   * disable clicking outside of dialog to close it
   */
  disableExit?: boolean;
  /**
   * @param {string | number} maxHeight
   * maxHeight of the dialog, could be percentage or px etc.
   */
  maxHeight?: string | number;
  /**
   * @default {false}
   * @param {boolean} showCloseButton
   * show close button in the top right of the dialog
   */
  showCloseButton?: boolean;
};

export interface Dialog {
  component?: React.ReactNode;
  options: DialogOptions;
}

/**
 * Context Initial State
 */
export interface InitialState {
  confirmation: Confirmation;
  snackbar: Snackbar;
  dialog: Dialog[];
}
