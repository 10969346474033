import { createContext } from "react";
import AuthService from "@s12solutions/auth/helpers/AuthService";
import { StatFormGroup } from "@s12solutions/types";
import { CognitoUserExt, Actor } from "common/types/auth";
import { authService } from "./authService";

/*
    AUTH CONTEXT
    - Global store for any and all auth related data that would be managed in state
    - You can access user session data by calling:

        const { user } = useContext(AuthContext);

        or available shorthand via hook

        const { user } = useAuth();


    - If for some unknown reason any mutation of user session data must happen,
      remember to update it here with:

        const { setCredentials } = useContext(AuthContext);
        ...
        setCredentials({ ...newUserData });
*/

export interface IAuthState {
  /**
   * @default {undefined}
   * @param {CognitoUserExt} user
   * cognito user signed in, this can either be a ward or an individual user
   */
  user?: CognitoUserExt;
  /**
   * @default {undefined}
   * @param {Actor} actor
   * actor is the user's name and email or phone number (this is required if
   * the cognito user signed in is a ward)
   */
  actor?: Actor;
  /**
   * @default {[]}
   * @param {StatFormGroup[]} formGroups
   * formGroups is an array of form groups that the cognito user signed in
   * has access to view, create and updated
   */
  formGroups: StatFormGroup[];
  /**
   * @default {undefined}
   * @param {AuthService} authService
   * authService is a set of methods that can be used to manage
   * authentication state using the @aws-amplify/auth package
   */
  authService: AuthService;
  clearCredentials: () => void;
  setCredentials: (user: CognitoUserExt) => void;
  clearActor: () => void;
  setActor: (actor: Actor) => void;
}

export const initialState: IAuthState = {
  user: undefined,
  actor: undefined,
  formGroups: [],
  authService,
  clearCredentials: () => {},
  setCredentials: () => {},
  clearActor: () => {},
  setActor: () => {},
};

export const AuthContext = createContext(initialState);
