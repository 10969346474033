import { useCallback, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CheckEmailLink } from "@s12solutions/types";
import { FormType } from "common/types/pdf";
import { ReviewingType } from "store/ui/uiContext";
import { useAPI, useAuth, useForm, useUI } from "hooks";
import generateFormProps from "utils/pdf/generateFormProps";
import { isEmailLinkReviewOnly } from "store/forms/helpers/isEmailLinkReviewOnly";
import { RenderedPDF } from "components/pdf";
import { Methods } from "api";
import { RouteConstants } from "common/constants/routes";
import { isWard } from "utils/auth";

interface EmailLinkAuthRes {
  /**
   * @default false
   * @param {boolean} loading - is the `CHECK_EMAIL_LINK` query loading
   */
  loading: boolean;
  /**
   * @default false
   * @param {boolean} isEmailLinkPresent - is there an email link present
   */
  isEmailLinkPresent: boolean;
  /**
   * @default true
   * @param {boolean} isValid - is the email link valid?
   */
  isValid: boolean;
  /**
   * @default ""
   * @param {string} requiredEmail - some forms (e.g. the CTO's) need the email
   * returned back to the "originator"
   */
  requiredEmail: string;
}

/**
 * useEmailLinkAuth()
 * Hook that looks for an `emailLinkId` and if present handles the authentication
 * (the user accessing link must be the user that started the form)
 * This hook is directly used by the `useFormAccess` hook and shouldn't be used directly - IS
 * @returns {EmailLinkAuthRes} - EmailLinkAuthRes
 */
export const useEmailLinkAuth = (): EmailLinkAuthRes => {
  const navigate = useNavigate();
  const { formType, formId, emailLinkId } = useParams();
  const castedFormId = formId as string;
  const isEmailLinkPresent = !!emailLinkId;
  const { user } = useAuth();
  const { formState } = useForm();
  const castedFormType = formType as FormType;
  const { currentSectionIndex, setReviewing } = useUI();

  const runOnceRef = useRef<boolean>(false);
  const shouldRun = isEmailLinkPresent && !runOnceRef.current;
  const [loading, setLoading] = useState<boolean>(shouldRun ? true : false);
  const [requiredEmail, setRequiredEmail] = useState<string>("");

  const handleReview = useCallback(() => {
    // To prevent "double review" for a form that only has to be signed

    if (isEmailLinkReviewOnly(castedFormType, currentSectionIndex)) {
      return;
    }

    const formProps = generateFormProps(formId, castedFormType, formState.data);

    // trigger pdf drawer via ui context
    setReviewing(<RenderedPDF {...formProps} />, {
      type: ReviewingType.EMAIL_LINK,
    });
  }, [
    castedFormType,
    currentSectionIndex,
    formId,
    formState.data,
    setReviewing,
  ]);

  const { data } = useAPI<CheckEmailLink, { id: string; formId: string }>({
    method: Methods.GET,
    fieldName: "checkEmailLink",
    skip: !shouldRun,
    args: {
      id: emailLinkId as string,
      formId: castedFormId,
    },
    onCompleted: (checkEmailLinkData) => {
      runOnceRef.current = true;
      if (checkEmailLinkData && checkEmailLinkData.isValid) {
        // Set access and required emails
        const accessEmailValue = checkEmailLinkData.email as string;
        const requiredEmailValue = checkEmailLinkData.fromEmail as string;
        setRequiredEmail(requiredEmailValue);

        // Email link can only be accessed by the user the form was sent to
        if (
          accessEmailValue.toLowerCase() !==
          user?.attributes.email.toLowerCase()
        ) {
          navigate(RouteConstants.LINK_INVALID);
          return;
        }

        if (!isWard(user)) {
          handleReview();
        }
      }
      setLoading(false);
    },
  });

  return {
    loading,
    isEmailLinkPresent,
    isValid: !isEmailLinkPresent ? true : !!data?.isValid,
    requiredEmail,
  };
};
