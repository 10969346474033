import { T2Form } from "common/types/t2";
import { formatInitialFormState } from "../helpers";

export const data: T2Form = {
  clinicianDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
    email: "",
    approvedClinician: true,
  },
  patientDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
    description: "",
  },
  clinicianSignature: {
    signature: "",
    trackingId: "",
    date: "",
  },
};

export const t2InitialState = formatInitialFormState(data);
