import { useContext } from "react";
import { AlertColor } from "@mui/material";
import { PopupsContext } from "store/popups/popupsContext";
import { Confirmation, Snackbar, Dialog } from "store/popups/types";

export interface UsePopups {
  confirmation: Confirmation;
  handleConfirmation: (
    message: string,
    successCallback: () => any,
    title?: string
  ) => void;
  closeConfirmation: () => void;
  snackbar: Snackbar;
  handleSnackbar: (type: AlertColor, message: string) => void;
  closeSnackbar: () => void;
  dialog: Dialog[];
  handleDialog: (
    component: Dialog["component"],
    options: Dialog["options"]
  ) => void;
  closeDialog: () => void;
}

export const usePopups = () => useContext(PopupsContext) as UsePopups;
