import React from "react";

// Form Components
import FormContainer from "components/forms/formContainer";
import CTO4Header from "components/formSections/cto4/CTO4Header";
import PatientDetails from "components/formSections/common/patientDetails";
import HospitalDetails from "components/formSections/cto4/hospitalDetails";
import FormLoading from "components/forms/formLoading";

// Custom Imports
import { Actors } from "common/types/statForms";
import {
  useForm,
  useFormAccess,
  useFormValidation,
  useActorTypeStageChange,
  useAuth,
} from "hooks";
import {
  generateFormItems,
  shouldEnableDownload,
  validationSchema,
} from "./helpers";
import { useStyles } from "common/styles/form";
import { isWard } from "utils/auth";
import { LoadingCircle } from "@s12solutions/ui";

/**
 * ------------------------------------------------------------
 * * WORKFLOW FOR CTO4 FORM
 * ------------------------------------------------------------
 * Stages
 * 1/ Clinician creates, signs and emails to MHA Admin
 * 2/ MHA admin downloads
 * ------------------------------------------------------------
 */

const CTO4Form = () => {
  const classes = useStyles();
  const { actor, user } = useAuth();
  const { loading, formState, dispatchFormState, actorType } = useForm();
  const { patientDetails, hospitalDetails } = formState.data;

  const { hasAccess } = useFormAccess("CTO");

  useActorTypeStageChange(actorType, {
    [Actors.Originator]: 0,
    [Actors.MHAAdmin]: 1,
  });

  useFormValidation(validationSchema, [
    { patientDetails },
    { hospitalDetails },
  ]);

  const formItems = generateFormItems();
  const part1Disabled = !!hospitalDetails.signature;

  // Render
  if (loading || !hasAccess) {
    return <LoadingCircle />;
  }
  if (isWard(user) && !actor) {
    return <FormLoading />;
  }

  return (
    <FormContainer
      sections={[
        <>
          <div className={classes.sectionContainer}>
            <CTO4Header />
            <div style={{ height: 20 }} />
            <PatientDetails
              testIdKey="cto4-patient"
              patientDetails={patientDetails}
              dispatchFormState={dispatchFormState}
              disabled={part1Disabled}
            />
          </div>
        </>,
        <div className={classes.sectionContainer}>
          <HospitalDetails
            testIdKey="cto4"
            hospitalDetails={hospitalDetails}
            dispatchFormState={dispatchFormState}
          />
        </div>,
      ]}
      formPartSelectorProps={{
        formItems,
        enableDownload: shouldEnableDownload(formState.data),
      }}
      formNavButtonProps={{
        formItems,
        lastSectionIndex: 1,
      }}
    />
  );
};

export default React.memo(CTO4Form);
