import { useCallback, useEffect } from "react";
import { useAuth, useForm, useUI } from "hooks";
import { isWard } from "utils/auth";
import { useParams } from "react-router-dom";
import { isEmailLinkReviewOnly } from "store/forms/helpers/isEmailLinkReviewOnly";
import generateFormProps from "utils/pdf/generateFormProps";
import { RenderedPDF } from "components/pdf";
import { FormType } from "common/types/pdf";
import { ReviewingType } from "store/ui/uiContext";
import { useHasValueChanged } from "./useHasValueChanges";

/**
 * useRequireActor()
 * - If user is a ward and does not have access to the form, show auth dialog
 * - If the form is an email link, show the pdf preview drawer
 * @returns {void} - void
 */

// NOTE: these are outside the hook as we need to keep track outside of the hook
// to prevent the auth dialog from showing twice and also showing when navigating to
// the dashboard via save & exit functionality.
let runOnceRef = false;
export const useRequireActor = (): void => {
  const { formId, formType, emailLinkId } = useParams();

  const castedFormType = formType as FormType;
  const { user, actor } = useAuth();
  const { formState, handleAuthDialog } = useForm();
  const { currentSectionIndex, setReviewing } = useUI();

  const hasFormIdChanged = useHasValueChanged(formId);

  const handleReview = useCallback(() => {
    // To prevent "double review" for a form section that only has to be signed
    if (isEmailLinkReviewOnly(castedFormType, currentSectionIndex)) {
      return;
    }

    const formProps = generateFormProps(formId, castedFormType, formState.data);

    // trigger pdf drawer via ui context
    setReviewing(<RenderedPDF {...formProps} />, {
      type: ReviewingType.EMAIL_LINK,
    });
  }, [
    castedFormType,
    currentSectionIndex,
    formId,
    formState.data,
    setReviewing,
  ]);

  if (hasFormIdChanged && runOnceRef && !actor) {
    runOnceRef = false;
  }

  // Handle showing auth dialog
  const shouldShowAuthDialog =
    !actor && isWard(user) && !runOnceRef ? true : false;

  const showDialog = useCallback(() => {
    handleAuthDialog("", emailLinkId ? handleReview : () => {});
  }, [emailLinkId, handleAuthDialog, handleReview]);

  useEffect(() => {
    if (!shouldShowAuthDialog) return;
    runOnceRef = true;

    showDialog();
  }, [showDialog, shouldShowAuthDialog]);
};
