import React, { useState, useCallback } from "react";

// Material UI
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Typography, Stack, Button } from "@mui/material";

// Custom Imports
import { useAuth, useForm } from "hooks";
import Action from "common/types/action";
import { AdmissionDetailsType, FormStateActions } from "common/types/h3";
import { useStyles } from "./styles";
import { StyledDatePicker, StyledTimePicker } from "components/uiElements";
import SignatureSection from "components/formSections/common/signatureSection";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

interface AdmissionDetailsProps {
  admission: AdmissionDetailsType;
  dispatchFormState: React.Dispatch<Action>;
}

const AdmissionDetailsPart2: React.FC<AdmissionDetailsProps> = ({
  admission,
  dispatchFormState,
}) => {
  const classes = useStyles();
  const { actor, user } = useAuth();
  const { handleEmail, sectionIndexValidation } = useForm();
  const [signedStatus, setSignedStatus] = useState<boolean>(
    !!admission.signaturePart2
  );

  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed
        ? actor
          ? actor?.name
          : user
          ? user?.attributes?.name
          : ""
        : "";
      dispatchFormState({
        type: FormStateActions.SET_SIGNATURE_PART2,
        payload: signedValue,
      });
    },
    [dispatchFormState, actor, user]
  );

  return (
    <Paper
      elevation={0}
      classes={{
        root: classes.paper,
      }}
    >
      <div style={{ paddingRight: "1rem" }}>
        <Stack spacing={2}>
          <Typography variant="h2" style={{ alignSelf: "center" }}>
            PART 2
          </Typography>

          <div
            style={{
              margin: "8px auto 0px",
              maxWidth: "85%",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h4"
              display="inline"
              className="italic"
              sx={{ textAlign: "center" }}
            >
              (To be completed only if the patient was admitted in pursuance of
              an emergency application under section 4 of the Act)
            </Typography>
          </div>

          <Grid container spacing={3} alignItems="center">
            <Grid
              item
              xs={12}
              style={{ padding: 0, marginTop: 2, marginBottom: 2 }}
            >
              <Typography variant="h4" noWrap sx={{ padding: 0 }}>
                Enter below the date and time the second medical recommendation
                to support the application for the admission of the patient was
                received
              </Typography>
            </Grid>
            <Grid item xs={1} className="text-right">
              <Typography variant="h4">On</Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledDatePicker
                testId="h3-admission-date-part-2"
                label=""
                value={admission.admissionDatePart2}
                disabled={signedStatus}
                onChange={(newValue: any) => {
                  dispatchFormState({
                    type: FormStateActions.SET_DATE_PART2,
                    payload: newValue,
                  });
                }}
                errorKey="admissionDetails.admissionDatePart2"
                customSectionIndexValidation={2}
              />
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h4" className="italic">
                (date)
              </Typography>
            </Grid>

            <Grid item xs={1} className="text-right">
              <Typography variant="h4" noWrap>
                at
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledTimePicker
                testId="h3-admission-time-part-2"
                label=""
                disabled={signedStatus}
                value={admission.admissionTimePart2}
                errorKey="admissionDetails.admissionTimePart2"
                customSectionIndexValidation={2}
                onChange={(newValue: any) => {
                  dispatchFormState({
                    type: FormStateActions.SET_TIME_PART2,
                    payload: newValue,
                  });
                }}
                validateWith={{
                  admissionDatePart2: admission.admissionDatePart2,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h4" noWrap className="italic">
                (time)
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">
              I received, on behalf of the hospital managers, the second medical
              recommendation in support of the application for the admission of
              the above named patient.{" "}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <SignatureSection
              name={admission.signaturePart2}
              date={admission.signatureDatePart2}
              description="on behalf of the hospital managers"
              signedStatus={signedStatus}
              customSectionIndexValidation={sectionIndexValidation[2]}
              setSignedStatus={handleSetSignedStatus}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              className="text-center"
              sx={{ maxWidth: "80%", margin: "0 auto" }}
            >
              <strong>
                NOTE: IF THE PATIENT IS BEING DETAINED AS A RESULT OF A TRANSFER
                FROM GUARDIANSHIP, THE PATIENT'S ADMISSION SHOULD BE RECORDED IN
                PART 2 OF THE FORM G8 WHICH AUTHORISED THE TRANSFER.
              </strong>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  data-test-id="h3-send-form"
                  variant="contained"
                  onClick={() => handleEmail({ includePDFAttachment: true })}
                  disabled={!signedStatus}
                  size="large"
                  sx={{ mr: 1 }}
                >
                  Send to MHA Admin
                </Button>
              </Grid>
              <Grid item>
                <PdfDownloadButton disabled={!signedStatus} />
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </div>
    </Paper>
  );
};

export default React.memo(AdmissionDetailsPart2);
