import * as Yup from "yup";
import {
  Actors,
  FormStatus,
  FormState,
  FormItemType,
  ClinicianDetailsType,
} from "common/types/statForms";
import {
  CTO12Form,
  PatientDetails,
  ClinicianSignature,
} from "common/types/cto12";
import {
  clinicianDetailsValidation,
  patientDetailsFullValidation,
} from "common/libs/validation";
import { CognitoUserExt } from "common/types/auth";
import { isDoctor, isWard } from "utils/auth";

export const handleStatus = (formState: FormState<CTO12Form>): FormStatus => {
  const { clinicianSignature } = formState.data;
  if (!clinicianSignature.signature) {
    return FormStatus.s10_draft;
  }
  return FormStatus.s40_signed;
};

/**
 * getActor
 * - if the user is a doctor -> stage 1 index 0
 * - if the user is a ward the doctor and part 2 is signed -> stage 2 index 1
 */
export const getActor = (
  formState: FormState<CTO12Form>,
  user: CognitoUserExt
): Actors | boolean => {
  const { clinicianSignature } = formState.data;

  if (isDoctor(user)) {
    return Actors.Originator;
  }

  if (isWard(user) && clinicianSignature.signature) {
    return Actors.MHAAdmin;
  }

  return false;
};

export const generateFormItems = (actor: Actors): FormItemType[] => [
  /** TO BE COMPLETED BY: Responsible Clinician */
  {
    index: 0,
    itemText: "1. Clinician Details",
    disabled: actor !== Actors.Originator,
    errorKey: "clinicianDetails",
  },
  {
    index: 1,
    itemText: "2. Patient Details",
    errorKey: "patientDetails",
  },
];

export const validationSchema = [
  Yup.object().shape(clinicianDetailsValidation),
  Yup.object().shape({
    ...patientDetailsFullValidation,
    capacity: Yup.string().required("Capacity is required"),
    description: Yup.string()
      .min(60, "Description needs to be greater than 60 characters")
      .required("Description is required"),
  }),
];

export const hasErrorsSection1 = (props: ClinicianDetailsType): boolean => {
  const {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    email,
  } = props;
  let error = false;

  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;
  if (!email) error = true;

  return error;
};

export const hasErrorsSection2 = (
  patientDetails: PatientDetails,
  clinicianSignature: ClinicianSignature,
  requireSignature: boolean = true
): boolean => {
  const {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    capacity,
    description,
  } = patientDetails;
  const { signature, date } = clinicianSignature;
  let error = false;

  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;
  if (!capacity) error = true;
  if (!description) error = true;
  if (requireSignature && !signature && !date) error = true;

  return error;
};

export const shouldDisablePrev = (
  actor: Actors,
  currentIndex: number
): boolean => {
  if (actor === Actors.MHAAdmin && currentIndex === 1) {
    return true;
  }

  return false;
};

export const shouldEnableDownload = (data: CTO12Form): boolean => {
  const { clinicianDetails, patientDetails, clinicianSignature } = data;

  if (
    hasErrorsSection1(clinicianDetails) ||
    hasErrorsSection2(patientDetails, clinicianSignature)
  ) {
    return false;
  }

  return true;
};
