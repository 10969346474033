import React, { useState, useCallback } from "react";

// Material UI Imports
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Custom Imports
import {
  FormStateActions,
  FurnishingReport as FurnishingReportType,
} from "common/types/cto7";
import Action from "common/types/action";
import { useAuth, useForm } from "hooks";
import { formatUserName } from "common/libs/formatUserName";
import SignatureSection from "components/formSections/common/signatureSection";
import Paper from "components/paper";
import { StyledInput } from "components/uiElements";
import Tooltip from "components/tooltip";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

interface FurnishingReportProps {
  testIdKey: string;
  furnishingReport: FurnishingReportType;
  dispatchFormState: React.Dispatch<Action>;
}
const FurnishingReport: React.FC<FurnishingReportProps> = ({
  testIdKey,
  furnishingReport: { consultedName, consultedProfession, signature, date },
  dispatchFormState,
}) => {
  const { user } = useAuth();
  const { handleEmail } = useForm();
  const [signedStatus, setSignedStatus] = useState<boolean>(!!signature);

  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed ? formatUserName(user) : "";

      dispatchFormState({
        type: FormStateActions.SET_FURNISHING_SIGNATURE,
        payload: signedValue,
      });
    },
    [dispatchFormState, user]
  );

  return (
    <Paper>
      <Stack spacing={3} mb={3}>
        <Typography variant="h4" display="inline">
          Before furnishing this report, I consulted
        </Typography>
        <Stack flexDirection="row" alignItems="center">
          <Typography variant="h4" display="inline" className="italic">
            (PRINT full name and profession of person consulted){" "}
          </Typography>
          <Tooltip text="If consulting a professional who is an AMHP, remember that ‘AMHP’ is not a profession. An AMHP may be a: social worker, nurse, OT or psychologist by profession." />
        </Stack>
        <StyledInput
          testId={`${testIdKey}-name`}
          label="Full Name"
          value={consultedName}
          onChange={(value: any) =>
            dispatchFormState({
              type: FormStateActions.SET_FURNISHING_CONSULTED_NAME,
              payload: value,
            })
          }
          disabled={signedStatus}
          errorKey="furnishingReport.consultedName"
        />
        <StyledInput
          testId={`${testIdKey}-profession`}
          label="Profession"
          value={consultedProfession}
          onChange={(value: any) =>
            dispatchFormState({
              type: FormStateActions.SET_FURNISHING_CONSULTED_PROFESSION,
              payload: value,
            })
          }
          errorKey="furnishingReport.consultedProfession"
          disabled={signedStatus}
        />
        <Typography variant="h4" display="inline">
          who has been professionally concerned with the patient's treatment
        </Typography>
        <Typography variant="h4" display="inline">
          I am furnishing this report by:{" "}
          <span className="italic">(Delete the phrase which do not apply)</span>
        </Typography>
        <Stack sx={{ paddingLeft: "2rem" }}>
          <Typography variant="h4" className="strikeThrough">
            today consigning it to the hospital managers’ internal mail system.
          </Typography>
          <Typography variant="h4">
            today sending it to the hospital managers, or a person authorised by
            them to receive it, by means of electronic communication.
          </Typography>
          <Typography variant="h4" className="strikeThrough">
            sending or delivering it without using the hospital managers’
            internal mail system.
          </Typography>
        </Stack>
      </Stack>
      <SignatureSection
        name={signature}
        date={date}
        signedStatus={signedStatus}
        setSignedStatus={handleSetSignedStatus}
      />
      <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
        <Button
          data-test-id="cto7-email-form-part-3"
          variant="contained"
          onClick={() => handleEmail()}
          disabled={!signedStatus}
          size="large"
          sx={{ mr: 1 }}
        >
          Send to Hospital Managers
        </Button>
        <PdfDownloadButton disabled={!signedStatus} />
      </Stack>
    </Paper>
  );
};

export default React.memo(FurnishingReport);
