import React, { useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import clsx from "clsx";

// Material UI
import { Switch, Typography, Stack, Button } from "@mui/material";

// Custom Imports
import {
  StyledDatePicker,
  StyledTimePicker,
  StyledSelect,
} from "components/uiElements";

import { useAuth, useDeviceType, useForm } from "hooks";
import Action from "common/types/action";
import { HandleEmailParams } from "store/forms/types";
import { AdmissionDetailsType, FormStateActions } from "common/types/h3";
import SignatureSection from "components/formSections/common/signatureSection";
import { useStyles } from "./styles";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

const getEmailParams = (isSection4Form: boolean): HandleEmailParams => {
  return isSection4Form
    ? {
        sectionIndex: 1,
      }
    : {
        includePDFAttachment: true,
      };
};

interface AdmissionDetailsProps {
  admission: AdmissionDetailsType;
  dispatchFormState: React.Dispatch<Action>;
}

const AdmissionDetails: React.FC<AdmissionDetailsProps> = ({
  admission,
  dispatchFormState,
}) => {
  const classes = useStyles();
  const { isMobile } = useDeviceType();
  const { actor, user } = useAuth();
  const { handleEmail, formErrors } = useForm();
  const [signedStatus, setSignedStatus] = useState<boolean>(
    !!admission.signaturePart1
  );

  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed
        ? actor
          ? actor?.name
          : user
          ? user?.attributes?.name
          : ""
        : "";

      dispatchFormState({
        type: FormStateActions.SET_SIGNATURE_PART1,
        payload: signedValue,
      });
    },
    [dispatchFormState, actor, user]
  );

  return (
    <Paper
      elevation={0}
      classes={{
        root: classes.paper,
      }}
    >
      <div style={{ paddingRight: "1rem" }}>
        <Stack spacing={3}>
          <Typography variant="h4" display="inline">
            Is the patient already an in-patient in the hospital?
          </Typography>
          <Stack direction={"row"} spacing={2} alignItems="center">
            <Typography
              variant="h4"
              display="inline"
              className={clsx({ [classes.inPatient]: admission.inPatient })}
            >
              No
            </Typography>
            <Switch
              data-test-id="h3-admission-date-in-patient-switch"
              checked={admission.inPatient}
              disabled={signedStatus}
              onChange={(e) =>
                dispatchFormState({
                  type: FormStateActions.SET_IN_PATIENT,
                  payload: e.target.checked,
                })
              }
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography
              variant="h4"
              display="inline"
              className={clsx({ [classes.inPatient]: admission.inPatient })}
            >
              Yes
            </Typography>
          </Stack>

          {/* Start of section a - the patient is not an inpatient */}
          {!admission.inPatient && (
            <>
              <Typography
                data-test-id="section-a-heading"
                variant="h4"
                display="inline"
              >
                The above named patient was admitted to hospital on
              </Typography>
              <Grid container spacing={3} alignItems="center">
                {!isMobile && (
                  <Grid item xs={1}>
                    {" "}
                  </Grid>
                )}
                <Grid item xs={12} md={4}>
                  <StyledDatePicker
                    testId="h3-admission-date-section-a"
                    label="Admission Date"
                    errorKey="admissionDetails.admissionDate"
                    value={admission.admissionDate}
                    disabled={signedStatus}
                    onChange={(newValue: any) => {
                      dispatchFormState({
                        type: FormStateActions.SET_DATE,
                        payload: newValue,
                      });
                    }}
                    validateWith={{ admissionDate: admission.admissionTime }}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography variant="h4" noWrap>
                    (date of admission to hospital)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Typography variant="h4" noWrap>
                    at
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledTimePicker
                    testId="h3-admission-time-section-a"
                    label="Admission Time"
                    errorKey="admissionDetails.admissionTime"
                    value={admission.admissionTime}
                    disabled={signedStatus}
                    onChange={(newValue: any) => {
                      dispatchFormState({
                        type: FormStateActions.SET_TIME,
                        payload: newValue,
                      });
                    }}
                    validateWith={{ admissionDate: admission.admissionDate }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="h4" noWrap>
                    (time)
                  </Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: 1 }}>
                  <Typography variant="h4" sx={{ marginBottom: 1 }}>
                    in pursuance of an application for admission under section{" "}
                    <span style={{ fontStyle: "italic" }}>(state section)</span>
                  </Typography>
                  <StyledSelect
                    testId="h3-section-a"
                    label=""
                    disabled={signedStatus}
                    errorKey="admissionDetails.section"
                    menuItems={[
                      { key: "2", value: "2" },
                      { key: "3", value: "3" },
                      { key: "4", value: "4" },
                    ]}
                    value={admission.section}
                    onChange={(e: any) => {
                      dispatchFormState({
                        type: FormStateActions.SET_SECTION,
                        payload: e.target.value,
                      });
                    }}
                  />
                  <Typography variant="h4" noWrap sx={{ marginTop: 1 }}>
                    of the Mental Health Act 1983.
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {/* Start of section b - the patient IS already in the hospital */}
          {admission.inPatient && (
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography
                data-test-id="section-b-heading"
                variant="h4"
                className="italic"
              >
                Enter here the section which has just been imposed, not any
                section to which the patient was already subject.
              </Typography>
              <Grid item xs={12}>
                <StyledSelect
                  testId="h3-section-b"
                  id="section-B"
                  label=""
                  className={classes.input}
                  disabled={signedStatus}
                  value={admission.section}
                  error={!!formErrors?.["admissionDetails.section"]}
                  errorText={formErrors?.["admissionDetails.section"]}
                  onChange={(e: any) => {
                    dispatchFormState({
                      type: FormStateActions.SET_SECTION,
                      payload: e.target.value,
                    });
                  }}
                  menuItems={[
                    { key: "2", value: "2" },
                    { key: "3", value: "3" },
                    { key: "4", value: "4" },
                  ]}
                />
                <Typography variant="h4" sx={{ marginTop: 1 }}>
                  of the Mental Health Act 1983 was received by me on behalf of
                  the hospital managers on
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <StyledDatePicker
                  testId="h3-admission-date-section-b"
                  label="Date section imposed"
                  value={admission.admissionDate}
                  disabled={signedStatus}
                  onChange={(newValue: any) => {
                    dispatchFormState({
                      type: FormStateActions.SET_DATE,
                      payload: newValue,
                    });
                  }}
                  error={!!formErrors?.["admissionDetails.admissionDate"]}
                  errorText={formErrors?.["admissionDetails.admissionDate"]}
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <Typography variant="h4" noWrap>
                  (date)
                </Typography>
              </Grid>
              <Grid item xs={12} md={1}>
                <Typography
                  variant="h4"
                  noWrap
                  className={isMobile ? "" : "text-right"}
                >
                  at
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <StyledTimePicker
                  testId="h3-admission-time-section-b"
                  label="Time section imposed"
                  value={admission.admissionTime}
                  disabled={signedStatus}
                  onChange={(newValue: any) => {
                    dispatchFormState({
                      type: FormStateActions.SET_TIME,
                      payload: newValue,
                    });
                  }}
                  error={!!formErrors?.["admissionDetails.admissionTime"]}
                  errorText={formErrors?.["admissionDetails.admissionTime"]}
                  validateWith={{ admissionDate: admission.admissionDate }}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h4" noWrap>
                  (time)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4">
                  and the patient was accordingly treated as admitted for the
                  purpose of the Act from that time.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" className="italic">
                  This form can be signed and dated later than the admission
                  time. If you are completing this form some time after the
                  admission, enter the correct date and time of admission. Your
                  signature date can be later than the admission date.
                </Typography>
              </Grid>
            </Grid>
          )}

          {/* Start of the signature section relating to part 1*/}
          <Grid item xs={12}>
            <SignatureSection
              name={admission.signaturePart1}
              date={admission.signatureDatePart1}
              description="on behalf of the hospital managers"
              signedStatus={signedStatus}
              setSignedStatus={handleSetSignedStatus}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  data-test-id="h3-send-form"
                  variant="contained"
                  onClick={() =>
                    handleEmail(getEmailParams(admission.section === "4"))
                  }
                  disabled={!signedStatus}
                  size="large"
                  sx={{ mr: 1 }}
                >
                  Send to{" "}
                  {admission.section === "4" ? "Hospital Manager" : "MHA Admin"}
                </Button>
              </Grid>
              <Grid item>
                <PdfDownloadButton disabled={!signedStatus} />
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </div>
    </Paper>
  );
};

export default AdmissionDetails;
