import { CTO7Form } from "common/types/cto7";
import { formatInitialFormState } from "../helpers";

export const data: CTO7Form = {
  hospitalDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
  },
  clinicianDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
    email: "",
  },
  patientDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
    ctoCreated: "",
    examined: "",
  },
  opinion: {
    for: [],
    details: "",
    signature: "",
    trackingId: "",
    date: "",
  },
  amhpDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
    email: "",
    authorityName: "",
    otherAuthority: undefined,
    onBehalfOf: undefined,
    signature: "",
    trackingId: "",
    date: "",
  },
  furnishingReport: {
    consultedName: "",
    consultedProfession: "",
    signature: "",
    trackingId: "",
    date: "",
  },
  wardSignature: {
    signature: "",
    trackingId: "",
    date: "",
  },
};

export const cto7InitialState = formatInitialFormState(data);
