import { FC, memo, useCallback, useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Stack,
} from "@mui/material";
import {
  MutationDisableEmailLinkArgs,
  StatFormEmail,
} from "@s12solutions/types";
import { useAPI, usePopups } from "hooks";
import { DialogButtonRow } from "components/dialog";
import EmailStatForm from "../emailStatForm";
import { Methods } from "api";

/**
 * useParseEmailLink
 * This function will parse the email link into usable variables
 * `formType`, `formId` and `formStep`
 */
const useParseEmailLink = (emailLink: StatFormEmail["emailLink"]) => {
  // First remove https:// from string (and http:// for sandboxes)
  const formattedEmailLink = emailLink.replace(/^https?:\/\//, "");
  const emailLinkParts = formattedEmailLink.split("/");

  // Assign `emailLinkParts` to variables
  const formType = emailLinkParts[1];
  const formId = emailLinkParts[2];
  // 👇 Reason for -1 on `formStep`:
  // `EmailStatForm` dialog will increment (as if you are sending the next stage to another user)
  // we are parsing the original email link which has the correct step we want. Therefore -1
  const formStep = Number(emailLinkParts[3]) - 1;

  return {
    formType,
    formId,
    formStep,
  };
};

type DisableEmailLinkProps = {
  /**
   * @param {StatFormEmail["id"]} emailLinkId - this is used to disable the link
   */
  emailLinkId: StatFormEmail["id"];
  /**
   * @param {StatFormEmail["emailLink"]} emailLink - we can reliably get all the information
   * we need to resend email via the email link, this will save a bit of prop stuffing
   */
  emailLink: StatFormEmail["emailLink"];
  /**
   * @param {() => void} onSuccess - this will be called when the email link is disabled
   * with the formId
   */
  onSuccess: () => void;
};

const DisableEmailLink: FC<DisableEmailLinkProps> = ({
  emailLinkId,
  emailLink,
  onSuccess,
}) => {
  const { closeDialog, handleDialog, handleSnackbar } = usePopups();
  const emailStatFormProps = useParseEmailLink(emailLink);
  const [shouldSendEmail, setShouldSendEmail] = useState<boolean>(true);

  const { trigger: disableEmailLink } = useAPI<
    boolean,
    MutationDisableEmailLinkArgs
  >({
    method: Methods.POST,
    fieldName: "disableEmailLink",
    args: {
      id: emailLinkId,
    },
    onCompleted: () => {
      onSuccess();
      handleSnackbar("success", "You have successfully disabled email link");
    },
  });

  const handleSubmit = useCallback(() => {
    disableEmailLink();
    closeDialog();

    if (!shouldSendEmail) return;

    handleDialog(
      <EmailStatForm
        {...emailStatFormProps}
        isFinalPdf={false}
        onSuccess={onSuccess}
      />,
      {
        title: "Send Email",
      }
    );
  }, [
    closeDialog,
    disableEmailLink,
    emailStatFormProps,
    handleDialog,
    onSuccess,
    shouldSendEmail,
  ]);

  return (
    <Stack spacing={3}>
      <FormControl>
        <FormLabel>
          Do you want to send this form to another recipient?
        </FormLabel>
        <RadioGroup
          defaultValue={shouldSendEmail}
          onChange={(e) => {
            // JSON.parse because `e.target.value`returns true/false as a string
            setShouldSendEmail(JSON.parse(e.target.value));
          }}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      <DialogButtonRow
        onSuccess={handleSubmit}
        submitButtonText="Disable email link"
      />
    </Stack>
  );
};

export default memo(DisableEmailLink);
