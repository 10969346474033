import React from "react";
import { makeStyles } from "@mui/styles";
import { Select, SelectProps, MenuItem, InputLabel } from "@mui/material";

import { useForm } from "hooks";
import ErrorLabel from "./ErrorLabel";

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    borderRadius: ".5rem",
    backgroundColor: theme.palette.common.white,
  },
  inputDisabled: {
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    borderRadius: ".5rem",
  },
  errorText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.error.dark,
    fontWeight: "bold",
  },
}));

interface StyledSelectProps {
  testId?: string;
  disabled?: boolean;
  value: any;
  label: string;
  onChange: any;
  errorText?: string;
  errorKey?: string;
  menuItems: { key: any; value: string }[];
}

const StyledSelect: React.FC<StyledSelectProps & SelectProps> = (
  {
    testId,
    disabled,
    value,
    label,
    onChange,
    errorKey,
    error,
    errorText,
    menuItems,
  },
  { ...rest }
) => {
  const classes = useStyles();
  const { formErrors, handleValidateValue } = useForm();

  return (
    <div>
      {label && (
        <InputLabel error={error} sx={{ fontSize: ".8rem" }}>
          {label}
        </InputLabel>
      )}
      <Select
        data-test-id={testId}
        color="primary"
        disabled={disabled}
        className={classes.select}
        autoComplete="off"
        value={value}
        onChange={async (e) => {
          if (errorKey) {
            await handleValidateValue(errorKey, e.target.value);
          }

          onChange(e);
        }}
        error={(errorKey && formErrors?.[errorKey]) || error}
        {...rest}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} value={item.key}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
      <ErrorLabel error={error} errorText={errorText} errorKey={errorKey} />
    </div>
  );
};

export { StyledSelect };
