import React from "react";

import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";

import { useForm } from "hooks";
import ErrorLabel from "./ErrorLabel";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  inputDisabled: {
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    borderRadius: ".5rem",
  },
  errorText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.error.dark,
    fontWeight: "bold",
  },
  dateTime: {
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  timePicker: {
    "& MuiOutlinedInput-notchedOutline": {
      borderColor: "red !important",
    },
  },
}));

interface StyledTimePickerProps {
  testId?: string;
  disabled?: boolean;
  value: number | string | null;
  label: string;
  onChange: any;
  errorKey?: string;
  customSectionIndexValidation?: number;
  error?: boolean;
  errorText?: string;
  /**
   * @param { {[key: string]: any} } validateWith
   * Sometimes we need more flexibility with input validation, e.g, when the input validation
   * relies on another value
   */
  validateWith?: { [key: string]: any };
}

const StyledTimePicker: React.FC<StyledTimePickerProps> = (
  {
    testId,
    disabled,
    value,
    label,
    onChange,
    errorKey,
    customSectionIndexValidation,
    error,
    errorText,
    validateWith,
  },
  { ...rest }
) => {
  const classes = useStyles();
  const { formErrors, handleValidateValue } = useForm();

  return (
    <div>
      <TextField
        data-test-id={testId}
        type="time"
        label={label}
        value={value}
        disabled={disabled}
        onChange={async (e) => {
          const { value } = e.target;

          onChange(value);

          if (errorKey) {
            let validateValue;

            if (validateWith) {
              const errorKeys = errorKey.split(".");
              const validateName = errorKeys[errorKeys.length - 1];
              validateValue = {
                ...validateWith,
                [validateName]: value,
              };
            } else {
              validateValue = value;
            }

            await handleValidateValue(errorKey, validateValue);
          }
        }}
        className={classes.dateTime}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        error={(errorKey && !!formErrors?.[errorKey]) || error}
      />
      <ErrorLabel error={error} errorText={errorText} errorKey={errorKey} />
    </div>
  );
};

export { StyledTimePicker };
