/**
 * -------------------------------------
 * * Dialog Button Row Component
 * -------------------------------------
 * This component is for usage within a dialog, it is a cancel and submit button row
 * which can be customised
 *
 * ? Usage:
 *
 *  <>
 *      <DialogButtonRow
 *        loading={formik.isSubmitting}
 *        disableSubmit={disableSubmit}
 *        submitButtonText="Send"
 *        onSuccess={formik.handleSubmit}
 *        onCancel={closeDialog}
 *      />
 *  </>
 *
 * * Note: to see what options are available see the types below (DialogButtonRowProps)
 * -------------------------------------
 */

import React, { useCallback } from "react";
import { Button, DialogActions } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { usePopups } from "hooks";

interface DialogButtonRowProps {
  /**
   * @param {string | undefined} testIdKey - this will prefix the cancel
   * and submit button `data-test-id` attribute
   */
  testIdKey?: string;
  onSuccess: Function;
  onCancel?: Function;
  loading?: boolean;
  disableSubmit?: boolean;
  submitButtonText?: string;
}

const DialogButtonRow: React.FC<DialogButtonRowProps> = ({
  testIdKey,
  onSuccess,
  onCancel = () => {},
  loading = false,
  disableSubmit = false,
  submitButtonText = "Submit",
}) => {
  const { closeDialog } = usePopups();
  const handleCancel = useCallback(() => {
    onCancel();
    closeDialog();
  }, [onCancel, closeDialog]);
  const handleSuccess = useCallback(() => {
    onSuccess();
  }, [onSuccess]);

  const cancelButtonProps = {
    onClick: handleCancel,
    variant: "outlined" as const,
    ...(testIdKey && { "data-test-id": `${testIdKey}-cancel` }),
  };

  const submitButtonProps = {
    loading,
    onClick: handleSuccess,
    variant: "contained" as const,
    color: "primary" as const,
    disabled: disableSubmit,
    ...(testIdKey && { "data-test-id": `${testIdKey}-submit` }),
  };

  return (
    <DialogActions sx={{ padding: 0 }}>
      <Button {...cancelButtonProps}>Cancel</Button>
      <LoadingButton {...submitButtonProps}>{submitButtonText}</LoadingButton>
    </DialogActions>
  );
};

export default React.memo(DialogButtonRow);
