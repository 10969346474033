import * as Yup from "yup";
import {
  Actors,
  FormStatus,
  FormState,
  FormItemType,
  ClinicianDetailsType,
} from "common/types/statForms";
import { Opinion } from "common/types/cto";
import {
  AMHPDetails,
  CTO7Form,
  HospitalDetails,
  PatientDetails,
  FurnishingReport,
} from "common/types/cto7";
import {
  amhpDetailsValidation,
  clinicianDetailsValidation,
  dateValidation,
  hospitalDetailsValidation,
  opinionValidation,
  patientDetailsFullValidation,
} from "common/libs/validation";
import { CognitoUserExt } from "common/types/auth";
import { isAmhp, isDoctor, isWard } from "utils/auth";

export const handleStatus = (formState: FormState<CTO7Form>): FormStatus => {
  const { opinion } = formState.data;

  if (!opinion.signature) return FormStatus.s10_draft;

  return FormStatus.s40_signed;
};

/**
 * getActor
 * - if the user is a doctor, and part 2 is not signed -> stage 1 index 0
 * - if the user is an amhp, part 1 is signed and part 3 is not signed -> stage 2 index 4
 * - if the user is a doctor and part 2 is signed -> stage 3 index 5
 * - if the user is a ward and part 3 is signed -> stage 4 index 6
 */
export const getActor = (
  formState: FormState<CTO7Form>,
  user: CognitoUserExt
): Actors | false => {
  const { opinion, amhpDetails, furnishingReport } = formState.data;

  if (isDoctor(user) && !amhpDetails.signature) {
    return Actors.Originator;
  }

  if (isAmhp(user) && opinion.signature) {
    return Actors.AMHP;
  }

  if (isDoctor(user) && amhpDetails.signature) {
    return Actors.Clinician;
  }

  if (isWard(user) && furnishingReport.signature) {
    return Actors.MHAAdmin;
  }

  return false;
};

export const generateFormItems = (actor: Actors): FormItemType[] => [
  /** TO BE COMPLETED BY: Responsible Clinician */
  {
    index: 0,
    itemText: "1. Hospital Details",
    disabled: actor !== Actors.Originator,
    errorKey: "hospitalDetails",
  },
  {
    index: 1,
    itemText: "2. Clinician Details",
    disabled: actor !== Actors.Originator,
    errorKey: "clinicianDetails",
  },
  {
    index: 2,
    itemText: "3. Patient Details",
    disabled: actor !== Actors.Originator,
    errorKey: "patientDetails",
  },
  {
    index: 3,
    itemText: "4. Opinion",
    disabled: actor !== Actors.Originator,
    errorKey: "opinion",
  },
  /** TO BE COMPLETED BY: AMHP */
  {
    index: 4,
    itemText: "5. AMHP Details",
    disabled: actor !== Actors.AMHP,
    errorKey: "amhpDetails",
  },
  /** TO BE COMPLETED BY: Responsible Clinician */
  {
    index: 5,
    itemText: "6. Furnishing Report",
    disabled: actor !== Actors.Clinician,
    errorKey: "furnishingReport",
  },
  /** TO BE COMPLETED BY: MHA Admin */
  {
    index: 6,
    itemText: "7. Hospital Signature",
    disabled: actor !== Actors.MHAAdmin,
  },
];

export const validationSchema = [
  Yup.object().shape(hospitalDetailsValidation),
  Yup.object().shape(clinicianDetailsValidation),
  Yup.object().shape({
    ...patientDetailsFullValidation,
    ctoCreated: dateValidation("CTO created date", {
      allowGreaterThan1YearAgo: true,
      allowFuture: false,
    }),
    examined: dateValidation("Examination date", {
      allowGreaterThan1YearAgo: true,
      allowFuture: false,
    }),
  }),
  Yup.object().shape(opinionValidation),
  Yup.object().shape({
    ...amhpDetailsValidation,
    email: Yup.string()
      .email("AMHP email is not valid")
      .required("AMHP email is required"),
  }),
  Yup.object().shape({
    consultedName: Yup.string().required("Name of consultant is required"),
    consultedProfession: Yup.string().required(
      "Profession of consultant is required"
    ),
  }),
];

export const hasErrorsSection1 = (props: HospitalDetails): boolean => {
  const { name, address, manualAddress1, manualAddress2, manualPostcode } =
    props;
  let error = false;

  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;

  return error;
};
export const hasErrorsSection2 = (props: ClinicianDetailsType): boolean => {
  const {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    email,
  } = props;
  let error = false;

  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;
  if (!email) error = true;

  return error;
};
export const hasErrorsSection3 = (props: PatientDetails): boolean => {
  const {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    ctoCreated,
    examined,
  } = props;
  let error = false;

  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;
  if (!ctoCreated) error = true;
  if (!examined) error = true;

  return error;
};
export const hasErrorsSection4 = (props: Opinion): boolean => {
  const { for: forValue, details } = props;
  let error = false;
  if (!forValue) error = true;
  if (!details) error = true;

  return error;
};

export const hasErrorsSection5 = (
  props: AMHPDetails,
  requireSignature: boolean = true
): boolean => {
  const {
    name,
    address,
    email,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    authorityName,
    signature,
  } = props;
  let error = false;
  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;
  if (!email) error = true;
  if (!authorityName) error = true;
  if (requireSignature && !signature) error = true;

  return error;
};
export const hasErrorsSection6 = (
  props: FurnishingReport,
  requireSignature: boolean = true
): boolean => {
  const { consultedName, consultedProfession, signature } = props;
  let error = false;
  if (!consultedName) error = true;
  if (!consultedProfession) error = true;
  if (requireSignature && !signature) error = true;

  return error;
};

export const shouldDisableNext = (
  currentIndex: number,
  actor: Actors
): boolean => {
  switch (currentIndex) {
    case 3:
      return actor !== Actors.AMHP;
    case 4:
      return actor !== Actors.Clinician;
    case 5:
      return actor !== Actors.MHAAdmin;
    default:
      return false;
  }
};

export const shouldDisablePrev = (
  actor: Actors,
  currentIndex: number
): boolean => {
  if (actor === Actors.AMHP && currentIndex === 4) return true;
  if (actor === Actors.Clinician && currentIndex === 5) return true;
  if (actor === Actors.MHAAdmin && currentIndex === 6) return true;

  return false;
};

export const shouldEnableDownload = (data: CTO7Form): boolean => {
  const {
    hospitalDetails,
    clinicianDetails,
    patientDetails,
    opinion,
    amhpDetails,
    furnishingReport,
  } = data;

  if (
    hasErrorsSection1(hospitalDetails) ||
    hasErrorsSection2(clinicianDetails) ||
    hasErrorsSection3(patientDetails) ||
    hasErrorsSection4(opinion) ||
    hasErrorsSection5(amhpDetails) ||
    hasErrorsSection6(furnishingReport)
  ) {
    return false;
  }

  return true;
};
