import React from "react";

// Form Components
import FormContainer from "components/forms/formContainer";

import CTO3Header from "components/formSections/cto3/CTO3Header";
import PatientDetails from "components/formSections/cto3/patientDetails";
import Opinion from "components/formSections/cto3/opinion";
import FormLoading from "components/forms/formLoading";

// Custom Imports
import { useForm, useFormValidation, useFormAccess, useAuth } from "hooks";
import {
  generateFormItems,
  shouldEnableDownload,
  validationSchema,
} from "./helpers";
import { useStyles } from "common/styles/form";
import { isWard } from "utils/auth";
import { LoadingCircle } from "@s12solutions/ui";

/**0.
 * ------------------------------------------------------------
 * * WORKFLOW FOR CTO3 FORM
 * ------------------------------------------------------------
 * Stages
 * 1/ Responsible Clinician creates and signs
 * ------------------------------------------------------------
 */

const CTO3Form = () => {
  const classes = useStyles();
  const { actor, user } = useAuth();
  const { loading, formState, dispatchFormState } = useForm();
  const { patientDetails, hospitalDetails, opinion } = formState.data;
  const { hasAccess } = useFormAccess("CTO");

  useFormValidation(validationSchema, [
    { section1: { patientDetails, hospitalDetails } },
    { opinion },
  ]);

  const formItems = generateFormItems();
  const part1Disabled = !!opinion.signature;

  // Render
  // Render
  if (loading || !hasAccess) {
    return <LoadingCircle />;
  }
  if (isWard(user) && !actor) {
    return <FormLoading />;
  }

  return (
    <FormContainer
      sections={[
        <>
          <div className={classes.sectionContainer}>
            <CTO3Header />
            <PatientDetails
              testIdKey="cto3-patient"
              patientDetails={patientDetails}
              hospitalDetails={hospitalDetails}
              dispatchFormState={dispatchFormState}
              disabled={part1Disabled}
            />
          </div>
        </>,
        <>
          <div className={classes.sectionContainer}>
            <Opinion
              testIdKey="cto3-opinion"
              opinion={opinion}
              dispatchFormState={dispatchFormState}
            />
          </div>
        </>,
      ]}
      formPartSelectorProps={{
        formItems,
        enableDownload: shouldEnableDownload(formState.data),
      }}
      formNavButtonProps={{
        formItems,
        lastSectionIndex: 1,
      }}
    />
  );
};

export default React.memo(CTO3Form);
