import React from "react";

// Material UI
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";

// Custom
import { useForm } from "hooks";
import ErrorLabel from "./ErrorLabel";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  inputDisabled: {
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    borderRadius: ".5rem",
  },
  errorText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.error.dark,
    fontWeight: "bold",
  },
  dateTime: {
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  datePicker: {
    "& MuiOutlinedInput-notchedOutline": {
      borderColor: "red !important",
    },
  },
}));

interface StyledDatePickerProps {
  testId?: string;
  disabled?: boolean;
  value: number | string | null | undefined;
  label: string;
  onChange: any;
  customSectionIndexValidation?: number;
  errorKey?: string;
  error?: boolean | undefined;
  errorText?: string;
  disableFuture?: boolean;
  /**
   * @param { {[key: string]: any} } validateWith
   * Sometimes we need more flexibility with input validation, e.g, when the input validation
   * relies on another value
   */
  validateWith?: { [key: string]: any };
}

const StyledDatePicker: React.FC<StyledDatePickerProps> = ({
  testId,
  disabled,
  value,
  label,
  onChange,
  error,
  errorText,
  errorKey,
  customSectionIndexValidation,
  disableFuture = false,
  validateWith,
  ...props
}) => {
  const classes = useStyles();
  const { formErrors, handleValidateValue } = useForm();

  return (
    <div>
      <DatePicker
        maxDate={disableFuture ? new Date() : undefined}
        label={label}
        value={value}
        inputFormat="DD-MM-YYYY"
        disabled={disabled}
        onChange={async (value) => {
          onChange(value);

          if (errorKey) {
            let validateValue;

            if (validateWith) {
              const errorKeys = errorKey.split(".");
              const validateName = errorKeys[errorKeys.length - 1];
              validateValue = {
                ...validateWith,
                [validateName]: value,
              };
            } else {
              validateValue = value;
            }

            await handleValidateValue(errorKey, validateValue);
          }
        }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            data-test-id={testId}
            className={classes.dateTime}
            InputLabelProps={{
              shrink: true,
            }}
            error={(errorKey && formErrors?.[errorKey]) || error}
            fullWidth
          />
        )}
        {...props}
      />
      <ErrorLabel error={error} errorText={errorText} errorKey={errorKey} />
    </div>
  );
};

export { StyledDatePicker };
