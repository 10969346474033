import React, { useState, useCallback } from "react";

// Material UI
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Custom imports
import Action from "common/types/action";
import {
  FormStateActions,
  Opinion as OpinionType,
  CtoPurpose,
  CtoFor,
} from "common/types/cto3";
import { useAuth, useForm } from "hooks";
import { formatUserName } from "common/libs/formatUserName";
import Paper from "components/paper";
import { StyledTextArea, StyledCheckboxes } from "components/uiElements";
import SignatureSection from "components/formSections/common/signatureSection";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

const checkboxValues = [
  {
    key: CtoPurpose.CONSIDERATION,
    value:
      "(i) consideration of extension of the community treatment period under section 20A ",
  },
  {
    key: CtoPurpose.ENABLE_PART_4A,
    value: "(ii) enabling a Part 4A certificate to be given.",
  },
];

interface OpinionProps {
  testIdKey: string;
  opinion: OpinionType;
  dispatchFormState: React.Dispatch<Action>;
}

const Opinion: React.FC<OpinionProps> = ({
  testIdKey,
  opinion: { for: forValue, purpose, details, signature, date },
  dispatchFormState,
}) => {
  const { user } = useAuth();
  const { handleEmail, formErrors } = useForm();
  const [signedStatus, setSignedStatus] = useState<boolean>(!!signature);

  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed ? formatUserName(user) : "";

      dispatchFormState({
        type: FormStateActions.SET_OPINION_SIGNATURE,
        payload: signedValue,
      });
    },
    [dispatchFormState, user]
  );

  const optionAClassName =
    forValue && forValue !== CtoFor.REQUIRES_TREATMENT ? "strikeThrough" : "";

  return (
    <Paper>
      <Stack spacing={2} mb={3}>
        <Typography variant="h4" display="inline">
          Choose the reason for recall from (a) or (b):
        </Typography>
        {!!formErrors?.["opinion.for"] && (
          <Typography variant="h5" color="error" sx={{ fontWeight: "bold" }}>
            {formErrors?.["opinion.for"]}
          </Typography>
        )}
        {/* Option A */}
        <Stack flexDirection="row" alignItems="center">
          <Radio
            data-test-id={`${testIdKey}-for-a`}
            checked={forValue === CtoFor.REQUIRES_TREATMENT}
            onChange={(e: any) =>
              dispatchFormState({
                type: FormStateActions.SET_OPINION_FOR,
                payload: e.target.value,
              })
            }
            value={CtoFor.REQUIRES_TREATMENT}
            disabled={signedStatus}
          />
          <Typography
            variant="h4"
            display="inline"
            className={optionAClassName}
          >
            (a) In my opinion,
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography
            variant="h4"
            display="inline"
            className={optionAClassName}
            style={{ marginLeft: "4rem" }}
          >
            (i) you require treatment in hospital for mental disorder,
          </Typography>
          <Typography
            variant="h4"
            display="inline"
            className={optionAClassName}
          >
            AND
          </Typography>
          <Typography
            variant="h4"
            display="inline"
            className={optionAClassName}
            style={{ marginLeft: "4rem" }}
          >
            (ii) there would be a risk of harm to your health or safety or to
            other persons if you were not recalled to hospital for that purpose.
          </Typography>
        </Stack>
        {/* Option A expanded */}
        {forValue === CtoFor.REQUIRES_TREATMENT && [
          <Box mt={2}></Box>,
          <StyledTextArea
            testId={`${testIdKey}-details`}
            label="Opinion"
            onChange={(value: any) =>
              dispatchFormState({
                type: FormStateActions.SET_OPINION_DETAILS,
                payload: value,
              })
            }
            value={details}
            disabled={signedStatus}
            errorKey="opinion.details"
            validateWith={{ for: forValue }}
          />,
        ]}
        {/* Option B */}
        <Stack flexDirection="row" alignItems="flex-end">
          <Radio
            data-test-id={`${testIdKey}-for-b`}
            checked={forValue === CtoFor.FAILED_TO_COMPLY}
            onChange={(e: any) =>
              dispatchFormState({
                type: FormStateActions.SET_OPINION_FOR,
                payload: e.target.value,
              })
            }
            value={CtoFor.FAILED_TO_COMPLY}
            disabled={signedStatus}
          />
          <Typography
            variant="h4"
            display="inline"
            className={
              forValue && forValue !== CtoFor.FAILED_TO_COMPLY
                ? "strikeThrough"
                : ""
            }
          >
            (b) You have failed to comply with the condition imposed under
            section 17B of the Mental Health Act 1983 that you make yourself
            available for examination for the purpose of:
          </Typography>
        </Stack>
        {/* Option B expanded */}
        {forValue === CtoFor.FAILED_TO_COMPLY && (
          <Stack style={{ marginLeft: "4rem" }}>
            <StyledCheckboxes
              testIdKey={`${testIdKey}-purpose`}
              value={purpose}
              onChange={(value: any) =>
                dispatchFormState({
                  type: FormStateActions.SET_OPINION_PURPOSE,
                  payload: value,
                })
              }
              items={checkboxValues}
              disabled={signedStatus}
              errorKey="opinion.purpose"
            />
          </Stack>
        )}
      </Stack>
      <SignatureSection
        name={signature}
        date={date}
        signedStatus={signedStatus}
        setSignedStatus={handleSetSignedStatus}
      />
      <Stack spacing={2} mt={3}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Button
            data-test-id={`cto3-email-final`}
            variant="contained"
            onClick={() =>
              handleEmail({
                toEmail: user?.attributes?.email,
                includePDFAttachment: true,
              })
            }
            disabled={!signedStatus}
            size="large"
            sx={{ mr: 1 }}
          >
            Send to self
          </Button>
          <PdfDownloadButton disabled={!signedStatus} />
        </Stack>
        <Typography variant="h4" className="bold text-center">
          A COPY OF THIS NOTICE IS TO BE FORWARDED TO THE MANAGERS OF THE
          HOSPITAL TO WHICH THE PATIENT IS RECALLED AS SOON AS POSSIBLE AFTER IT
          IS SERVED ON THE PATIENT. IF THAT HOSPITAL IS NOT THE RESPONSIBLE
          HOSPITAL, YOU SHOULD INFORM THE HOSPITAL MANAGERS THE NAME AND ADDRESS
          OF THE RESPONSIBLE HOSPITAL.
        </Typography>
        <Typography variant="h4" className="bold text-center">
          This notice is sufficient authority for the managers of the named
          hospital to detain the patient there in accordance with the provisions
          of section 17E of the Mental Health Act 1983.
        </Typography>
      </Stack>
    </Paper>
  );
};

export default React.memo(Opinion);
