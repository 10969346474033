import React, { useState } from "react";

// Material UI
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

// Custom imports
import { StyledInput, StyledAddressFinder } from "components/uiElements";
import { HospitalDetailsType } from "common/types/statForms";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    backgroundColor: theme.palette.info.dark,
  },
  input: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: ".5rem",
  },
  header: {
    padding: "0 1rem",
  },
  errorText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.error.dark,
    fontWeight: "bold",
  },
}));

interface HospitalDetailsProps {
  /**
   * @param {string | undefined} testIdK{ey - this will prefix all of the `data-test-id`}
   * attributes for cypress testing
   */
  testIdKey?: string;
  label?: string | React.ReactNode;
  guidance?: string;
  italicLabel?: boolean;
  name: string;
  address: string;
  manualAddress1?: string;
  manualAddress2?: string;
  manualPostcode?: string;
  setHospital?: (hospital: HospitalDetailsType) => void;
  errorKey?: string;
  disabled?: boolean;
}

const HospitalDetails: React.FC<HospitalDetailsProps> = ({
  testIdKey,
  label = "(Name and address of hospital)",
  guidance,
  italicLabel = true,
  name,
  address,
  manualAddress1,
  manualAddress2,
  manualPostcode,
  setHospital,
  errorKey = "hospitalDetails",
  disabled,
}) => {
  const classes = useStyles();

  const [manualMode, setManualMode] = useState(false);
  const [address1, setAddress1] = useState<string>(manualAddress1 || "");
  const [address2, setAddress2] = useState<string>(manualAddress2 || "");
  const [postcode, setPostcode] = useState<string>(manualPostcode || "");
  const [saved, setSaved] = useState(false);

  const manualAddressObject = {
    manualAddress1: address1,
    manualAddress2: address2,
    manualPostcode: postcode,
  };

  function formatSubAddress(substring: string): string {
    const specialCharacterRegExp = new RegExp(/[&/\\#+()$~%.'":*?<>{}]/g);
    const lastCommaRegExp = new RegExp(/,\s*$/g);
    return substring
      .trim()
      .replace(specialCharacterRegExp, "")
      .replace(lastCommaRegExp, "");
  }

  function saveManualAddress(): void {
    setSaved(true);
    if (!address1 || !postcode) {
      return;
    }
    let res: string = "";
    res = res + formatSubAddress(address1) + ", ";
    if (address2) {
      res += formatSubAddress(address2) + ", ";
    }
    res += postcode;
    if (setHospital) {
      setHospital({ name, address: res, ...manualAddressObject });
    }
    setManualMode(false);
  }

  function cancelManualAddress(): void {
    setSaved(false);
    setManualMode(false);
  }

  return (
    <Paper
      elevation={0}
      classes={{
        root: classes.paper,
      }}
    >
      <Stack spacing={2}>
        {typeof label === "string" ? (
          <Typography
            variant="h4"
            style={{
              fontStyle: italicLabel && !guidance ? "italic" : undefined,
            }}
            display="inline"
          >
            {label}
          </Typography>
        ) : (
          label
        )}

        {guidance && (
          <Typography
            variant="h4"
            style={{ fontStyle: "italic" }}
            display="inline"
          >
            {guidance}
          </Typography>
        )}

        <StyledInput
          testId={`${testIdKey}-hospital-name`}
          label="Hospital Name"
          value={name}
          errorKey={`${errorKey}.name`}
          onChange={(value: any) => {
            if (setHospital) {
              setHospital({
                name: value,
                address,
                ...manualAddressObject,
              });
            }
          }}
          disabled={disabled}
        />

        <StyledAddressFinder
          testId={`${testIdKey}-hospital-address`}
          label="Hospital Address"
          value={address}
          errorKey={`${errorKey}.address`}
          disabled={disabled || manualMode}
          onChange={(address: string) => {
            if (setHospital) {
              setHospital({ name, address, ...manualAddressObject });
            }
          }}
        />

        <Stack
          spacing={2}
          sx={{
            typography: "body1",
            "& > :not(style) + :not(style)": {
              ml: 2,
            },
          }}
        >
          <Link
            underline="hover"
            style={{ cursor: "pointer", width: 220 }}
            onClick={() => {
              setManualMode(!manualMode);
            }}
          >
            <Typography
              variant="h5"
              style={{ fontStyle: "italic" }}
              display="inline"
            >
              *To enter address manually, click here
            </Typography>
          </Link>
          {manualMode && (
            <>
              <StyledInput
                sx={{ mt: 5 }}
                error={saved && !address1}
                errorText="Address line 1 is required"
                value={address1}
                label="Address Line 1"
                onChange={(value: any) => {
                  setAddress1(value);
                  if (setHospital) {
                    setHospital({ name, address, ...manualAddressObject });
                  }
                }}
                disabled={disabled}
              />
              <StyledInput
                sx={{ mt: 5 }}
                value={address2}
                label="Address Line 2"
                onChange={(value: any) => {
                  setAddress2(value);
                  if (setHospital) {
                    setHospital({ name, address, ...manualAddressObject });
                  }
                }}
                disabled={disabled}
              />
              <Typography
                variant="h5"
                style={{ fontStyle: "italic" }}
                display="inline"
              >
                Enter the postcode if known, otherwise enter "Unknown" or "N/A"
              </Typography>
              <StyledInput
                sx={{ mt: 5 }}
                error={saved && !postcode}
                errorText="Postcode is required"
                value={postcode}
                label="Postcode"
                onChange={(value: any) => {
                  setPostcode(value);
                  if (setHospital) {
                    setHospital({ name, address, ...manualAddressObject });
                  }
                }}
                disabled={disabled}
              />
              <Typography
                variant="h5"
                style={{ fontStyle: "italic" }}
                display="inline"
              >
                If entering the hospital address manually, you must save it
                below before submitting this form
              </Typography>
              <Box>
                <Button
                  style={{
                    width: 150,
                    marginRight: 15,
                    marginBottom: 5,
                  }}
                  variant="contained"
                  onClick={saveManualAddress}
                >
                  SAVE ADDRESS
                </Button>
                <Button
                  style={{ width: 100 }}
                  variant="outlined"
                  onClick={cancelManualAddress}
                >
                  CANCEL
                </Button>
              </Box>
            </>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default React.memo(HospitalDetails);
