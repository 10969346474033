import React, { useCallback } from "react";

// Material UI
import { makeStyles } from "@mui/styles";
import { Grid, Button, Stepper, Step, StepLabel } from "@mui/material";

import { useDeviceType, useForm, useUI } from "hooks";
import { FormItemType } from "common/types/statForms";
import { isSectionErrored } from "utils/validationHelpers";
import { RouteConstants } from "common/constants/routes";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
}));

export interface FormNavButtonsProps {
  formItems: FormItemType[];
  lastSectionIndex: number;
  disablePrev?: boolean;
  disableNext?: boolean;
  sectionIndexFunctions?: {
    [key: number]: () => void;
  };
}

export const FormNavButtons: React.FC<FormNavButtonsProps> = ({
  formItems,
  lastSectionIndex,
  disablePrev,
  disableNext,
  sectionIndexFunctions,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { isMobile } = useDeviceType();
  const {
    currentSectionIndex,
    setCurrentSectionIndex,
    navigateForward,
    navigateBack,
  } = useUI();
  const { handleSave, sectionIndexValidation, formErrors } = useForm();

  // We validate from the current index to the index the user wants to go jump to
  // If there is an error we highlight the error and keep them on the section instead
  const handleSetCurrentSectionIndex = useCallback(
    async (jumpToIndex: number) => {
      const promiseArr = [];
      if (sectionIndexValidation) {
        for (let i = currentSectionIndex; i < jumpToIndex; i++) {
          promiseArr.push(await sectionIndexValidation[i]());
        }
      }
      if (promiseArr) {
        await Promise.all(promiseArr);
      }
      setCurrentSectionIndex(jumpToIndex);
    },
    [currentSectionIndex, sectionIndexValidation, setCurrentSectionIndex]
  );

  const handleNavigateForward = useCallback(async () => {
    if (
      sectionIndexValidation &&
      sectionIndexValidation?.[currentSectionIndex]
    ) {
      await sectionIndexValidation[currentSectionIndex]();
    }

    // If there is a function callback for the current section index, call it
    // NOTE: if there is a section index function you will need to manually handle navigating forwards after
    if (sectionIndexFunctions && sectionIndexFunctions?.[currentSectionIndex]) {
      sectionIndexFunctions[currentSectionIndex]();
      return;
    }

    navigateForward();
  }, [
    sectionIndexValidation,
    sectionIndexFunctions,
    currentSectionIndex,
    navigateForward,
  ]);

  if (isMobile) {
    return (
      <Grid container spacing={2}>
        {/* Only render stepper if user has to complete more than 1 section */}
        {formItems.filter((formItem) => !formItem.disabled).length > 1 && (
          <Grid item xs={12}>
            <Stepper activeStep={currentSectionIndex}>
              {formItems.map(
                (formItem, index) =>
                  !formItem.disabled && (
                    <Step
                      key={index}
                      onClick={() => handleSetCurrentSectionIndex(index)}
                    >
                      <StepLabel
                        error={isSectionErrored(formErrors, formItem?.errorKey)}
                      ></StepLabel>
                    </Step>
                  )
              )}
            </Stepper>
          </Grid>
        )}
        <Grid item xs={12} marginTop="16px">
          <Button
            data-test-id="form-nav-next-button"
            variant="contained"
            onClick={handleNavigateForward}
            disabled={currentSectionIndex === lastSectionIndex || disableNext}
            fullWidth
          >
            Next
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => handleSave({ shouldNavigate: true })}
            fullWidth
          >
            Save & Exit
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => navigate(RouteConstants.DASHBOARD)}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} className="text-center">
          <Button
            data-test-id="form-nav-previous-button"
            variant="text"
            onClick={navigateBack}
            disabled={currentSectionIndex === 0 || disablePrev}
          >
            Go Back
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} className={classes.buttonContainer}>
      <Button
        data-test-id="form-nav-previous-button"
        variant="outlined"
        onClick={navigateBack}
        disabled={currentSectionIndex === 0 || disablePrev}
      >
        Go Back
      </Button>
      <Button
        data-test-id="form-nav-next-button"
        variant="contained"
        onClick={handleNavigateForward}
        disabled={currentSectionIndex === lastSectionIndex || disableNext}
      >
        Next
      </Button>
    </Grid>
  );
};
