import { FC, memo } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "auto",
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(1.5),
}));
const StyledFiberManualRecordIcon = styled(FiberManualRecordIcon)({
  fontSize: "0.4rem",
});
const StyledListItem = styled(ListItem)({
  alignItems: "flex-start",
});

interface BulletListProps {
  items: string[];
  heading: string;
}
const BulletList: FC<BulletListProps> = ({ items, heading }) => (
  <>
    <Typography variant="h3">{heading}</Typography>
    <List sx={{ marginTop: 1 }}>
      {items.map((item, index) => (
        <StyledListItem key={index}>
          <StyledListItemIcon>
            <StyledFiberManualRecordIcon />
          </StyledListItemIcon>
          <ListItemText
            primary={item}
            primaryTypographyProps={{ className: "fw300" }}
          />
        </StyledListItem>
      ))}
    </List>
  </>
);

export default memo(BulletList);
