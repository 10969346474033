import React, { useState } from "react";

// Material UI
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

// Custom imports
import { StyledInput, StyledAddressFinder } from "components/uiElements";
import { AddressType } from "common/types/statForms";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    backgroundColor: theme.palette.info.dark,
  },
  input: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: ".5rem",
  },
  header: {
    padding: "0 1rem",
  },
  errorText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.warning.dark,
    fontWeight: "bold",
  },
}));

interface AddressDetailsProps {
  testIdKey?: string;
  address: string;
  addressLabel?: string;
  manualAddress1?: string;
  manualAddress2?: string;
  manualPostcode?: string;
  setAddress?: (address: AddressType) => void;
  disabled?: boolean;
  errorKey?: string;
  noFixedAbode?: boolean;
}
const AddressDetails: React.FC<AddressDetailsProps> = ({
  testIdKey,
  address,
  addressLabel = "Address",
  errorKey = "addressDetails",
  manualAddress1,
  manualAddress2,
  manualPostcode,
  setAddress,
  disabled,
  noFixedAbode,
}) => {
  const classes = useStyles();

  const [manualMode, setManualMode] = useState(false);
  const [address1, setAddress1] = useState<string>(manualAddress1 || "");
  const [address2, setAddress2] = useState<string>(manualAddress2 || "");
  const [postcode, setPostcode] = useState<string>(manualPostcode || "");
  const [saved, setSaved] = useState(false);

  const manualAddressObject = {
    manualAddress1: address1,
    manualAddress2: address2,
    manualPostcode: postcode,
  };

  function formatSubAddress(substring: string): string {
    const specialCharacterRegExp = new RegExp(/[&/\\#+()$~%.'":*?<>{}]/g);
    const lastCommaRegExp = new RegExp(/,\s*$/g);
    return substring
      .trim()
      .replace(specialCharacterRegExp, "")
      .replace(lastCommaRegExp, "");
  }

  function saveManualAddress(): void {
    setSaved(true);

    // Handle no fixed abode
    if (
      postcode &&
      ["unknown", "n/a", "na", "nfa"].includes(postcode.toLowerCase())
    ) {
      if (setAddress) setAddress({ address: postcode, ...manualAddressObject });
      setManualMode(false);
      return;
    }

    // Handle manual entry
    if (!address1 || !postcode) {
      return;
    }
    let res: string = "";
    res = res + formatSubAddress(address1) + ", ";
    if (address2) {
      res += formatSubAddress(address2) + ", ";
    }
    res += postcode;
    if (setAddress) {
      setAddress({ address: res, ...manualAddressObject });
    }
    setManualMode(false);
  }

  function cancelManualAddress(): void {
    setSaved(false);
    setManualMode(false);
  }

  return (
    <Paper
      elevation={0}
      classes={{
        root: classes.paper,
      }}
    >
      <Stack spacing={2}>
        <StyledAddressFinder
          testId={`${testIdKey}-address`}
          label={addressLabel}
          value={address}
          errorKey={errorKey}
          disabled={disabled || manualMode}
          onChange={(address: string) => {
            if (setAddress) {
              setAddress({ address, ...manualAddressObject });
            }
          }}
        />

        <Stack
          spacing={2}
          sx={{
            typography: "body1",
            "& > :not(style) + :not(style)": {
              ml: 2,
            },
          }}
        >
          <Link
            underline="hover"
            style={{ cursor: "pointer", width: 220 }}
            onClick={() => {
              setManualMode(!manualMode);
            }}
          >
            <Typography
              variant="h5"
              style={{ fontStyle: "italic" }}
              display="inline"
            >
              *To enter address manually, click here
            </Typography>
          </Link>
          {manualMode && (
            <>
              <StyledInput
                sx={{ mt: 5 }}
                error={
                  saved &&
                  !address1 &&
                  !["n/a", "na", "nfa", "unknown"].includes(
                    postcode.toLowerCase()
                  )
                }
                errorText="Address line 1 is required"
                value={address1}
                label="Address Line 1"
                onChange={(value: any) => {
                  setAddress1(value);
                  if (setAddress) {
                    setAddress({ address, ...manualAddressObject });
                  }
                }}
                disabled={disabled}
              />
              <StyledInput
                sx={{ mt: 5 }}
                value={address2}
                label="Address Line 2"
                onChange={(value: any) => {
                  setAddress2(value);
                  if (setAddress) {
                    setAddress({ address, ...manualAddressObject });
                  }
                }}
                disabled={disabled}
              />
              {noFixedAbode && (
                <Typography
                  variant="h5"
                  style={{ fontStyle: "italic" }}
                  display="inline"
                >
                  Enter the postcode if known, otherwise enter "Unknown" or
                  "N/A"
                </Typography>
              )}
              <StyledInput
                sx={{ mt: 5 }}
                error={saved && !postcode}
                errorText="Postcode is required"
                value={postcode}
                label="Postcode"
                onChange={(value: any) => {
                  setPostcode(value);
                  if (setAddress) {
                    setAddress({ address, ...manualAddressObject });
                  }
                }}
                disabled={disabled}
              />
              <Typography
                variant="h5"
                style={{ fontStyle: "italic" }}
                display="inline"
              >
                If entering address manually, you must save it below before
                submitting this form
              </Typography>
              <Box>
                <Button
                  style={{
                    width: 150,
                    marginRight: 15,
                    marginBottom: 5,
                  }}
                  variant="contained"
                  onClick={saveManualAddress}
                >
                  SAVE ADDRESS
                </Button>
                <Button
                  style={{ width: 100 }}
                  variant="outlined"
                  onClick={cancelManualAddress}
                >
                  CANCEL
                </Button>
              </Box>
            </>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default React.memo(AddressDetails);
