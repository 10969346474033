import React from "react";
import { View } from "@react-pdf/renderer";
import { PDFSubHeader } from "../formComponents/baseComponents/PDFSubHeader";
import {
  StyledDateOrTime,
  DateOrTime,
} from "../formComponents/StyledDateOrTime";
import { PractitionerOrClinician } from "./PractitionerOrClinician";
import { StyledInformationBox } from "../formComponents/StyledInformationBox";
import { StyledText } from "../formComponents/StyledText";
import { RadioOptionsList } from "../formComponents/RadioOptionsList";
import { PDFSignatures } from "../formComponents/baseComponents/PDFSignatures";
import { H1Form } from "common/types/h1";

/**
 * H1Body
 * @param {H1Form} H1Form
 * @returns {JSX.Element}
 */
export const H1Body: React.FC<H1Form> = (props) => {
  // Destructure
  const {
    hospitalDetails: {
      name: hospitalName,
      address: hospitalAddress,
      manualAddress1,
      manualAddress2,
      manualPostcode,
    },
    yourDetails: { practitionerName, isNominee, isMedicalPractitioner },
    patientDetails: { name: patientName, detailedReasons },
    furnishingReport: { signature, date, trackingId },
    wardSignature: {
      signature: wardSignature,
      date: wardSignatureDate,
      trackingId: wardTrackingId,
    },
  } = props;

  // Build hospital address string
  const hospitalAddressText = hospitalAddress
    ? hospitalAddress
    : `${manualAddress1}\n${manualAddress2}\n${manualPostcode}`;
  const hospitalLocation = `${hospitalName}\n${hospitalAddressText}`;

  return (
    <View>
      {/* Part 1 -> Hospital Details */}
      <PDFSubHeader subsubheading="PART 1" noMargin style={{ marginTop: 2 }} />
      <PDFSubHeader
        text="(To be completed by a medical practitioner or an approved clinician qualified to do so under section 5(2) of the Act)"
        style={{ margin: "0 auto", maxWidth: "92%" }}
      />
      <StyledText
        instruction="To the managers of"
        guidance="(name and address of hospital)"
        style={{ marginBottom: 2 }}
      />
      <StyledInformationBox
        value={hospitalLocation}
        minHeight={50}
        style={{ marginBottom: 8 }}
      />
      <StyledText
        instruction="I am"
        guidance="(PRINT full name)"
        style={{ margin: 0, marginBottom: 2 }}
      />
      <StyledInformationBox
        value={practitionerName}
        style={{ marginBottom: 2 }}
      />
      {/* Your Details */}
      <StyledText
        instruction="and I am"
        guidance="(Delete (a) or (b) as appropriate)"
        noMargin
      />
      <PractitionerOrClinician
        isNominee={isNominee}
        isMedicalPractitioner={isMedicalPractitioner}
      />

      {/* Patient Details */}
      <StyledText
        instruction="in charge of the treatment of"
        guidance="(PRINT full name of patient)"
      />
      <StyledInformationBox
        value={patientName}
        style={{ padding: "5px 5px 1px" }}
        after={
          <div style={{ maxWidth: "90%" }}>
            <StyledText
              noMargin
              instruction="who is an in-patient in this hospital and not at present liable to be detained under the Mental Health Act 1983."
            />
          </div>
        }
      />
      <StyledText instruction="It appears to me that an application ought to be made under Part 2 of the Act for this patient's admission to hospital for the following reasons—" />
      <StyledText
        style={{ fontStyle: "italic", marginTop: 6 }}
        instruction="(The full reasons why informal treatment is no longer appropriate must be given.)"
      />
      <StyledInformationBox
        value={detailedReasons}
        minHeight={240}
        style={{ marginTop: 2, marginBottom: 0 }}
      />

      {/* Furnishing Report */}
      <StyledText
        pageBreak
        instruction="I am furnishing this report by:"
        guidance="(Delete the phrase which does not apply)"
      />
      {/* Hard coding value to 2 as it can only be sent electronically */}
      <RadioOptionsList
        renderAsParagraph={false}
        value={2}
        itemPadding={4}
        options={[
          {
            value: 1,
            label:
              "consigning it to the hospital managers' internal mail system today at",
            labelFontSize: 11,
            after: (
              <div style={{ marginTop: 2 }}>
                <StyledDateOrTime
                  value={""}
                  label="(time)"
                  dateOrTime={DateOrTime.time}
                  strikethrough={true}
                />
              </div>
            ),
          },
          {
            value: 2,
            label:
              "today sending it to the hospital managers, or a person authorised by them to receive it, by means of electronic communication",
            labelFontSize: 11,
          },
          {
            value: 3,
            label:
              "delivering it (or having it delivered) by hand to a person authorised by the hospital",
            labelFontSize: 11,
            after: (
              <StyledText
                noMargin
                instruction="managers to receive it."
                disabled
              />
            ),
          },
        ]}
      />
      <PDFSignatures
        signed={signature}
        date={date}
        sha256={trackingId}
        showTime
      />

      {/* Part 2 -> Ward Signature */}
      <PDFSubHeader subheading="PART 2" style={{ margin: "24px 0px" }} />
      <PDFSubHeader text="(To be completed on behalf of the hospital managers)" />
      <StyledText
        instruction="This report was"
        guidance="(Delete the phrase which does not apply)"
      />
      {/* Hard coding value to 2 as it can only be sent electronically */}
      <RadioOptionsList
        renderAsParagraph={false}
        value={2}
        itemPadding={4}
        options={[
          {
            value: 1,
            label:
              "furnished to the hospital managers through their internal mail system",
          },
          {
            value: 2,
            label:
              "furnished to the hospital managers, or a person authorised by them to receive it, by means of electronic communication",
          },
          {
            value: 3,
            label:
              "delivered to me in person as someone authorised by the hospital managers to",
            after: (
              <>
                <StyledText
                  noMargin
                  instruction="receive this report at"
                  disabled
                />
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <View style={{ marginRight: 40 }}>
                    <StyledDateOrTime
                      value={""}
                      label="(time)"
                      dateOrTime={DateOrTime.time}
                      strikethrough={true}
                    />
                  </View>
                  <StyledDateOrTime
                    value={""}
                    prefix="on"
                    label="(date)"
                    dateOrTime={DateOrTime.date}
                    strikethrough={true}
                  />
                </View>
              </>
            ),
          },
        ]}
      />
      <PDFSignatures
        signed={wardSignature}
        date={wardSignatureDate}
        sha256={wardTrackingId}
        onBehalfOf="the hospital managers"
        withPrintName
      />
    </View>
  );
};
