import React, { useState, useCallback } from "react";

// Material UI
import { Button, Typography, Stack, List, ListItem } from "@mui/material";

// Custom imports
import Action from "common/types/action";
import { FormStateActions, Opinion as OpinionType } from "common/types/cto1";
import { useAuth, useForm } from "hooks";
import { formatUserName } from "common/libs/formatUserName";
import Paper from "components/paper";
import { StyledTextArea } from "components/uiElements";
import SignatureSection from "components/formSections/common/signatureSection";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

interface OpinionProps {
  opinion: OpinionType;
  dispatchFormState: React.Dispatch<Action>;
}

const FurtherConditions: React.FC<OpinionProps> = ({
  opinion: { furtherConditions, signature, date },
  dispatchFormState,
}) => {
  const { user } = useAuth();
  const { handleEmail } = useForm();
  const [signedStatus, setSignedStatus] = useState<boolean>(!!signature);

  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed ? formatUserName(user) : "";

      dispatchFormState({
        type: FormStateActions.SET_OPINION_SIGNATURE,
        payload: signedValue,
      });
    },
    [dispatchFormState, user]
  );

  return (
    <Paper>
      <Stack>
        <Stack spacing={4} sx={{ marginBottom: 2 }}>
          <Typography variant="h4" display="inline" className="text-center">
            <strong>
              Conditions to which the patient is to be subject by virtue of this
              community treatment order
            </strong>
          </Typography>
          <Typography variant="h4" display="inline">
            The patient is to make himself or herself available for examination
            under section 20A, as requested.
          </Typography>
          <Typography variant="h4" display="inline">
            If it is proposed to give a certificate under Part 4A of the Act in
            the patient's case, the patient is to make himself or herself
            available for examination to enable the certificate to be given, as
            requested.
          </Typography>
          <Stack spacing={2}>
            <Typography variant="h4" display="inline">
              The patient is also to be subject to the following conditions (if
              any) under section 17B(2) of the Act:
            </Typography>
            <StyledTextArea
              testId="cto1-opinion-further-conditions"
              label="Further Conditions"
              value={furtherConditions}
              onChange={(value: any) =>
                dispatchFormState({
                  type: FormStateActions.SET_OPINION_FURTHER_CONDITIONS,
                  payload: value,
                })
              }
              disabled={signedStatus}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h4" display="inline">
              I confirm that I consider the above conditions to be made under
              section 17B(2) of the Act are necessary or appropriate for one or
              more of the following purposes:
            </Typography>
            <List dense>
              <ListItem sx={{ display: "list-item" }}>
                <Typography variant="h4" display="inline">
                  &#x2022; to ensure that the patient receives medical treatment
                </Typography>
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <Typography variant="h4" display="inline">
                  &#x2022; to prevent risk of harm to the patient's health or
                  safety
                </Typography>
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <Typography variant="h4" display="inline">
                  &#x2022; to protect other persons.
                </Typography>
              </ListItem>
            </List>
          </Stack>
        </Stack>
        <SignatureSection
          name={signature}
          date={date}
          signedStatus={signedStatus}
          setSignedStatus={handleSetSignedStatus}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          mt={2}
        >
          <Button
            data-test-id="cto1-email-part-1"
            variant="contained"
            onClick={() => handleEmail()}
            disabled={!signedStatus}
            size="large"
            sx={{ mr: 1 }}
          >
            Send to AMHP
          </Button>
          <PdfDownloadButton disabled={!signedStatus} />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default React.memo(FurtherConditions);
