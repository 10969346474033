import React from "react";

// Material UI
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Custom imports
import Action from "common/types/action";
import { FormStateActions } from "common/types/h1";
import { StyledInput } from "components/uiElements";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    backgroundColor: theme.palette.info.dark,
  },
  input: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: ".5rem",
  },
  header: {
    padding: "0 1rem",
  },
}));

interface PatientDetailsProps {
  name: string;
  dispatchFormState: React.Dispatch<Action>;
  disabled: boolean;
}

const PatientDetails: React.FC<PatientDetailsProps> = ({
  name,
  dispatchFormState,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <Paper
      elevation={0}
      classes={{
        root: classes.paper,
      }}
    >
      <Stack spacing={3}>
        <Typography
          variant="h4"
          style={{ fontStyle: "italic" }}
          display="inline"
        >
          (PRINT full name of patient)
        </Typography>

        <StyledInput
          testId="h3-patient-name"
          label="Patient Name"
          variant="outlined"
          value={name}
          errorKey="section1.patientDetails.name"
          onChange={(value: any) =>
            dispatchFormState({
              type: FormStateActions.SET_PATIENT_NAME,
              payload: value,
            })
          }
          disabled={disabled}
        />
      </Stack>
    </Paper>
  );
};

export default PatientDetails;
