/**
 * -------------------------------------
 * * Next Actor Dialog Content
 * -------------------------------------
 *
 * This component is used when a user is navigating through forms. It will warn the user
 * that to continue to the next step the actor will need to change as it must be filled out by a different
 * actor
 *
 * Note: an example of this will be used in the H1Form
 * -------------------------------------
 */

import React, { useState, useCallback } from "react";

// Material UI
import { Grid, Typography as Text } from "@mui/material";

// Custom Imports
import { FormType } from "common/types/pdf";
import { usePopups } from "hooks";
import AuthDialogContent from "components/auth/AuthDialogContent/AuthDialogContent";
import DialogButtonRow from "components/dialog/DialogButtonRow";

interface NextActorDialogContentProps {
  formType: FormType;
  onSuccess: () => void;
  actorType?: string;
}

const NextActorDialogContent: React.FC<NextActorDialogContentProps> = ({
  formType,
  onSuccess,
  actorType,
}) => {
  const { closeDialog } = usePopups();

  /**
   * ? Indexes
   * 0: Warning the user that to proceed to the next step the actor will need to change
   * 1: Inputs for the actor change
   */
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleActorSuccess = useCallback(() => {
    onSuccess();
    closeDialog();
  }, [onSuccess, closeDialog]);

  if (currentIndex === 0) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Text>
            This step requires action from {actorType || "another actor"}.
            Please only continue if you are authorised.
          </Text>
        </Grid>
        <Grid item xs={12}>
          <DialogButtonRow
            testIdKey="nextActorDialog"
            onSuccess={() => setCurrentIndex(currentIndex + 1)}
            submitButtonText="Next"
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <AuthDialogContent
      onSuccess={handleActorSuccess}
      onCancel={closeDialog}
    />
  );
};

export default React.memo(NextActorDialogContent);
