import { memo, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { saveAs } from "file-saver";

// Material UI
import Button from "@mui/material/Button";

// Custom
import { useAPI, useAuth, useForm } from "hooks";
import { convertPDFToBlob } from "utils/pdf";
import generateFormProps from "utils/pdf/generateFormProps";
import { FormType } from "common/types/pdf";
import { Methods } from "api";
import { FormStatus } from "common/types/statForms";

type PDFDownloadButtonProps = {
  disabled?: boolean;
};

/**
 * PDFDownloadButton
 * - A self contained solution for converting JSX formatted PDF documents to downloads
 * @param {React.ReactElement} document - JSX document containing populated and completed form for download
 * @returns {JSX.Element} - The self contained download button
 */
const PDFDownloadButton = ({
  disabled,
}: PDFDownloadButtonProps): JSX.Element => {
  const { actor } = useAuth();
  const { handleSave, formState } = useForm();
  const { formId, formType } = useParams();
  const castedFormType = formType as FormType;
  const castedFormIdWithFallback = (formId || formState.id) as string;
  const [loading, setLoading] = useState<boolean>(false);
  const { trigger: createStatFormActivity } = useAPI({
    method: Methods.POST,
    fieldName: "createStatFormActivity",
  });

  const handleDownload = useCallback(async () => {
    setLoading(true);

    // Keep form up to date with formData and mark as downloaded
    handleSave({
      shouldNavigate: false,
      formStatus: FormStatus.s51_signed_and_downloaded,
    });

    const formProps = generateFormProps(
      castedFormIdWithFallback,
      castedFormType,
      formState.data
    );

    const fileName = `s12-${
      formProps?.form
    }-${castedFormIdWithFallback}-${dayjs().format("DD-MM-YYYY")}.pdf`;

    const blob = await convertPDFToBlob(formProps);
    saveAs(blob, fileName);

    // Save download in form activity log
    createStatFormActivity({
      input: {
        id: uuidv4(),
        form: castedFormIdWithFallback,
        actor: JSON.stringify(actor) || "",
        action: "download",
        createdAt: new Date().toISOString(),
      },
    });

    setLoading(false);
  }, [
    actor,
    castedFormIdWithFallback,
    castedFormType,
    createStatFormActivity,
    formState.data,
    handleSave,
  ]);

  if (loading) {
    return (
      <Button
        data-test-id="form-download"
        color="primary"
        variant="outlined"
        disabled
        size="large"
      >
        Loading
      </Button>
    );
  }

  return (
    <Button
      data-test-id="form-download"
      color="primary"
      variant="contained"
      onClick={handleDownload}
      disabled={disabled}
      size="large"
    >
      Download
    </Button>
  );
};

export default memo(PDFDownloadButton);
