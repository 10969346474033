import React from "react";
import { View } from "@react-pdf/renderer";
import {
  StyledDateOrTime,
  DateOrTime,
} from "../formComponents/StyledDateOrTime";
import { StyledInformationBox } from "../formComponents/StyledInformationBox";
import { StyledText } from "../formComponents/StyledText";
import { PDFSignatures } from "../formComponents/baseComponents/PDFSignatures";
import { CTO4Form } from "common/types/cto4";
import { formatNameAndAddressText } from "utils/pdf";

/**
 * CTO4Form
 * @param {CTO4Form} CTO4Form
 * @returns {JSX.Element}
 */
export const CTO4Body: React.FC<CTO4Form> = ({
  patientDetails,
  hospitalDetails,
}) => {
  // Patient Details
  const patientNameAndAddress = formatNameAndAddressText(
    patientDetails.name,
    patientDetails.address,
    patientDetails.manualAddress1,
    patientDetails.manualAddress2,
    patientDetails.manualPostcode
  );

  // Hospital Details
  const hospitalNameAndAddress = formatNameAndAddressText(
    hospitalDetails.name,
    hospitalDetails.address,
    hospitalDetails.manualAddress1,
    hospitalDetails.manualAddress2,
    hospitalDetails.manualPostcode
  );

  return (
    <View>
      {/* Patient Details */}
      <StyledText
        instruction=""
        guidance="(PRINT full name and address of patient)"
        style={{ fontSize: 12 }}
      />
      <StyledInformationBox
        value={patientNameAndAddress}
        minHeight={50}
        after={
          <StyledText
            instruction="(‘the patient’) is currently a community patient. "
            style={{ fontSize: 12 }}
            noMargin
          />
        }
      />
      {/* Hospital Details */}
      <StyledText
        instruction="In pursuance of a notice recalling the patient to hospital under section 17E of the Act, the patient was detained in"
        guidance="(full name and address of hospital)"
        style={{ fontSize: 12 }}
      />
      <StyledInformationBox
        value={hospitalNameAndAddress}
        minHeight={50}
        after={
          <StyledText
            instruction="on"
            guidance="(enter date and time at which the patient’s detention in the hospital as a result of the recall notice began)"
            style={{ fontSize: 12, maxWidth: "95%" }}
            noMargin
          />
        }
      />
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: 450,
        }}
      >
        <StyledDateOrTime
          dateOrTime={DateOrTime.date}
          value={hospitalDetails.detentionDate}
          label="(date)"
          empty={!!!hospitalDetails.detentionDate}
        />
        <StyledDateOrTime
          dateOrTime={DateOrTime.time}
          value={hospitalDetails.detentionTime}
          label="(time)"
          prefix="at "
          empty={!!!hospitalDetails.detentionTime}
        />
      </View>
      <PDFSignatures
        signed={hospitalDetails.signature}
        date={hospitalDetails.date}
        withPrintName
        onBehalfOf="the hospital managers"
        empty={!hospitalDetails.signature}
        sha256={hospitalDetails.trackingId}
        noMargin
      />
      <StyledText instruction="Time" style={{ fontSize: 12 }} noMargin />
      <StyledDateOrTime
        dateOrTime={DateOrTime.time}
        value={hospitalDetails.date}
        label=""
        empty={!!!hospitalDetails.date}
        minHeight={25}
      />
    </View>
  );
};
