import React from "react";
import { Document, Page, Text, Font, View } from "@react-pdf/renderer";

import { FormType } from "common/types/pdf";

import { PDFHeader } from "./formComponents/baseComponents/PDFHeader";

// Form bodies
import { H1Body } from "./H1";
import { H3Body } from "./H3";
import { CTO1Body } from "./CTO1";
import { CTO3Body } from "./CTO3";
import { CTO4Body } from "./CTO4";
import { CTO5Body } from "./CTO5";
import { CTO7Body } from "./CTO7";
import { CTO12Body } from "./CTO12";
import { T2Body } from "./T2";

// Register Fonts
const LibSansReg = require("assets/fonts/LiberationSans-Regular.ttf");
const LibSansBold = require("assets/fonts/LiberationSans-Bold.ttf");
const LibSansItalic = require("assets/fonts/LiberationSans-Italic.ttf");
const LibSansBoldItalic = require("assets/fonts/LiberationSans-BoldItalic.ttf");

export interface RenderedPDFProps {
  id?: string | null;
  longTitle: string;
  longTitleFontSize?: number;
  longTitleCenter?: boolean;
  /**
   * @param {boolean | undefined} shouldDisplayPDFHeader
   * This was required for the CTO12 form. Sometimes you will need to customise
   * the header instead of use the custom component PDFHeader when you require more flexibility
   */
  shouldDisplayPDFHeader?: boolean;
  regulation: string;
  form: FormType;
  formProps: any;
  sha256?: string;
  signed?: string[];
  fontSize?: number;
  footerText?: string;
  enablePagination?: boolean;
  /**
   * @param {number | string} pagePadding
   * H1 form needed slightly different padding to get the footer and the variable length
   * text box to fit correctly. And but I don't want to throw off the H3 form, sorry -IS
   * @default {number} 25
   */
  pagePadding?: number | string;
  border?: number | string;
  pdfStyle?: {};
  /**
   * @default false
   * @param {boolean} footerTextPage1
   * footerText normally only appears after the first page, but some forms require it to appear on the first page
   */
  footerTextPage1?: boolean;
}

Font.register({
  family: "LiberationSans",
  fonts: [
    { src: LibSansReg },
    { src: LibSansBold, fontWeight: "bold" },
    { src: LibSansItalic, fontStyle: "italic" },
    { src: LibSansBoldItalic, fontWeight: "bold", fontStyle: "italic" },
  ],
});

const getBackgroundColor = (form: FormType): string => {
  switch (form) {
    // case FormType.AUTHORITY_TO_CONVEY:
    //   return "#ECF6FF";
    case FormType.CTO12:
      return "white";
    default:
      return "#FFEAF0";
  }
};

const renderBody = (form: FormType, props: any) => {
  if (!form || !props)
    throw new Error("Invalid arguments passed to renderBody");
  switch (form) {
    case FormType.H1:
      return <H1Body {...props} />;
    case FormType.H3:
      return <H3Body {...props} />;
    case FormType.CTO1:
      return <CTO1Body {...props} />;
    case FormType.CTO3:
      return <CTO3Body {...props} />;
    case FormType.CTO4:
      return <CTO4Body {...props} />;
    case FormType.CTO5:
      return <CTO5Body {...props} />;
    case FormType.CTO7:
      return <CTO7Body {...props} />;
    case FormType.CTO12:
      return <CTO12Body {...props} />;
    case FormType.T2:
      return <T2Body {...props} />;
    default:
      return <Text>No Form Selected!</Text>;
  }
};

/**
 * RenderedPDF
 * Basically the pdf wrapper for every single form
 * @param {FormType} form - One of the registered form types, H3 etc
 * @param {string} longTitle - The form "subtitle"
 * @param {string} regulation - Any relevant regulation attached to the form
 * @param {any} formProps - A wide variety of form specific props and data
 * @returns
 */
export const RenderedPDF: React.FC<RenderedPDFProps> = (
  props: RenderedPDFProps
) => {
  const {
    longTitle,
    longTitleFontSize,
    longTitleCenter,
    shouldDisplayPDFHeader = true,
    regulation,
    form,
    formProps,
    footerText,
    enablePagination,
    fontSize = 12,
    pagePadding = 25,
    border,
    pdfStyle,
    footerTextPage1,
  } = props;

  return (
    <Document>
      <Page
        size="A4"
        wrap
        style={{
          padding: pagePadding,
          backgroundColor: getBackgroundColor(form),
          fontSize,
          fontFamily: "LiberationSans",
          borderWidth: border || 15,
          borderColor: "white",
          height: `calc("100vh - 55px")`,
          paddingBottom: 55,
          ...pdfStyle,
        }}
      >
        {shouldDisplayPDFHeader && (
          <PDFHeader
            form={form}
            regulation={regulation}
            longTitle={longTitle}
            longTitleFontSize={longTitleFontSize}
            longTitleCenter={longTitleCenter}
          />
        )}
        {form && formProps && renderBody(form, formProps)}
        <View
          fixed
          style={{
            fontSize: "10px",
            fontStyle: "italic",
            height: 35,
            position: "absolute",
            bottom: 0,
            left: 18,
            right: 5,
          }}
        >
          <Text
            style={{
              textAlign: "right",
            }}
            render={({ pageNumber, totalPages }) => {
              if (enablePagination) {
                return totalPages > 1
                  ? `page ${pageNumber} of ${totalPages}`
                  : null;
              } else if (totalPages > 1 && pageNumber < totalPages) {
                return "continue overleaf";
              } else {
                return null;
              }
            }}
          />
          {form && props && footerText && (
            <Text
              style={{
                fontSize,
                position: "absolute",
                bottom: 0,
                left: "-18px",
              }}
              render={({ pageNumber, totalPages }) => {
                // If page is not the last we will render continue overleaf
                // and total pages is greater than 1
                // * This will only render once and is a common MHA form pattern, but maybe it could be better named
                if (footerTextPage1 || (totalPages > 1 && pageNumber < 2)) {
                  return footerText;
                } else {
                  return null;
                }
              }}
            />
          )}
        </View>
      </Page>
    </Document>
  );
};
