import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "common/styles/pdf";

/**
 * PDFSubHeader
 * A subheader component for PDF forms
 * @param {string} [subheading] - eg: "Part 1"
 * @param {string} [subsubheading] - eg: "Part 1a"
 * @param {string} [text] - body: "To be completed by a medical practitioner or..."
 * @param {any} [style] - additional styles needed for overwriting in specific situations
 * @param {boolean} [noMargin] -
 * @returns {JSX.Element}
 */
export const PDFSubHeader = ({
  subheading,
  subsubheading,
  text,
  style,
  fontStyle = {},
  noMargin = false,
  pageBreak = false,
}: {
  subheading?: string;
  subsubheading?: string;
  text?: string;
  style?: any;
  fontStyle?: any;
  noMargin?: boolean;
  pageBreak?: boolean;
}) => (
  <View
    break={pageBreak}
    style={{
      width: "100%",
      flexDirection: "row",
      justifyContent: "center",
      marginBottom: !noMargin ? 4 : 0,
      ...style,
    }}
  >
    <View style={styles.flexColCenter}>
      {subheading && (
        <Text
          style={{
            ...styles.mediumBoldFont,
            ...fontStyle,
            textAlign: "center",
          }}
        >
          {subheading}
        </Text>
      )}
      {subsubheading && (
        <Text
          style={{ ...styles.smallBoldFont, ...fontStyle, textAlign: "center" }}
        >
          {subsubheading}
        </Text>
      )}
      {text && (
        <Text
          style={{
            ...styles.smallItalicFont,
            ...fontStyle,
            textAlign: "center",
          }}
        >
          {text || ""}
        </Text>
      )}
    </View>
  </View>
);
