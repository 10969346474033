import React from "react";

// Material UI
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Custom imports
import Paper from "components/paper";
import {
  StyledClinicianInput,
  StyledClinicianEmailInput,
} from "components/uiElements";
import AddressDetails from "components/formSections/common/addressDetails";
import Action from "common/types/action";
import { FormStateActions } from "common/types/cto1";
import { ClinicianDetailsType } from "common/types/statForms";

export interface ClinicianDetailsProps<T = ClinicianDetailsType> {
  testIdKey?: string;
  clinicianDetails: T;
  dispatchFormState: React.Dispatch<Action>;
  disabled: boolean;
}

const ClinicianDetails: React.FC<ClinicianDetailsProps> = ({
  testIdKey,
  clinicianDetails: {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    email,
  },
  dispatchFormState,
  disabled,
}) => {
  return (
    <Paper>
      <Stack spacing={3}>
        <Stack spacing={2}>
          {/* Name */}
          <Typography variant="h4" display="inline">
            I (PRINT full name, address, and, if sending by means of electronic
            communication, email address of the responsible clinician)
          </Typography>
          <StyledClinicianInput
            testId={`${testIdKey}-name`}
            label="Clinician Name"
            name="clinician-name"
            type="text"
            variant="outlined"
            value={name}
            errorKey="clinicianDetails.name"
            onChange={(value: any) =>
              dispatchFormState({
                type: FormStateActions.SET_CLINICIAN_NAME,
                payload: value,
              })
            }
            disabled={disabled}
          />
          {/* Address */}
          <AddressDetails
            testIdKey={testIdKey}
            address={address}
            addressLabel="Clinician Address"
            manualAddress1={manualAddress1}
            manualAddress2={manualAddress2}
            manualPostcode={manualPostcode}
            setAddress={(value: any) =>
              dispatchFormState({
                type: FormStateActions.SET_CLINICIAN_ADDRESS,
                payload: value,
              })
            }
            errorKey="clinicianDetails.address"
            disabled={disabled}
          />
          {/* Email */}
          <StyledClinicianEmailInput
            testId={`${testIdKey}-email`}
            label="Clinician Email"
            variant="outlined"
            value={email}
            onChange={(value: any) =>
              dispatchFormState({
                type: FormStateActions.SET_CLINICIAN_EMAIL,
                payload: value,
              })
            }
            errorKey="clinicianDetails.email"
            disabled={disabled}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default React.memo(ClinicianDetails);
