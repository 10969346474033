import React from "react";

// Form Components
import FormContainer from "components/forms/formContainer";
import CTO5Header from "components/formSections/cto5/CTO5Header";
import ClinicianDetails from "components/formSections/common/clinicianDetails";
import PatientDetails from "components/formSections/common/patientDetails";
import Opinion from "components/formSections/cto5/opinion";
import AMHPDetails from "components/formSections/cto5/amhpDetails";
import CTODetails from "components/formSections/cto5/ctoDetails";
import HospitalDetails from "components/formSections/common/hospitalDetails";
import FormLoading from "components/forms/formLoading";

// Custom Imports
import {
  useForm,
  useUI,
  useFormValidation,
  useActorTypeStageChange,
  useFormAccess,
  useAuth,
} from "hooks";
import { FormStateActions } from "common/types/cto5";
import {
  generateFormItems,
  shouldDisablePrev,
  shouldDisableNext,
  shouldEnableDownload,
  validationSchema,
} from "./helpers";
import { useStyles } from "common/styles/form";
import { Actors } from "common/types/statForms";
import { isWard } from "utils/auth";
import { LoadingCircle } from "@s12solutions/ui";

/**
 * ------------------------------------------------------------
 * * WORKFLOW FOR CTO5 FORM
 * ------------------------------------------------------------
 * Stages
 * 1/ Responsible Clinician creates, signs and emails to AMHP
 * 2/ AHMP updates signs and emails back to Responsible Clinician
 * 3/ Responsible Clinician updates signs and emails to hospital managers
 * ------------------------------------------------------------
 */

const CTO5Form = () => {
  const classes = useStyles();
  const { actor, user } = useAuth();
  const { loading, formState, dispatchFormState, actorType, handleEmail } =
    useForm();
  const { currentSectionIndex } = useUI();

  const {
    clinicianDetails,
    patientDetails,
    hospitalDetails,
    opinion,
    amhpDetails,
    ctoDetails,
  } = formState.data;

  // * Start Component lifecycle methods ------------------------
  const { hasAccess, requiredEmail } = useFormAccess("CTO");

  useActorTypeStageChange(actorType, {
    [Actors.Originator]: 0,
    [Actors.AMHP]: 3,
    [Actors.Clinician]: 4,
    [Actors.MHAAdmin]: 4,
  });

  useFormValidation(validationSchema, [
    { clinicianDetails },
    { section1: { patientDetails, hospitalDetails } },
    { opinion },
    { amhpDetails },
    { ctoDetails },
  ]);

  const formItems = generateFormItems(actorType);
  const part1Disabled = !!opinion.signature;

  // Render
  // Render
  if (loading || !hasAccess) {
    return <LoadingCircle />;
  }
  if (isWard(user) && !actor) {
    return <FormLoading />;
  }

  return (
    <FormContainer
      sections={[
        <>
          <div className={classes.sectionContainer}>
            <CTO5Header />
          </div>
          <div className={classes.sectionContainer}>
            <ClinicianDetails
              testIdKey="cto5-clinician"
              clinicianDetails={clinicianDetails}
              dispatchFormState={dispatchFormState}
              disabled={part1Disabled}
            />
          </div>
        </>,
        <>
          <div className={classes.sectionContainer}>
            <PatientDetails
              testIdKey="cto5-patient"
              patientDetails={patientDetails}
              dispatchFormState={dispatchFormState}
              patientString="community patient"
              errorKey="section1.patientDetails"
              disabled={part1Disabled}
            />
            <div style={{ marginBottom: 12 }}></div>
            <HospitalDetails
              testIdKey="cto5"
              label="who is detained in (name and address of hospital)"
              name={hospitalDetails.name}
              address={hospitalDetails.address}
              manualAddress1={hospitalDetails.manualAddress1}
              manualAddress2={hospitalDetails.manualAddress2}
              manualPostcode={hospitalDetails.manualPostcode}
              setHospital={(value: any) =>
                dispatchFormState({
                  type: FormStateActions.SET_HOSPITAL,
                  payload: value,
                })
              }
              errorKey="section1.hospitalDetails"
              disabled={part1Disabled}
            />
          </div>
        </>,
        <div className={classes.sectionContainer}>
          <Opinion
            testIdKey="cto5-opinion"
            opinion={opinion}
            dispatchFormState={dispatchFormState}
          />
        </div>,
        <div className={classes.sectionContainer}>
          <AMHPDetails
            testIdKey="cto5-amhp"
            amhpDetails={amhpDetails}
            dispatchFormState={dispatchFormState}
            handleEmail={() =>
              handleEmail({
                toEmail: requiredEmail,
              })
            }
          />
        </div>,
        <div className={classes.sectionContainer}>
          <CTODetails
            testIdKey="cto5"
            ctoDetails={ctoDetails}
            dispatchFormState={dispatchFormState}
          />
        </div>,
      ]}
      formPartSelectorProps={{
        formItems,
        enableDownload: shouldEnableDownload(formState.data),
      }}
      formNavButtonProps={{
        formItems,
        lastSectionIndex: 4,
        disablePrev: shouldDisablePrev(actorType, currentSectionIndex),
        disableNext: shouldDisableNext(currentSectionIndex, actorType),
      }}
    />
  );
};

export default React.memo(CTO5Form);
