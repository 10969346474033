import dayjs from "dayjs";
import * as Yup from "yup";
import {
  Actors,
  FormState,
  PatientDetailsType,
  HospitalDetailsType,
  FormItemType,
  FormStatus,
} from "common/types/statForms";
import { AdmissionDetailsType, H3Form } from "common/types/h3";
import { signedFormHashed } from "common/libs/forms";
import {
  dateValidation,
  hospitalDetailsValidation,
  timeValidation,
} from "common/libs/validation";
import { CognitoUserExt } from "common/types/auth";
import { isWard } from "utils/auth";

/**
 * getActor
 * - if the user is a doctor and part 2 isn't signed -> stage 1 index 0
 * - if the user is a ward and part 1 is signed -> stage 2 index 1
 */
export const getActor = (
  formState: FormState<H3Form>,
  user: CognitoUserExt
): Actors | false => {
  const { admissionDetails } = formState.data;

  if (!admissionDetails.signaturePart1) {
    return Actors.Originator;
  }

  if (isWard(user) && admissionDetails.signaturePart1) {
    return Actors.MHAAdmin;
  }

  return false;
};

export const generateFormItems = (actor: Actors): FormItemType[] => {
  return [
    {
      index: 0,
      itemText: "1. Patient Details",
      disabled: actor !== Actors.Originator,
      errorKey: "section1",
    },
    {
      index: 1,
      itemText: "2. Admission Details",
      errorKey: "admissionDetails",
    },
  ];
};

export const handleStatus = (formState: FormState<H3Form>) => {
  if (
    (formState.status === "" || formState.status === FormStatus.s10_draft) &&
    !formState.data.admissionDetails.signaturePart1
  ) {
    return FormStatus.s10_draft;
  }

  return FormStatus.s40_signed;
};

export const validationSchema = [
  Yup.object().shape({
    hospitalDetails: Yup.object().shape(hospitalDetailsValidation),
    patientDetails: Yup.object().shape({
      name: Yup.string().trim().required("Patient name is required"),
    }),
  }),
  Yup.object().shape({
    inPatient: Yup.boolean(),
    admissionDate: dateValidation("Admission date", {
      allowFuture: false,
      allowGreaterThan1YearAgo: false,
    }),
    admissionTime: timeValidation("Admission time", {
      dateField: "admissionDate",
    }),
    section: Yup.string().nullable().required("Section is required"),
  }),
  Yup.object().shape({
    admissionDatePart2: dateValidation("Admission date", {
      allowFuture: false,
      allowGreaterThan1YearAgo: false,
    }),
    admissionTimePart2: timeValidation("Admission time", {
      dateField: "admissionDatePart2",
    }),
  }),
];

export const hasErrorsSection1 = (props: {
  patient: PatientDetailsType;
  hospital: HospitalDetailsType;
}) => {
  const { patient, hospital } = props;
  let error = false;
  if (!patient.name) error = true;
  if (!hospital.name) error = true;
  if (!hospital.address) error = true;
  return error;
};

export const hasErrorsSection2Part1 = (props: AdmissionDetailsType) => {
  const { signaturePart1, namePart1, signatureDatePart1 } = props;
  let error = false;

  if (!signaturePart1 && !namePart1 && !signatureDatePart1) error = true;

  return error;
};

export const hasErrorsSection2Part2 = (props: AdmissionDetailsType) => {
  const {
    section,
    admissionDatePart2,
    admissionTimePart2,
    signaturePart2,
    namePart2,
    signatureDatePart2,
  } = props;
  let error = false;

  if (section === "4") {
    if (!admissionDatePart2) error = true;
    if (!admissionTimePart2) error = true;
    if (!signaturePart2 && !namePart2 && !signatureDatePart2) error = true;
  }

  return error;
};

export const shouldDisableNext = (
  actorType: Actors,
  currentIndex: number,
  data: H3Form
): boolean => {
  const { admissionDetails } = data;
  switch (currentIndex) {
    case 1:
      return (
        actorType !== Actors.Originator ||
        hasErrorsSection2Part1(admissionDetails) ||
        admissionDetails.section !== "4"
      );
    default:
      return false;
  }
};

export const shouldDisablePrev = (
  actor: Actors,
  currentIndex: number
): boolean => {
  if (actor === Actors.MHAAdmin && currentIndex === 1) {
    return true;
  }

  return false;
};

export const shouldEnableDownload = (data: H3Form): boolean => {
  const { patientDetails, hospitalDetails, admissionDetails } = data;

  if (
    hasErrorsSection1({ patient: patientDetails, hospital: hospitalDetails })
  ) {
    return false;
  }
  if (hasErrorsSection2Part1(admissionDetails)) {
    return false;
  }
  if (
    admissionDetails.section === "4" &&
    hasErrorsSection2Part2(admissionDetails)
  ) {
    return false;
  }

  return true;
};

export const formatSaveCallback = (data: H3Form) => {
  // Create a composite object from objects in state
  // We will convert the dates to isoString at the end to allow typing within the inputs
  // As well as using the picker
  const newData = {
    hospitalDetails: { ...data.hospitalDetails },
    patientDetails: { ...data.patientDetails },
    admissionDetails: {
      ...data.admissionDetails,
      admissionDate: data.admissionDetails.admissionDate
        ? dayjs(data.admissionDetails.admissionDate).toISOString()
        : data.admissionDetails.admissionDate,
    },
  };

  // Handle admission details part 1 signature tracking id
  if (
    newData.admissionDetails.signaturePart1 &&
    !newData.admissionDetails.trackingIdPart1
  ) {
    newData.admissionDetails.trackingIdPart1 = signedFormHashed(newData);
  }

  // Handle admission details part 2 signature tracking id
  if (
    newData.admissionDetails.signaturePart2 &&
    !newData.admissionDetails.trackingIdPart2
  ) {
    newData.admissionDetails.trackingIdPart2 = signedFormHashed(newData);
  }

  return newData;
};
