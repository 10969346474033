import React, { useState, useCallback } from "react";

// Material UI
import { Button, Grid, Stack, Typography } from "@mui/material";

// Custom imports
import Action from "common/types/action";
import { FormStateActions } from "common/types/cto1";
import { CTODetails as CTODetailsType } from "common/types/cto";
import { useAuth, useForm } from "hooks";
import { formatUserName } from "common/libs/formatUserName";
import Paper from "components/paper";
import { StyledDatePicker, StyledTimePicker } from "components/uiElements";
import SignatureSection from "components/formSections/common/signatureSection";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

interface CTODetailsProps {
  testIdKey?: string;
  ctoDetails: CTODetailsType;
  dispatchFormState: React.Dispatch<Action>;
}

const CTODetails: React.FC<CTODetailsProps> = ({
  testIdKey,
  ctoDetails: { effectiveFromDate, effectiveFromTime, signature, date },
  dispatchFormState,
}) => {
  const { user } = useAuth();
  const { handleEmail } = useForm();
  const [signedStatus, setSignedStatus] = useState<boolean>(!!signature);

  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed ? formatUserName(user) : "";

      dispatchFormState({
        type: FormStateActions.SET_CTO_SIGNATURE,
        payload: signedValue,
      });
    },
    [dispatchFormState, user]
  );

  return (
    <Paper>
      <Stack spacing={4}>
        <Typography variant="h2" style={{ alignSelf: "center" }}>
          PART 3
        </Typography>
        <Stack spacing={4}>
          <Typography variant="h4" display="inline">
            I exercise my power under section 17A of the Mental Health Act 1983
            to make a community treatment order in respect of the patient named
            in Part 1 of this Form.
          </Typography>
          <Stack>
            <Grid container mb={2}>
              <Grid item xs={12} mb={3}>
                <Typography variant="h4" display="inline">
                  This community treatment order is to be effective from
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item>
                    <StyledDatePicker
                      testId={`${testIdKey}-date`}
                      label="CTO Date"
                      value={effectiveFromDate}
                      onChange={(newValue: any) => {
                        dispatchFormState({
                          type: FormStateActions.SET_CTO_DATE,
                          payload: newValue,
                        });
                      }}
                      errorKey="ctoDetails.effectiveFromDate"
                      disabled={signedStatus}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h4"
                      display="inline"
                      sx={{ marginLeft: 2 }}
                    >
                      (date)
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item xs={1}>
                    <Typography variant="h4" display="inline">
                      at
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <StyledTimePicker
                      testId={`${testIdKey}-time`}
                      label="CTO Time"
                      value={effectiveFromTime}
                      onChange={(newValue: any) => {
                        dispatchFormState({
                          type: FormStateActions.SET_CTO_TIME,
                          payload: newValue,
                        });
                      }}
                      errorKey="ctoDetails.effectiveFromTime"
                      disabled={signedStatus}
                      validateWith={{ effectiveFromDate }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      variant="h4"
                      display="inline"
                      sx={{ marginLeft: 2 }}
                    >
                      (time)
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <SignatureSection
              name={signature}
              date={date}
              signedStatus={signedStatus}
              setSignedStatus={handleSetSignedStatus}
            />
          </Stack>
          <Grid container>
            <Grid item sx={{ maxWidth: "80%", margin: "0 auto" }}>
              <Typography variant="h4" style={{ textAlign: "center" }}>
                <strong>
                  THIS COMMUNITY TREATMENT ORDER IS NOT VALID UNLESS ALL THREE
                  PARTS ARE COMPLETED AND SIGNED. IT MUST BE FURNISHED AS SOON
                  AS PRACTICABLE TO THE MANAGERS OF THE HOSPITAL IN WHICH THE
                  PATIENT WAS LIABLE TO BE DETAINED BEFORE THE ORDER WAS MADE
                </strong>
              </Typography>
            </Grid>
          </Grid>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            mt={2}
          >
            <Button
              data-test-id={`${testIdKey}-email-final`}
              variant="contained"
              onClick={() =>
                handleEmail({
                  includePDFAttachment: true,
                })
              }
              disabled={!signedStatus}
              size="large"
              sx={{ mr: 1 }}
            >
              Send to MHA Admin
            </Button>
            <PdfDownloadButton disabled={!signedStatus} />
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default React.memo(CTODetails);
