import React, { useState, useCallback } from "react";

// Material UI Imports
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Custom Imports
import {
  ClinicianSignature as ClinicianSignatureType,
  FormStateActions,
  PatientDetails as PatientDetailsType,
} from "common/types/cto12";
import { useAuth, useForm } from "hooks";
import { formatUserName } from "common/libs/formatUserName";
import GenericPatientDetails, {
  PatientDetailsProps as GenericPatientDetailsProps,
} from "components/formSections/common/patientDetails";
import SignatureSection from "components/formSections/common/signatureSection";
import Paper from "components/paper";
import { StyledTextArea, StyledRadioGroup } from "components/uiElements";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

const certifyItems = [
  {
    testId: "cto12-patient-capacity",
    key: "HAS_CAPACITY",
    value: "has the capacity",
  },
  {
    testId: "cto12-patient-competent",
    key: "IS_COMPETENT",
    value: "is competent to consent",
  },
];

type PatientDetailsProps<T> = GenericPatientDetailsProps<T> & {
  clinicianSignature: ClinicianSignatureType;
};

const PatientDetails: React.FC<PatientDetailsProps<PatientDetailsType>> = ({
  testIdKey,
  patientDetails,
  patientDetails: { capacity, description },
  clinicianSignature: { signature, date },
  dispatchFormState,
}) => {
  const { user } = useAuth();
  const { handleEmail, formErrors } = useForm();
  const [signedStatus, setSignedStatus] = useState<boolean>(!!signature);

  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed ? formatUserName(user) : "";

      dispatchFormState({
        type: FormStateActions.SET_CLINICIAN_SIGNATURE,
        payload: signedValue,
      });
    },
    [dispatchFormState, user]
  );

  return (
    <>
      <GenericPatientDetails
        testIdKey={testIdKey}
        patientDetails={patientDetails}
        dispatchFormState={dispatchFormState}
        disabled={signedStatus}
      />
      <Paper>
        <Stack spacing={2} mt={2}>
          <Typography variant="h4">
            who is subject to a community treatment order.
          </Typography>
          <Stack spacing={1}>
            <Typography variant="h4" display="inline">
              I certify that this patient{" "}
              <span className="italic">
                (Select the one option that is applicable)
              </span>
            </Typography>
            <StyledRadioGroup
              testId=""
              label=""
              value={capacity}
              onChange={(e: any) =>
                dispatchFormState({
                  type: FormStateActions.SET_PATIENT_CAPACITY,
                  payload: e.target.value,
                })
              }
              items={certifyItems}
              itemPadding="0 0 8px 0"
              disabled={signedStatus}
              error={!!formErrors?.["patientDetails.capacity"]}
              errorText={formErrors?.["patientDetails.capacity"]}
            />
            <Typography variant="h4" display="inline">
              and has consented to the following treatment. The treatment is:
            </Typography>
            <Typography variant="h4" display="inline" className="italic">
              (Give description of treatment or plan of treatment.)
            </Typography>
            <StyledTextArea
              testId={`${testIdKey}-description`}
              label=""
              value={description}
              onChange={(value: any) =>
                dispatchFormState({
                  type: FormStateActions.SET_PATIENT_DESCRIPTION,
                  payload: value,
                })
              }
              disabled={signedStatus}
              errorKey="patientDetails.description"
            />
          </Stack>
          <Stack>
            <SignatureSection
              name={signature}
              date={date}
              signedStatus={signedStatus}
              setSignedStatus={handleSetSignedStatus}
            />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Button
              data-test-id="cto12-email-final"
              variant="contained"
              onClick={() =>
                handleEmail({
                  includePDFAttachment: true,
                })
              }
              disabled={!signedStatus}
              size="large"
              sx={{ mr: 1 }}
            >
              Send to MHA Admin
            </Button>
            <PdfDownloadButton disabled={!signedStatus} />
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};

export default React.memo(PatientDetails);
