import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    top: "0",
    positionLeft: 0,
    height: ".1rem",
    zIndex: "100",
  },
}));

interface StrikeThroughProps {
  width: number;
}

const renderLine = (width: number) => {
  return new Array(width).fill("_").join("").toString();
};
const StrikeThrough: React.FC<StrikeThroughProps> = ({ width }) => {
  const classes = useStyles();
  return <div className={classes.container}>{renderLine(width)}</div>;
};

export { StrikeThrough };
