import * as Yup from "yup";
import {
  Actors,
  FormStatus,
  FormState,
  FormItemType,
  PatientDetails,
  ClinicianDetailsType,
} from "common/types/statForms";
import { AMHPDetails, CTODetails } from "common/types/cto";
import { CTO1Form, Opinion } from "common/types/cto1";
import {
  amhpDetailsValidation,
  clinicianDetailsValidation,
  dateValidation,
  opinionValidation,
  patientDetailsFullValidation,
  timeValidation,
} from "common/libs/validation";
import { CognitoUserExt } from "common/types/auth";
import { isAmhp, isDoctor, isWard } from "utils/auth";

export const handleStatus = (formState: FormState<CTO1Form>): FormStatus => {
  const { opinion } = formState.data;

  if (!opinion.signature) {
    return FormStatus.s10_draft;
  }
  return FormStatus.s40_signed;
};

/**
 * getActor
 * - if the user is a doctor, and part 2 is not signed -> stage 1 index 0
 * - if the user is an amhp, part 1 is signed and part 3 is not signed -> stage 2 index 4
 * - if the user is a doctor and part 2 is signed -> stage 3 index 5
 */

export const getActor = (
  formState: FormState<CTO1Form>,
  user: CognitoUserExt
): Actors | false => {
  const { opinion, amhpDetails } = formState.data;

  if (isDoctor(user) && !amhpDetails.signature) {
    return Actors.Originator;
  }

  if (isAmhp(user) && opinion.signature) {
    return Actors.AMHP;
  }

  if (isDoctor(user) && amhpDetails.signature) {
    return Actors.Clinician;
  }

  if (isWard(user)) {
    return Actors.MHAAdmin;
  }

  return false;
};

export const generateFormItems = (actor: Actors): FormItemType[] => [
  /** TO BE COMPLETED BY: Responsible Clinician */
  {
    index: 0,
    itemText: "1. Clinician Details",
    disabled: actor !== Actors.Originator,
    errorKey: "clinicianDetails",
  },
  {
    index: 1,
    itemText: "2. Patient Details",
    disabled: actor !== Actors.Originator,
    errorKey: "patientDetails",
  },
  {
    index: 2,
    itemText: "3. Opinion",
    disabled: actor !== Actors.Originator,
    errorKey: "opinion",
  },
  {
    index: 3,
    itemText: "4. Further Conditions",
    disabled: actor !== Actors.Originator,
  },
  /** TO BE COMPLETED BY: AMHP */
  {
    index: 4,
    itemText: "5. AMHP Details",
    disabled: actor !== Actors.AMHP,
    errorKey: "amhpDetails",
  },
  /** TO BE COMPLETED BY: Responsible Clinician */
  {
    index: 5,
    itemText: "6. CTO Details",
    disabled: actor !== Actors.Clinician && actor !== Actors.MHAAdmin,
    errorKey: "ctoDetails",
  },
];

export const validationSchema = [
  Yup.object().shape(clinicianDetailsValidation),
  Yup.object().shape(patientDetailsFullValidation),
  Yup.object().shape(opinionValidation),
  Yup.object().shape({}),
  Yup.object().shape(amhpDetailsValidation),
  Yup.object().shape({
    effectiveFromDate: dateValidation("CTO Date", {
      allowFuture: true,
      allowGreaterThan1YearAgo: false,
    }),
    effectiveFromTime: timeValidation("CTO Time", {
      allowFuture: true,
      allowGreaterThan1YearAgo: false,
      dateField: "effectiveFromDate",
    }),
  }),
];

export const hasErrorsSection1 = (props: ClinicianDetailsType): boolean => {
  const {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    email,
  } = props;
  let error = false;

  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;
  if (!email) error = true;

  return error;
};

export const hasErrorsSection2 = (props: PatientDetails): boolean => {
  const { name, address, manualAddress1, manualAddress2, manualPostcode } =
    props;
  let error = false;

  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;

  return error;
};

export const hasErrorsSection3 = (props: Opinion): boolean => {
  const { for: forValue, details } = props;
  let error = false;
  if (!forValue) error = true;
  if (!details) error = true;

  return error;
};

export const hasErrorsSection4 = (props: Opinion): boolean => {
  const { signature } = props;
  let error = false;
  if (!signature) error = true;

  return error;
};

export const hasErrorsSection5 = (
  props: AMHPDetails,
  requireSignature: boolean = true
): boolean => {
  const {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    authorityName,
    signature,
  } = props;
  let error = false;
  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;
  if (!authorityName) error = true;
  if (!signature && requireSignature) error = true;

  return error;
};

export const hasErrorsSection6 = (
  props: CTODetails,
  requireSignature: boolean = true
): boolean => {
  const { effectiveFromDate, effectiveFromTime, signature } = props;
  let error = false;
  if (!effectiveFromDate) error = true;
  if (!effectiveFromTime) error = true;
  if (!signature && requireSignature) error = true;

  return error;
};

export const shouldDisableNext = (
  currentIndex: number,
  actor: Actors
): boolean => {
  switch (currentIndex) {
    case 3:
      return actor === Actors.Originator;
    case 4:
      return actor === Actors.AMHP;
    default:
      return false;
  }
};

export const shouldDisablePrev = (actor: Actors, currentIndex: number) => {
  if (actor === Actors.AMHP && currentIndex === 4) {
    return true;
  }
  if (actor === Actors.Clinician && currentIndex === 5) {
    return true;
  }

  return false;
};

export const shouldEnableDownload = (data: CTO1Form): boolean => {
  const { clinicianDetails, patientDetails, opinion, amhpDetails, ctoDetails } =
    data;

  if (
    hasErrorsSection1(clinicianDetails) ||
    hasErrorsSection2(patientDetails) ||
    hasErrorsSection3(opinion) ||
    hasErrorsSection4(opinion) ||
    hasErrorsSection5(amhpDetails) ||
    hasErrorsSection6(ctoDetails)
  ) {
    return false;
  }

  return true;
};
