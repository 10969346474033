import { FC, memo, useCallback } from "react";
import dayjs from "dayjs";

// Material UI
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import HistoryIcon from "@mui/icons-material/History";

// Custom Imports
import { StatForm, StatFormEmail } from "@s12solutions/types";
import { Methods } from "api";
import { FormType } from "common/types/pdf";
import { FormState, ListStatFormEmailsResponse } from "common/types/statForms";
import { useAPI, useDeviceType, usePopups } from "hooks";
import Table from "components/table";
import DisableEmailLink from "components/forms/email/disableEmailLink/DisableEmailLink";
import StatFormActivity from "components/forms/activity/statFormActivity/StatFormActivity";

type EmailListProps = {
  handleView: (id: string, type: FormType, data: FormState["data"]) => void;
};

const EmailList: FC<EmailListProps> = ({ handleView }) => {
  const { handleDialog } = usePopups();
  const { isMobile } = useDeviceType();

  const {
    data,
    loading,
    trigger: refetch,
  } = useAPI<ListStatFormEmailsResponse>({
    method: Methods.GET,
    fieldName: "listStatFormEmails",
  });

  const handleActivityDialog = useCallback(
    (id: StatForm["id"]) => {
      handleDialog(<StatFormActivity formId={id} refetch={refetch} />, {
        title: "Activity Log",
        maxHeight: "55vh",
      });
    },
    [handleDialog, refetch]
  );

  const handleDisableEmail = useCallback(
    (emailLinkId: StatFormEmail["id"], emailLink: StatFormEmail["emailLink"]) =>
      handleDialog(
        <DisableEmailLink
          emailLinkId={emailLinkId}
          emailLink={emailLink}
          onSuccess={refetch}
        />,
        {
          title: "Disable email link",
        }
      ),
    [handleDialog, refetch]
  );

  return (
    <Box data-test-id="emaillist-table">
      <Table
        loading={loading}
        pageSize={isMobile ? 5 : 10}
        columns={[
          {
            field: "emailLinkType",
            headerName: "",
            width: 50,
            disableColumnMenu: true,
            hideSortIcons: true,
            renderCell: (params: any) => {
              const { emailLink } = params.row;
              return !emailLink ? (
                <Tooltip title="Email contains final pdf">
                  <TextSnippetIcon
                    style={{
                      color: "rgba(0, 0, 0, 0.54)",
                      height: 14,
                      width: 14,
                    }}
                  />
                </Tooltip>
              ) : (
                <></>
              );
            },
          },
          {
            field: "toEmail",
            headerName: "Sent To",
            width: isMobile ? 180 : 300,
          },
          {
            field: "patientName",
            headerName: "Patient",
            width: isMobile ? 160 : 220,
            renderCell: (params: any) =>
              params.row.patientName || (
                <span className="italic">Unspecified</span>
              ),
          },
          {
            field: "type",
            headerName: "Type",
            width: 80,
            valueGetter: (params: any) => `${params.row.type.toUpperCase()}`,
          },
          {
            field: "disabled",
            headerName: "Active Status",
            width: 120,
            renderCell: (params: any) => {
              const { disabled, emailLink } = params.row;

              return disabled ? (
                <Tooltip title="Email link is disabled">
                  <CloseIcon color="error" />
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    !emailLink
                      ? "Final pdf is ready for download"
                      : "Email link is active"
                  }
                >
                  <DoneIcon color="success" />
                </Tooltip>
              );
            },
          },
          {
            field: "createdAt",
            headerName: "Sent at",
            width: 190,
            valueGetter: (params: any) =>
              `${dayjs(params.row.createdAt).format("MMMM D YYYY [at] HH:mm")}`,
          },
          {
            field: "actions",
            headerName: "Actions",
            width: 180,
            disableColumnMenu: true,
            hideSortIcons: true,
            renderCell: (params: any) => {
              const {
                id,
                form: formId,
                type,
                data,
                emailLink,
                disabled,
              } = params.row;

              return (
                <Grid container flexDirection="row" alignItems="center">
                  <Grid item>
                    <Tooltip title="View Form Activity">
                      <IconButton
                        data-test-id=""
                        onClick={() => handleActivityDialog(formId)}
                      >
                        <HistoryIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="View Form">
                      <IconButton
                        data-test-id="forms-view"
                        onClick={() =>
                          handleView(formId, type as FormType, JSON.parse(data))
                        }
                      >
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Disable Email Link">
                      <IconButton
                        data-test-id="forms-disable-email-link"
                        onClick={() => handleDisableEmail(id, emailLink)}
                        disabled={disabled || !emailLink}
                      >
                        <NotInterestedIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              );
            },
          },
        ]}
        rows={data}
        card
      />
    </Box>
  );
};

export default memo(EmailList);
