import { useCallback, useState } from "react";

// Custom imports
import { LoadingCircle } from "@s12solutions/ui";
import { StatFormGroup, UserHasFormGroups } from "@s12solutions/types";
import { Actor, CognitoUserExt } from "common/types/auth";
import { Methods } from "api";
import { useAPI } from "hooks";
import { AuthContext } from "./authContext";
import { initialState } from "store/auth/authContext";

/**
 * AuthProvider
 * A very basic wrapper to enable putting the provider in the application tree where
 * we need it to be. Would rather do this at the root level application but can't with
 * current design choices there.
 *
 * This wrapper also handles the form groups the user has access to
 *
 * @param {React.ReactNode} children - the rest of the app
 * @returns stat forms application
 */

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  // State
  const [user, setUser] = useState<CognitoUserExt | undefined>(
    initialState.user
  );
  const [actor, setActor] = useState<Actor | undefined>(initialState.actor);
  const [formGroups, setFormGroups] = useState<StatFormGroup[]>(
    initialState.formGroups
  );

  /**
   * Queries
   * `userHasFormGroups`: to handle form group access
   */
  const shouldSkip: boolean = !user || (user && !user.signInUserSession);
  const { loading, trigger: refetchUserHasFormGroups } = useAPI<
    UserHasFormGroups[]
  >({
    method: Methods.GET,
    fieldName: "userHasFormGroups",
    skip: shouldSkip,
    onCompleted: useCallback(
      (data) => {
        if (formGroups.length === 0 && data) {
          setFormGroups(data.map((item: UserHasFormGroups) => item.form));
        }
      },
      [formGroups]
    ),
  });

  if (loading) {
    return <LoadingCircle />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        actor,
        formGroups,
        authService: initialState.authService,
        setCredentials: (user) => {
          setUser(user);

          if (user && user.signInUserSession) {
            refetchUserHasFormGroups();
          }
        },
        clearCredentials: () => {
          setFormGroups([]);
          setActor(undefined);
          setUser(undefined);
        },
        setActor,
        clearActor: () => setActor(undefined),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
