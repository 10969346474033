import {
  ClinicianDetailsType as GenericClinicianDetailsType,
  PatientDetails as GenericPatientDetailsType,
  SignatureType,
} from "common/types/statForms";
import { FormPDFConfigData } from "./pdf";

export type ClinicianDetailsType = GenericClinicianDetailsType & {
  approvedClinician?: boolean;
};

export type PatientDetailsType = GenericPatientDetailsType & {
  description: string;
};

export interface T2Form {
  clinicianDetails: ClinicianDetailsType;
  patientDetails: PatientDetailsType;
  clinicianSignature: SignatureType;
}

export const pdfConfig: FormPDFConfigData = {
  longTitle: "Section 58(3)(a) — certificate of consent to treatment",
  regulation: "27(2)",
  footerText: "Form amended by SI 2020/1072 for use from 1 December 2020",
  footerTextPage1: true,
};

export enum FormStateActions {
  LOAD_FORM = "LOAD_FORM",
  SET_CLINICIAN_NAME = "SET_CLINICIAN_NAME",
  SET_CLINICIAN_ADDRESS = "SET_CLINICIAN_ADDRESS",
  SET_CLINICIAN_EMAIL = "SET_CLINICIAN_EMAIL",
  SET_CLINICIAN_ROLE = "SET_CLINICIAN_ROLE",
  SET_PATIENT_NAME = "SET_PATIENT_NAME",
  SET_PATIENT_ADDRESS = "SET_PATIENT_ADDRESS",
  SET_PATIENT_TREATMENT_DESCRIPTION = "SET_PATIENT_TREATMENT_DESCRIPTION",
  SET_CLINICIAN_SIGNATURE = "SET_CLINICIAN_SIGNATURE",
}
