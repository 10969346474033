import { FC, memo, ReactElement } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

// Homepage
import Dashboard from "pages/dashboard";
// Hospital Forms
import H1 from "pages/h1";
import H3 from "pages/h3";
// CTO Forms
import CTO1 from "pages/cto1";
import CTO3 from "pages/cto3";
import CTO4 from "pages/cto4";
import CTO5 from "pages/cto5";
import CTO7 from "pages/cto7";
import CTO12 from "pages/cto12";
// Treatment Forms
import T2 from "pages/t2";
// Error Pages
import EmailLinkInvalid from "pages/emailLinkInvalid";
import Unauthorised from "pages/unauthorised";
import NotFound from "pages/notFound";
// Types
import { RouteConstants } from "common/constants/routes";
import { FormType } from "common/types/pdf";
// Store
import { FormProvider } from "store/forms/formProvider";

/**
 * -------------------------------------------------------------------
 * 👇 Add forms below to make them accessible available via /:formType
 * -------------------------------------------------------------------
 */
const formTypeToComponentLookup: { [key in FormType]: ReactElement } = {
  // Hospital
  [FormType.H1]: <H1 />,
  [FormType.H3]: <H3 />,
  // CTO
  [FormType.CTO1]: <CTO1 />,
  [FormType.CTO4]: <CTO4 />,
  [FormType.CTO3]: <CTO3 />,
  [FormType.CTO5]: <CTO5 />,
  [FormType.CTO7]: <CTO7 />,
  [FormType.CTO12]: <CTO12 />,
  // Treatment
  [FormType.T2]: <T2 />,
};

// `Form` will return the correct form based on the urlParam `formType`, wrapped
// in a fresh `FormProvider` every time
const Form: FC = memo(() => {
  const { formType } = useParams();
  const castedFormType = formType as FormType; // always safe to cast here

  // Check if formType is valid
  const validFormTypes = Object.keys(formTypeToComponentLookup);
  if (!validFormTypes.includes(castedFormType)) {
    return <Navigate to={RouteConstants.NOT_FOUND} replace />;
  }

  // FormProvider needs to be refreshed every time the formType changes
  const RenderedForm = () => (
    <FormProvider>{formTypeToComponentLookup[castedFormType]}</FormProvider>
  );

  return <RenderedForm />;
});

// Application Routes
const ApplicationRoutes: FC = () => {
  return (
    <Routes>
      {/* Dashboard */}
      <Route path={RouteConstants.ROOT}>
        <Route index element={<Navigate to={RouteConstants.DASHBOARD} />} />
        <Route path={RouteConstants.DASHBOARD} element={<Dashboard />} />
      </Route>
      {/* Form Editor */}
      <Route path=":formType">
        <Route path="" element={<Form />} />
        <Route path=":formId">
          <Route path=":stage">
            <Route index element={<Form />} />
            <Route path=":emailLinkId" element={<Form />} />
          </Route>
        </Route>
      </Route>
      {/* Error Routes */}
      <Route
        path={RouteConstants.LINK_INVALID}
        element={<EmailLinkInvalid />}
      />
      <Route path={RouteConstants.UNAUTHORISED} element={<Unauthorised />} />
      <Route path={RouteConstants.NOT_FOUND} element={<NotFound />} />
      {/* Fallback redirect to not-found */}
      <Route
        path="*"
        element={<Navigate to={RouteConstants.NOT_FOUND} replace />}
      />
    </Routes>
  );
};

export default memo(ApplicationRoutes);
