import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    backgroundColor: theme.palette.info.dark,
  },
  input: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  inputDisabled: {
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    borderRadius: ".5rem",
  },
  row: {
    display: "flex",
  },
  inPatient: {
    color: theme.palette.primary.main,
  },
  dateTime: {
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
}));

export { useStyles };
