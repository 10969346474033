import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { StyledInformationBox } from "../formComponents/StyledInformationBox";
import { StyledText } from "../formComponents/StyledText";
import { StyledCheckbox } from "../formComponents/StyledCheckbox";
import { PDFSignatures } from "../formComponents/baseComponents/PDFSignatures";
import { T2Form } from "common/types/t2";
import { formatNameAndAddressText } from "utils/pdf";

/**
 * T2Form
 * @param {T2Form} T2Form
 * @returns {JS.Element}
 */
export const T2Body: React.FC<T2Form> = ({
  clinicianDetails,
  patientDetails,
  clinicianSignature: { signature, date, trackingId },
}) => {
  console.log("🚀 ~ file: index.tsx:20 ~ clinicianDetails:", clinicianDetails);
  // Clinician Details
  const clinicianNameAndAddress = formatNameAndAddressText(
    clinicianDetails.name,
    clinicianDetails.address,
    clinicianDetails.manualAddress1,
    clinicianDetails.manualAddress2,
    clinicianDetails.manualPostcode,
    clinicianDetails.email
  );

  // Patient Details
  const patientNameAndAddress = formatNameAndAddressText(
    patientDetails.name,
    patientDetails.address,
    patientDetails.manualAddress1,
    patientDetails.manualAddress2,
    patientDetails.manualPostcode
  );

  return (
    <View>
      <StyledText
        instruction="I"
        guidance="(PRINT full name, address and, if sending by means of electronic communication, email address)"
      />
      <StyledInformationBox value={clinicianNameAndAddress} minHeight={50} />

      <Text style={{ maxWidth: "94%", marginTop: 2, marginBottom: 8 }}>
        <Text
          style={{
            textDecoration: !clinicianDetails.approvedClinician
              ? "line-through"
              : undefined,
          }}
        >
          the approved clinician in charge of the treatment described below
        </Text>{" "}
        /{" "}
        <Text
          style={{
            textDecoration: clinicianDetails.approvedClinician
              ? "line-through"
              : undefined,
          }}
        >
          a registered medical practitioner appointed for the purposes of Part 4
          of the Act (a SOAD)
        </Text>
        <Text style={{ fontStyle: "italic" }}>
          {" "}
          (delete the phrase which does not apply){" "}
        </Text>
        certify that
      </Text>

      <StyledText
        instruction=""
        guidance="(PRINT full name and address of patient)"
        noMargin
        style={{ fontSize: 12 }}
      />
      <StyledInformationBox
        value={patientNameAndAddress}
        minHeight={50}
        style={{ margin: 0, marginBottom: 6 }}
      />
      <View style={{ marginBottom: 6 }}>
        <StyledCheckbox
          value={0}
          options={[
            {
              value: 0,
              component: (
                <View>
                  <StyledText
                    instruction="(a) is capable of understanding the nature, purpose and likely effects of: (Give description of "
                    noMargin
                  />
                  <StyledText
                    instruction="treatment or plan of treatment. Indicate clearly if the certificate is only to apply to any or all of the treatment for a specific period.)"
                    noMargin
                  />
                  <View style={{ marginTop: 2 }}>
                    <StyledInformationBox
                      value={patientDetails.description}
                      minHeight={290}
                    />
                    <StyledText
                      instruction="AND"
                      style={{ marginTop: 12, marginBottom: 12 }}
                    />
                  </View>
                </View>
              ),
            },
            {
              value: 1,
              label: "(b) has consented to that treatment.",
            },
          ]}
          display
        />
      </View>

      <PDFSignatures sha256={trackingId} date={date} signed={signature} />
    </View>
  );
};
