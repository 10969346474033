import React, { useRef } from "react";
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useForm } from "hooks";
import ErrorLabel from "./ErrorLabel";

interface StyledCheckboxesItemProps {
  key: any;
  value: string | React.ReactElement;
}
interface StyledCheckboxesProps {
  testIdKey?: string;
  disabled?: boolean;
  label?: string;
  value: any[];
  onChange: (value: any) => void;
  error?: boolean;
  errorText?: string;
  errorKey?: string;
  /**
   * @param { {[key: string]: any} } validateWith
   * Sometimes we need more flexibility with input validation, e.g, when the input validation
   * relies on another value
   */
  validateWith?: { [key: string]: any };
  items: StyledCheckboxesItemProps[];
  itemPadding?: number;
}

const StyledCheckboxes: React.FC<StyledCheckboxesProps> = ({
  testIdKey,
  disabled,
  label,
  value,
  onChange,
  error,
  errorText,
  errorKey,
  validateWith,
  items,
  itemPadding,
}) => {
  const { handleValidateValue } = useForm();
  // We use refs to keep track of the values to validate as we can't rely on the
  // `formState`. It's not updated in time after triggering the `onChange` function.
  const checkboxRefs = useRef(Array(items.length).fill(null));

  return (
    <div
      style={{
        borderRadius: ".5rem",
        background: "white",
        marginTop: 4,
        marginBottom: 4,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 32,
        border: "1px solid rgba(0, 0, 0, 0.23)",
      }}
    >
      {label && (
        <Typography variant="h4" display="inline">
          {label}
        </Typography>
      )}
      <FormGroup
        // disabled={disabled}
        aria-label={label}
        onChange={async (e: any) => {
          const { value: newValue } = e.target;
          onChange(newValue);

          if (errorKey) {
            // Rebuild value using refs as `formState` will not be up to date
            // at the point the validation is supposed to run
            const checkboxValues: StyledCheckboxesProps["value"] =
              checkboxRefs.current
                .map((checkboxRef) => checkboxRef.checked && checkboxRef.id)
                .filter(Boolean);
            let validateValue;

            if (validateWith) {
              const errorKeys = errorKey.split(".");
              const validateName = errorKeys[errorKeys.length - 1];
              validateValue = {
                ...validateWith,
                [validateName]: checkboxValues,
              };
            } else {
              validateValue = checkboxValues;
            }

            await handleValidateValue(errorKey, validateValue);
          }
        }}
      >
        {items.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item.key}
            control={
              <Checkbox
                data-test-id={`${testIdKey}-${index + 1}`}
                id={item.key}
                inputRef={(ref) => (checkboxRefs.current[index] = ref)}
                sx={{ padding: 0.5, paddingBottom: itemPadding || undefined }}
                checked={value.includes(item.key)}
                disabled={disabled}
              />
            }
            label={
              <Typography
                variant="h4"
                display="inline"
                // className={value.includes(item.key) ? "strikeThrough" : ""}
              >
                {item.value}
              </Typography>
            }
            sx={{ fontWeight: 400 }}
          />
        ))}
      </FormGroup>
      <ErrorLabel
        error={error}
        errorText={errorText}
        errorKey={errorKey}
        align="left"
      />
    </div>
  );
};

export default React.memo(StyledCheckboxes);
