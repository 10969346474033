import {
  PatientDetails as GenericPatientDetails,
  SignatureType,
  ClinicianDetailsType,
} from "common/types/statForms";
import { FormPDFConfigData } from "./pdf";

export type PatientDetails = GenericPatientDetails & {
  capacity?: string;
  description: string;
};

export type ClinicianSignature = SignatureType;

export interface CTO12Form {
  clinicianDetails: ClinicianDetailsType;
  patientDetails: PatientDetails;
  clinicianSignature: ClinicianSignature;
}

export const pdfConfig: FormPDFConfigData = {
  fontSize: 11,
  longTitle: `Section 64C(4A) – Certificate that community patient has capacity to consent
  (or if under 16 is competent to consent) to treatment and has done so
  (Part 4A consent certificate)`,
  longTitleFontSize: 11,
  longTitleCenter: true,
  regulation: "28(1A)",
  pagePadding: "12px 22px 2px",
  footerText: "Form amended by SI 2020/1072 for use from 1 December 2020",
  footerTextPage1: true,
  shouldDisplayPDFHeader: false,
};

export enum FormStateActions {
  LOAD_FORM = "LOAD_FORM",
  SET_CLINICIAN_NAME = "SET_CLINICIAN_NAME",
  SET_CLINICIAN_ADDRESS = "SET_CLINICIAN_ADDRESS",
  SET_CLINICIAN_EMAIL = "SET_CLINICIAN_EMAIL",
  SET_PATIENT_NAME = "SET_PATIENT_NAME",
  SET_PATIENT_ADDRESS = "SET_PATIENT_ADDRESS",
  SET_PATIENT_CAPACITY = "SET_PATIENT_CAPACITY",
  SET_PATIENT_DESCRIPTION = "SET_PATIENT_DESCRIPTION",
  SET_CLINICIAN_SIGNATURE = "SET_CLINICIAN_SIGNATURE",
}
