import React from "react";

// Form Components
import FormContainer from "components/forms/formContainer";
import CTO1Header from "components/formSections/cto1/CTO1Header";
import ClinicianDetails from "components/formSections/common/clinicianDetails";
import PatientDetails from "components/formSections/common/patientDetails";
import Opinion from "components/formSections/common/opinion";
import FurtherConditions from "components/formSections/cto1/furtherConditions";
import AMHPDetails from "components/formSections/cto1/amhpDetails";
import CTODetails from "components/formSections/cto1/ctoDetails";
import FormLoading from "components/forms/formLoading/FormLoading";

// Custom Imports
import {
  useActorTypeStageChange,
  useAuth,
  useForm,
  useFormAccess,
  useFormValidation,
  useUI,
} from "hooks";
import {
  generateFormItems,
  shouldDisablePrev,
  shouldDisableNext,
  shouldEnableDownload,
  validationSchema,
} from "./helpers";
import { useStyles } from "common/styles/form";
import { Actors } from "common/types/statForms";
import { isWard } from "utils/auth";
import { LoadingCircle } from "@s12solutions/ui";

/**
 * ------------------------------------------------------------
 * * WORKFLOW FOR CTO1 FORM
 * ------------------------------------------------------------
 * Stages
 * 1/ Responsible Clinician creates, signs and emails to AMHP
 * 2/ AHMP updates signs and emails back to Responsible Clinician
 * 3/ Responsible Clinician updates signs and emails to hospital managers
 * ------------------------------------------------------------
 */

const CTO1Form = () => {
  const classes = useStyles();
  const { actor, user } = useAuth();
  const { loading, formState, dispatchFormState, actorType, handleEmail } =
    useForm();
  const { currentSectionIndex } = useUI();
  const { clinicianDetails, patientDetails, opinion, amhpDetails, ctoDetails } =
    formState.data;
  const { hasAccess, requiredEmail } = useFormAccess("CTO");

  useActorTypeStageChange(actorType, {
    [Actors.Originator]: 0,
    [Actors.AMHP]: 4,
    [Actors.Clinician]: 5,
    [Actors.MHAAdmin]: 5,
  });

  useFormValidation(validationSchema, [
    { clinicianDetails },
    { patientDetails },
    { opinion },
    {}, // further details is optional
    { amhpDetails },
    { ctoDetails },
  ]);

  const formItems = generateFormItems(actorType);
  const part1Disabled = !!opinion.signature;

  // Render
  if (loading || !hasAccess) {
    return <LoadingCircle />;
  }
  if (isWard(user) && !actor) {
    return <FormLoading />;
  }

  return (
    <FormContainer
      sections={[
        <>
          <div className={classes.sectionContainer}>
            <CTO1Header />
          </div>
          <div className={classes.sectionContainer}>
            <ClinicianDetails
              testIdKey="cto1-clinician"
              clinicianDetails={clinicianDetails}
              dispatchFormState={dispatchFormState}
              disabled={part1Disabled}
            />
          </div>
        </>,
        <div className={classes.sectionContainer}>
          <PatientDetails
            testIdKey="cto1-patient"
            patientDetails={patientDetails}
            dispatchFormState={dispatchFormState}
            disabled={part1Disabled}
          />
        </div>,
        <div className={classes.sectionContainer}>
          <Opinion
            testIdKey="cto1-opinion"
            opinion={opinion}
            dispatchFormState={dispatchFormState}
            disabled={part1Disabled}
          />
        </div>,
        <div className={classes.sectionContainer}>
          <FurtherConditions
            opinion={opinion}
            dispatchFormState={dispatchFormState}
          />
        </div>,
        <div className={classes.sectionContainer}>
          <AMHPDetails
            amhpDetails={amhpDetails}
            dispatchFormState={dispatchFormState}
            handleEmail={() =>
              handleEmail({
                toEmail: requiredEmail,
              })
            }
          />
        </div>,
        <div className={classes.sectionContainer}>
          <CTODetails
            testIdKey="cto1-details"
            ctoDetails={ctoDetails}
            dispatchFormState={dispatchFormState}
          />
        </div>,
      ]}
      formPartSelectorProps={{
        formItems,
        enableDownload: shouldEnableDownload(formState.data),
      }}
      formNavButtonProps={{
        formItems,
        lastSectionIndex: 5,
        disablePrev: shouldDisablePrev(actorType, currentSectionIndex),
        disableNext: shouldDisableNext(currentSectionIndex, actorType),
      }}
    />
  );
};

export default React.memo(CTO1Form);
