import { useState, useCallback } from "react";
import { AlertColor } from "@mui/material";

import {
  Dialog as DialogType,
  Confirmation as ConfirmationType,
  Snackbar as SnackbarType,
} from "./types";
import { PopupsContext, initialState } from "./popupsContext";

import Dialog from "components/dialog";
import Confirmation from "components/confirmation";
import Snackbar from "components/snackbar";

/**
 * The popups provider wraps the whole application and allows global use of the
 * confirmation dialog (components/confirmation) and snackbar component (components/snackbar).
 * The values and functions in this provider are accessible via the usePopups hook defined at the bottom of this file
 */
export const PopupsProvider = ({ children }: { children: React.ReactNode }) => {
  const [confirmation, setConfirmation] = useState<ConfirmationType>(
    initialState.confirmation
  );
  const [snackbar, setSnackbar] = useState<SnackbarType>(initialState.snackbar);
  const [dialog, setDialog] = useState<DialogType[]>(initialState.dialog);

  const closeConfirmation = useCallback(() => {
    setConfirmation(initialState.confirmation);
  }, []);

  /**
   * Handle Confirmation
   * create a confirmation dialog with a custom message and success callback function
   * @param {string} message The message you would like to display to the user in the confirmation dialog
   * @param {Function} successCallback The function you would like to execute if the user clicks confirm, this will also close the dialog
   * @returns void
   */
  const handleConfirmation = useCallback(
    (message: string, successCallback: Function, title: string) => {
      setConfirmation({
        message,
        successCallback: () => {
          successCallback();
          closeConfirmation();
        },
        title,
      });
    },
    [closeConfirmation]
  );

  /**
   * Handle Snackbar
   * create any variant of a Material UI snackbar with a custom message
   * @param {AlertColor} type The variant of the Material UI snackbar
   * @param {string} message The message you would like to display to the user
   * @returns void
   */
  const handleSnackbar = useCallback((type: AlertColor, message: string) => {
    setSnackbar({
      type,
      message,
    });
  }, []);

  const closeSnackbar = useCallback(() => {
    setSnackbar(initialState.snackbar);
  }, []);

  /**
   * Handle Dialog
   * creates a dialog with a custom component as its child with custom options
   * @param {DialogType["component"]} component The component you would like to display in the dialog
   * @param {DialogType["options"]} options The options you would like to pass to the Material UI Dialog component
   * @returns void
   */
  const handleDialog = useCallback(
    (component: DialogType["component"], options: DialogType["options"]) => {
      if (
        options.key &&
        dialog.find((dialog) => dialog.options.key === options.key)
      ) {
        return;
      }

      setDialog((prevState) => [
        ...prevState,
        {
          component,
          options,
        },
      ]);
    },
    [dialog]
  );

  const closeDialog = useCallback(() => {
    setDialog((prevState) => prevState.slice(0, -1));
  }, []);

  const contextValue = {
    confirmation,
    handleConfirmation,
    closeConfirmation,
    snackbar,
    handleSnackbar,
    closeSnackbar,
    dialog,
    handleDialog,
    closeDialog,
  };

  return (
    <PopupsContext.Provider value={contextValue}>
      {children}
      {dialog.map((_, index) => (
        <Dialog key={index} index={index} />
      ))}
      <Confirmation />
      <Snackbar />
    </PopupsContext.Provider>
  );
};
