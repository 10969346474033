import React, { useCallback, useState } from "react";

// Material UI
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Custom imports
import Action from "common/types/action";
import {
  FormStateActions,
  FurnishingReportType,
  H1PatientDetailsType,
} from "common/types/h1";
import BulletList from "components/bulletList/BulletList";
import Paper from "components/paper";
import { StyledInput, StyledTextArea } from "components/uiElements";
import SignatureSection from "components/formSections/common/signatureSection/SignatureSection";
import { useAuth, useForm } from "hooks";
import { Button } from "@mui/material";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

const DetailedReasons = () => (
  <Stack>
    <BulletList
      heading="Includes:"
      items={[
        "When the patient was admitted",
        "Reason for admission",
        "Evidence of mental disorder (not just a description of recent behaviour)",
        "Risks to self or others, stemming from the mental disorder, which need to be managed as an in-patient, and why the patient should not be allowed to leave.",
        "Patient’s attitude to being kept in hospital (Objecting? Consenting, but consent not reliable? Lacking capacity to consent to ongoing admission/treatment?)",
      ]}
    />
  </Stack>
);

interface PatientDetailsProps {
  patientDetails: H1PatientDetailsType;
  furnishingReport: FurnishingReportType;
  dispatchFormState: React.Dispatch<Action>;
  disabled: boolean;
}

const PatientDetails: React.FC<PatientDetailsProps> = ({
  patientDetails: { name: patientName, detailedReasons },
  furnishingReport: { signature, date },
  dispatchFormState,
  disabled,
}) => {
  const { user, actor } = useAuth();
  const { handleEmail } = useForm();
  const [signedStatus, setSignedStatus] = useState<boolean>(!!signature);

  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed
        ? actor
          ? actor?.name
          : user
          ? user?.attributes?.name
          : ""
        : "";

      dispatchFormState({
        type: FormStateActions.SET_PRACTITIONER_SIGNATURE,
        payload: signedValue,
      });
    },
    [dispatchFormState, actor, user]
  );

  return (
    <Paper>
      <Stack spacing={3}>
        <Stack spacing={2}>
          <Typography variant="h4" display="inline">
            in charge of the treatment of (PRINT full name of patient)
          </Typography>
          <Stack spacing={1}>
            <StyledInput
              testId="h1-patient-name"
              label="Patient Name"
              variant="outlined"
              value={patientName}
              errorKey="patientDetails.name"
              onChange={(value: any) =>
                dispatchFormState({
                  type: FormStateActions.SET_PATIENT_NAME,
                  payload: value,
                })
              }
              disabled={disabled}
            />
            <Typography variant="h4" display="inline">
              who is an in-patient of this hospital and not at present liable to
              be detained under the Mental Health Act 1983.
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <Typography variant="h4" display="inline">
            It appears to me that an application ought to be made under Part 2
            of the Act for this patient's admission to hospital for the
            following reasons-
          </Typography>
          <Typography
            variant="h4"
            display="inline"
            sx={{ fontStyle: "italic" }}
          >
            (The full reasons why informal treatment is no longer appropriate
            must be given.)
          </Typography>
          <StyledTextArea
            testId="h1-patient-details"
            label=""
            value={detailedReasons}
            errorKey="patientDetails.detailedReasons"
            onChange={(value: any) =>
              dispatchFormState({
                type: FormStateActions.SET_DETAILED_REASONS,
                payload: value,
              })
            }
            templateProps={{
              name: "Detailed Reasons Template",
              template: DetailedReasons,
            }}
            disabled={disabled}
          />
        </Stack>
        <Stack>
          <SignatureSection
            name={signature}
            date={date}
            signedStatus={signedStatus}
            setSignedStatus={handleSetSignedStatus}
          />
        </Stack>
        <Stack flexDirection="row" justifyContent="center" alignItems="center">
          <Button
            data-test-id="h1-email-form-part-1"
            variant="contained"
            onClick={() => handleEmail()}
            disabled={!signedStatus}
            size="large"
            sx={{ mr: 1 }}
          >
            Send to Hospital Manager
          </Button>
          <PdfDownloadButton disabled={!signedStatus} />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default React.memo(PatientDetails);
