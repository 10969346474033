import { FormState, FormTypes } from "common/types/statForms";

export const formatInitialFormState = <T = FormTypes>(
  data: T
): FormState<T> => ({
  initialData: data,
  data,
  version: 0,
  status: "",
  type: undefined,
  createdAt: "",
  createdBy: "",
  updatedAt: "",
  updatedBy: "",
});
