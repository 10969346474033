import { CTO3Form } from "common/types/cto3";
import { formatInitialFormState } from "../helpers";

export const data: CTO3Form = {
  patientDetails: {
    name: "",
  },
  hospitalDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
  },
  opinion: {
    for: undefined,
    details: "",
    purpose: [],
    signature: "",
    trackingId: "",
    date: "",
  },
};

export const cto3InitialState = formatInitialFormState(data);
