import {
  HospitalDetailsType,
  PatientDetailsType,
  SignatureType,
} from "common/types/statForms";
import { FormPDFConfigData } from "./pdf";

export type YourDetailsType = {
  practitionerName: string;
  isNominee?: boolean;
  isMedicalPractitioner?: boolean;
};

export type H1PatientDetailsType = PatientDetailsType & {
  detailedReasons: string;
};

export type FurnishingReportType = SignatureType;

export type WardSignatureType = SignatureType;

export interface H1Form {
  hospitalDetails: HospitalDetailsType;
  yourDetails: YourDetailsType;
  patientDetails: H1PatientDetailsType;
  furnishingReport: FurnishingReportType;
  wardSignature: WardSignatureType;
}

export enum FormStateActions {
  LOAD_FORM = "LOAD_FORM",
  SET_HOSPITAL = "SET_HOSPITAL",
  SET_PRACTITIONER_NAME = "SET_PRACTITIONER_NAME",
  SET_IS_NOMINEE = "SET_IS_NOMINEE",
  SET_IS_MEDICAL_PRACTITIONER = "SET_IS_MEDICAL_PRACTITIONER",
  SET_PATIENT_NAME = "SET_PATIENT_NAME",
  SET_DETAILED_REASONS = "SET_DETAILED_REASONS",
  SET_PRACTITIONER_SIGNATURE = "SET_PRACTITIONER_SIGNATURE",
  SET_PRACTITIONER_SIGNATURE_DATE = "SET_PRACTITIONER_SIGNATURE_DATE",
  SET_WARD_SIGNATURE = "SET_WARD_SIGNATURE",
  SET_WARD_SIGNATURE_DATE = "SET_WARD_SIGNATURE_DATE",
}

export const pdfConfig: FormPDFConfigData = {
  longTitle: "Section 5(2) - report on hospital in-patient",
  regulation: "4(1)(g)",
  footerText: "Form amended by SI 2020/1072 for use from 1 December 2020",
  pagePadding: "16px 22px 0px 20px",
};
