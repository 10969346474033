import React from "react";
import { View } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { PDFSubHeader } from "../formComponents/baseComponents/PDFSubHeader";
import {
  StyledDateOrTime,
  DateOrTime,
} from "../formComponents/StyledDateOrTime";
import { StyledInformationBox } from "../formComponents/StyledInformationBox";
import { StyledText } from "../formComponents/StyledText";
import { StyledCheckbox } from "../formComponents/StyledCheckbox";
import { PDFSignatures } from "../formComponents/baseComponents/PDFSignatures";
import { CTO3Form, CtoFor, CtoPurpose } from "common/types/cto3";
import { formatNameAndAddressText } from "utils/pdf";

/**
 * CTO3Form
 * @param {CTO3Form} CTO3Form
 * @returns {JSX.Element}
 */
export const CTO3Body: React.FC<CTO3Form> = ({
  patientDetails,
  hospitalDetails,
  opinion: { for: forValue, details, purpose, signature, trackingId, date },
}) => {
  // Hospital Details
  const hospitalNameAndAddress = formatNameAndAddressText(
    hospitalDetails.name,
    hospitalDetails.address,
    hospitalDetails.manualAddress1,
    hospitalDetails.manualAddress2,
    hospitalDetails.manualPostcode
  );

  return (
    <View>
      {/* Patient Details */}
      <PDFSubHeader
        text="(To be completed by the responsible clinician)"
        style={{ maxWidth: "90%", margin: "12px auto" }}
        fontStyle={{ fontSize: 11.5, fontStyle: "italic" }}
      />
      <StyledText instruction="I notify you," style={{ marginBottom: 6 }} />
      <StyledText
        instruction=""
        guidance="(PRINT name of community patient),"
        noMargin
      />
      <StyledInformationBox
        value={patientDetails.name}
        after={<StyledText instruction="that you are recalled to" noMargin />}
      />
      {/* Hospital Details */}
      <StyledText
        instruction=""
        guidance="(PRINT full name and address of the hospital)"
      />
      <StyledInformationBox
        value={hospitalNameAndAddress}
        after={
          <StyledText
            instruction="under section 17E of the Mental Health Act 1983."
            noMargin
          />
        }
        minHeight={50}
      />
      {/* Opinion */}
      <StyledText
        instruction=""
        guidance="Complete either (a) or (b) below and delete the one which does not apply."
        style={{ margin: "16px 0px 8px" }}
      />
      <View style={{ maxWidth: "92%" }}>
        <StyledCheckbox
          value={forValue}
          itemisation="Alphabetical"
          itemPadding="0 0 0px 16px"
          options={[
            {
              value: CtoFor.REQUIRES_TREATMENT,
              label: "In my opinion",
              after: (
                <View>
                  <View style={{ paddingLeft: 28 }}>
                    <StyledCheckbox
                      value={forValue}
                      itemisation="Roman"
                      itemPadding={10}
                      options={[
                        {
                          value: CtoFor.REQUIRES_TREATMENT,
                          label:
                            "you require treatment in hospital for mental disorder,",
                          after: (
                            <StyledText
                              instruction="AND"
                              style={{
                                marginLeft: -28,
                                marginTop: 10,
                                marginBottom: 10,
                                textDecoration:
                                  forValue !== CtoFor.REQUIRES_TREATMENT
                                    ? "line-through"
                                    : undefined,
                              }}
                            />
                          ),
                        },
                        {
                          value: CtoFor.REQUIRES_TREATMENT,
                          label:
                            "there would be a risk of harm to your health or safety or to other persons if you were not recalled to hospital for that purpose.",
                        },
                      ]}
                    />
                  </View>
                  <StyledText instruction="This opinion is founded on the following grounds —" />
                  <StyledInformationBox
                    value={details || "N/A"}
                    minHeight={270}
                  />
                </View>
              ),
            },
            {
              value: CtoFor.FAILED_TO_COMPLY,
              label:
                "You have failed to comply with the condition imposed under section 17B of the Mental Health Act 1983 that you make yourself available for examination for the purpose of:",
              after: (
                <View style={{ paddingLeft: 35 }}>
                  <StyledText
                    instruction=""
                    guidance="(delete as appropriate)"
                    style={{ marginBottom: 6 }}
                  />
                  <StyledCheckbox
                    value={
                      forValue === CtoFor.FAILED_TO_COMPLY ? purpose : null
                    }
                    itemisation="Roman"
                    itemPadding={6}
                    options={[
                      {
                        value: CtoPurpose.CONSIDERATION,
                        label:
                          "consideration of extension of the community treatment period under section 20A",
                      },
                      {
                        value: CtoPurpose.ENABLE_PART_4A,
                        label: "enabling a Part 4A certificate to be given.",
                      },
                    ]}
                    noMargin
                  />
                </View>
              ),
            },
          ]}
        />
      </View>
      <PDFSignatures
        sha256={trackingId}
        date={date}
        signed={signature}
        signedBy="Responsible clinician"
        withPrintName
        after={
          <View>
            <StyledText instruction="Time" />
            <StyledDateOrTime
              empty={!date}
              value={date ? dayjs(date).format("HH:mm") : ""}
              dateOrTime={DateOrTime.time}
              label=""
              noMargin
              minHeight={25}
            />
          </View>
        }
      />
      <PDFSubHeader
        subheading="A COPY OF THIS NOTICE IS TO BE FORWARDED TO THE MANAGERS OF THE HOSPITAL TO WHICH THE PATIENT IS RECALLED AS SOON AS POSSIBLE AFTER IT IS SERVED ON THE PATIENT. IF THAT HOSPITAL IS NOT THE RESPONSIBLE HOSPITAL, YOU SHOULD INFORM THE HOSPITAL MANAGERS THE NAME"
        style={{
          margin: "12px auto 0px",
          textAlign: "center",
          maxWidth: "88.5%",
        }}
        fontStyle={{ fontSize: 12 }}
      />
      <PDFSubHeader
        subheading="AND ADDRESS OF THE RESPONSIBLE HOSPITAL."
        style={{
          margin: "0px auto 10px",
          textAlign: "center",
        }}
        fontStyle={{ fontSize: 12 }}
      />
      <PDFSubHeader
        text="This notice is sufficient authority for the managers of the named hospital to detain the patient there in accordance with the provisions of section 17E of the Mental Health Act 1983. "
        style={{ margin: "10px auto", textAlign: "center", maxWidth: "92%" }}
        fontStyle={{ fontSize: 11.5 }}
      />
    </View>
  );
};
