import dayjs from "dayjs";
import { CommonStateActions, FormState } from "common/types/statForms";
import { CTO4Form, FormStateActions } from "common/types/cto4";
import Action from "common/types/action";
import { signedFormHashed } from "common/libs/forms";
import { cto4InitialState } from "../initialState";

export const cto4Reducer = (state: FormState<CTO4Form>, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case CommonStateActions.RESET_FORM:
      return { ...cto4InitialState };
    case CommonStateActions.LOAD_FORM:
      return {
        ...state,
        ...payload,
      };
    case CommonStateActions.SET_INITIAL_DATA:
      return {
        ...state,
        initialData: state.data,
      };
    case FormStateActions.SET_PATIENT_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          patientDetails: {
            ...state.data.patientDetails,
            name: payload,
          },
        },
      };
    case FormStateActions.SET_PATIENT_ADDRESS:
      return {
        ...state,
        data: {
          ...state.data,
          patientDetails: {
            ...state.data.patientDetails,
            ...payload,
          },
        },
      };
    case FormStateActions.SET_HOSPITAL_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          hospitalDetails: {
            ...state.data.hospitalDetails,
            name: payload,
          },
        },
      };
    case FormStateActions.SET_HOSPITAL_ADDRESS:
      return {
        ...state,
        data: {
          ...state.data,
          hospitalDetails: {
            ...state.data.hospitalDetails,
            ...payload,
          },
        },
      };
    case FormStateActions.SET_HOSPITAL_DETENTION_DATE:
      return {
        ...state,
        data: {
          ...state.data,
          hospitalDetails: {
            ...state.data.hospitalDetails,
            detentionDate: payload,
          },
        },
      };
    case FormStateActions.SET_HOSPITAL_DETENTION_TIME:
      return {
        ...state,
        data: {
          ...state.data,
          hospitalDetails: {
            ...state.data.hospitalDetails,
            detentionTime: payload,
          },
        },
      };
    case FormStateActions.SET_HOSPITAL_SIGNATURE:
      return {
        ...state,
        data: {
          ...state.data,
          hospitalDetails: {
            ...state.data.hospitalDetails,
            signature: payload,
            date: payload ? dayjs().toISOString() : "",
            trackingId: payload ? signedFormHashed(state.data) : "",
          },
        },
      };
    default:
      return state;
  }
};
