// Hospital
import { H1Form } from "common/types/h1";
import { H3Form } from "common/types/h3";
// CTO
import { CTO1Form } from "common/types/cto1";
import { CTO3Form } from "common/types/cto3";
import { CTO4Form } from "common/types/cto4";
import { CTO5Form } from "common/types/cto5";
import { CTO7Form } from "common/types/cto7";
import { CTO12Form } from "common/types/cto12";
// Treatment
import { T2Form } from "common/types/t2";

import { FormType } from "./pdf";
import { StatForm, StatFormEmail } from "@s12solutions/types";

export type FormTypes =
  | H1Form
  | H3Form
  | CTO1Form
  | CTO3Form
  | CTO4Form
  | CTO5Form
  | CTO7Form
  | CTO12Form
  | T2Form;

export interface FormState<Form = FormTypes> {
  initialData: Form;
  data: Form;
  version: number;
  status: string;
  type?: FormType;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

export type SectionIndexValidation = {
  [key: number]: () => Promise<boolean>;
};

export enum Actors {
  AMHP = "AMHP",
  Clinician = "Clinician",
  MHAAdmin = "MHAAdmin",
  Originator = "Originator",
}

export type AddressType = {
  address: string;
  manualAddress1?: string;
  manualAddress2?: string;
  manualPostcode?: string;
};

export type SignatureType = {
  signature: string;
  trackingId: string;
  date: string;
};

export type ClinicianDetailsType = AddressType & {
  name: string;
  email: string;
};

export type PatientDetailsType = {
  name: string;
};

export type PatientDetails = AddressType & {
  name: string;
};

export type HospitalDetailsType = AddressType & {
  name: string;
};

export type FormItemType = {
  index: number;
  itemText: string;
  disabled?: boolean;
  errorKey?: string;
};

export enum FormStatus {
  s10_draft = "s10_draft",
  s20_awaiting_other_doctor = "s20_awaiting_other_doctor",
  s30_changes_in_progress = "s30_changes_in_progress",
  s40_signed = "s40_signed",
  s50_signed_and_sent = "s50_signed_and_sent",
  s51_signed_and_downloaded = "s51_signed_and_downloaded",
  s52_signed_and_pdf_sent = "s52_signed_and_pdf_sent",
  s60_signed_in_amhp_team_inbox = "s60_signed_in_amhp_team_inbox",
  s70_multi_stage_form_progressed = "s70_multi_stage_form_progressed",
}

export enum CommonStateActions {
  RESET_FORM = "RESET_FORM",
  LOAD_FORM = "LOAD_FORM",
  SET_INITIAL_DATA = "SET_INITIAL_DATA",
}

export type ListStatFormEmailsResponse = Pick<
  StatFormEmail,
  "id" | "form" | "actor" | "toEmail" | "emailLink" | "disabled" | "createdAt"
> &
  Pick<StatForm, "data" | "patientName" | "type">[];

export type ListStatFormsResponse = StatForm &
  { isEmailLink: boolean; disabled: boolean; emailLink?: string }[];
