/**
 * -------------------------------------
 * * Dialog Component
 * -------------------------------------
 * This component is mounted inside App.tsx and the content of the dialog,
 * size and the showing of the dialog is controlled via the usePopups hook.
 *
 * ? Usage:
 *
 * import { usePopups } from "hooks";
 * const {handleDialog, closeDialog} = usePopups();
 *
 * handleDialog(<CustomDialogContent />, {...DialogOptions});
 *
 * * Note: to see what options are available go to hooks/usePopups.tsx and view the types
 * -------------------------------------
 */

import React, { useCallback } from "react";
import clsx from "clsx";
import {
  Box,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { usePopups } from "hooks";

const useStyles = makeStyles((theme) => ({
  dialogSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  dialogWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
  },
  dialogInfo: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
  },
  dialogError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
}));

type DialogProps = {
  index: number;
};
const Dialog: React.FC<DialogProps> = ({ index }) => {
  const classes = useStyles();
  const { dialog, closeDialog } = usePopups();

  const {
    component,
    options: { title, size, disableExit, maxHeight, type, showCloseButton },
  } = dialog[index];

  // if there is a component in the context state then that means the dialog is open
  // as this is cleared on close
  const isOpen = !!component;

  const handleBackdropClick = useCallback(() => {
    if (disableExit) return;
    closeDialog();
  }, [disableExit, closeDialog]);

  if (!isOpen) return <></>;

  return (
    <MuiDialog
      open={isOpen}
      maxWidth={size}
      onBackdropClick={handleBackdropClick}
      disableEscapeKeyDown={disableExit}
      fullWidth
    >
      {title && (
        <DialogTitle
          component="div"
          className={clsx({
            [classes.dialogSuccess]: type === "success",
            [classes.dialogWarning]: type === "warning",
            [classes.dialogInfo]: type === "info",
            [classes.dialogError]: type === "error",
            [classes.dialogTitle]: true,
          })}
        >
          {type === "success" ? (
            <CheckCircleOutlineIcon />
          ) : type === "error" ? (
            <ErrorOutlineIcon />
          ) : type === "info" ? (
            <InfoIcon />
          ) : (
            <></>
          )}
          {type !== "standard" && " "}
          <Typography variant="h2" sx={{ fontSize: 18 }}>
            {title}
          </Typography>
          {showCloseButton && (
            <IconButton
              aria-label="close"
              onClick={closeDialog}
              sx={{ marginLeft: "auto" }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent
        sx={{
          maxHeight,
        }}
      >
        <Box sx={{ paddingTop: 1.5 }}>{component}</Box>
      </DialogContent>
    </MuiDialog>
  );
};

export default React.memo(Dialog);
