import dayjs from "dayjs";
import { CommonStateActions, FormState } from "common/types/statForms";
import { CTO12Form, FormStateActions } from "common/types/cto12";
import Action from "common/types/action";
import { signedFormHashed } from "common/libs/forms";
import { cto12InitialState } from "../initialState";

export const cto12Reducer = (state: FormState<CTO12Form>, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case CommonStateActions.RESET_FORM:
      return { ...cto12InitialState };
    case CommonStateActions.LOAD_FORM:
      return {
        ...state,
        ...payload,
      };
    case CommonStateActions.SET_INITIAL_DATA:
      return {
        ...state,
        initialData: state.data,
      };
    case FormStateActions.SET_CLINICIAN_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          clinicianDetails: {
            ...state.data.clinicianDetails,
            name: payload,
          },
        },
      };
    case FormStateActions.SET_CLINICIAN_ADDRESS:
      return {
        ...state,
        data: {
          ...state.data,
          clinicianDetails: {
            ...state.data.clinicianDetails,
            ...payload,
          },
        },
      };
    case FormStateActions.SET_CLINICIAN_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          clinicianDetails: {
            ...state.data.clinicianDetails,
            email: payload,
          },
        },
      };
    case FormStateActions.SET_PATIENT_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          patientDetails: {
            ...state.data.patientDetails,
            name: payload,
          },
        },
      };
    case FormStateActions.SET_PATIENT_ADDRESS:
      return {
        ...state,
        data: {
          ...state.data,
          patientDetails: {
            ...state.data.patientDetails,
            ...payload,
          },
        },
      };
    case FormStateActions.SET_PATIENT_CAPACITY:
      return {
        ...state,
        data: {
          ...state.data,
          patientDetails: {
            ...state.data.patientDetails,
            capacity: payload,
          },
        },
      };
    case FormStateActions.SET_PATIENT_DESCRIPTION:
      return {
        ...state,
        data: {
          ...state.data,
          patientDetails: {
            ...state.data.patientDetails,
            description: payload,
          },
        },
      };
    case FormStateActions.SET_CLINICIAN_SIGNATURE:
      return {
        ...state,
        data: {
          ...state.data,
          clinicianSignature: {
            ...state.data.clinicianSignature,
            signature: payload,
            date: payload ? dayjs().toISOString() : "",
            trackingId: payload ? signedFormHashed(state.data) : "",
          },
        },
      };
    default:
      return state;
  }
};
