import React from "react";
import { makeStyles } from "@mui/styles";
import Stack from "@mui/material/Stack";
import Header from "../common/header/Header";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.info.dark,
  },
}));

const CTO4Header: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Stack spacing={2}>
        <Header
          regulation="Regulation 6(3)(d)"
          title="Section 17E — Community treatment order: record of patient’s detention in hospital after recall"
        />
      </Stack>
    </div>
  );
};

export default CTO4Header;
