import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

// Material UI
import AppBar from "@mui/material/AppBar";
import { Box } from "@mui/system";
import { Button, Chip } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Fade from "@mui/material/Fade";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import FaceIcon from "@mui/icons-material/Face";
import RefreshIcon from "@mui/icons-material/Refresh";

// Custom imports
import { RouteConstants } from "common/constants/routes";
import * as constants from "../../common/constants/theme";
import { useAuth, useDeviceType, usePopups } from "hooks";
import { formatUserName } from "common/libs/formatUserName";
import { useStyles } from "./styles";
import Logo from "components/logo";
import {
  ProfileOptionsLoggedIn,
  ProfileOptionsLoggedOut,
} from "./profileConfig";
import { isAmhp } from "utils/auth";
import { deployAuthDialog } from "store/forms/helpers/deployAuthDialog";

export interface AppbarProps {
  open: boolean;
  handleDrawerOpen: () => void;
}

const Appbar: React.FC<AppbarProps> = ({ open, handleDrawerOpen }) => {
  const { authService, user, clearCredentials, actor } = useAuth();
  const { handleDialog, closeDialog } = usePopups();
  const { isMobile } = useDeviceType();
  const classes = useStyles({ loggedIn: !!user, isMobile });
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const isUserAmhp = isAmhp(user!);

  // Derive the menu items for the profile popover, when the user clicks on the profile icon
  const profileOptions = user
    ? [...ProfileOptionsLoggedIn]
    : [...ProfileOptionsLoggedOut];

  const isMenuOpen = Boolean(anchorEl);

  // The user has clicked on the profile icon while the popover menu is closed
  const handleProfileMenuOpen = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    setPopoverOpen(!popoverOpen);
    setAnchorEl(anchorEl ? null : event?.currentTarget);
  };

  // The user has clicked on the profile icon while the popover menu is open
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Close the menu before redirecting to signin screen
  const handleSignout = async () => {
    try {
      handleMenuClose();
      // Sign out of cognito
      await authService.signout();

      // Clear auth context
      clearCredentials();
    } catch (err) {
      console.log("Error signing out: ", err);
    }
  };

  // Handle the user selection from the popover menu
  const handleProfileSelection = (selected: number) => {
    switch (selected) {
      case 0:
        handleSignout();
        break;
      case 99: // empty item, for version
        break;
      default:
        navigate(`/${profileOptions[selected].path}`);
    }
  };

  const handleNavigateHome = useCallback(() => {
    navigate(RouteConstants.DASHBOARD);
  }, [navigate]);

  const RenderMenuButton = () => {
    if (isUserAmhp) return <></>;

    return (
      <div
        data-test-id="menu-button"
        className={clsx(classes.menuButton, classes.sideMenuButton)}
        onClick={handleDrawerOpen}
      >
        <MenuIcon />
        {!isMobile && (
          <Typography className={classes.title} variant="h6" noWrap>
            MENU
          </Typography>
        )}
      </div>
    );
  };

  // Handle auth dialog -> I wanted this in the Auth Provider but
  // Auth Context is passed into the auth wrapper and doesn't have access
  // to the handleDialog function :(
  const handleAuthDialog = useCallback(
    (successCallback: () => void = () => {}) => {
      const shouldNavigateOnCancel = false;

      deployAuthDialog(
        "",
        successCallback,
        handleDialog,
        closeDialog,
        navigate,
        shouldNavigateOnCancel
      );
    },
    [handleDialog, closeDialog, navigate]
  );

  const handleSwitchActor = useCallback(() => {
    handleAuthDialog();
  }, [handleAuthDialog]);

  const id = popoverOpen ? "simple-popover" : undefined;

  const renderMenu = (
    <Popover
      data-test-id="user-menu"
      id={id}
      elevation={0}
      PaperProps={{
        classes: {
          root: classes.popoverPaper,
        },
        square: true,
      }}
      open={isMenuOpen}
      anchorEl={anchorEl}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: -11,
        horizontal: "right",
      }}
    >
      <Fade in={isMenuOpen} {...(isMenuOpen ? { timeout: 300 } : {})}>
        <List classes={{ root: classes.list }}>
          {profileOptions.map(({ key, label, icon: Icon, divider, testId }) => (
            <ListItemButton
              key={`${key}${label}`}
              data-test-id={testId}
              divider={divider}
              onClick={() => handleProfileSelection(key)}
              classes={{
                root: classes.listItem,
              }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText secondary={label} />
            </ListItemButton>
          ))}
        </List>
      </Fade>
    </Popover>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        data-test-id="application-appbar"
        position="fixed"
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: !isMobile ? open : false,
        })}
        classes={{
          root: classes.root,
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: constants.s12Blue,
          }}
        >
          {RenderMenuButton()}
          <Box ml={isMobile ? 0 : 1}>
            <Button
              onClick={handleNavigateHome}
              startIcon={
                <Box m={0} p={0} sx={{ fontSize: "5px !important" }}>
                  <Logo />
                </Box>
              }
            >
              <Typography
                variant={isMobile ? "h4" : "h3"}
                sx={{ color: constants.white }}
              >
                MHA Forms
              </Typography>
            </Button>
          </Box>
          <Box pl={1.5}>
            {(() => {
              switch (process.env.REACT_APP_ENV) {
                case "preprod":
                  return (
                    <Chip
                      label="Preprod"
                      className={classes.modeChipWhite}
                      size="small"
                    />
                  );
                case "dev":
                  return (
                    <Chip
                      label="Development"
                      className={classes.modeChipRed}
                      size="small"
                    />
                  );
                case "sandbox":
                  return (
                    <Chip
                      label="Sandbox"
                      className={classes.modeChipPink}
                      size="small"
                    />
                  );
                default:
                  return null;
              }
            })()}
          </Box>
          <div className={classes.grow} />
          {actor && (
            <Chip
              icon={
                <FaceIcon
                  className="text-white"
                  sx={{ height: 16, width: 16 }}
                />
              }
              deleteIcon={<RefreshIcon className="text-white fs-small" />}
              onClick={handleSwitchActor}
              onDelete={handleSwitchActor}
              label={actor.name}
              className="text-white"
              // variant="filled"
              clickable
              size="small"
              sx={{ mr: 2 }}
            />
          )}
          {user?.attributes?.name && (
            <Typography
              data-test-id="user-loggedin"
              className={classes.title}
              variant="h6"
              noWrap
            >
              {`${formatUserName(user)}`}
            </Typography>
          )}
          <MenuItem
            data-test-id="user-menu-button"
            onClick={handleProfileMenuOpen}
            classes={{
              root: clsx(classes.profileButton, !user && classes.loggedOut),
            }}
          >
            <AccountCircle />
          </MenuItem>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
};

export default React.memo(Appbar);
