import { useAuth, useEmailLinkAuth } from "hooks";
import { StatFormGroup } from "@s12solutions/types";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "common/constants/routes";
import { isWard } from "utils/auth";

interface FormAccessRes {
  loading: boolean;
  hasAccess: boolean;
  requiredEmail: string;
}

/**
 * useFormAccess()
 * @param {StatFormGroup} formGroup - the `formGroup` of the form the user is trying to access.
 * @returns {FormAccessRes} formAccessRes
 * This is run one once and first. If the user does not have access to the form a relevant
 * snackbar message will appear and the user will be navigated to the homepage. It will also
 * grant the user access after entering details into email link authentication dialog.
 * NOTE: the navigate away guard statements for valid email links abd form group access still
 * need to return or the destructure when this hook has been used will crash the system. - IS
 */
export const useFormAccess = (formGroup: StatFormGroup): FormAccessRes => {
  const navigate = useNavigate();
  const { formGroups, user, actor } = useAuth();
  const {
    loading,
    isEmailLinkPresent,
    isValid: isEmailLinkValid,
    requiredEmail,
  } = useEmailLinkAuth();

  // Handle email link access
  if (isEmailLinkPresent && !loading && !isEmailLinkValid) {
    navigate(RouteConstants.LINK_INVALID);

    return {
      loading,
      hasAccess: false,
      requiredEmail,
    };
  }

  // Handle userHasFormGroups access
  if (!formGroups.includes(formGroup)) {
    navigate(RouteConstants.UNAUTHORISED);

    return {
      loading,
      hasAccess: false,
      requiredEmail,
    };
  }

  if (isWard(user) && !actor) {
    return {
      loading,
      hasAccess: false,
      requiredEmail,
    };
  }

  return {
    loading,
    hasAccess: true,
    requiredEmail,
  };
};
