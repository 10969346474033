import React, { useMemo } from "react";

// Form Components
import FormContainer from "components/forms/formContainer";
import H3Header from "components/formSections/h3/H3Header";
import HospitalDetails from "components/formSections/common/hospitalDetails";
import PatientDetails from "components/formSections/h3/patientDetails";
import AdmissionDetails from "components/formSections/h3/admissionsDetails";
import AdmissionDetailsPart2 from "components/formSections/h3/admissionsDetailsPart2";
import FormLoading from "components/forms/formLoading";

// Custom imports
import {
  useActorTypeStageChange,
  useAuth,
  useForm,
  useFormAccess,
  useFormValidation,
  useUI,
} from "hooks";
import {
  generateFormItems,
  shouldDisableNext,
  shouldDisablePrev,
  shouldEnableDownload,
  validationSchema,
} from "./helpers";
import { isWard } from "utils/auth";
import { FormStateActions } from "common/types/h3";
import { Actors } from "common/types/statForms";
import { useStyles } from "common/styles/form";
import { LoadingCircle } from "@s12solutions/ui";

const H3Form: React.FC = () => {
  const classes = useStyles();
  const { user, actor } = useAuth();
  const { loading, formState, dispatchFormState, actorType, setActorType } =
    useForm();
  const { currentSectionIndex } = useUI();

  const sectionIndexFunctions = useMemo(
    () => ({
      1: () => setActorType(Actors.MHAAdmin),
    }),
    [setActorType]
  );

  // Destructure
  const {
    hospitalDetails,
    hospitalDetails: {
      name: hospitalName,
      address: hospitalAddress,
      manualAddress1,
      manualAddress2,
      manualPostcode,
    },
    patientDetails,
    admissionDetails,
  } = formState.data;

  // * Start Component lifecycle methods ------------------------

  useFormAccess("Hospital");

  useActorTypeStageChange(actorType, {
    [Actors.Originator]: 0,
    [Actors.MHAAdmin]: 1,
  });

  useFormValidation(validationSchema, [
    {
      section1: { hospitalDetails, patientDetails },
    },
    { admissionDetails },
    { admissionDetails },
  ]);

  // * End Component lifecycle methods ------------------------

  const formItems = generateFormItems(actorType);
  const part1Disabled = !!admissionDetails.signaturePart1;

  // Render
  if (loading) {
    return <LoadingCircle />;
  }
  if (isWard(user) && !actor) {
    return <FormLoading />;
  }

  return (
    <FormContainer
      sections={[
        <>
          <div className={classes.sectionContainer}>
            <H3Header />
          </div>
          <div className={classes.sectionContainer}>
            <HospitalDetails
              testIdKey="h3"
              name={hospitalName}
              address={hospitalAddress}
              manualAddress1={manualAddress1 || ""}
              manualAddress2={manualAddress2 || ""}
              manualPostcode={manualPostcode || ""}
              setHospital={(value: any) => {
                dispatchFormState({
                  type: FormStateActions.SET_HOSPITAL,
                  payload: value,
                });
              }}
              errorKey="section1.hospitalDetails"
              disabled={part1Disabled}
            />
          </div>
          <div className={classes.sectionContainer}>
            <PatientDetails
              name={patientDetails.name || ""}
              dispatchFormState={dispatchFormState}
              disabled={part1Disabled}
            />
          </div>
        </>,
        <>
          {actorType === Actors.Originator ? (
            <div className={classes.sectionContainer}>
              <AdmissionDetails
                admission={admissionDetails}
                dispatchFormState={dispatchFormState}
              />
            </div>
          ) : actorType === Actors.MHAAdmin ? (
            <div className={classes.sectionContainer}>
              <AdmissionDetailsPart2
                admission={admissionDetails}
                dispatchFormState={dispatchFormState}
              />
            </div>
          ) : (
            <></>
          )}
        </>,
      ]}
      formPartSelectorProps={{
        formItems,
        enableDownload: shouldEnableDownload(formState.data),
      }}
      formNavButtonProps={{
        formItems,
        lastSectionIndex: 2,
        sectionIndexFunctions,
        disableNext: shouldDisableNext(
          actorType,
          currentSectionIndex,
          formState.data
        ),
        disablePrev: shouldDisablePrev(actorType, currentSectionIndex),
      }}
    />
  );
};

export default React.memo(H3Form);
