import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, TextField, BaseTextFieldProps } from "@mui/material";
import { useForm, usePopups } from "hooks";
import ErrorLabel from "./ErrorLabel";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
}));

type TemplateProps = {
  template: React.ReactNode;
  name: string;
};

interface StyledTextAreaProps extends BaseTextFieldProps {
  testId?: string;
  disabled?: boolean;
  value: number | string | null;
  label: string;
  onChange: any;
  errorText?: string;
  errorKey?: string;
  /**
   * @param { {[key: string]: any} } validateWith
   * Sometimes we need more flexibility with input validation, e.g, when the input validation
   * relies on another value
   */
  validateWith?: { [key: string]: any };
  /**
   * @param {TemplateProps} templateProps
   * Displays a button underneath the text area that when clicked opens
   * a popup with helper text/templates on how to fill out the text area
   */
  templateProps?: TemplateProps;
}

const StyledTextArea: React.FC<StyledTextAreaProps> = ({
  testId,
  disabled,
  value,
  label,
  onChange,
  errorText,
  errorKey,
  validateWith,
  error,
  helperText,
  templateProps,
  templateProps: { template: Template } = { template: <></> },
  ...rest
}) => {
  const classes = useStyles();
  const { handleDialog } = usePopups();
  const { formErrors, handleValidateValue } = useForm();

  return (
    <div>
      <TextField
        data-test-id={testId}
        className={classes.input}
        label={label}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        autoComplete="off"
        value={value}
        onChange={async (e) => {
          const { value } = e.target;

          onChange(value);

          if (errorKey) {
            let validateValue;

            if (validateWith) {
              const errorKeys = errorKey.split(".");
              const validateName = errorKeys[errorKeys.length - 1];
              validateValue = {
                ...validateWith,
                [validateName]: value,
              };
            } else {
              validateValue = value;
            }

            await handleValidateValue(errorKey, validateValue);
          }
        }}
        error={(errorKey && !!formErrors?.[errorKey]) || error}
        multiline
        minRows={10}
        helperText={!error && helperText}
        {...rest}
      />
      <ErrorLabel error={error} errorText={errorText} errorKey={errorKey} />
      {templateProps && templateProps.name && Template && (
        <div style={{ marginTop: 8 }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() =>
              // @ts-ignore (JSX element does not have any construct or call signatures)
              // ignore is safe here as `Template` is a {ReactNode} - IS
              handleDialog(<Template />, {
                title: templateProps.name,
                showCloseButton: true,
              })
            }
            size="small"
          >
            Show {templateProps.name}
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(StyledTextArea);
