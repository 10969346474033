/**
 * ! ----------------------------------------------------------------
 * ! WARNING
 * ! this function should be accessed via the `useForm` hook as function
 * ! parameters are prefilled
 * ! ----------------------------------------------------------------
 */
import { FormType } from "common/types/pdf";
import { RenderedPDFProps } from "components/pdf";
import { UsePopups } from "hooks/usePopups";
import { HandleEmailParams, HandleSaveParams } from "../types";

import EmailStatForm from "components/forms/email/emailStatForm";

export const deployEmailDialog = async (
  formId: string,
  formType: FormType,
  currentSectionIndex: number,
  formProps: RenderedPDFProps,
  emailParams: HandleEmailParams,
  handleSave: (saveParams: HandleSaveParams) => void,
  handleDialog: UsePopups["handleDialog"],
  handleSuccess: () => void
) => {
  const { toEmail, sectionIndex, includePDFAttachment } = emailParams;
  handleSave({ shouldNavigate: false });

  let formStep = sectionIndex;
  if (!formStep) {
    formStep = currentSectionIndex + 1;
  }

  handleDialog(
    <EmailStatForm
      formId={formId}
      formType={formType}
      formStep={formStep}
      toEmail={toEmail}
      isFinalPdf={includePDFAttachment || false}
      onSuccess={handleSuccess}
    />,
    { title: "Send Form" }
  );
};
