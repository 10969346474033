import { PatientDetails, ClinicianDetailsType } from "common/types/statForms";
import {
  Opinion as GenericOpinion,
  AMHPDetails,
  CTODetails,
} from "common/types/cto";
import { FormPDFConfigData } from "./pdf";

export type Opinion = GenericOpinion & {
  furtherConditions: string;
};

export interface CTO1Form {
  clinicianDetails: ClinicianDetailsType;
  patientDetails: PatientDetails;
  opinion: Opinion;
  amhpDetails: AMHPDetails;
  ctoDetails: CTODetails;
}

export const pdfConfig: FormPDFConfigData = {
  longTitle: "Section 17A - community treatment order",
  regulation: "6(1)(a), (b) and 6(2)(a)",
  footerText: "Form amended by SI 2020/1072 for use from 1 December 2020",
  pagePadding: "16px 22px 0px 20px",
  fontSize: 11,
  enablePagination: true,
};

export enum FormStateActions {
  LOAD_FORM = "LOAD_FORM",
  SET_CLINICIAN_NAME = "SET_CLINICIAN_NAME",
  SET_CLINICIAN_ADDRESS = "SET_CLINICIAN_ADDRESS",
  SET_CLINICIAN_EMAIL = "SET_CLINICIAN_EMAIL",
  SET_PATIENT_NAME = "SET_PATIENT_NAME",
  SET_PATIENT_ADDRESS = "SET_PATIENT_ADDRESS",
  SET_OPINION_FOR = "SET_OPINION_FOR",
  SET_OPINION_DETAILS = "SET_OPINION_DETAILS",
  SET_OPINION_FURTHER_CONDITIONS = "SET_OPINION_FURTHER_CONDITIONS",
  SET_OPINION_SIGNATURE = "SET_OPINION_SIGNATURE",
  SET_AMHP_NAME = "SET_AMHP_NAME",
  SET_AMHP_ADDRESS = "SET_AMHP_ADDRESS",
  SET_AMHP_OTHER_AUTHORITY = "SET_AMHP_OTHER_AUTHORITY",
  SET_AMHP_AUTHORITY_NAME = "SET_AMHP_AUTHORITY_NAME",
  SET_AMHP_ON_BEHALF_OF = "SET_AMHP_ON_BEHALF_OF",
  SET_AMHP_SIGNATURE = "SET_AMHP_SIGNATURE",
  SET_CTO_DATE = "SET_CTO_DATE",
  SET_CTO_TIME = "SET_CTO_TIME",
  SET_CTO_SIGNATURE = "SET_CTO_SIGNATURE",
}
