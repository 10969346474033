import React, { useState, useCallback } from "react";

// Material UI Imports
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Custom Imports
import { useAuth, useForm } from "hooks";
import { formatUserName } from "common/libs/formatUserName";
import Action from "common/types/action";
import { HospitalDetailsType, FormStateActions } from "common/types/cto4";
import HospitalDetails from "components/formSections/common/hospitalDetails/HospitalDetails";
import SignatureSection from "components/formSections/common/signatureSection";
import Paper from "components/paper";
import { StyledDatePicker, StyledTimePicker } from "components/uiElements";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

type HospitalDetailsProps = {
  testIdKey: string;
  hospitalDetails: HospitalDetailsType;
  dispatchFormState: React.Dispatch<Action>;
};

const PatientDetails: React.FC<HospitalDetailsProps> = ({
  testIdKey,
  hospitalDetails: {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    detentionDate,
    detentionTime,
    signature,
    date,
  },
  dispatchFormState,
}) => {
  const { user } = useAuth();
  const { handleEmail } = useForm();
  const [signedStatus, setSignedStatus] = useState<boolean>(!!signature);

  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed ? formatUserName(user) : "";

      dispatchFormState({
        type: FormStateActions.SET_HOSPITAL_SIGNATURE,
        payload: signedValue,
      });
    },
    [dispatchFormState, user]
  );

  return (
    <>
      <HospitalDetails
        testIdKey={testIdKey}
        name={name}
        address={address}
        label={
          <Typography variant="h4">
            In pursuance of a notice recalling the patient to hospital under
            section 17E of the Act, the patient was detained in{" "}
            <span className="italic">(full name and address of hospital)</span>
          </Typography>
        }
        manualAddress1={manualAddress1}
        manualAddress2={manualAddress2}
        manualPostcode={manualPostcode}
        setHospital={(value: any) =>
          dispatchFormState({
            type: FormStateActions.SET_HOSPITAL_ADDRESS,
            payload: value,
          })
        }
        disabled={signedStatus}
      />
      <Paper>
        <Stack spacing={2} mt={3} mb={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h4">
                on{" "}
                <span className="italic">
                  (enter date and time at which the patient’s detention in the
                  hospital as a result of the recall notice began)
                </span>
                .
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledDatePicker
                testId={`${testIdKey}-ddate`}
                label="Detention Date"
                errorKey="hospitalDetails.detentionDate"
                value={detentionDate}
                onChange={(newValue: any) => {
                  dispatchFormState({
                    type: FormStateActions.SET_HOSPITAL_DETENTION_DATE,
                    payload: newValue,
                  });
                }}
                disabled={signedStatus}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="h4" noWrap>
                (date)
              </Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              <Typography variant="h4" noWrap>
                at
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledTimePicker
                testId={`${testIdKey}-dtime`}
                label="Detention Time"
                errorKey="hospitalDetails.detentionTime"
                value={detentionTime}
                disabled={signedStatus}
                onChange={(newValue: any) => {
                  dispatchFormState({
                    type: FormStateActions.SET_HOSPITAL_DETENTION_TIME,
                    payload: newValue,
                  });
                }}
                validateWith={{ detentionDate }}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <Typography variant="h4" noWrap>
                (time)
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" className="italic">
            This form can be signed and dated later than the admission time. If
            you are completing this form some time after the admission, enter
            the correct date and time of admission. Your signature date can be
            later than the admission date.
          </Typography>
        </Stack>
        <SignatureSection
          name={signature}
          date={date}
          signedStatus={signedStatus}
          setSignedStatus={handleSetSignedStatus}
          description="On behalf of the hospital managers"
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          mt={2}
        >
          <Button
            data-test-id="cto4-email-final"
            variant="contained"
            onClick={() => handleEmail({ includePDFAttachment: true })}
            disabled={!signedStatus}
            size="large"
            sx={{ mr: 1 }}
          >
            Send to MHA Admin
          </Button>
          <PdfDownloadButton disabled={!signedStatus} />
        </Stack>
      </Paper>
    </>
  );
};

export default React.memo(PatientDetails);
