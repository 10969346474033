import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Header from "../common/header/Header";
import { useDeviceType } from "hooks";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.info.dark,
  },
}));

const H1Header: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useDeviceType();

  return (
    <div className={classes.container}>
      <Stack spacing={2}>
        <Header
          regulation="Regulation 4(1)(g)"
          title="Sections 5(2) — report on hospital in-patient"
        />
        <Typography variant="h2" style={{ alignSelf: "center" }}>
          PART 1
        </Typography>
        <Typography
          variant="h4"
          style={{
            alignSelf: "center",
            fontStyle: "italic",
            maxWidth: isMobile ? "90%" : "80%",
            textAlign: "center",
          }}
        >
          (To be completed by a medical practitioner or an approved clinician
          qualified to do so under section 5(2) of the Act)
        </Typography>
      </Stack>
    </div>
  );
};

export default H1Header;
