import React from "react";

// Material UI
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

// Custom imports
import { StrikeThrough } from "components/uiElements/StrikeThrough";

interface ThemeProps {
  strikeThrough: boolean;
}

const useStyles = makeStyles<Theme, ThemeProps>((theme) => ({
  contentContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: theme.palette.grey.A200,
    width: "100%",
    minHeight: "3.2rem",
  },
  strikeThrough: {
    position: "absolute",
    top: "15%",
  },
}));

interface OutputTextFieldProps {
  value?: string;
  strikeThrough?: boolean;
  strikeThroughWidth?: number;
}

const OutputTextField: React.FC<OutputTextFieldProps> = ({
  strikeThrough = false,
  strikeThroughWidth = 0,
  children,
}) => {
  const classes = useStyles({ strikeThrough });

  return (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.strikeThrough}>
          {strikeThrough && <StrikeThrough width={strikeThroughWidth} />}
        </div>
        {children}
      </div>
    </>
  );
};

export { OutputTextField };
