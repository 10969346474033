import { FormType } from "common/types/pdf";

const formTypeToSignOnlyIndexLookupTable: {
  [key in FormType]?: number[];
} = {
  [FormType.H1]: [3, 4],
  [FormType.CTO7]: [6],
};

export const isEmailLinkReviewOnly = (
  formType: FormType,
  index: number
): boolean => {
  const signOnlySections = formTypeToSignOnlyIndexLookupTable[formType];
  if (!signOnlySections) return false;

  return signOnlySections.includes(index);
};
