import dayjs from "dayjs";
import { CommonStateActions, FormState } from "common/types/statForms";
import { CTO7Form, FormStateActions } from "common/types/cto7";
import Action from "common/types/action";
import { signedFormHashed } from "common/libs/forms";
import { cto7InitialState } from "../initialState";

export const cto7Reducer = (state: FormState<CTO7Form>, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case CommonStateActions.RESET_FORM:
      return { ...cto7InitialState };
    case CommonStateActions.LOAD_FORM:
      return {
        ...state,
        ...payload,
      };
    case CommonStateActions.SET_INITIAL_DATA:
      return {
        ...state,
        initialData: state.data,
      };
    case FormStateActions.SET_HOSPITAL:
      return {
        ...state,
        data: {
          ...state.data,
          hospitalDetails: {
            ...state.data.hospitalDetails,
            ...payload,
          },
        },
      };
    case FormStateActions.SET_CLINICIAN_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          clinicianDetails: {
            ...state.data.clinicianDetails,
            name: payload,
          },
        },
      };
    case FormStateActions.SET_CLINICIAN_ADDRESS:
      return {
        ...state,
        data: {
          ...state.data,
          clinicianDetails: {
            ...state.data.clinicianDetails,
            ...payload,
          },
        },
      };
    case FormStateActions.SET_CLINICIAN_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          clinicianDetails: {
            ...state.data.clinicianDetails,
            email: payload,
          },
        },
      };
    case FormStateActions.SET_PATIENT_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          patientDetails: {
            ...state.data.patientDetails,
            name: payload,
          },
        },
      };
    case FormStateActions.SET_PATIENT_ADDRESS:
      return {
        ...state,
        data: {
          ...state.data,
          patientDetails: {
            ...state.data.patientDetails,
            ...payload,
          },
        },
      };
    case FormStateActions.SET_PATIENT_CTO_CREATED:
      return {
        ...state,
        data: {
          ...state.data,
          patientDetails: {
            ...state.data.patientDetails,
            ctoCreated: payload,
          },
        },
      };
    case FormStateActions.SET_PATIENT_EXAMINED:
      return {
        ...state,
        data: {
          ...state.data,
          patientDetails: {
            ...state.data.patientDetails,
            examined: payload,
          },
        },
      };
    case FormStateActions.SET_OPINION_FOR:
      // Need to handle checkboxes, if exists, remove, else add
      const forValue = state.data.opinion.for?.includes(payload)
        ? state.data.opinion.for.filter((value) => value !== payload)
        : [...state.data.opinion.for, payload];

      return {
        ...state,
        data: {
          ...state.data,
          opinion: {
            ...state.data.opinion,
            for: [...forValue],
          },
        },
      };
    case FormStateActions.SET_OPINION_DETAILS:
      return {
        ...state,
        data: {
          ...state.data,
          opinion: {
            ...state.data.opinion,
            details: payload,
          },
        },
      };
    case FormStateActions.SET_OPINION_SIGNATURE:
      return {
        ...state,
        data: {
          ...state.data,
          opinion: {
            ...state.data.opinion,
            signature: payload,
            date: payload ? dayjs().toISOString() : "",
            trackingId: payload ? signedFormHashed(state.data) : "",
          },
        },
      };
    case FormStateActions.SET_AMHP_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          amhpDetails: {
            ...state.data.amhpDetails,
            name: payload,
          },
        },
      };
    case FormStateActions.SET_AMHP_ADDRESS:
      return {
        ...state,
        data: {
          ...state.data,
          amhpDetails: {
            ...state.data.amhpDetails,
            ...payload,
          },
        },
      };
    case FormStateActions.SET_AMHP_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          amhpDetails: {
            ...state.data.amhpDetails,
            email: payload,
          },
        },
      };
    case FormStateActions.SET_AMHP_ON_BEHALF_OF:
      return {
        ...state,
        data: {
          ...state.data,
          amhpDetails: {
            ...state.data.amhpDetails,
            onBehalfOf: payload,
          },
        },
      };
    case FormStateActions.SET_AMHP_OTHER_AUTHORITY:
      return {
        ...state,
        data: {
          ...state.data,
          amhpDetails: {
            ...state.data.amhpDetails,
            otherAuthority: payload,
            onBehalfOf: "",
          },
        },
      };
    case FormStateActions.SET_AMHP_AUTHORITY_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          amhpDetails: {
            ...state.data.amhpDetails,
            authorityName: payload,
          },
        },
      };
    case FormStateActions.SET_AMHP_SIGNATURE:
      return {
        ...state,
        data: {
          ...state.data,
          amhpDetails: {
            ...state.data.amhpDetails,
            signature: payload,
            date: payload ? dayjs().toISOString() : "",
            trackingId: payload ? signedFormHashed(state.data) : "",
          },
        },
      };
    case FormStateActions.SET_FURNISHING_CONSULTED_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          furnishingReport: {
            ...state.data.furnishingReport,
            consultedName: payload,
          },
        },
      };
    case FormStateActions.SET_FURNISHING_CONSULTED_PROFESSION:
      return {
        ...state,
        data: {
          ...state.data,
          furnishingReport: {
            ...state.data.furnishingReport,
            consultedProfession: payload,
          },
        },
      };
    case FormStateActions.SET_FURNISHING_SIGNATURE:
      return {
        ...state,
        data: {
          ...state.data,
          furnishingReport: {
            ...state.data.furnishingReport,
            signature: payload,
            date: payload ? dayjs().toISOString() : "",
            trackingId: payload ? signedFormHashed(state.data) : "",
          },
        },
      };
    case FormStateActions.SET_WARD_SIGNATURE:
      return {
        ...state,
        data: {
          ...state.data,
          wardSignature: {
            signature: payload,
            date: payload ? dayjs().toISOString() : "",
            trackingId: payload ? signedFormHashed(state.data) : "",
          },
        },
      };
    default:
      return state;
  }
};
