import { Dispatch } from "react";
import { useParams } from "react-router-dom";
import { FormType } from "common/types/pdf";
import Action from "common/types/action";
import { FormState } from "common/types/statForms";
import createFormStore from "store/forms/formStateStore";

interface UseFormStateSetupRes {
  formState: FormState;
  dispatchFormState: Dispatch<Action>;
}

type Store = {
  [key in FormType]: ReturnType<typeof createFormStore>;
};
const stores: Store = {
  [FormType.H1]: createFormStore(FormType.H1),
  [FormType.H3]: createFormStore(FormType.H3),
  [FormType.CTO1]: createFormStore(FormType.CTO1),
  [FormType.CTO3]: createFormStore(FormType.CTO3),
  [FormType.CTO4]: createFormStore(FormType.CTO4),
  [FormType.CTO5]: createFormStore(FormType.CTO5),
  [FormType.CTO7]: createFormStore(FormType.CTO7),
  [FormType.CTO12]: createFormStore(FormType.CTO12),
  [FormType.T2]: createFormStore(FormType.T2),
};

const getOrCreateFormStore = (formType: FormType) => {
  if (!stores[formType]) {
    stores[formType] = createFormStore(formType);
  }
  return stores[formType];
};

export const useFormStateSetup = (): UseFormStateSetupRes => {
  const { formType } = useParams();
  const castedFormType = formType as FormType;
  const useStore = getOrCreateFormStore(castedFormType);
  const formState = useStore((state) => state.formState);
  const dispatchFormState = useStore((state) => state.dispatch);

  return {
    formState,
    dispatchFormState,
  };
};
