/**
 * ! ----------------------------------------------------------------
 * ! WARNING
 * ! this function should be accessed via the `useForm` hook as function
 * ! parameters are prefilled
 * ! ----------------------------------------------------------------
 */
import { NavigateFunction } from "react-router-dom";
import { UsePopups } from "hooks/usePopups";
import AuthDialogContent from "components/auth/AuthDialogContent";
import { RouteConstants } from "common/constants/routes";

export const deployAuthDialog = (
  requiredEmail: string | undefined,
  successCallback: () => void,
  handleDialog: UsePopups["handleDialog"],
  closeDialog: UsePopups["closeDialog"],
  navigate: NavigateFunction,
  shouldNavigateOnCancel: boolean = true
): void => {
  handleDialog(
    <AuthDialogContent
      requiredEmail={requiredEmail}
      onSuccess={() => {
        successCallback();
        closeDialog();
      }} // on success will just close the dialog and allow the user to proceed
      onCancel={() =>
        shouldNavigateOnCancel && navigate(RouteConstants.DASHBOARD)
      }
    />,
    {
      key: "AUTH_DIALOG",
      title: "Please identify yourself",
      disableExit: true,
    }
  );
};
