import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useDeviceType } from "hooks";
import Header from "../common/header/Header";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.info.dark,
  },
}));

const CTO5Header: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useDeviceType();

  return (
    <div className={classes.container}>
      <Stack spacing={2}>
        <Header
          regulation="Regulation 6(8)(a) and (b)"
          title="Section 17F(4) - revocation of community treatment order"
        />
        <Typography
          variant="h4"
          style={{
            alignSelf: "center",
            fontStyle: "italic",
            maxWidth: isMobile ? "90%" : "80%",
            textAlign: "center",
          }}
        >
          (Parts 1 and 3 of this form are to be completed by the responsible
          clinician and Part 2 by an approved mental health professional)
        </Typography>
        <Typography variant="h2" style={{ alignSelf: "center" }}>
          PART 1
        </Typography>
      </Stack>
    </div>
  );
};

export default CTO5Header;
