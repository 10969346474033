import React from "react";
import dayjs from "dayjs";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "common/styles/pdf";

export enum DateOrTime {
  "date" = "date",
  "time" = "time",
}

interface Props {
  value: any;
  label: string;
  dateOrTime: DateOrTime;
  prefix?: string;
  strikethrough?: boolean;
  empty?: boolean;
  noMargin?: boolean;
  minHeight?: number;
}

/**
 * StyledDateOrTime
 * Time box component
 *
 * ---
 * IMPORTANT NOTE: Strikethrough and empty are two distinct seperate states.
 *
 * Empty is "intentionally" COMPLETELY blank, strikethough is blank with a line through.
 *
 * In the event of a datetime field being both empty and the need to be struck through,
 * ignore the empty flag and just set strikethrough = true, it is ASSUMED empty.
 * In the future, if we need a value inserted and actively struck out, this component will
 * need to be updated.
 * ---
 *
 * @param {string} value - time value
 * @param {string} label - box label
 * @param {date | time} dateOrTime - is it a date, or is it a time?
 * @param {boolean} [strikethrough=false] - flag to control strikethrough
 * @param {boolean} [empty=false] - flag to control if intentionally empty
 * @param {string} [prefix] - component prefix
 * @returns {JSX.Element}
 */
export const StyledDateOrTime = (props: Props) => {
  const {
    value,
    label,
    prefix,
    strikethrough,
    dateOrTime,
    empty,
    noMargin,
    minHeight,
  } = props;
  const isDate: boolean = dateOrTime === "date";
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginTop: !noMargin ? 5 : undefined,
        marginBottom: !noMargin ? 5 : undefined,
      }}
    >
      {prefix && (
        <Text
          style={{
            marginLeft: dateOrTime === "time" ? "-20" : 0,
            marginRight: 5,
          }}
        >
          {prefix}
        </Text>
      )}
      <View
        style={{
          ...styles.timeDateBoxWrapper,
          ...(minHeight && { height: minHeight }),
        }}
      >
        <Text
          style={{
            textDecoration: strikethrough ? "line-through" : "none",
            textDecorationColor: "black",
            color: "black",
          }}
        >
          {strikethrough ? (
            <View>
              {isDate ? (
                <View>
                  <span style={{ color: "transparent" }}>{">>invisi"}</span>
                  {"/"}
                  <span>{"           "}</span>
                  {"/"}
                  <span style={{ color: "transparent" }}>{"invisi<<"}</span>
                </View>
              ) : (
                <View>
                  <span style={{ color: "transparent" }}>{">>invisible"}</span>
                  {":"}
                  <span style={{ color: "transparent" }}>{"invisible<<"}</span>
                </View>
              )}
            </View>
          ) : empty ? (
            isDate ? (
              "/          /"
            ) : (
              ":"
            )
          ) : isDate ? (
            value ? (
              dayjs(value).format("DD / MM / YYYY")
            ) : (
              ""
            )
          ) : value ? (
            value.length > 6 || dayjs.isDayjs(value) ? (
              dayjs(value).format("HH:mm")
            ) : (
              value
            )
          ) : (
            ""
          )}
        </Text>
      </View>
      <Text
        style={{
          fontStyle: "italic",
          textDecoration: strikethrough ? "line-through" : "none",
        }}
      >
        {label}
      </Text>
    </View>
  );
};
