import * as Yup from "yup";
import {
  Actors,
  FormStatus,
  FormState,
  FormItemType,
  PatientDetails,
} from "common/types/statForms";
import { CTO4Form, HospitalDetailsType } from "common/types/cto4";
import {
  dateValidation,
  hospitalDetailsValidation,
  patientDetailsFullValidation,
  timeValidation,
} from "common/libs/validation";
import { CognitoUserExt } from "common/types/auth";
import { isDoctor, isWard } from "utils/auth";

export const handleStatus = (formState: FormState<CTO4Form>): FormStatus => {
  const { hospitalDetails } = formState.data;

  if (!hospitalDetails.signature) {
    return FormStatus.s10_draft;
  }
  return FormStatus.s40_signed;
};

/**
 * getActor
 * - if the user is a doctor grant access
 * - if the user is a ward and the form is signed grant access
 */
export const getActor = (
  formState: FormState<CTO4Form>,
  user: CognitoUserExt
): Actors | false => {
  const { hospitalDetails } = formState.data;

  if (isDoctor(user)) {
    return Actors.Originator;
  }

  if (isWard(user) && hospitalDetails.signature) {
    return Actors.MHAAdmin;
  }

  return false;
};

export const generateFormItems = (): FormItemType[] => [
  /** TO BE COMPLETED BY: Responsible Clinician */
  {
    index: 0,
    itemText: "1. Patient Details",
    errorKey: "patientDetails",
  },
  {
    index: 1,
    itemText: "2. Hospital Details",
    errorKey: "hospitalDetails",
  },
];

export const validationSchema = [
  Yup.object().shape(patientDetailsFullValidation),
  Yup.object().shape({
    ...hospitalDetailsValidation,
    detentionDate: dateValidation("Detention date", {
      allowFuture: false,
      allowGreaterThan1YearAgo: false,
    }),
    detentionTime: timeValidation("Detention time", {
      allowFuture: false,
      allowGreaterThan1YearAgo: false,
      dateField: "detentionDate",
    }),
  }),
];

export const hasErrorsSection1 = (props: PatientDetails): boolean => {
  const { name, address, manualAddress1, manualAddress2, manualPostcode } =
    props;
  let error = false;

  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;

  return error;
};

export const hasErrorsSection2 = (
  props: HospitalDetailsType,
  requireSignature: boolean = true
): boolean => {
  const {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    detentionDate,
    detentionTime,
    signature,
  } = props;
  let error = false;

  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;
  if (!detentionDate && !detentionTime) error = true;
  if (requireSignature && !signature) error = true;

  return error;
};

export const shouldEnableDownload = (data: CTO4Form): boolean => {
  const { patientDetails, hospitalDetails } = data;

  if (hasErrorsSection1(patientDetails) || hasErrorsSection2(hospitalDetails)) {
    return false;
  }

  return true;
};
