import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
const { version, devBuild } = require("../../../package.json");

type ProfileOptions = {
  key: number;
  label: string;
  divider: boolean;
  icon: any;
  path?: string;
  testId?: string;
};

const ProfileOptionsLoggedIn: ProfileOptions[] = [
  {
    key: 0,
    label: "Sign out",
    divider: false,
    icon: LogoutIcon,
    testId: "user-menu-logout",
  },
  {
    key: 99,
    label: `Version: ${version}${
      process.env.NODE_ENV !== "production" ? devBuild : ""
    }`,
    divider: false,
    icon: BuildCircleIcon,
    testId: "user-menu-version",
  },
];

const ProfileOptionsLoggedOut: ProfileOptions[] = [
  { key: 0, label: "Sign in", divider: false, icon: LoginIcon, path: "signin" },
];

export { ProfileOptionsLoggedIn, ProfileOptionsLoggedOut };
