import React from "react";
import { makeStyles } from "@mui/styles";
import { TextField, BaseTextFieldProps } from "@mui/material";
import { useForm } from "hooks";
import ErrorLabel from "./ErrorLabel";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  inputDisabled: {
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    borderRadius: ".5rem",
  },
  errorText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.error.dark,
    fontWeight: "bold",
  },
}));

export interface StyledInputProps extends BaseTextFieldProps {
  testId?: string;
  disabled?: boolean;
  value: number | string | null | undefined;
  label: string;
  onChange: (value: any) => void;
  errorKey?: string;
  errorText?: string;
  /**
   * @param { {[key: string]: any} } validateWith
   * Sometimes we need more flexibility with input validation, e.g, when the input validation
   * relies on another value
   */
  validateWith?: { [key: string]: any };
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

const StyledInput: React.FC<StyledInputProps> = ({
  id,
  name,
  testId,
  disabled,
  value,
  label,
  onChange,
  error,
  errorText,
  errorKey,
  validateWith,
  startAdornment,
  endAdornment,
  size,
  ...rest
}) => {
  const classes = useStyles();
  const { formErrors, handleValidateValue } = useForm();

  return (
    <div>
      <TextField
        id={id}
        name={name}
        className={classes.input}
        label={label}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        autoComplete="off"
        value={value}
        onChange={async (e) => {
          const { value } = e.target;

          onChange(value);

          if (errorKey) {
            let validateValue;

            if (validateWith) {
              const errorKeys = errorKey.split(".");
              const validateName = errorKeys[errorKeys.length - 1];
              validateValue = {
                ...validateWith,
                [validateName]: value,
              };
            } else {
              validateValue = value;
            }

            await handleValidateValue(errorKey, validateValue);
          }
        }}
        error={(errorKey && !!formErrors?.[errorKey]) || error}
        inputProps={{
          "data-test-id": testId,
        }}
        InputProps={{
          startAdornment,
          endAdornment,
        }}
        size={size}
        {...rest}
      />

      <ErrorLabel error={error} errorText={errorText} errorKey={errorKey} />
    </div>
  );
};

export { StyledInput };
