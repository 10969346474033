import { CTO4Form } from "common/types/cto4";
import { formatInitialFormState } from "../helpers";

export const data: CTO4Form = {
  patientDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
  },
  hospitalDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
    detentionDate: "",
    detentionTime: "",
    signature: "",
    trackingId: "",
    date: "",
  },
};

export const cto4InitialState = formatInitialFormState(data);
