import dayjs, { Dayjs } from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(AdvancedFormat);
dayjs.extend(isBetween);

// Module is ported directly from S12 main app
// Mostly just a bunch of various formatting tools for DayJS datetimes
// Will refrain from neurotically commenting each one as they are very self explanatory.

export const LONG_DATE_FORMAT = "dddd D MMMM YYYY";
export const SHORT_DATE_FORMAT = "DD/MM/YYYY";
export const SHORT_DATE_FORMAT_WITH_TIME = "DD/MM/YYYY HH:mm";
export const DATE_FORMAT_WITH_TIME = "ddd D MMM YYYY [at] HH:mm";
export const DATE_FORMAT_WITHOUT_TIME = "ddd D MMM YYYY";
export const ORDINAL_DATE = "Do MMMM YYYY";
export const DATE_FOR_FILES = "DD-MM-YYYY";
export const DATE_TIME_ONLY = "HH:mm";

const format = (format: string, date?: string | Date | Dayjs) =>
  (date ? (dayjs.isDayjs(date) ? date : dayjs(date)) : dayjs()).format(format);

export const formatCardDate = (date?: string | Date | Dayjs) =>
  format(DATE_FORMAT_WITH_TIME, date);

export const formatShortDate = (date?: string | Date | Dayjs) =>
  format(SHORT_DATE_FORMAT, date);

export const formatOrdinalDate = (date?: string | Date | Dayjs) =>
  format(ORDINAL_DATE, date);

export const formatLongDate = (date?: string | Date | Dayjs) =>
  format(LONG_DATE_FORMAT, date);

export const formatDateTime = (date?: string | Date | Dayjs) =>
  format(DATE_FORMAT_WITH_TIME, date);

export const formatShortDateTime = (date?: string | Date | Dayjs) =>
  format(SHORT_DATE_FORMAT_WITH_TIME, date);

export const formatDateFileName = (date?: string | Date | Dayjs) =>
  format(DATE_FOR_FILES, date);

export const formatDateTimeOnly = (date?: string | Date | Dayjs) =>
  format(DATE_TIME_ONLY, date);

export function ucFirst(string: string) {
  return string
    ? string
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
        .trim()
    : "";
}

export const ensureDayjs = (date?: string | Date | Dayjs) =>
  dayjs.isDayjs(date) ? date : dayjs(date);
