import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Header from "../common/header/Header";
import { useDeviceType } from "hooks";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.info.dark,
  },
}));

const CTO3Header: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useDeviceType();

  return (
    <div className={classes.container}>
      <Stack spacing={2}>
        <Header
          regulation="Regulation 6(3)(a)"
          title="Section 17E — Community treatment order: notice of recall to hospital"
        />
        <Typography
          variant="h4"
          style={{
            alignSelf: "center",
            fontStyle: "italic",
            maxWidth: isMobile ? "90%" : "80%",
            textAlign: "center",
            marginBottom: isMobile ? 8 : 0,
          }}
        >
          (To be completed by the responsible clinician)
        </Typography>
      </Stack>
    </div>
  );
};

export default CTO3Header;
