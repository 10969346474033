import dayjs from "dayjs";
import { CommonStateActions, FormState } from "common/types/statForms";
import { CTO3Form, FormStateActions } from "common/types/cto3";
import Action from "common/types/action";
import { signedFormHashed } from "common/libs/forms";
import { cto3InitialState } from "../initialState";

export const cto3Reducer = (state: FormState<CTO3Form>, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case CommonStateActions.RESET_FORM:
      return { ...cto3InitialState };
    case CommonStateActions.LOAD_FORM:
      return {
        ...state,
        ...payload,
      };
    case CommonStateActions.SET_INITIAL_DATA:
      return {
        ...state,
        initialData: state.data,
      };
    case FormStateActions.SET_PATIENT_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          patientDetails: {
            ...state.data.patientDetails,
            name: payload,
          },
        },
      };
    case FormStateActions.SET_HOSPITAL:
      return {
        ...state,
        data: {
          ...state.data,
          hospitalDetails: {
            ...state.data.hospitalDetails,
            ...payload,
          },
        },
      };
    case FormStateActions.SET_OPINION_FOR:
      return {
        ...state,
        data: {
          ...state.data,
          opinion: {
            ...state.data.opinion,
            for: payload,
            details: "",
            purpose: [],
          },
        },
      };
    case FormStateActions.SET_OPINION_DETAILS:
      return {
        ...state,
        data: {
          ...state.data,
          opinion: {
            ...state.data.opinion,
            details: payload,
          },
        },
      };
    case FormStateActions.SET_OPINION_PURPOSE:
      // Need to handle checkboxes, if exists, remove, else add
      const purpose = state.data.opinion.purpose?.includes(payload)
        ? state.data.opinion.purpose.filter((value) => value !== payload)
        : [...state.data.opinion.purpose, payload];

      return {
        ...state,
        data: {
          ...state.data,
          opinion: {
            ...state.data.opinion,
            purpose,
          },
        },
      };
    case FormStateActions.SET_OPINION_SIGNATURE:
      return {
        ...state,
        data: {
          ...state.data,
          opinion: {
            ...state.data.opinion,
            signature: payload,
            date: payload ? dayjs().toISOString() : "",
            trackingId: payload ? signedFormHashed(state.data) : "",
          },
        },
      };
    default:
      return state;
  }
};
