import { createContext } from "react";
import { InitialState } from "./types";

export const initialState: InitialState = {
  confirmation: {
    message: "",
    successCallback: () => {},
    title: "",
  },
  snackbar: {
    type: undefined,
    message: "",
  },
  dialog: [],
};

export const PopupsContext = createContext(initialState);
