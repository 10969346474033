import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { getItemisation } from "common/libs/getItemisation";

interface RadioOptionsListProps {
  value: any;
  options: {
    /**
     * @param {any} value - the value of the option list item, if this matches
     * the value from the top level component it won't be struck through
     * @required
     */
    value: any;
    /**
     * @param {string | undefined} label - radio option label, optional because you
     * can choose to display string label or insert a component
     * @default {undefined}
     */
    label?: string;
    /**
     * @param {string | number} labelFontSize - label font size, optional because it defaults at 16
     * @default {16}
     */
    labelFontSize?: string | number;
    /**
     * @param {React.ReactNode | undefined} component - radio option component, optional because you
     * can choose to display string label. This is here for more flexibility e.g. in the use of
     * the 5(2) form component PractitionerOrClinician.
     * @default {undefined}
     */
    component?: React.ReactNode;
    /**
     * @param {React.ReactNode | undefined} after - this is the component to display underneath
     * the radio option label/component. An example of this is used in the 5(2) form to display
     * an option with a time box and a date and time component underneath.
     * @default {undefined}
     */
    after?: React.ReactNode;
    prefix?: string;
    /**
     * @param {string} guidance - this is the text to display in italics after the label
     * NOTE: this only works when specifying a label instead of a component, if you are using a component
     * you will need to create the guidance string manually as I haven't been able to figure out a way of displaying
     * this correctly within the pdf - IS
     * @default {undefined}
     */
    guidance?: string;
  }[];
  groupPrefix?: string;
  groupAddendum?: string;
  groupGuidance?: string;
  renderAsParagraph?: boolean;
  itemisation?: any;
  itemPadding?: number;
}

/**
 * RadioOptionsList
 * A vertically, or horizontally stacking list rendered from an options list.
 * @param {string} value - the chosen value from the options
 * @param {array} options - the full list of options
 * @param {string} [groupPrefix] - an optional prefix to a list (eg: '(a)', '1 -' )
 * @param {string} [groupAddendum] - an optional extra bit of text to follow a grouping
 * @param {string} [groupGuidance] - optional group guidance following addendum
 * @param {boolean} [renderAsParagraph=false] - controls viewing mode
 * @param {string} [itemisation] - optional chosen itemisation: numerals or alphabetical
 * @param {string} [itemPadding]
 * @returns {JSX.Element}
 */
export const RadioOptionsList: React.FC<RadioOptionsListProps> = ({
  value,
  options,
  groupPrefix,
  groupAddendum,
  groupGuidance,
  renderAsParagraph,
  itemisation,
  itemPadding,
}) => {
  return (
    <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 20 }}>
      {groupPrefix && <Text>{groupPrefix}</Text>}
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          marginLeft: 10,
        }}
      >
        {renderAsParagraph
          ? // Paragraph view
            disclaimerParagraph(
              value,
              options,
              0,
              options.length - 1,
              itemisation,
              groupAddendum,
              groupGuidance
            )
          : // List view
            options.map((option, i: number) => (
              <View
                key={i}
                style={{
                  paddingBottom:
                    itemPadding && i !== options.length - 1
                      ? itemPadding
                      : undefined,
                }}
              >
                <View
                  wrap
                  style={[
                    {
                      flexDirection: "row",
                    },
                  ]}
                >
                  {(!!option.prefix || !!itemisation) &&
                    itemisationSnippet(i, option.prefix, itemisation)}
                  <View wrap>
                    {option.component ? (
                      option.component
                    ) : (
                      <Text wrap>
                        <Text
                          style={{
                            textDecoration:
                              option.value === value ? "none" : "line-through",
                            fontSize: option.labelFontSize || undefined,
                          }}
                        >
                          {option.label}
                        </Text>
                        {option.guidance && (
                          <Text
                            wrap
                            style={{
                              fontStyle: "italic",
                              textDecoration: "none",
                            }}
                          >
                            {" "}
                            {option.guidance}
                          </Text>
                        )}
                      </Text>
                    )}
                  </View>
                </View>
                {option.after && option.after}
              </View>
            ))}
      </View>
    </View>
  );
};

/**
 * disclaimerParagraph
 * The horizontal paragraph style view rendering used in 5(2) form.
 * This function is renders recursively, the only way I could personally think
 * of how to overcome the problem of CSS inheritance when we specifically want
 * children elements to have different CSS to their parents.
 * NB: the name is terrible but I couldn't think of better, feel free to change.
 * @param {string} value - the chosen value from the options
 * @param {array} options - the full list of options
 * @param {number} index - current index from options being processed
 * @param {number} maxIndex - max length to iterate through, probably options.length
 * @param {string} [groupAddendum] - an optional extra bit of text to follow a grouping
 * @param {string} [groupGuidance] - optional group guidance following addendum
 * @param {string} [itemisation] - optional chosen itemisation: numerals or alphabetical
 * @returns {JSX.Element}
 */
const disclaimerParagraph = (
  value: string,
  options: any[],
  index: number,
  maxIndex: number,
  itemisation?: any,
  groupAddendum?: string,
  groupGuidance?: string
) => (
  <Text>
    {(!!options[index].prefix || !!itemisation) &&
      itemisationSnippet(index, options[index].prefix)}
    <Text
      style={{
        flexDirection: "row",
        textDecoration:
          options[index].value === value ? "none" : "line-through",
      }}
    >
      {options[index].label}
      {index !== maxIndex && "/"}
    </Text>
    {index === maxIndex
      ? guidanceSnippet(groupAddendum, groupGuidance)
      : disclaimerParagraph(value, options, index + 1, maxIndex, itemisation)}
  </Text>
);

/**
 * itemisationSnippet
 * A bit of styled text to render itemised values with
 * @param {string} [itemisation] - the chosen itemisation type
 * @param {string} [prefix] - any specific prefix supplied
 * @param {number} index - number to be conveted into itemised value
 * @returns {JSX.Element}
 */
const itemisationSnippet = (
  index: number,
  prefix?: string,
  itemisation?: any
) => (
  <Text
    style={{
      minWidth: 16,
      marginRight: 10,
    }}
  >
    {prefix || (itemisation && "(" + getItemisation(itemisation, index) + ")")}
  </Text>
);

/**
 * guidanceSnippet
 * A bit of styled text to render guidance
 * @param {string} [groupAddendum] - addendum supplied from parent
 * @param {string} [groupGuidance] - guidance supplied from parent
 * @returns {JSX.Element}
 */
const guidanceSnippet = (groupAddendum?: string, groupGuidance?: string) => (
  <Text style={{ textDecoration: "none" }}>
    {groupAddendum}{" "}
    <Text style={{ fontStyle: "italic" }}> {groupGuidance}</Text>
  </Text>
);
