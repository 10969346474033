import React from "react";

// Hooks
import { usePopups } from "hooks";

// Material UI
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface ConfirmationDialogProps {
  open: boolean;
  title?: string;
  message: string;
  closeConfirmation: () => void;
  successCallback: () => any;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  message,
  title = "Confirm",
  closeConfirmation,
  successCallback,
}) => (
  <Dialog open={open} maxWidth="xs" onBackdropClick={closeConfirmation}>
    <DialogTitle component="div">
      <Typography data-test-id="confirmation-title" variant="h3">
        {title}
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography variant="body2">{message}</Typography>
    </DialogContent>
    <DialogActions>
      <Button
        data-test-id="confirmation-cancel"
        autoFocus
        onClick={closeConfirmation}
      >
        Cancel
      </Button>
      <Button data-test-id="confirmation-confirm" onClick={successCallback}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

const Confirmation: React.FC = () => {
  const {
    confirmation: { message, successCallback, title },
    closeConfirmation,
  } = usePopups();
  const isOpen = message ? true : false;

  if (!isOpen) return <></>;

  return (
    <ConfirmationDialog
      open={isOpen}
      title={title}
      message={message}
      closeConfirmation={closeConfirmation}
      successCallback={successCallback}
    />
  );
};

export default React.memo(Confirmation);
