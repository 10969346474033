import React, { useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { Stack } from "@mui/material";
import { useAPI, useAuth, usePopups } from "hooks";
import {
  StatFormEmail,
  StatFormType,
  EmailStatFormInput as EmailStatFormInputType,
} from "@s12solutions/types";
import { StyledTextArea as TextArea, StyledInput } from "components/uiElements";
import { DialogButtonRow } from "components/dialog";
import { formatUserName } from "common/libs/formatUserName";
import { Methods } from "api";

type EmailStatFormInput = EmailStatFormInputType & {
  isFinalPdf?: boolean;
};

interface Values {
  toEmail: string;
  emailText: string;
}

const initialValues: Values = {
  toEmail: "",
  emailText: "",
};

const validationSchema = Yup.object({
  toEmail: Yup.string()
    .email("Invalid email format")
    .required("Email address is required"),
  emailText: Yup.string().required("Email text is required"),
});

const defaultEmailText = `{USER} has sent you a {FORM} that requires action.`;
const defaultEmailTextWithAttachment = `{USER} has completed a {FORM}, you can download this via your dashboard after signing in.`;

const returnDefaultEmailText = (
  data: {
    name: string;
    email?: string;
  },
  formType: string,
  isFinalPdf: boolean
) => {
  const { name, email } = data;

  const emailText = isFinalPdf
    ? defaultEmailTextWithAttachment
    : defaultEmailText;

  return emailText
    .replace("{USER}", `${name} ${email ? `(${email})` : ""}`)
    .replace("{FORM}", formType.toUpperCase());
};

interface EmailStatFormProps {
  formId: string;
  formType: string;
  /**
   * @required
   * @default {1}
   * @param {number} formStep - this is used to build form link to be sent via email
   */
  formStep: number;
  /**
   * @param {string} toEmail - predetermined email e.g. CTO1 form must go back to originator
   */
  toEmail?: string;
  /**
   * @param {boolean} isFinalPdf - is final form ?
   */
  isFinalPdf: boolean;
  /**
   * @param {Function | undefined} onSuccess
   * optional function to run on successful submission
   */
  onSuccess?: Function;
}
const EmailStatForm: React.FC<EmailStatFormProps> = ({
  formId,
  formType,
  formStep,
  toEmail: toEmailAddress,
  isFinalPdf,
  onSuccess,
}) => {
  const { user, actor, clearActor } = useAuth();
  const { closeDialog } = usePopups();

  const { trigger: emailStatForm } = useAPI<
    StatFormEmail,
    { input: EmailStatFormInput }
  >({
    method: Methods.POST,
    fieldName: "emailStatForm",
  });

  const onSubmit = useCallback(
    async (values: Values) => {
      const { toEmail, emailText } = values;

      // Send email
      await emailStatForm({
        input: {
          id: uuidv4(),
          form: formId,
          formStep,
          formType: formType as StatFormType,
          actor: actor ? JSON.stringify(actor) : "",
          toEmail,
          emailText,
          isFinalPdf,
          createdAt: new Date().toISOString(),
        },
      });

      // Clear actor
      if (actor) clearActor();

      // Close dialog
      closeDialog();

      // onSuccess?
      onSuccess?.();
    },
    [
      actor,
      emailStatForm,
      formId,
      formStep,
      formType,
      isFinalPdf,
      clearActor,
      closeDialog,
      onSuccess,
    ]
  );

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      ...(toEmailAddress ? { toEmail: toEmailAddress } : {}),
      emailText: returnDefaultEmailText(
        actor || {
          name: formatUserName(user),
          email: user!.attributes.email,
        },
        formType,
        isFinalPdf
      ),
    },
    onSubmit,
    validationSchema,
  });

  const disableEmailEntry = !!toEmailAddress;

  const disableSubmit = React.useRef<boolean>(
    !formik.isValid || formik.isSubmitting
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <StyledInput
          id="toEmail"
          testId="emailDialog-email"
          name="toEmail"
          label="To Email Address"
          value={formik.values.toEmail}
          onChange={(value: any) => formik.setFieldValue("toEmail", value)}
          error={!!(formik.touched.toEmail && formik.errors.toEmail)}
          errorText={
            formik.touched.toEmail && formik.errors.toEmail
              ? formik.errors.toEmail
              : ""
          }
          autoFocus={!disableEmailEntry}
        />
        <TextArea
          id="emailText"
          testId="emailDialog-text"
          name="emailText"
          placeholder="Email Text"
          label="Email Text"
          value={formik.values.emailText}
          onChange={(value: any) => formik.setFieldValue("emailText", value)}
          error={!!(formik.touched.emailText && formik.errors.emailText)}
          errorText={
            formik.touched.emailText && formik.errors.emailText
              ? formik.errors.emailText
              : ""
          }
          helperText={"Further notes can be added to the email if required"}
        />
        <DialogButtonRow
          testIdKey="emailDialog"
          loading={formik.isSubmitting}
          disableSubmit={disableSubmit.current}
          submitButtonText="Send"
          onSuccess={formik.handleSubmit}
          onCancel={closeDialog}
        />
      </Stack>
    </form>
  );
};

export default React.memo(EmailStatForm);
