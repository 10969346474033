import React from "react";
import { View } from "@react-pdf/renderer";
import { PDFSubHeader } from "../formComponents/baseComponents/PDFSubHeader";
import {
  StyledDateOrTime,
  DateOrTime,
} from "../formComponents/StyledDateOrTime";
import { StyledInformationBox } from "../formComponents/StyledInformationBox";
import { StyledText } from "../formComponents/StyledText";
import { StyledCheckbox } from "../formComponents/StyledCheckbox";
import { PDFSignatures } from "../formComponents/baseComponents/PDFSignatures";
import { CtoFor } from "common/types/cto";
import { CTO1Form } from "common/types/cto1";
import { formatNameAndAddressText } from "utils/pdf";

/**
 * CTO1Form
 * @param {CTO1Form} CTO1Form
 * @returns {JSX.Element}
 */
export const CTO1Body: React.FC<CTO1Form> = ({
  clinicianDetails,
  patientDetails,
  opinion,
  amhpDetails,
  ctoDetails,
}) => {
  // Clinician Details
  const clinicianNameAndAddress = formatNameAndAddressText(
    clinicianDetails.name,
    clinicianDetails.address,
    clinicianDetails.manualAddress1,
    clinicianDetails.manualAddress2,
    clinicianDetails.manualPostcode,
    clinicianDetails.email
  );

  // Patient Details
  const patientNameAndAddress = formatNameAndAddressText(
    patientDetails.name,
    patientDetails.address,
    patientDetails.manualAddress1,
    patientDetails.manualAddress2,
    patientDetails.manualPostcode
  );

  // Amhp Details
  const amhpNameAndAddress = formatNameAndAddressText(
    amhpDetails.name,
    amhpDetails.address,
    amhpDetails.manualAddress1,
    amhpDetails.manualAddress2,
    amhpDetails.manualPostcode
  );

  return (
    <View>
      {/* Part 1 -> Hospital Details */}
      <PDFSubHeader
        text="(Parts 1 and 3 of this form are to be completed by the responsible clinician and Part 2 by an approved mental health professional)"
        style={{ maxWidth: "90%", margin: "18px auto" }}
        fontStyle={{ fontSize: 11.5 }}
      />
      <PDFSubHeader subsubheading="PART 1" fontStyle={{ fontSize: 12 }} />
      <StyledText
        instruction="I"
        guidance="(PRINT full name, address and, if sending by means of electronic communication, email address of the responsible clinician)"
        style={{ fontSize: 12 }}
      />

      <StyledInformationBox value={clinicianNameAndAddress} minHeight={50} />
      <StyledText
        instruction="am the responsible clinician for"
        style={{ marginTop: 10 }}
      />
      <StyledText
        instruction=""
        guidance="(PRINT full name and address of patient)."
        noMargin
      />
      <StyledInformationBox
        value={patientNameAndAddress}
        minHeight={50}
        style={{ margin: 0 }}
      />
      <StyledText instruction="In my opinion," style={{ marginTop: 28 }} />
      <View style={{ marginLeft: 20, maxWidth: "82%" }}>
        <StyledCheckbox
          itemisation="Alphabetical"
          itemPadding={12}
          display
          options={[
            {
              label:
                "this patient is suffering from mental disorder of a nature or degree which makes it appropriate for the patient to receive medical treatment,",
            },
            {
              component: (
                <View>
                  <StyledText instruction="it is necessary for" noMargin />
                  <StyledCheckbox
                    value={opinion.for}
                    itemisation="Roman"
                    noMargin
                    options={[
                      {
                        value: CtoFor.PATIENTS_HEALTH,
                        label: "the patient's health",
                      },
                      {
                        value: CtoFor.PATIENTS_SAFETY,
                        label: "the patient's safety",
                      },
                      {
                        value: CtoFor.SAFETY_OF_OTHERS,
                        label: "the protection of other persons",
                      },
                    ]}
                  />
                  <StyledText
                    instruction="(delete any phrase which is not applicable)"
                    noMargin
                    style={{ fontStyle: "italic" }}
                  />
                  <StyledText
                    instruction="that the patient should receive such treatment;"
                    style={{ marginTop: 24, marginBottom: 6 }}
                  />
                </View>
              ),
            },
            {
              label:
                "such treatment can be provided without the patient continuing to be detained in a hospital provided the patient is liable to being recalled to hospital for medical treatment;",
            },
            {
              label:
                "it is necessary that the responsible clinician should be able to exercise the power under section 17E(1) to recall the patient to hospital;",
            },
            {
              label:
                "taking into account the nature and degree of the mental disorder from which the patient is suffering and all other circumstances of the case, appropriate medical treatment is available to the patient.",
            },
          ]}
        />
      </View>
      {/* Page 2 */}
      <StyledText
        pageBreak
        instruction="My opinion is founded on the following grounds &mdash;"
        style={{ marginBottom: 4 }}
      />
      <StyledInformationBox
        value={opinion.details}
        minHeight={350}
        style={{ margin: 0 }}
      />
      <StyledText
        instruction="I confirm that in determining whether the criterion at (d) above is met, I have considered what risk"
        style={{ marginBottom: 0 }}
      />
      <StyledText
        instruction="there would be of deterioration of the patient's condition if the patient were not detained in hospital,"
        style={{ margin: 0 }}
      />
      <StyledText
        instruction="with regard to the patient's history of mental disorder and any other relevant factors."
        style={{ margin: 0 }}
      />
      <PDFSubHeader
        subsubheading="Conditions to which the patient is to be subject by virtue of this community treatment order"
        fontStyle={{ fontSize: 11, fontStyle: "italic" }}
        style={{ marginVertical: 25 }}
      />
      <View style={{ maxWidth: "80%" }}>
        <StyledText
          instruction="The patient is to make himself or herself available for examination under section 20A, as requested."
          style={{ marginBottom: 12 }}
        />
      </View>
      <View style={{ maxWidth: "86%" }}>
        <StyledText
          instruction="If it is proposed to give a certificate under Part 4A of the Act in the patient's case, the patient is to make himself or herself available for examination to enable the certificate to be given,"
          noMargin
        />
        <StyledText instruction="as requested." noMargin />
      </View>
      {/* Page 3 */}
      <StyledText
        pageBreak
        instruction="The patient is also to be subject to the following conditions (if any) under section 17B(2) of the Act:"
        style={{ marginBottom: 4 }}
      />
      <StyledInformationBox
        value={opinion.furtherConditions || "N/A"}
        minHeight={450}
      />
      <StyledText
        instruction="I confirm that I consider the above conditions to be made under section 17B(2) of the Act are"
        noMargin
      />
      <StyledText
        instruction="necessary or appropriate for one or more of the following purposes:"
        style={{ marginBottom: 12 }}
      />
      <StyledCheckbox
        value={""}
        display
        itemPadding={8}
        noMargin
        itemisation="Bullets"
        options={[
          {
            label: "to ensure that the patient receives medical treatment",
          },
          {
            label: "to prevent risk of harm to the patient's health or safety",
          },
          {
            label: "to protect other persons.",
          },
        ]}
      />
      <PDFSignatures
        signed={opinion.signature}
        date={opinion.date}
        sha256={opinion.trackingId}
      />
      {/* Page 4 */}
      <PDFSubHeader
        subsubheading="PART 2"
        fontStyle={{ fontSize: 12 }}
        pageBreak
      />
      <StyledText instruction="I" guidance="(PRINT full name and address)" />
      <StyledInformationBox value={amhpNameAndAddress} minHeight={50} />
      <StyledText
        instruction="am acting on behalf of"
        guidance="(name of local social services authority)"
        noMargin
      />
      <StyledInformationBox value={amhpDetails.authorityName} minHeight={40} />
      <StyledText
        instruction="and am approved to act as an approved mental health professional for the purposes of the"
        style={{ marginBottom: 0 }}
      />
      <StyledText
        instruction="Act by"
        guidance="(delete as appropriate)"
        noMargin
        style={{ maxWidth: "92%", marginBottom: 6 }}
      />
      <View style={{ paddingLeft: 22 }}>
        <StyledCheckbox
          value={
            typeof amhpDetails.otherAuthority === "undefined" &&
            !amhpDetails.onBehalfOf
              ? undefined // user hasn't filled out yet (initial state)
              : typeof amhpDetails.otherAuthority === "undefined" &&
                amhpDetails.onBehalfOf // user has filled out without altering initial state
              ? [true]
              : [amhpDetails.otherAuthority]
          }
          itemPadding={6}
          noMargin
          options={[
            {
              value: false,
              label: "that authority",
            },
            {
              value: true,
              label:
                "(name of local social services authority that approved you, if different)",
              labelStyle: "italic",
            },
          ]}
        />
        <StyledInformationBox
          value={
            typeof amhpDetails.onBehalfOf === "undefined"
              ? ""
              : amhpDetails.onBehalfOf || "N/A"
          }
          minHeight={40}
        />
      </View>
      <StyledText instruction="I agree that:" />
      <View style={{ maxWidth: "78%", marginLeft: 20 }}>
        <StyledCheckbox
          value={""}
          display
          itemPadding={4}
          noMargin
          itemisation="Roman"
          options={[
            {
              label:
                "the above patient meets the criteria for a community treatment order to be made",
            },
            {
              label:
                "it is appropriate to make a community treatment order, and",
            },
            {
              label:
                "the conditions made above under section 17B(2) are necessary or appropriate for one or more of the purposes specified.",
            },
          ]}
        />
      </View>
      <PDFSignatures
        signed={amhpDetails.signature}
        date={amhpDetails.date}
        sha256={amhpDetails.trackingId}
        after={
          <StyledText
            instruction="Approved mental health professional"
            noMargin
          />
        }
      />
      {/* Part 3 */}
      <PDFSubHeader subsubheading="PART 3" fontStyle={{ fontSize: 12 }} />
      <StyledText
        instruction="I exercise my power under section 17A of the Mental Health Act 1983 to make a community treatment order in respect of the patient named in Part 1 of this Form."
        style={{ maxWidth: "90%" }}
      />
      <StyledText instruction="This community treatment order is to be effective from" />
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: 450,
        }}
      >
        <StyledDateOrTime
          dateOrTime={DateOrTime.date}
          value={ctoDetails.effectiveFromDate}
          label="(date)"
          empty={!!!ctoDetails.effectiveFromDate}
        />
        <StyledDateOrTime
          dateOrTime={DateOrTime.time}
          value={ctoDetails.effectiveFromTime}
          label="(time)"
          prefix="at "
          empty={!!!ctoDetails.effectiveFromTime}
        />
      </View>
      <PDFSignatures
        signed={ctoDetails.signature}
        date={ctoDetails.date}
        sha256={ctoDetails.trackingId}
        after={<StyledText instruction="Responsible clinician" noMargin />}
      />
      <PDFSubHeader
        subsubheading="THIS COMMUNITY TREATMENT ORDER IS NOT VALID UNLESS ALL THREE PARTS ARE COMPLETED AND SIGNED. IT MUST BE FURNISHED AS SOON AS PRACTICABLE TO THE MANAGERS OF THE HOSPITAL IN WHICH THE PATIENT WAS LIABLE TO BE DETAINED BEFORE THE ORDER WAS MADE"
        fontStyle={{ fontSize: 12 }}
        style={{ margin: "0 auto 20px", maxWidth: "88%" }}
      />
    </View>
  );
};
