import {
  PatientDetails,
  SignatureType,
  HospitalDetailsType as GenericHospitalDetailsType,
} from "common/types/statForms";
import { FormPDFConfigData } from "./pdf";

export type HospitalDetailsType = GenericHospitalDetailsType &
  SignatureType & {
    detentionDate: string;
    detentionTime: string;
  };

export interface CTO4Form {
  patientDetails: PatientDetails;
  hospitalDetails: HospitalDetailsType;
}

export const pdfConfig: FormPDFConfigData = {
  longTitle:
    "Section 17E — Community treatment order: record of patient’s detention in hospital after recall ",
  longTitleFontSize: 11,
  regulation: "6(3)d",
};

export enum FormStateActions {
  LOAD_FORM = "LOAD_FORM",
  SET_PATIENT_NAME = "SET_PATIENT_NAME",
  SET_PATIENT_ADDRESS = "SET_PATIENT_ADDRESS",
  SET_HOSPITAL_NAME = "SET_HOSPITAL_NAME",
  SET_HOSPITAL_ADDRESS = "SET_HOSPITAL_ADDRESS",
  SET_HOSPITAL_DETENTION_DATE = "SET_HOSPITAL_DETENTION_DATE",
  SET_HOSPITAL_DETENTION_TIME = "SET_HOSPITAL_DETENTION_TIME",
  SET_HOSPITAL_SIGNATURE = "SET_HOSPITAL_SIGNATURE",
}
