import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(3),
  },
  sectionContainer: {
    padding: "2rem",
    backgroundColor: theme.palette.info.dark,
  },
  buttonContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    marginTop: 10,
    marginBottom: 20,
  },
  formPartSelector: {
    position: "sticky",
    top: "4rem",
    height: "80vh",
  },
}));

export { useStyles };
