import { useEffect, useRef, useState } from "react";
import { useAuth } from "hooks";

type FetchTokensResult = {
  userId: string;
  accessToken: Buffer;
  idToken: Buffer;
  refreshToken: Buffer;
};

type UseMagicLink = {
  loading: boolean;
};

/**
 * Env Variables
 */
const ORIGIN = process.env.REACT_APP_ORIGIN || "http://localhost:3005";
const S12_API_ENDPOINT = process.env.REACT_APP_S12_API_EP;

/**
 * useMagicLink()
 * This hook enables authentication between app v2 (and at some point probably app v3)
 * and the stat forms platform.
 *
 * If the `session` url param is specified and there is no authenticated user
 * the user will be authenticated.
 *
 * @returns {UseMagicLink} - link will need a loading state to prevent flicker between log in screen
 */
export const useMagicLink = (): UseMagicLink => {
  const { user, setCredentials, authService } = useAuth();
  const runOnceRef = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    /**
     * clearQueryString() - once this has run we don't need the query string
     * and we also don't want people inadvertently sending people their urls
     * with this still there.
     * @returns {void}
     */
    function clearQueryString(): void {
      window.history.replaceState(null, "", window.location.pathname);
    }

    /**
     * handleRunOnce() - we only want this hook to run once
     * @returns {void}
     */
    function handleRunOnce(): void {
      clearQueryString();
      setLoading(false);
      runOnceRef.current = true;
    }

    /**
     * fetchTokens()
     * @returns {Promise<FetchTokensResult>} returns the userId, idToken and accessToken
     * to authenticate the user
     */
    async function fetchTokens(): Promise<FetchTokensResult> {
      const response = await fetch(
        `${S12_API_ENDPOINT}checkMagicLink?session=${session}`,
        {
          headers: {
            "Access-Control-Request-Headers": "X-Requested-With",
            "Access-Control-Request-Method": "GET",
            Origin: ORIGIN,
          },
          method: "GET",
        }
      );
      const data = await response.json();

      return data as FetchTokensResult;
    }

    /**
     * handleLogin() - use the credentials fetched using the sessionId
     * to log the user in.
     * @returns {Promise<void>}
     */
    async function handleLogin(): Promise<void> {
      const {
        userId,
        accessToken: accessTokenBuffer,
        idToken: idTokenBuffer,
        refreshToken: refreshTokenBuffer,
      } = await fetchTokens();
      const accessToken = Buffer.from(accessTokenBuffer).toString();
      const idToken = Buffer.from(idTokenBuffer).toString();
      const refreshToken = Buffer.from(refreshTokenBuffer).toString();

      const user = await authService.signInWithJwtToken(
        userId,
        accessToken,
        idToken,
        refreshToken
      );
      setCredentials(user);

      handleRunOnce();
    }

    // If the hook has already been run, exit.
    if (runOnceRef.current) {
      return;
    }

    // If user is authenticated, exit.
    if (user) {
      handleRunOnce();
      return;
    }

    // Handle session query string
    const searchParams = new URLSearchParams(window.location.search);
    const parsedQueryString = Object.fromEntries(searchParams.entries());
    const session = parsedQueryString?.session ?? undefined;

    // If there is no session query param, exit.
    if (!session) {
      handleRunOnce();
      return;
    }

    handleLogin();

    // Cleanup unmount e.g. log out
    return () => {
      setLoading(true);
      runOnceRef.current = false;
    };
  }, [authService, user, setCredentials]);

  return { loading };
};
