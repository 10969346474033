import { useEffect, useRef } from "react";
import * as Yup from "yup";
import { SectionObject } from "store/forms/types";
import { useForm } from "hooks";
import { isEqual } from "common/libs/isEqual";

/**
 * useFormValidation()
 * @param {Yup.AnyObjectSchema[]} validationSchema -  an array of yup schemas,
 * the index of the validation schema will be the index of the form `currentSectionIndex`
 * @param {SectionObject[]} sectionObjectArr - the `formState` object to
 * be validated against the index of the `validationSchema`
 * @returns {void} void
 * NOTE: we could pass in the form data and use each section as opposed
 * to the sectionObjectArr but it's important that the section index is
 * mapped to the correct section in the data object e.g. CTO1 index's 2
 * and 3 both point to the same data object (opinion) but use a different validation schema - IS
 */
export const useFormValidation = (
  validationSchema: Yup.AnyObjectSchema[],
  sectionObjectArr: SectionObject[]
) => {
  const { generateSectionIndexValidation } = useForm();
  const prevValue = useRef<SectionObject[]>();
  useEffect(() => {
    // Only retrigger update when `sectionObjectArr` has changed
    if (isEqual(prevValue.current, sectionObjectArr)) return;

    generateSectionIndexValidation(validationSchema, sectionObjectArr);
    prevValue.current = sectionObjectArr;
  }, [generateSectionIndexValidation, sectionObjectArr, validationSchema]);
};
