/**
 * ! ----------------------------------------------------------------
 * ! WARNING
 * ! this function should be accessed via the `useForm` hook as function
 * ! parameters are prefilled
 * ! ----------------------------------------------------------------
 */
import dayjs from "dayjs";
import { StatFormType } from "@s12solutions/types";
import { FormState } from "common/types/statForms";
import { FormType } from "common/types/pdf";
import { UsePopups } from "hooks/usePopups";
import { HandleSaveParams } from "../types";

export const saveForm = async (
  formType: FormType,
  formId: string,
  formData: FormState,
  user: any,
  actor: any,
  emailLinkId: string | undefined,
  saveParams: HandleSaveParams,
  createOrUpdateStatForm: any,
  handleSnackbar: UsePopups["handleSnackbar"],
  handleSuccess: (successMessage?: string) => void
) => {
  const { shouldNavigate, formStatus } = saveParams;

  // ? We cast `newData` because at this point it could be many different forms
  // ? and it is easier to work with and format the data correctly
  let newData = formData.data as any;

  /**
   * `formatSaveCallback` can be used to format the data before it is saved. It will look for the
   * `formatSaveCallback` in the associated form `helpers.ts` file.
   * An example is used in the H3 form
   */
  const formatSaveCallback = (await import(`pages/${formType}/helpers`))
    .formatSaveCallback;
  if (formatSaveCallback) {
    newData = formatSaveCallback(newData);
  }

  const handleStatus = !formStatus
    ? (await import(`pages/${formType}/helpers`)).handleStatus
    : () => {};

  const variables = {
    input: {
      id: formId,
      type: formType as StatFormType,
      patientName: newData?.patientDetails?.name || "",
      version: 1,
      userId: user?.username as string, // this is safe as there will always be a user at this point
      status: formStatus || handleStatus(formData),
      data: JSON.stringify(JSON.stringify(newData)), // this is required to correctly escape quotations and escape them properly
      actor: actor ? JSON.stringify(actor) : "",
      sha256: "",
      createdBy: formData?.createdBy || user?.username,
      createdAt: formData?.createdAt || dayjs().toISOString(),
      updatedBy: user?.username,
      updatedAt: dayjs().toISOString(),
      emailLinkId,
    },
  };

  try {
    const res = await createOrUpdateStatForm(variables);
    if (!res) throw new Error("An unknown error has occured");

    // This is conditional because we save the form when the user clicks the email button
    // So the latest changes are being emailed
    if (shouldNavigate) {
      const successMessage =
        variables.input.createdAt === variables.input.updatedAt
          ? "Form successfully updated the form"
          : // undefined because the default message is used for creation
            undefined;
      handleSuccess(successMessage);
    }

    return true;
  } catch (error) {
    handleSnackbar("error", "An unknown error has occured");

    return false;
  }
};
