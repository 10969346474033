import * as Yup from "yup";
import {
  Actors,
  FormState,
  SignatureType,
  FormItemType,
  FormStatus,
} from "common/types/statForms";
import {
  T2Form,
  PatientDetailsType,
  ClinicianDetailsType,
} from "common/types/t2";
import {
  clinicianDetailsValidation,
  patientDetailsFullValidation,
} from "common/libs/validation";
import { CognitoUserExt } from "common/types/auth";
import { isDoctor, isWard } from "utils/auth";

/**
 * getActor
 * - if the user is a doctor -> stage 1 index 0
 * - if the user is a ward the doctor and part 2 is signed -> stage 2 index 1
 */
export const getActor = (
  formState: FormState<T2Form>,
  user: CognitoUserExt
): Actors | boolean => {
  const { clinicianSignature } = formState.data;

  if (isDoctor(user)) {
    return Actors.Originator;
  }

  if (isWard(user) && clinicianSignature.signature) {
    return Actors.MHAAdmin;
  }

  return false;
};

export const generateFormItems = (): FormItemType[] => {
  return [
    /** TO BE COMPLETED BY: Responsible Clinician */
    {
      index: 0,
      itemText: "1. Clinician Details",
      errorKey: "clinicianDetails",
    },
    {
      index: 1,
      itemText: "2. Patient Details",
      errorKey: "patientDetails",
    },
  ];
};

export const handleStatus = (formState: FormState<T2Form>): FormStatus => {
  if (
    (formState.status === "" || formState.status === FormStatus.s10_draft) &&
    !formState.data.clinicianSignature.signature
  ) {
    return FormStatus.s10_draft;
  }

  return FormStatus.s40_signed;
};

export const validationSchema = [
  Yup.object().shape({
    ...clinicianDetailsValidation,
    approvedClinician: Yup.boolean().required("Role is required"),
  }),
  Yup.object().shape({
    ...patientDetailsFullValidation,
    description: Yup.string()
      .required("Description is required")
      .min(60, "Description needs to be greater than 60 characters"),
  }),
];

export const hasErrorsSection1 = (data: ClinicianDetailsType): boolean => {
  const {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    approvedClinician,
  } = data;

  let error = false;
  if (!name) error = true;
  if (!address && !manualAddress1 && !manualAddress2 && !manualPostcode)
    error = true;
  if (approvedClinician === undefined) error = true;

  return error;
};

export const hasErrorsSection2 = (
  patientDetails: PatientDetailsType,
  clinicianSignature: SignatureType,
  requireSignature: boolean = true
): boolean => {
  const {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    description,
  } = patientDetails;
  const { signature } = clinicianSignature;

  let error = false;
  if (!name) error = true;
  if (!address && !manualAddress1 && !manualAddress2 && !manualPostcode)
    error = true;
  if (!description) error = true;
  if (requireSignature && !signature) error = true;

  return error;
};

export const shouldDisablePrev = (
  actor: Actors,
  currentIndex: number
): boolean => {
  if (actor === Actors.MHAAdmin && currentIndex === 1) {
    return true;
  }

  return false;
};

export const shouldEnableDownload = (data: T2Form): boolean => {
  const { clinicianDetails, patientDetails, clinicianSignature } = data;

  if (
    hasErrorsSection1(clinicianDetails) ||
    hasErrorsSection2(patientDetails, clinicianSignature)
  ) {
    return false;
  }

  return true;
};
