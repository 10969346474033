import { ReactNode, useCallback, useState } from "react";
import {
  UIContext,
  Reviewing,
  ReviewingParams,
  initialState,
} from "store/ui/uiContext";
import PDFPreviewDrawer from "components/pdf/pdfPreviewDrawer";

/**
 * handleUrlChange()
 * If uuid and stage is set in url increment the stage and reset the url
 * Using window because you don't want react router to act upon the url change
 */
const handleUrlChange = (newIndex: number) => {
  const currentState = window.history.state;
  const path = window.location.pathname;
  const pathSplitArr = path.split("/");

  // If form is being created there will not be a uuid or stage
  // We use value `2` because the first element in the array is an empty string,
  // and the second element will be the form type.
  const isFormBeingCreated = pathSplitArr.length === 2;
  if (isFormBeingCreated) return;

  const formStageUrlIndex = 3;
  pathSplitArr[formStageUrlIndex] = (newIndex + 1).toString(); // newIndex + 1 because url index doesn't start at 0
  const newPath = pathSplitArr.join("/");

  window.history.replaceState(currentState, "", newPath);
};

/**
 * handleScrollToTop
 * Scrolls to top of form container
 */
const handleScrollToTop = () => {
  const formContainerTop = document.getElementById("scroll-top");
  if (formContainerTop) {
    formContainerTop.scrollIntoView({ behavior: "smooth" });
  }
};

/**
 * UIProvider
 * - handles the navigating between stages on a form
 * - handles form pdf preview
 */
const UIProvider = ({ children }: { children: ReactNode }) => {
  // UI state
  const [formSummaryMode, setFormSummaryMode] = useState<boolean>(false);
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
  const [reviewing, setReviewing] = useState<Reviewing | undefined>(undefined);

  const handleNavigateForward = useCallback(() => {
    const newIndex = currentSectionIndex + 1;
    setCurrentSectionIndex(newIndex);
    handleUrlChange(newIndex);
    handleScrollToTop();
  }, [currentSectionIndex, setCurrentSectionIndex]);

  const handleNavigateBack = useCallback(() => {
    const newIndex = currentSectionIndex - 1;
    setCurrentSectionIndex(newIndex);
    handleUrlChange(newIndex);
    handleScrollToTop();
  }, [currentSectionIndex, setCurrentSectionIndex]);

  const handleSetCurrentSectionIndex = useCallback(
    (newIndex: number) => {
      setCurrentSectionIndex(newIndex);
      handleUrlChange(newIndex);
      handleScrollToTop();
    },
    [setCurrentSectionIndex]
  );

  const handleSetReviewing = useCallback(
    (document: React.ReactElement, params: ReviewingParams) => {
      setReviewing(() => ({
        document,
        ...params,
      }));
    },
    [setReviewing]
  );

  const handleClearReviewing = useCallback(() => {
    setReviewing(initialState.reviewing);
  }, [setReviewing]);

  return (
    <UIContext.Provider
      value={{
        formSummaryMode,
        currentSectionIndex,
        reviewing,
        navigateForward: handleNavigateForward,
        navigateBack: handleNavigateBack,
        setCurrentSectionIndex: handleSetCurrentSectionIndex,
        setFormSummaryMode,
        setReviewing: handleSetReviewing,
        clearReviewing: handleClearReviewing,
      }}
    >
      {children}
      <PDFPreviewDrawer />
    </UIContext.Provider>
  );
};

export { UIProvider };
