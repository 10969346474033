import {
  ReactNode,
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";

// Custom
import {
  StatForm,
  StatFormInput,
  StatFormsActivity,
  StatFormsActivityInput,
} from "@s12solutions/types";
import { FormState } from "./types";
import { FormContext, initialState } from "store/forms/formContext";
import { FormType } from "common/types/pdf";
import { Actors, CommonStateActions } from "common/types/statForms";
import {
  HandleEmailParams,
  HandleSaveParams,
  SectionObject,
} from "store/forms/types";
import {
  useAPI,
  useAuth,
  useFormStateSetup,
  usePopups,
  useUI,
  useUnsavedChangesWarning,
} from "hooks";
import generateFormProps from "utils/pdf/generateFormProps";
import {
  generateSectionIndexValidation,
  validateValue,
} from "./helpers/handleValidation";
import { saveForm } from "./helpers/handleSave";
import { deployAuthDialog } from "./helpers/deployAuthDialog";
import { deployEmailDialog } from "./helpers/deployEmailDialog";
import LoadingCircle from "components/loadingCircle/LoadingCircle";
import { RouteConstants } from "common/constants/routes";
import { Methods } from "api";

/**
 * FormProvider
 * Hereby lives the state for the forms.
 * @returns form
 */
export const FormProvider = ({ children }: { children: ReactNode }) => {
  /**
   * *-------------------------------------------------------
   * * Hooks
   * *-------------------------------------------------------
   */
  const { formType, formId, stage, emailLinkId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentStage = stage as string;

  const castedFormType = formType as FormType;
  const { user, actor, clearActor } = useAuth();
  const { currentSectionIndex, setCurrentSectionIndex } = useUI();
  const { handleDialog, closeDialog, handleSnackbar } = usePopups();
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const url = location.pathname;

  /**
   * *-------------------------------------------------------
   * * GraphQL
   * *-------------------------------------------------------
   */
  useAPI<StatForm, { id?: string }>({
    method: Methods.GET,
    fieldName: "statFormById",
    args: {
      id: formId,
    },
    skip: !formId,
    onCompleted: async (statFormData) => {
      if (statFormData) {
        const data = {
          ...(typeof statFormData.data === "string"
            ? JSON.parse(statFormData.data)
            : statFormData.data),
        };
        const initialData = structuredClone(data);
        const newFormState = {
          ...statFormData,
          data,
          initialData,
          createdBy: statFormData.userId,
        };

        dispatchFormState({
          type: CommonStateActions.LOAD_FORM,
          payload: newFormState,
        });

        const getActor = (await import(`pages/${formType}/helpers`)).getActor;
        const actorType = getActor(newFormState, user);

        if (!actorType) {
          navigate(RouteConstants.UNAUTHORISED);
          return;
        }

        setActorType(actorType);

        // Set the correct index based on the url
        if (stage && Number(stage) !== 1) {
          setCurrentSectionIndex(Number(stage) - 1); // Minus one because route stage starts at 1 and not 0
        }
      }

      setLoading(false);
    },
  });

  const { trigger: createStatFormActivity } = useAPI<
    StatFormsActivity,
    { input: StatFormsActivityInput }
  >({
    method: Methods.POST,
    fieldName: "createStatFormActivity",
    args: {
      input: {
        id: uuidv4(),
        form: formId as string,
        actor: JSON.stringify(actor) || "",
        action: "view",
        createdAt: new Date().toISOString(),
      },
    },
  });

  const { trigger: createOrUpdateStatForm } = useAPI<
    StatForm,
    {
      input: StatFormInput & {
        emailLinkId?: string;
      };
    }
  >({
    method: Methods.POST,
    fieldName: "createOrUpdateStatForm",
  });

  /**
   * *-------------------------------------------------------
   * * Form State
   * *-------------------------------------------------------
   */
  const [loading, setLoading] = useState<boolean>(true);
  const { formState, dispatchFormState } = useFormStateSetup();
  const formStateRef = useRef<typeof formState>(formState);
  formStateRef.current = formState;

  const [actorType, setActorType] = useState<Actors>(initialState.actorType);
  const [formErrors, setFormErrors] = useState(initialState.formErrors);

  const [sectionIndexValidation, setSectionIndexValidation] = useState(
    initialState.sectionIndexValidation
  );
  const [validationSchema, setValidationSchema] = useState<
    Yup.AnyObjectSchema[]
  >([]);

  // This ref makes she we only register the "view" activity log entry once per form
  const registeredViewRef = useRef<boolean>(false);

  // This ref is needed after saving and navigating (because the state has
  // changed we don't want to prompt user about "unsaved changes" after they
  // have completed a form or clicked save and exit)
  const overrideUnsavedChangesBlockerRef = useRef<boolean>(false);

  const stateChanged = useMemo(
    () =>
      formState.initialData &&
      Object.keys(formState.initialData).length > 0 &&
      formState.data &&
      Object.keys(formState.data).length > 0 &&
      JSON.stringify(formState.initialData) !== JSON.stringify(formState.data)
        ? true
        : false,
    [formState.data, formState.initialData]
  );

  /**
   * *-------------------------------------------------------
   * * Form Setters
   * *-------------------------------------------------------
   */
  const clearForm = useCallback(() => {
    clearActor();
    setCurrentSectionIndex(0);
    setFormErrors(() => ({ ...initialState.formErrors }));
    setSectionIndexValidation(() => ({
      ...initialState.sectionIndexValidation,
    }));
  }, [clearActor, setCurrentSectionIndex]);

  const clearFormErrors = useCallback(
    () => setFormErrors({ ...initialState.formErrors }),
    []
  );

  /**
   * *-------------------------------------------------------
   * * Form Functions
   * *-------------------------------------------------------
   */

  const handleAuthDialog = useCallback(
    (requiredEmail?: string, successCallback: () => void = () => {}) =>
      deployAuthDialog(
        requiredEmail,
        successCallback,
        handleDialog,
        closeDialog,
        navigate
      ),
    [handleDialog, closeDialog, navigate]
  );

  const getValidationSchema = useCallback(
    (index?: number) => validationSchema[index ?? currentSectionIndex],
    [currentSectionIndex, validationSchema]
  );

  const handleSuccess = useCallback(
    (successMessage: string = "Form created successfully") => {
      overrideUnsavedChangesBlockerRef.current = true;
      clearForm();
      handleSnackbar("success", successMessage);
      navigate(RouteConstants.DASHBOARD);
    },
    [clearForm, handleSnackbar, navigate]
  );

  const handleSaveForm = useCallback(
    (saveParams: HandleSaveParams) =>
      new Promise(async () => {
        const saveResSuccess = await saveForm(
          castedFormType,
          formId as string,
          formStateRef.current,
          user,
          actor,
          emailLinkId,
          saveParams,
          createOrUpdateStatForm,
          handleSnackbar,
          handleSuccess
        );

        if (saveParams.shouldResetForm) {
          dispatchFormState({
            type: CommonStateActions.RESET_FORM,
            payload: undefined,
          });
        } else {
          dispatchFormState({
            type: CommonStateActions.SET_INITIAL_DATA,
            payload: undefined,
          });
        }

        if (!saveResSuccess) {
          navigate("/");
        }
      }),
    [
      castedFormType,
      formId,
      user,
      actor,
      emailLinkId,
      createOrUpdateStatForm,
      handleSnackbar,
      handleSuccess,
      dispatchFormState,
      navigate,
    ]
  );

  const handleEmail = useCallback(
    async (
      emailParams: HandleEmailParams = { includePDFAttachment: false }
    ) => {
      const formProps = generateFormProps(
        formId,
        castedFormType,
        formState.data
      );

      deployEmailDialog(
        formId as string,
        castedFormType,
        currentSectionIndex,
        formProps,
        emailParams,
        handleSaveForm,
        handleDialog,
        () => {
          handleSuccess("Form successfully emailed");
          closeDialog();
        }
      );
    },
    [
      castedFormType,
      closeDialog,
      currentSectionIndex,
      formId,
      formState.data,
      handleDialog,
      handleSaveForm,
      handleSuccess,
    ]
  );

  const handleValidateValue = useCallback(
    async (
      errorKey: string,
      sectionValue: any,
      sectionIndex?: number
    ): Promise<boolean> =>
      await validateValue(
        errorKey,
        sectionValue,
        sectionIndex,
        formErrors,
        getValidationSchema,
        setFormErrors
      ),
    [formErrors, getValidationSchema]
  );

  /**
   * handleGenerateSectionIndexValidation
   * NOTE: this function is used by `useFormValidation` hook don't use directly - IS
   */
  const handleGenerateSectionIndexValidation = useCallback(
    (
      validationSchema: Yup.AnyObjectSchema[],
      sectionObjectArr: SectionObject[]
    ): void =>
      generateSectionIndexValidation(
        validationSchema,
        sectionObjectArr,
        setValidationSchema,
        setSectionIndexValidation,
        setFormErrors
      ),
    [setSectionIndexValidation]
  );

  /**
   * *-------------------------------------------------------
   * * Common Form Lifecycles
   * *-------------------------------------------------------
   */

  // Set currentSectionIndex based on url
  // For whatever reason the current stage as a dependency alone
  // doesn't trigger the useEffect to trigger on stage change resulting
  // in the currentSectionIndex not being updated correctly and forms not being reset
  // once selected from the sidemenu - IS
  useEffect(() => {
    setCurrentSectionIndex(Number(currentStage) - 1);
  }, [setCurrentSectionIndex, currentStage, url]);

  // Register view activity log entry
  useEffect(() => {
    if (
      // If the form has already been created
      !formState.createdAt ||
      // If there is form data in the state
      Object.keys(formState).length === 0 ||
      // If we haven't already registered a view
      registeredViewRef.current
    )
      return;

    createStatFormActivity();
    registeredViewRef.current = true;
  }, [createStatFormActivity, formState]);

  // Handle unsaved changes
  useEffect(() => {
    if (stateChanged && !overrideUnsavedChangesBlockerRef.current) {
      setDirty();
    } else {
      setPristine();
    }
  }, [setDirty, setPristine, stateChanged]);

  // * Provider values and setters
  const valuesAndSetters = useMemo(
    () =>
      ({
        // Form values
        loading,
        formState,
        actorType,
        formErrors,
        sectionIndexValidation,

        // Form setters
        setLoading,
        setActorType,
        clearForm,
        clearFormErrors,

        // Form Functions
        dispatchFormState,
        handleAuthDialog,
        handleSave: handleSaveForm,
        handleEmail,
        generateSectionIndexValidation: handleGenerateSectionIndexValidation,
        handleValidateValue,
      } satisfies FormState),
    [
      actorType,
      clearForm,
      clearFormErrors,
      dispatchFormState,
      formErrors,
      formState,
      handleAuthDialog,
      handleEmail,
      handleGenerateSectionIndexValidation,
      handleSaveForm,
      handleValidateValue,
      loading,
      sectionIndexValidation,
    ]
  );

  return (
    <FormContext.Provider value={valuesAndSetters}>
      {loading ? <LoadingCircle /> : children}
      {Prompt}
    </FormContext.Provider>
  );
};
