import React, { useCallback } from "react";
// Material UI Imports
import { Button, Grid, Typography } from "@mui/material";
// Custom Imports
import { useStyles } from "./styles";
import { StyledDatePicker, StyledInput } from "components/uiElements";
import { useUI, useForm, usePopups, useDeviceType } from "hooks";
import { RenderedPDF } from "components/pdf";
import generateFormProps from "utils/pdf/generateFormProps";
import { FormType } from "common/types/pdf";
import { ReviewingType } from "store/ui/uiContext";
import { useParams } from "react-router-dom";
import { FormStatus } from "common/types/statForms";

interface SignatureSectionProps {
  name: string | undefined | null;
  date: string | undefined | null;
  description?: string;
  signedStatus: boolean;
  setSignedStatus: any;
  customSectionIndexValidation?: Function;
}

const SignatureSection: React.FC<SignatureSectionProps> = ({
  name,
  date,
  description,
  signedStatus,
  setSignedStatus,
  customSectionIndexValidation,
}) => {
  const { formType, formId } = useParams();
  const classes = useStyles();
  const { isMobile } = useDeviceType();
  const { setReviewing, currentSectionIndex } = useUI();
  const { formState, sectionIndexValidation, clearFormErrors, handleSave } =
    useForm();
  const { handleConfirmation, handleSnackbar } = usePopups();

  const handleReview = useCallback(async () => {
    clearFormErrors();

    let success: boolean = true;

    // validate up to the current section index unless there is custom section index validation specified
    if (customSectionIndexValidation) {
      success = await customSectionIndexValidation();
    } else if (sectionIndexValidation) {
      const sectionIndexValidateFunctions = Object.values(
        sectionIndexValidation
      );
      for (let i = 0; i <= currentSectionIndex; i++) {
        const validationResponse = await sectionIndexValidateFunctions[i]();
        if (!validationResponse) {
          success = false;
        }
      }
    }

    // if validation isn't passed the user won't be able to review and sign
    // instead errored sections are highlighted to the user
    if (!success) {
      handleSnackbar("error", "There are form errors that need correcting");
    }

    // generate form props format
    const formProps = generateFormProps(
      formId as string,
      formType as FormType,
      formState.data
    );

    // trigger pdf drawer via ui context
    setReviewing(<RenderedPDF {...formProps} />, {
      type: ReviewingType.SIGNATURE,
      callback: () => {
        setSignedStatus(true);

        // Didn't want to have to put this timeout in but it seems to execute save
        // before the dispatch has executed the signature update
        setTimeout(() => {
          handleSave({
            shouldNavigate: false,
            formStatus: FormStatus.s40_signed,
          });
        }, 1000);
      },
      disableSign: !success,
    });
  }, [
    clearFormErrors,
    currentSectionIndex,
    customSectionIndexValidation,
    formId,
    formState.data,
    formType,
    handleSave,
    handleSnackbar,
    sectionIndexValidation,
    setReviewing,
    setSignedStatus,
  ]);

  const handleUnsign = useCallback(() => {
    handleConfirmation(
      "Are you sure you want to unsign and edit this form?",
      () => {
        setSignedStatus(false);
        handleSave({
          shouldNavigate: false,
          formStatus: FormStatus.s10_draft,
        });
      }
    );
  }, [handleConfirmation, handleSave, setSignedStatus]);

  return (
    <Grid container spacing={2}>
      {signedStatus && (
        <>
          <Grid item xs={12}>
            <Typography variant="h4">Signed</Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledInput
              className={classes.input}
              InputLabelProps={{
                shrink: true,
              }}
              disabled // disabled because this input will be filled in using user details upon signing
              label="Signature"
              value={name}
              onChange={() => {}}
            />
            {description && (
              <Typography variant="h4" sx={{ marginTop: 1 }}>
                {description}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant="h4">PRINT NAME</Typography>
          </Grid>
          {!isMobile && (
            <Grid item xs={12} md={3}>
              <Typography variant="h4">Date</Typography>
            </Grid>
          )}
          <Grid item xs={12} md={9}>
            <StyledInput
              data-test-id="h3-name-part-2"
              id="name-part-2"
              className={classes.input}
              InputLabelProps={{
                shrink: true,
              }}
              disabled // disabled because this input will be filled in using user details upon signing
              label="Name"
              value={name}
              onChange={() => {}}
            />
          </Grid>
          {isMobile && (
            <Grid item xs={12} md={3}>
              <Typography variant="h4">Date</Typography>
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <StyledDatePicker
              label="Signature Date"
              value={date}
              disabled
              error={false}
              onChange={() => {}}
            />
          </Grid>
        </>
      )}
      <Grid container item xs={12}>
        <div style={{ margin: "0 auto", textAlign: "center" }}>
          {signedStatus ? (
            <Button
              data-test-id="form-unsign"
              variant="outlined"
              onClick={handleUnsign}
              size="small"
            >
              Unsign and Edit
            </Button>
          ) : (
            <>
              <Button
                data-test-id="form-review"
                variant="contained"
                onClick={async () => {
                  await handleReview();
                }}
                style={{ margin: "0 auto" }}
              >
                REVIEW FORM
              </Button>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default React.memo(SignatureSection);
