// Main entry point for the app
// Sets up state store and router parents
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { LicenseInfo } from "@mui/x-license-pro";
import { AuthProvider } from "store/auth/authProvider";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "@fontsource/roboto";
import { UIProvider } from "store/ui/uiProvider";

// MUIX License
const muixProLicenseKey = process.env.REACT_APP_MUIX_PRO_LICENSE_KEY!;
LicenseInfo.setLicenseKey(muixProLicenseKey);

// Title bar to environment
switch (process.env.NODE_ENV) {
  case "production":
    document.title = "S12 Forms";
    break;
  case "test":
    document.title = "S12 Forms - Preprod";
    break;
  default:
    document.title = "S12 Forms - Development";
    break;
}

const StatFormsApp = () => (
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <UIProvider>
          <App />
        </UIProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

ReactDOM.render(<StatFormsApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
