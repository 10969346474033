import { CognitoUserExt } from "common/types/auth";

/**
 * formatUserName()
 * This function has been put in as at the moment when we create a stat
 * form user via the admin panel the site name is prepended to the full name.
 * This will be changed at some point but for now this is here - IS
 * @param {CognitoUserExt | string} user - user object from the auth provider or string
 * @returns {string} username formatted
 */
export const formatUserName = (
  user: CognitoUserExt | string | undefined
): string => {
  if (typeof user === "undefined") {
    return "";
  }
  const usernameValue = typeof user === "string" ? user : user.attributes.name;

  if (usernameValue.includes(" - ")) {
    return usernameValue.split(" - ")[1];
  }

  return usernameValue;
};
