import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Header from "../common/header/Header";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.info.dark,
  },
}));

const H3Header: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Stack spacing={2}>
        <Header
          regulation="Regulation 4(4) and (5)"
          title="Sections 2, 3 and 4 — record of detention in hospital"
        />
        <Typography
          variant="h4"
          style={{
            alignSelf: "center",
            textAlign: "center",
            fontStyle: "italic",
          }}
        >
          (To be attached to the application for admission)
        </Typography>
        <Typography variant="h2" style={{ alignSelf: "center" }}>
          PART 1
        </Typography>
      </Stack>
    </div>
  );
};

export default H3Header;
