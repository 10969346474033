import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { FormType } from "common/types/pdf";
import { styles } from "common/styles/pdf";
interface PDFHeading {
  regulation: string;
  longTitle: string;
  form: FormType;
  longTitleFontSize?: number;
  longTitleCenter?: boolean;
}

/**
 * PDFHeader
 * A header component for PDF forms
 * @param {string} regulation - example: "4(1)(g)"
 * @param {string} form - example: "H3"
 * @param {string} longTitle - example: "Section 5(2) – report on hospital in-patient"
 * @returns {JSX.Element}
 */
export const PDFHeader = ({
  regulation,
  form,
  longTitle,
  longTitleFontSize,
  longTitleCenter,
}: PDFHeading) => {
  const formTitle = `Form ${form.toUpperCase()}`;
  return (
    <View>
      <View style={{ ...styles.flexRowSpaceBetween, marginBottom: 4 }}>
        <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
          <Text style={{ ...styles.largeBoldFont, margin: 0, marginRight: 5 }}>
            {formTitle}
          </Text>
          {regulation && (
            <Text style={{ ...styles.mediumItalicFont, marginBottom: 2 }}>
              Regulation {regulation}
            </Text>
          )}
        </View>
        <Text style={styles.mediumBoldFont}>Mental Health Act 1983</Text>
      </View>
      {longTitle && (
        <Text
          style={{
            ...styles.smallBoldFont,
            marginBottom: 12,
            fontSize: longTitleFontSize || styles.smallBoldFont.fontSize,
            textAlign: longTitleCenter ? "center" : undefined,
          }}
        >
          {longTitle}
        </Text>
      )}
    </View>
  );
};
