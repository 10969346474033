import * as Yup from "yup";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ensureDayjs } from "./dateFormat";
dayjs.extend(customParseFormat);

type DateValidationParams = {
  /**
   * @default false
   * @param {boolean} allowGreaterThan1YearAgo
   */
  allowGreaterThan1YearAgo?: boolean;
  /**
   * @default true
   * @param {boolean} allowFuture
   */
  allowFuture?: boolean;
};

export const dateValidation = (
  fieldName: string = "Date",
  params: DateValidationParams = {
    allowGreaterThan1YearAgo: false,
    allowFuture: true,
  }
) =>
  Yup.string()
    .nullable()
    .test(
      "valid-date",
      `${fieldName} is not valid (DD-MM-YYYY)`,
      (value: any) => {
        if (!value) return true; // exit early because we don't want to return error message
        if (value === "Invalid Date") return false;

        if (dayjs(value).isValid() && dayjs(value, "DD-MM-YYYY", true)) {
          return true;
        }

        return false;
      }
    )
    .test(
      "past-date",
      `${fieldName} cannot be greater than 1 year ago`,
      (value: any) => {
        if (!value || value === "Invalid Date") return true; // exit early because we don't want to return error message
        if (params.allowGreaterThan1YearAgo) return true;

        const currentDate = dayjs();
        const oneYearAgo = currentDate.subtract(1, "year");
        const dateToCheck = dayjs(value);

        return dateToCheck.isAfter(oneYearAgo);
      }
    )
    .test(
      "future-date",
      `${fieldName} cannot be in the future`,
      (value: any) => {
        if (!value || value === "Invalid Date") return true; // exit early because we don't want to return error message
        if (params.allowFuture) return true;

        const currentDate = dayjs();
        const dateToCheck = dayjs(value);

        return dateToCheck.isBefore(currentDate);
      }
    )
    .required(`${fieldName} is required`);

type TimeValidationParams = {
  /**
   * @default false
   * @param {boolean} allowFuture
   */
  allowFuture?: boolean;
  /**
   * @default false
   * @param {boolean} allowGreaterThan1YearAgo
   */
  allowGreaterThan1YearAgo?: boolean;
  /**
   * @default undefined
   * @param {string} dateField - name of the date field to validate full date and time
   */
  dateField?: string;
};
export const timeValidation = (
  fieldName: string = "Time",
  { allowFuture, dateField, allowGreaterThan1YearAgo }: TimeValidationParams = {
    allowFuture: true,
    allowGreaterThan1YearAgo: false,
    dateField: undefined,
  }
) =>
  Yup.string()
    .nullable()
    .test(
      "valid-time",
      `${fieldName} is not valid (HH:mm)`,
      (value: any, context: Yup.TestContext) => {
        if (!value) return true; // exit early because we don't want to return error message
        if (value === "Invalid Date") return false;

        if (dayjs(value, "HH:mm", true).isValid()) {
          return true;
        }

        return false;
      }
    )
    .test(
      "past-time",
      `${fieldName} cannot be greater than 1 year ago`,
      (value: any, context: Yup.TestContext) => {
        if (
          !value ||
          value === "Invalid Date" ||
          !dateField ||
          allowGreaterThan1YearAgo
        )
          return true; // exit early because we don't want to return error message

        const dateValue = dateField ? context.parent[dateField] : undefined;
        if (!dateValue) return true;

        const currentDate = dayjs();
        const oneYearAgo = currentDate.subtract(1, "year");

        // Extracting the hours and minutes from times string and creating full date object
        const dateToCheck = ensureDayjs(dateValue);
        const [hours, minutes] = value.split(":").map(Number);
        const fullDate = dateToCheck
          .set("hours", hours)
          .set("minutes", minutes);

        return fullDate.isAfter(oneYearAgo);
      }
    )
    .test(
      "future-date",
      `${fieldName} cannot be in the future`,
      (value: any, context: Yup.TestContext) => {
        if (!value || value === "Invalid Date") return true; // exit early because we don't want to return error message
        if (allowFuture) return true;

        const dateValue = dateField ? context.parent[dateField] : undefined;
        if (!dateValue) return true;

        const currentDate = dayjs();
        const dateToCheck = ensureDayjs(dateValue);
        const [hours, minutes] = value.split(":").map(Number);
        const fullDate = dateToCheck
          .set("hours", hours)
          .set("minutes", minutes);

        return fullDate.isBefore(currentDate);
      }
    )
    .required(`${fieldName} is required`);

export const addressDetailsValidation = (
  customMessage: string = "Address is required",
  allowUnknown: boolean = false // used for patient address to enable "no fixed abode" functionality
) => ({
  address: Yup.string()
    .trim()
    .test(
      "valid-address",
      customMessage.replace("required", "not valid"),
      (value: any) => {
        if (
          allowUnknown &&
          ["unknown", "n/a", "na", "nfa"].includes(value.toLowerCase())
        ) {
          return true;
        }

        if (value && value.split(",").length < 2) {
          return false;
        }

        return true;
      }
    )
    .required(customMessage),
});

export const hospitalDetailsValidation = {
  ...addressDetailsValidation("Hospital address is required"),
  name: Yup.string().trim().required("Hospital name is required"),
};

export const clinicianDetailsValidation = {
  ...addressDetailsValidation("Clinician address is required"),
  name: Yup.string().trim().required("Clinician name is required"),
  email: Yup.string()
    .trim()
    .email("Clinician email is not valid")
    .required("Clinician email is required"),
};

export const patientDetailsValidation = {
  name: Yup.string().trim().required("Patient name is required"),
};
export const patientDetailsFullValidation = {
  ...addressDetailsValidation("Patient address is required", true),
  ...patientDetailsValidation,
};

export const opinionValidation = {
  for: Yup.array().min(1, "Please select at least one of the options"),
  details: Yup.string()
    .trim()
    .min(60, "Opinion details needs to be greater than 60 characters")
    .required("Opinion details is required"),
};

export const amhpDetailsValidation = {
  ...addressDetailsValidation("AMHP address is required"),
  name: Yup.string().trim().required("AMHP name is required"),
  authorityName: Yup.string().trim().required("Authority name is required"),
  otherAuthority: Yup.boolean(),
  onBehalfOf: Yup.string()
    .trim()
    .when("otherAuthority", {
      is: (otherAuthorityValue: boolean | undefined) => {
        if (otherAuthorityValue || typeof otherAuthorityValue === "undefined") {
          return true;
        }
      },
      then: Yup.string()
        .trim()
        .required("Local social services authority name required"),
    }),
};

export const ctoDetailsValidation = {
  effectiveFromDate: dateValidation("CTO Date", {
    allowFuture: false,
    allowGreaterThan1YearAgo: false,
  }),
  effectiveFromTime: timeValidation("CTO Time", {
    allowFuture: false,
    allowGreaterThan1YearAgo: false,
    dateField: "effectiveFromDate",
  }),
};
