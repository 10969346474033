import React, { useState, useCallback } from "react";

// Material UI Imports
import { Button, Grid, Stack, Typography } from "@mui/material";

// Custom Imports
import { useAuth, useForm } from "hooks";
import Paper from "components/paper";
import SignatureSection from "components/formSections/common/signatureSection";
import Action from "common/types/action";
import { WardSignatureType, FormStateActions } from "common/types/h1";
import { OutputTime, OutputDate } from "components/uiElements";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

interface WardSignatureProps {
  wardSignature: WardSignatureType;
  dispatchFormState: React.Dispatch<Action>;
}

const WardSignature: React.FC<WardSignatureProps> = ({
  wardSignature: { signature, date },
  dispatchFormState,
}) => {
  const { actor, user } = useAuth();
  const { handleEmail } = useForm();

  const [signedStatus, setSignedStatus] = useState<boolean>(!!signature);

  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed
        ? actor
          ? actor?.name
          : user
          ? user?.attributes?.name
          : ""
        : "";

      dispatchFormState({
        type: FormStateActions.SET_WARD_SIGNATURE,
        payload: signedValue,
      });
    },
    [dispatchFormState, actor, user]
  );

  return (
    <Paper>
      <Stack spacing={3}>
        <Stack spacing={3}>
          <Typography variant="h2" style={{ alignSelf: "center" }}>
            PART 2
          </Typography>
          <Typography
            variant="h4"
            style={{
              alignSelf: "center",
              fontStyle: "italic",
              maxWidth: "80%",
              textAlign: "center",
            }}
          >
            (To be completed on behalf of the hospital managers)
          </Typography>
        </Stack>
        <Typography variant="h4">This report was</Typography>
        <div style={{ marginLeft: "2rem" }}>
          <Stack spacing={2}>
            <Typography variant="h4" className="strikeThrough">
              furnished to the hospital managers through their internal mail
              system
            </Typography>
            <Typography variant="h4">
              furnished to the hospital managers, or a person authorised by them
              to receive it, by means of electronic communication
            </Typography>
            <Grid container>
              <Grid item>
                <Typography variant="h4" className="strikeThrough">
                  delivered to me in person as someone authorised by the
                  hospital managers to receive this report at
                </Typography>
                <Grid container alignItems="center" spacing={1} mt={1}>
                  <Grid container item xs={5} alignItems="center">
                    <OutputTime time={""} strikeThrough noBackground />
                    <Typography
                      variant="h4"
                      className="strikeThrough italic"
                      sx={{ marginLeft: 2 }}
                    >
                      (time)
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      variant="h4"
                      className="strikeThrough italic text-right"
                    >
                      on
                    </Typography>
                  </Grid>
                  <Grid container item xs={5} alignItems="center">
                    <OutputDate iSODate={""} strikeThrough noBackground />
                    <Typography
                      variant="h4"
                      className="strikeThrough italic"
                      sx={{ marginLeft: 2 }}
                    >
                      (date)
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </div>
        <Stack>
          <SignatureSection
            name={signature}
            date={date}
            description="on behalf of the hospital managers"
            signedStatus={signedStatus}
            setSignedStatus={handleSetSignedStatus}
          />
        </Stack>
        <Stack flexDirection="row" justifyContent="center" alignItems="center">
          <Button
            data-test-id="h1-email-form-final"
            variant="contained"
            onClick={() => handleEmail({ includePDFAttachment: true })}
            disabled={!signedStatus}
            size="large"
            sx={{ mr: 1 }}
          >
            Send to MHA Admin
          </Button>
          <PdfDownloadButton disabled={!signedStatus} />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default React.memo(WardSignature);
