import { StoreApi, create } from "zustand";
import { FormType } from "common/types/pdf";
import Action from "common/types/action";
import { formTypeToReducerConfig } from "store/formState/helpers";
import { FormState } from "common/types/statForms";

export type StoreState = {
  formState: FormState;
  dispatch: (action: Action) => void;
};

const createFormStore = (
  formType: FormType
): ReturnType<typeof create<StoreApi<StoreState>>> => {
  const formStateReducer = formTypeToReducerConfig[formType].reducer;
  const initialFormState = formTypeToReducerConfig[formType].initialState;

  return create<StoreState>((set) => ({
    formState: initialFormState,
    dispatch: (action) =>
      set((state) => {
        const newState = formStateReducer(state.formState, action);
        return { formState: { ...newState } };
      }),
  }));
};

export default createFormStore;
