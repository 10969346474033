import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    backgroundColor: theme.palette.info.dark,
  },
  input: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  select: {
    width: "100%",
    borderRadius: ".5rem",
    backgroundColor: theme.palette.common.white,
  },
  inputDisabled: {
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    borderRadius: ".5rem",
  },
  row: {
    display: "flex",
  },
  inPatient: {
    color: theme.palette.primary.main,
  },
  dateTime: {
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  helperAlert: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "0.4rem",
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    margin: "0.5rem",
    "& .MuiAlert-icon": {
      color: theme.palette.primary.main,
    },
  },
}));

export { useStyles };
