import {
  HospitalDetailsType,
  PatientDetailsType,
} from "common/types/statForms";
import { FormPDFConfigData } from "./pdf";

export type AdmissionDetailsType = {
  inPatient: boolean;
  admissionDate: string | null;
  admissionTime: string | null;
  section: string | null;
  signaturePart1: string | null;
  trackingIdPart1: string | null;
  namePart1: string | null;
  signatureDatePart1: string | null;
  // Fields on part 2 of the form
  admissionDatePart2: string | null;
  admissionTimePart2: string | null;
  signaturePart2: string | null;
  trackingIdPart2: string | null;
  namePart2: string | null;
  signatureDatePart2: string | null;
};

export interface H3Form {
  hospitalDetails: HospitalDetailsType;
  patientDetails: PatientDetailsType;
  admissionDetails: AdmissionDetailsType;
}

export enum FormStateActions {
  LOAD_FORM = "LOAD_FORM",
  SET_HOSPITAL = "SET_HOSPITAL",
  SET_PATIENT_NAME = "SET_PATIENT_NAME",
  SET_DATE = "SET_DATE",
  SET_TIME = "SET_TIME",
  SET_SECTION = "SET_SECTION",
  SET_IN_PATIENT = "SET_IN_PATIENT",
  SET_SIGNATURE_PART1 = "SET_SIGNATURE_PART1",
  SET_SIGNATURE_DATE_PART1 = "SET_SIGNATURE_DATE_PART1",
  SET_DATE_PART2 = "SET_DATE_PART2",
  SET_TIME_PART2 = "SET_TIME_PART2",
  SET_SIGNATURE_PART2 = "SET_SIGNATURE_PART2",
  SET_SIGNATURE_DATE_PART2 = "SET_SIGNATURE_DATE_PART2",
}

export const pdfConfig: FormPDFConfigData = {
  longTitle: "Sections 2, 3 and 4 – record of detention in hospital",
  regulation: "4(4)and (5)",
  pagePadding: "26px 22px 2px",
};
