import { useCallback, useState, useEffect, useContext } from "react";
import { History, Transition } from "history";
import { Navigator } from "react-router";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import { useAuth, useFormStateSetup, usePopups } from "hooks";
import { UNSAVED_WARNING } from "common/constants/messages";
import { CommonStateActions } from "common/types/statForms";

type ExtendNavigator = Navigator & Pick<History, "block">;
const useBlocker = (
  blocker: (tx: Transition) => void,
  when: boolean = false
) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;
    const unblock = (navigator as ExtendNavigator).block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

const usePrompt = (message: string, when: boolean = false) => {
  const { clearActor } = useAuth();
  const { dispatchFormState } = useFormStateSetup();
  const { handleConfirmation } = usePopups();
  const blocker = useCallback(
    (tx: Transition) => {
      handleConfirmation(
        message,
        () => {
          clearActor();
          dispatchFormState({ type: CommonStateActions.RESET_FORM });
          tx.retry();
        },
        "Warning!"
      );
    },
    [clearActor, handleConfirmation, message, dispatchFormState]
  );

  useBlocker(blocker, when);
};

const useUnsavedChangesWarning = (): [any, any, any] => {
  const [isDirty, setDirty] = useState(false);
  const routerPrompt = usePrompt(UNSAVED_WARNING, isDirty);

  return [routerPrompt, () => setDirty(true), () => setDirty(false)];
};

export default useUnsavedChangesWarning;
