const ignoreCapitalArray = ["and"];

// ? e.g. "s10_signed_and_sent" => "Signed and sent"
export const formatStatus = (status: string): string => {
  const words = status.replaceAll("_", " ").split(" ");

  return words
    .map((word) => {
      return ignoreCapitalArray.includes(word)
        ? word
        : word.charAt(0).toUpperCase() + word.slice(1);
    })
    .slice(1)
    .join(" ");
};
