/**
 * ! ----------------------------------------------------------------
 * ! WARNING
 * ! this function should be accessed via the `useForm` hook as function
 * ! parameters are prefilled
 * ! ----------------------------------------------------------------
 */
import React from "react";
import * as Yup from "yup";
import dayjs from "dayjs";

import { SectionIndexValidation } from "common/types/statForms";
import { FormErrors, SectionObject } from "../types";

export const validateSection = async (
  currentValidationSchema: Yup.AnyObjectSchema,
  sectionObject: SectionObject,
  setFormErrors: (value: React.SetStateAction<FormErrors>) => void
): Promise<boolean> => {
  const sectionKey = Object.keys(sectionObject)[0];
  const sectionValue = sectionObject[sectionKey];

  try {
    await currentValidationSchema?.validate(sectionValue, {
      abortEarly: false,
    });

    return true;
  } catch (error: any) {
    const allFormErrors: FormErrors = {};
    error?.inner?.forEach((err: any) => {
      if (!err.path || !err.message) return;
      allFormErrors[`${sectionKey}.${err.path}` as string] = err.message;
    });
    setFormErrors((prevState) => ({ ...prevState, ...allFormErrors }));

    return false;
  }
};

export const validateValue = async (
  errorKey: string,
  sectionValue: any,
  sectionIndex: number | undefined,
  formErrors: FormErrors,
  getValidationSchema: (index?: number) => Yup.AnyObjectSchema,
  setFormErrors: (value: React.SetStateAction<FormErrors>) => void
) => {
  const currentValidationSchema = getValidationSchema(sectionIndex);
  // If validation schema has not loaded yet, don't validate
  if (!currentValidationSchema) return false;

  try {
    const errorKeys = errorKey.split(".");
    const shouldHandleNested = errorKeys.length > 2;

    // ? This is used when two common form sections have been combined
    // ? e.g. CTO3 form patient details and hospital details are combined
    if (shouldHandleNested) {
      const sectionName = `${errorKeys[1]}.${errorKeys[2]}`;

      await Yup.reach(currentValidationSchema, sectionName)?.validate(
        sectionValue
      );
    } else {
      const sectionName = errorKeys[errorKeys.length - 1];

      // ? NOTE: If section value is an object, not an array and not a date it means
      // ? the validation relies on other fields in the object
      if (
        typeof sectionValue === "object" &&
        !Array.isArray(sectionValue) &&
        !dayjs.isDayjs(sectionValue)
      ) {
        await currentValidationSchema.validateAt(sectionName, sectionValue);
      } else {
        await Yup.reach(currentValidationSchema, sectionName)?.validate(
          sectionValue
        );
      }
    }

    // If successful remove error key from formErrors
    const newFormErrors = formErrors;
    delete newFormErrors[errorKey];
    setFormErrors(() => ({ ...newFormErrors }));

    return true;
  } catch (error: any) {
    // If unsuccessful add error to formErrors
    setFormErrors((prevState) => ({
      ...prevState,
      [errorKey]: error.message,
    }));

    return false;
  }
};

export const generateSectionIndexValidation = (
  validationSchema: Yup.AnyObjectSchema[],
  sectionObjectArr: SectionObject[],
  setValidationSchema: any,
  setSectionIndexValidation: React.Dispatch<
    React.SetStateAction<SectionIndexValidation>
  >,
  setFormErrors: (value: React.SetStateAction<FormErrors>) => void
): void => {
  // set validation schema within provider (this is used for input validation)
  setValidationSchema(validationSchema);

  const sectionIndexValidation: SectionIndexValidation = {};

  sectionObjectArr.forEach((sectionObject, index) => {
    const currentValidationSchema = validationSchema[index];
    sectionIndexValidation[index] = () =>
      validateSection(currentValidationSchema, sectionObject, setFormErrors);
  });

  setSectionIndexValidation(sectionIndexValidation);
};
