import React, { useState, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

// Material UI
import { Stack } from "@mui/material";

// Custom Imports
import { Input } from "@s12solutions/ui";
import { useAuth } from "hooks";
import { DialogButtonRow } from "components/dialog";
import { FormType } from "common/types/pdf";

interface InitialValues {
  name: string;
  email: string;
}

const initialValues: InitialValues = {
  name: "",
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Email address must be valid")
    .required("Email address is required"),
  name: Yup.string().required("Name is required"),
});

interface AuthDialogContentProps {
  /**
   * @default {undefined}
   * @param {string | undefined} requiredEmail - The email address that is required for access to the form.
   */
  requiredEmail?: string;
  /**
   * @param {Function} onSuccess - callback to be called when the form is successfully submitted
   */
  onSuccess: () => void;
  /**
   * @default {undefined}
   * @param {Function | undefined} onCancel - callback to be called when the form is successfully cancelled
   */
  onCancel?: () => void;
  /**
   * @default {"Submit"}
   * @param {string | undefined} submitButtonText - The text to display on the submit button
   */
  submitButtonText?: string;
}

const AuthDialogContent: React.FC<AuthDialogContentProps> = ({
  requiredEmail,
  onSuccess,
  onCancel,
  submitButtonText = "Submit",
}) => {
  const { setActor } = useAuth();
  const requiredEmailValidation: boolean = !!requiredEmail;
  const [emailValid, setEmailValid] = useState<boolean>(true);

  const onSubmit = useCallback(
    async (values: { name: string; email: string }) => {
      // If there is a required email present (i.e trying to access a form via email link)
      // then we will check that the email is valid
      if (requiredEmailValidation) {
        if (values.email !== requiredEmail) {
          setEmailValid(false);
          return;
        }
      }

      setEmailValid(true);

      // Set `actor` in the auth context if the form is a hospital form
      const actor = {
        name: values.name,
        email: values.email,
      };
      setActor(actor);

      // Execute onSuccess callback (which will navigate to form)
      onSuccess();
    },
    [onSuccess, requiredEmail, requiredEmailValidation, setActor]
  );

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const disableSubmit: boolean =
    formik.isSubmitting || !(formik.values.name && formik.values.email);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <Input
          testId="authDialog-name"
          id="name"
          name="name"
          placeholder="Name"
          label="Name"
          value={formik.values.name}
          onChange={(e: any) => formik.setFieldValue("name", e.target.value)}
          errorText={
            formik.touched.name && formik.errors.name ? formik.errors.name : ""
          }
        />
        <Input
          testId="authDialog-email"
          id="email"
          name="email"
          placeholder="Email"
          label="Email"
          value={formik.values.email}
          onChange={(e: any) => formik.setFieldValue("email", e.target.value)}
          error={
            (formik.touched.email && formik.errors.email ? true : false) ||
            !emailValid
          }
          errorText={
            !emailValid
              ? "Please use the email address this link was sent to"
              : formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ""
          }
        />
        <DialogButtonRow
          testIdKey="authDialog"
          loading={formik.isSubmitting}
          submitButtonText={submitButtonText}
          disableSubmit={disableSubmit}
          onSuccess={formik.handleSubmit}
          onCancel={() => onCancel?.()}
        />
      </Stack>
    </form>
  );
};

export default React.memo(AuthDialogContent);
