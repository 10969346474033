import React from "react";

// Form Components
import FormContainer from "components/forms/formContainer";

import CTO7Header from "components/formSections/cto7/CTO7Header";
import HospitalDetails from "components/formSections/common/hospitalDetails";
import ClinicianDetails from "components/formSections/common/clinicianDetails";
import PatientDetails from "components/formSections/cto7/patientDetails";
import Opinion from "components/formSections/common/opinion";
import AMHPDetails from "components/formSections/cto7/amhpDetails";
import FurnishingReport from "components/formSections/cto7/furnishingReport/FurnishingReport";
import WardSignature from "components/formSections/cto7/wardSignature/WardSignature";
import FormLoading from "components/forms/formLoading";

// Custom Imports
import {
  useForm,
  useUI,
  useFormValidation,
  useActorTypeStageChange,
  useFormAccess,
  useAuth,
} from "hooks";
import { FormStateActions } from "common/types/cto7";
import {
  generateFormItems,
  shouldDisablePrev,
  shouldDisableNext,
  shouldEnableDownload,
  validationSchema,
} from "./helpers";
import { useStyles } from "common/styles/form";
import { Actors } from "common/types/statForms";
import { isWard } from "utils/auth";
import { LoadingCircle } from "@s12solutions/ui";

/**
 * ------------------------------------------------------------
 * * WORKFLOW FOR CTO7 FORM
 * ------------------------------------------------------------
 * Stages
 * 1/ Responsible Clinician creates, signs and emails to AMHP
 * 2/ AHMP updates signs and emails back to Responsible Clinician
 * 3/ Responsible Clinician updates signs and emails to hospital managers/ mha admin
 * 4/ mha admin will complete part 4 and download
 * ------------------------------------------------------------
 */

const CTO7Form = () => {
  const classes = useStyles();
  const { actor, user } = useAuth();
  const { loading, formState, dispatchFormState, actorType, handleEmail } =
    useForm();
  const { currentSectionIndex } = useUI();

  const {
    hospitalDetails,
    clinicianDetails,
    patientDetails,
    opinion,
    amhpDetails,
    furnishingReport,
    wardSignature,
  } = formState.data;

  // * Start Component lifecycle methods ------------------------
  const { requiredEmail } = useFormAccess("CTO");

  useActorTypeStageChange(actorType, {
    [Actors.Originator]: 0,
    [Actors.AMHP]: 4,
    [Actors.Clinician]: 5,
    [Actors.MHAAdmin]: 6,
  });

  useFormValidation(validationSchema, [
    { hospitalDetails },
    { clinicianDetails },
    { patientDetails },
    { opinion },
    { amhpDetails },
    { furnishingReport },
    {},
  ]);
  // * End Component lifecycle methods ------------------------

  const formItems = generateFormItems(actorType);
  const part1Disabled = !!opinion.signature;

  // Render
  if (loading) {
    return <LoadingCircle />;
  }
  if (isWard(user) && !actor) {
    return <FormLoading />;
  }

  return (
    <FormContainer
      sections={[
        <>
          <div className={classes.sectionContainer}>
            <CTO7Header />
          </div>
          <div className={classes.sectionContainer}>
            <HospitalDetails
              testIdKey="cto7"
              label="To the managers of"
              guidance="(name and address of the responsible hospital)"
              name={hospitalDetails.name}
              address={hospitalDetails.address}
              manualAddress1={hospitalDetails.manualAddress1}
              manualAddress2={hospitalDetails.manualAddress2}
              manualPostcode={hospitalDetails.manualPostcode}
              setHospital={(value: any) =>
                dispatchFormState({
                  type: FormStateActions.SET_HOSPITAL,
                  payload: value,
                })
              }
              disabled={part1Disabled}
            />
          </div>
        </>,
        <>
          <div className={classes.sectionContainer}>
            <ClinicianDetails
              testIdKey="cto7-clinician"
              clinicianDetails={clinicianDetails}
              dispatchFormState={dispatchFormState}
              disabled={part1Disabled}
            />
          </div>
        </>,
        <div className={classes.sectionContainer}>
          <PatientDetails
            testIdKey="cto7-patient"
            patientDetails={patientDetails}
            dispatchFormState={dispatchFormState}
            disabled={part1Disabled}
          />
        </div>,
        <div className={classes.sectionContainer}>
          <Opinion
            testIdKey="cto7-opinion"
            opinion={opinion}
            dispatchFormState={dispatchFormState}
            handleEmail={handleEmail}
            checkString="(delete any indent which is not applicable)"
            pointASemiColon
            disabled={part1Disabled}
          />
        </div>,
        <div className={classes.sectionContainer}>
          <AMHPDetails
            testIdKey="cto7-amhp"
            amhpDetails={amhpDetails}
            dispatchFormState={dispatchFormState}
            handleEmail={() =>
              handleEmail({
                toEmail: requiredEmail,
              })
            }
          />
        </div>,
        <div className={classes.sectionContainer}>
          <FurnishingReport
            testIdKey="cto7-furnishing"
            furnishingReport={furnishingReport}
            dispatchFormState={dispatchFormState}
          />
        </div>,
        <div className={classes.sectionContainer}>
          <WardSignature
            wardSignature={wardSignature}
            dispatchFormState={dispatchFormState}
          />
        </div>,
      ]}
      formPartSelectorProps={{
        formItems,
        enableDownload: shouldEnableDownload(formState.data),
      }}
      formNavButtonProps={{
        formItems,
        lastSectionIndex: 6,
        disablePrev: shouldDisablePrev(actorType, currentSectionIndex),
        disableNext: shouldDisableNext(currentSectionIndex, actorType),
      }}
    />
  );
};

export default React.memo(CTO7Form);
