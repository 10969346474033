import React from "react";
import { Text, View } from "@react-pdf/renderer";

interface Props {
  value?: string;
  disabled?: boolean;
  minHeight?: number;
  /**
   * @param {boolean} pageBreak
   * @see https://react-pdf.org/advanced#page-breaks
   * Note: might be a better way to typehint react-pdf props, if we have to
   * start specifying all of them here we should look into extending types
   * @default false
   */
  pageBreak?: boolean;
  style?: any;
  after?: React.ReactNode;
}
/**
 * StyledInformationBox
 * A generic text component
 * @param {string} [value] - The text in the box
 * @param {boolean} [disabled=false] - Strikethough if disabled
 * @returns {JSX.Element}
 */

// ℹ️ If the text box is large enough and can potentially span another page we need to handle it on
// a paragraph by paragraph basis as this can cause issues with the footer
function format(str: string | undefined, disabled: boolean) {
  if (!str) return <Text></Text>;
  if (disabled) return <DisabledText />;

  let formattedStr = str;
  formattedStr = formattedStr.replace(/(^\s*)|(\s*$)/gi, "");
  formattedStr = formattedStr.replace(/[ ]{2,}/gi, " ");
  formattedStr = formattedStr.replace(/\n /, "\n");

  if (formattedStr.split.length >= 320) {
    return str;
  }

  const returnStr =
    str.split(" ").slice(0, 320).join(" ") +
    `\n\n` +
    str.split(" ").slice(320).join(" ");

  return returnStr.split("\n").map((line, index) => (
    <View key={index} style={{ marginBottom: 5 }}>
      <Text>{line}</Text>
    </View>
  ));
}

const DisabledText = () => (
  <Text
    style={{
      textDecoration: "line-through",
      textDecorationColor: "black",
      color: "transparent",
      paddingTop: 8,
      margin: 0,
    }}
  >
    {">>>>>>>>>>>>>>>>>>>>>>>>>invisblePlaceholder<<<<<<<<<<<<<<<<<<<<<<<<<<"}
  </Text>
);

export const StyledInformationBox: React.FC<Props> = ({
  value,
  disabled = false,
  minHeight,
  pageBreak,
  style,
  after,
}) => {
  const formattedText = format(value, disabled);

  return (
    <>
      <View
        style={{
          backgroundColor: "white",
          padding: "5px 5px 0px",
          minHeight: minHeight || 40,
          ...style,
        }}
        break={pageBreak}
      >
        {formattedText}
      </View>
      {after ?? null}
    </>
  );
};
