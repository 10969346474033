import * as Yup from "yup";
import {
  FormStatus,
  FormState,
  FormItemType,
  HospitalDetailsType as HospitalDetails,
  Actors,
} from "common/types/statForms";
import { Opinion, PatientDetails, CTO3Form } from "common/types/cto3";
import {
  hospitalDetailsValidation,
  patientDetailsValidation,
} from "common/libs/validation";
import { CtoFor } from "common/types/cto3";
import { CognitoUserExt } from "common/types/auth";
import { isDoctor } from "utils/auth";

export const handleStatus = (formState: FormState<CTO3Form>): FormStatus => {
  const { opinion } = formState.data;

  if (!opinion.signature) {
    return FormStatus.s10_draft;
  }

  return FormStatus.s40_signed;
};

/**
 * getActor
 * - if the user is a doctor grant access
 */
export const getActor = (
  formState: FormState<CTO3Form>,
  user: CognitoUserExt
): Actors | false => {
  if (isDoctor(user)) {
    return Actors.Originator;
  }

  return false;
};

export const generateFormItems = (): FormItemType[] => [
  /** TO BE COMPLETED BY: Responsible Clinician */
  {
    index: 0,
    itemText: "1. Patient Details",
    errorKey: "section1",
  },
  {
    index: 1,
    itemText: "2. Opinion",
    errorKey: "opinion",
  },
];

export const validationSchema = [
  Yup.object().shape({
    patientDetails: Yup.object().shape(patientDetailsValidation),
    hospitalDetails: Yup.object().shape(hospitalDetailsValidation),
  }),
  Yup.object().shape({
    for: Yup.string().required("Please select (a) or (b)"),
    details: Yup.string().when("for", {
      is: CtoFor.REQUIRES_TREATMENT,
      then: Yup.string()
        .min(60, "Opinion requires 60 or more characters")
        .required("Opinion is required"),
    }),
    purpose: Yup.array().when("for", {
      is: CtoFor.FAILED_TO_COMPLY,
      then: Yup.array().min(1, "Purpose is required"),
    }),
  }),
];

export const hasErrorsSection1 = (props: PatientDetails): boolean => {
  const { name } = props;

  let error = false;
  if (!name) error = true;

  return error;
};

export const hasErrorsSection2 = (props: HospitalDetails): boolean => {
  const { name, address, manualAddress1, manualAddress2, manualPostcode } =
    props;
  let error = false;

  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;

  return error;
};

export const hasErrorsSection3 = (
  props: Opinion,
  requireSignature: boolean = true
): boolean => {
  const { details, purpose, date, signature } = props;

  let error = false;
  if (!details && purpose.length === 0) error = true;
  if (requireSignature && !date && !signature) error = true;

  return error;
};

export const shouldEnableDownload = (data: CTO3Form): boolean => {
  const { patientDetails, hospitalDetails, opinion } = data;

  if (
    hasErrorsSection1(patientDetails) ||
    hasErrorsSection2(hospitalDetails) ||
    hasErrorsSection3(opinion)
  ) {
    return false;
  }

  return true;
};
