import { Link as RouterLink } from "react-router-dom";
import { styled, Theme } from "@mui/material/styles";
import { Link, Typography } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const RootContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  flexDirection: "column",
  marginTop: -80,
});

const TextContainer = styled("div")(({ theme }) => ({
  maxWidth: 400,
  marginTop: theme.spacing(2),
  "& *": {
    fontWeight: "300 !important",
  },
}));

interface IconProps {
  fontSize?: string;
  theme?: Theme;
}

const Icon = styled(SentimentVeryDissatisfiedIcon)<IconProps>(
  ({ fontSize, theme }) => ({
    fontSize: fontSize || "5rem",
    marginBottom: theme.spacing(2),
  })
);

const Unauthorised = () => (
  <RootContainer>
    <Icon />
    <Typography variant="h2" component="h1" align="center">
      Unauthorised
    </Typography>
    <TextContainer>
      <Typography variant="body1" paragraph>
        We're sorry, but you don't have permission to access this form. If this
        is a mistake, please contact support.
      </Typography>
      <Typography variant="body1" paragraph mt={0}>
        If you're not sure where to go next, you can always go back to the{" "}
        <Link component={RouterLink} to="/">
          home page
        </Link>
        .
      </Typography>
    </TextContainer>
  </RootContainer>
);

export default Unauthorised;
