import React from "react";

// Material UI
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Custom imports
import Paper from "components/paper";
import { StyledInput } from "components/uiElements";
import AddressDetails from "components/formSections/common/addressDetails";
import Action from "common/types/action";
import { PatientDetails as PatientDetailsType } from "common/types/statForms";
import { FormStateActions } from "common/types/cto1";

export interface PatientDetailsProps<T = PatientDetailsType> {
  testIdKey?: string;
  patientDetails: T;
  dispatchFormState: React.Dispatch<Action>;
  patientString?: string;
  errorKey?: string;
  disabled?: boolean;
}

const PatientDetails: React.FC<PatientDetailsProps> = ({
  testIdKey,
  patientDetails: {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
  },
  dispatchFormState,
  patientString = "patient",
  errorKey = "patientDetails",
  disabled,
}) => (
  <Paper>
    <Stack spacing={3}>
      <Stack spacing={2}>
        <Typography variant="h4" display="inline">
          (PRINT full name and address of {patientString}).
        </Typography>
        <StyledInput
          testId={`${testIdKey}-name`}
          label="Patient Name"
          variant="outlined"
          value={name}
          errorKey={`${errorKey}.name`}
          onChange={(value: any) =>
            dispatchFormState({
              type: FormStateActions.SET_PATIENT_NAME,
              payload: value,
            })
          }
          disabled={disabled}
        />
        {/* Address */}
        <AddressDetails
          testIdKey={testIdKey}
          address={address}
          addressLabel="Patient Address"
          manualAddress1={manualAddress1}
          manualAddress2={manualAddress2}
          manualPostcode={manualPostcode}
          setAddress={(value: any) =>
            dispatchFormState({
              type: FormStateActions.SET_PATIENT_ADDRESS,
              payload: value,
            })
          }
          errorKey={`${errorKey}.address`}
          disabled={disabled}
          noFixedAbode
        />
      </Stack>
    </Stack>
  </Paper>
);

export default React.memo(PatientDetails);
