import React from "react";

// Form Components
import FormContainer from "components/forms/formContainer";
import CTO12Header from "components/formSections/cto12/CTO12Header";
import ClinicianDetails from "components/formSections/common/clinicianDetails";
import PatientDetails from "components/formSections/cto12/patientDetails";
import FormLoading from "components/forms/formLoading";

// Custom Imports
import {
  useForm,
  useUI,
  useFormValidation,
  useFormAccess,
  useActorTypeStageChange,
  useAuth,
} from "hooks";
import {
  generateFormItems,
  shouldDisablePrev,
  shouldEnableDownload,
  validationSchema,
} from "./helpers";
import { useStyles } from "common/styles/form";
import { Actors } from "common/types/statForms";
import { isWard } from "utils/auth";
import { LoadingCircle } from "@s12solutions/ui";

/**
 * ------------------------------------------------------------
 * * WORKFLOW FOR CTO12 FORM
 * ------------------------------------------------------------
 * Stages
 * 1/ Clinician creates, signs and emails to MHA Admin
 * 2/ MHA admin downloads
 * ------------------------------------------------------------
 */

const CTO12Form = () => {
  const classes = useStyles();
  const { actor, user } = useAuth();
  const { loading, formState, dispatchFormState, actorType } = useForm();
  const { currentSectionIndex } = useUI();

  const { clinicianDetails, patientDetails, clinicianSignature } =
    formState.data;

  useFormAccess("CTO");

  useActorTypeStageChange(actorType, {
    [Actors.Originator]: 0,
    [Actors.MHAAdmin]: 1,
  });

  useFormValidation(validationSchema, [
    { clinicianDetails },
    { patientDetails },
  ]);

  const formItems = generateFormItems(actorType);
  const part1Disabled = !!clinicianSignature.signature;

  // Render
  if (loading) {
    return <LoadingCircle />;
  }
  if (isWard(user) && !actor) {
    return <FormLoading />;
  }

  return (
    <FormContainer
      sections={[
        <>
          <div className={classes.sectionContainer}>
            <CTO12Header />
            <div style={{ height: 20 }} />
            <ClinicianDetails
              testIdKey="cto12-clinician"
              clinicianDetails={clinicianDetails}
              dispatchFormState={dispatchFormState}
              disabled={part1Disabled}
            />
          </div>
        </>,
        <>
          <div className={classes.sectionContainer}>
            <PatientDetails
              testIdKey="cto12-patient"
              patientDetails={patientDetails}
              clinicianSignature={clinicianSignature}
              dispatchFormState={dispatchFormState}
            />
          </div>
        </>,
      ]}
      formPartSelectorProps={{
        formItems,
        enableDownload: shouldEnableDownload(formState.data),
      }}
      formNavButtonProps={{
        formItems,
        lastSectionIndex: 1,
        disablePrev: shouldDisablePrev(actorType, currentSectionIndex),
      }}
    />
  );
};

export default React.memo(CTO12Form);
