import React from "react";
import { View } from "@react-pdf/renderer";
import { PDFSubHeader } from "../formComponents/baseComponents/PDFSubHeader";
import {
  StyledDateOrTime,
  DateOrTime,
} from "../formComponents/StyledDateOrTime";
import { StyledInformationBox } from "../formComponents/StyledInformationBox";
import { StyledText } from "../formComponents/StyledText";
import { StyledCheckbox } from "../formComponents/StyledCheckbox";
import { PDFSignatures } from "../formComponents/baseComponents/PDFSignatures";
import { CtoFor } from "common/types/cto";
import { CTO5Form } from "common/types/cto5";
import { formatNameAndAddressText } from "utils/pdf";

/**
 * CTO5Form
 * @param {CTO5Form} CTO5Form
 * @returns {JSX.Element}
 */
export const CTO5Body: React.FC<CTO5Form> = ({
  clinicianDetails,
  patientDetails,
  hospitalDetails,
  opinion,
  amhpDetails,
  ctoDetails,
}) => {
  // Clinician Details
  const clinicianNameAndAddress = formatNameAndAddressText(
    clinicianDetails.name,
    clinicianDetails.address,
    clinicianDetails.manualAddress1,
    clinicianDetails.manualAddress2,
    clinicianDetails.manualPostcode,
    clinicianDetails.email
  );

  // Patient Details
  const patientNameAndAddress = formatNameAndAddressText(
    patientDetails.name,
    patientDetails.address,
    patientDetails.manualAddress1,
    patientDetails.manualAddress2,
    patientDetails.manualPostcode
  );

  // Hospital Details
  const hospitalNameAndAddress = formatNameAndAddressText(
    hospitalDetails.name,
    hospitalDetails.address,
    hospitalDetails.manualAddress1,
    hospitalDetails.manualAddress2,
    hospitalDetails.manualPostcode
  );

  // Amhp Details
  const amhpNameAndAddress = formatNameAndAddressText(
    amhpDetails.name,
    amhpDetails.address,
    amhpDetails.manualAddress1,
    amhpDetails.manualAddress2,
    amhpDetails.manualPostcode,
    amhpDetails.email
  );

  return (
    <View>
      {/* Part 1 -> Hospital Details */}
      <PDFSubHeader
        text="(Parts 1 and 3 of this form are to be completed by the responsible clinician and Part 2 by an approved mental health professional)"
        style={{ maxWidth: "90%", margin: "18px auto" }}
        fontStyle={{ fontSize: 11.5 }}
      />
      <PDFSubHeader subsubheading="PART 1" fontStyle={{ fontSize: 12 }} />
      <StyledText
        instruction="I"
        guidance="(PRINT full name, address and, if sending by means of electronic communication,"
        style={{ fontSize: 12, marginBottom: 0 }}
      />
      <StyledText
        instruction=""
        guidance="email address of the responsible clinician)"
        style={{ fontSize: 12 }}
        noMargin
      />

      <StyledInformationBox value={clinicianNameAndAddress} minHeight={50} />
      <StyledText
        instruction="am the responsible clinician for"
        noMargin
        style={{ marginBottom: 6 }}
      />
      <StyledText
        instruction=""
        guidance="(PRINT full name and address of community patient)"
        noMargin
        style={{ fontSize: 12 }}
      />
      <StyledInformationBox
        value={patientNameAndAddress}
        minHeight={50}
        style={{ margin: 0 }}
      />
      <StyledText
        instruction="who is detained in"
        noMargin
        style={{ marginBottom: 3 }}
      />
      <StyledText
        instruction=""
        guidance="(name and address of hospital)."
        noMargin
      />
      <StyledInformationBox
        value={hospitalNameAndAddress}
        minHeight={50}
        style={{ margin: 0 }}
      />
      <StyledText
        instruction="having been recalled to hospital under section 17E(1) of the Act."
        noMargin
      />
      {/* Opinion */}
      <StyledText instruction="In my opinion," style={{ marginTop: 18 }} />
      <View style={{ marginLeft: 20, maxWidth: "82%" }}>
        <StyledCheckbox
          itemisation="Alphabetical"
          itemPadding={6}
          display
          options={[
            {
              label:
                "this patient is suffering from mental disorder of a nature or degree which makes it appropriate for the patient to receive medical treatment in a hospital,",
              after: (
                <StyledText
                  instruction="AND"
                  style={{ marginLeft: -20, marginTop: 10, marginBottom: 10 }}
                />
              ),
            },
            {
              component: (
                <View>
                  <StyledText
                    instruction="it is necessary for"
                    noMargin
                    style={{ marginBottom: 8 }}
                  />
                  <StyledCheckbox
                    value={opinion.for}
                    itemisation="Roman"
                    itemPadding={4}
                    noMargin
                    options={[
                      {
                        value: CtoFor.PATIENTS_HEALTH,
                        label: "the patient's own health",
                      },
                      {
                        value: CtoFor.PATIENTS_SAFETY,
                        label: "the patient's own safety",
                      },
                      {
                        value: CtoFor.SAFETY_OF_OTHERS,
                        label: "the protection of other persons",
                      },
                    ]}
                  />
                  <StyledText
                    instruction="(delete the indents not applicable)"
                    style={{ fontStyle: "italic", marginTop: 4 }}
                  />
                  <StyledText
                    instruction="that this patient should receive treatment in hospital,"
                    style={{ marginTop: 12, marginBottom: 6 }}
                  />
                </View>
              ),
              after: (
                <StyledText
                  instruction="AND"
                  style={{ marginLeft: -20, marginTop: 10, marginBottom: 10 }}
                />
              ),
            },
            {
              label:
                "such treatment cannot be provided unless the patient is detained for medical treatment under the Act,",
            },
          ]}
        />
      </View>
      <View break style={{ marginTop: 5 }}>
        <StyledText
          instruction="because —"
          guidance="(Your reasons should cover (a), (b) and (c) above. As part of them: describe the"
          style={{ marginBottom: 0 }}
        />
        <StyledText
          instruction=""
          guidance="patient’s symptoms and behaviour and explain how those symptoms and behaviour lead you to"
          noMargin
        />
        <StyledText
          instruction=""
          guidance="your opinion; say whether other methods of treatment or care (eg out-patient treatment or social"
          noMargin
        />
        <StyledText
          instruction=""
          guidance="services) are available and, if so, why they are not appropriate; indicate why informal admission is"
          noMargin
        />
        <StyledText instruction="" guidance="not appropriate.)" noMargin />
      </View>
      <StyledInformationBox
        value={opinion.details}
        minHeight={550}
        style={{ margin: 0 }}
      />
      <StyledText
        instruction="I am also of the opinion that taking into account the nature and degree of the mental disorder"
        style={{ marginBottom: 0 }}
      />
      <StyledText
        instruction="from which the patient is suffering and all other circumstances of the case, appropriate medical"
        noMargin
      />
      <StyledText
        instruction="treatment is available to the patient at the hospital named above."
        noMargin
      />
      <PDFSignatures
        signed={opinion.signature}
        date={opinion.date}
        sha256={opinion.trackingId}
        after={<StyledText instruction="Responsible clinician" noMargin />}
      />
      {/* Part 2 -> AMHP Details */}
      <PDFSubHeader
        subsubheading="PART 2"
        fontStyle={{ fontSize: 12 }}
        pageBreak
      />
      <StyledText
        instruction="I"
        guidance="(PRINT full name, address and, if sending by means of electronic communication, email address)"
        style={{ fontSize: 12 }}
      />
      <StyledInformationBox value={amhpNameAndAddress} minHeight={50} />
      <StyledText
        instruction="am acting on behalf of,"
        noMargin
        style={{ marginBottom: 4 }}
      />
      <StyledText
        instruction=""
        guidance="(name of local social services authority)"
        noMargin
      />
      <StyledInformationBox value={amhpDetails.authorityName} minHeight={40} />
      <StyledText
        instruction="and am approved to act as an approved mental health professional for the purposes of the Act by"
        noMargin
      />
      <StyledText
        instruction=""
        guidance="(delete as appropriate)"
        noMargin
        style={{ marginBottom: 6 }}
      />
      <StyledCheckbox
        value={
          typeof amhpDetails.otherAuthority === "undefined" &&
          !amhpDetails.onBehalfOf
            ? undefined // user hasn't filled out yet (initial state)
            : typeof amhpDetails.otherAuthority === "undefined" &&
              amhpDetails.onBehalfOf // user has filled out without altering initial state
            ? [true]
            : [amhpDetails.otherAuthority]
        }
        itemPadding={0}
        noMargin
        options={[
          {
            value: false,
            label: "that authority",
          },
          {
            value: true,
            label:
              "(name of local social services authority that approved you, if different)",
            labelStyle: "italic",
          },
        ]}
      />
      <View style={{ paddingLeft: 22 }}>
        <StyledInformationBox
          value={
            typeof amhpDetails.onBehalfOf === "undefined"
              ? ""
              : amhpDetails.onBehalfOf || "N/A"
          }
          minHeight={40}
        />
      </View>
      <StyledText instruction="I agree that:" />
      <View style={{ maxWidth: "90%", marginLeft: 20 }}>
        <StyledCheckbox
          value={""}
          display
          itemPadding={4}
          noMargin
          itemisation="Roman"
          options={[
            {
              label:
                "the patient meets the criteria for detention in hospital set out above and",
            },
            {
              label:
                "it is appropriate to revoke the community treatment order.",
            },
          ]}
        />
      </View>
      <PDFSignatures
        signed={amhpDetails.signature}
        date={amhpDetails.date}
        sha256={amhpDetails.trackingId}
        after={
          <StyledText
            instruction="Approved mental health professional"
            noMargin
          />
        }
      />
      {/* Part 3 */}
      <PDFSubHeader subsubheading="PART 3" fontStyle={{ fontSize: 12 }} />
      <StyledText
        instruction="I exercise my power under section 17F(4) to revoke the community treatment order in respect of the patient named in Part 1 who has been detained in hospital since"
        noMargin
        style={{ maxWidth: "90%" }}
      />
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: 450,
        }}
      >
        <StyledDateOrTime
          dateOrTime={DateOrTime.time}
          value={ctoDetails.effectiveFromTime}
          label="(time)"
          empty={!!!ctoDetails.effectiveFromTime}
        />
        <StyledDateOrTime
          dateOrTime={DateOrTime.date}
          value={ctoDetails.effectiveFromDate}
          label="(date)"
          prefix="on "
          empty={!!!ctoDetails.effectiveFromDate}
        />
      </View>
      <StyledText
        instruction="having been recalled under section 17E(1)."
        noMargin
      />
      <PDFSignatures
        signed={ctoDetails.signature}
        date={ctoDetails.date}
        sha256={ctoDetails.trackingId}
        after={<StyledText instruction="Responsible clinician" noMargin />}
      />
      <PDFSubHeader
        subsubheading="THIS REVOCATION ORDER IS NOT VALID UNLESS ALL THREE PARTS ARE COMPLETED AND SIGNED. IT MUST BE SENT AS SOON AS PRACTICABLE TO THE MANAGERS OF THE HOSPITAL IN WHICH THE PATIENT IS DETAINED "
        fontStyle={{ fontSize: 12 }}
        style={{ margin: "0 auto", maxWidth: "98%" }}
      />
    </View>
  );
};
