import { AddressType, SignatureType } from "common/types/statForms";

export enum CtoFor {
  PATIENTS_HEALTH = "PATIENTS_HEALTH",
  PATIENTS_SAFETY = "PATIENTS_SAFETY",
  SAFETY_OF_OTHERS = "SAFETY_OF_OTHERS",
}

export type Opinion<T = CtoFor[]> = SignatureType & {
  for: T;
  details: string;
};

export type AMHPDetails = AddressType &
  SignatureType & {
    name: string;
    authorityName: string;
    otherAuthority?: boolean;
    onBehalfOf?: string; // If this is empty the amhp is acting for that authority
  };

export type CTODetails = SignatureType & {
  effectiveFromDate: string;
  effectiveFromTime: string;
};
