import React, { useCallback, useState } from "react";

// Auth
import Auth from "@s12solutions/auth/Auth";
import { AuthContext } from "store/auth/authContext";

// Custom Imports
import { useMagicLink, usePopups } from "hooks";
import Appbar from "./appbar";
import SideMenu from "./sidemenu";
import ApplicationRoutes from "./routes/ApplicationRoutes";

const Layout: React.FC = () => {
  const { handleSnackbar } = usePopups();
  const [open, setOpen] = useState<boolean>(false);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const { loading } = useMagicLink();

  // Title bar to environment
  switch (process.env.REACT_APP_ENV) {
    case "dev":
      document.title = "S12 Forms - Development";
      break;
    case "preprod":
      document.title = "S12 Forms - Preprod";
      break;
    case "sandbox":
      document.title = "S12 Forms - Sandbox";
      break;
    default:
      document.title = "S12 Forms";
      break;
  }

  return (
    <Auth
      context={AuthContext}
      handleSnackbar={handleSnackbar}
      enabledUserGroups={["StatForms", "AMHP", "Doctor"]}
      loading={loading}
    >
      <>
        <div id="scroll-top" />
        <Appbar open={open} handleDrawerOpen={handleDrawerOpen} />
        <SideMenu open={open} handleDrawerClose={handleDrawerClose} />
        <ApplicationRoutes />
      </>
    </Auth>
  );
};

export default React.memo(Layout);
