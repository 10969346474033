import { alpha, Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import * as constants from "common/constants/theme";

const drawerWidth = 220;

type ThemeProps = {
  isMobile: boolean;
  loggedIn: boolean;
};

const useStyles = makeStyles<Theme, ThemeProps>((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: theme.zIndex.appBar + 1,
  },
  popoverPaper: {
    backgroundColor: "white",
    border: `1px solid ${theme.palette.divider}`,
    width: "16rem",
  },
  list: {
    padding: 0,
  },
  listItem: {
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.secondary,
    },
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
    alignItems: "center",
  },
  menuButton: {
    ...theme.mixins.toolbar,
    display: "flex",
    alignItems: "center",
  },
  sideMenuButton: {
    justifyContent: "space-evenly",
    alignItems: "center",
    width: ({ isMobile }) => (isMobile ? "3.5rem" : "7.2rem"),
    borderRight: ({ isMobile }) =>
      isMobile ? "" : `1px solid ${theme.palette.divider}`,
    "&:hover": { color: theme.palette.grey[200], cursor: "pointer" },
  },
  submitButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "12rem",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      cursor: "pointer",
    },
  },
  title: {
    display: "none",
    textTransform: "uppercase",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    alignSelf: "center",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  appBar: {
    ...theme.mixins.toolbar,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px + 5.8rem)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      // easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.short,
    }),
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  profileButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "3rem",
    height: "3rem",
    marginRight: "1rem",
    marginLeft: "1rem",
    color: (props) =>
      props.loggedIn ? theme.palette.primary.light : theme.palette.grey[100],
  },
  loggedOut: {
    color: theme.palette.grey[500],
  },
  modeChipRed: {
    backgroundColor: constants.red,
    color: constants.white,
  },
  modeChipPink: {
    backgroundColor: constants.darkPink,
    color: constants.white,
  },
  modeChipWhite: {
    backgroundColor: constants.white,
  },
}));

export { useStyles };
