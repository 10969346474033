import { createContext } from "react";
import { Actors } from "common/types/statForms";
import { FormState } from "./types";

export const initialState: FormState = {
  // Form Values
  loading: true,
  formState: null,
  actorType: Actors.Originator,
  formErrors: {},
  sectionIndexValidation: {},

  // Form Setters
  setLoading: () => {},
  setActorType: () => {},
  clearForm: () => {},
  clearFormErrors: () => {},

  // Form Functions
  dispatchFormState: () => {},
  handleAuthDialog: () => {},
  handleSave: () => {},
  handleEmail: () => {},
  generateSectionIndexValidation: () => {},
  handleValidateValue: () => Promise.resolve(false),
};

export const FormContext = createContext(initialState);
