import React, { useEffect, useRef } from "react";
import { useAuth } from "hooks";
import { StyledInput, StyledInputProps } from "./StyledInput";
import { formatUserName } from "common/libs/formatUserName";

const StyledClinicianInput: React.FC<StyledInputProps> = ({
  value,
  onChange,
  ...props
}) => {
  const initialValueRef = useRef<string>((value as string) || "");
  const prefilledClinician = useRef<boolean>(false);
  const { user } = useAuth();

  useEffect(() => {
    if (prefilledClinician.current || !user || initialValueRef.current) return;

    onChange(formatUserName(user));

    prefilledClinician.current = true;
  }, [user, onChange]);

  return <StyledInput value={value} onChange={onChange} {...props} />;
};

export { StyledClinicianInput };
