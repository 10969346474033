import React from "react";
import { Text, View } from "@react-pdf/renderer";

interface Props {
  instruction: string;
  guidance?: string;
  style?: any;
  prefix?: string;
  disabled?: boolean;
  noPrefixMargin?: boolean;
  noMargin?: boolean;
  pageBreak?: boolean;
  className?: any;
}
/**
 * StyledText
 * A generic text component
 * @param {string} instruction - example: "4(1)(g)"
 * @param {string} [guidance] - appears at the end in italics
 * @param {any} [style] - extra styles to overwrite container with specific functionality
 * @param {string} [prefix] - a negatively indented prefix e.g: (a)
 * @param {boolean} [disabled=false] - strikethrough logic
 * @returns {JSX.Element}
 */
export const StyledText = (props: Props) => {
  const {
    instruction,
    guidance,
    style,
    prefix,
    noPrefixMargin = false,
    noMargin = false,
    disabled,
    pageBreak,
  } = props;

  return (
    <View
      break={pageBreak}
      style={{
        flexDirection: "row",
        marginTop: !noMargin ? 10 : undefined,
        ...style,
      }}
    >
      {prefix && (
        <Text
          style={{
            marginLeft: noPrefixMargin ? undefined : "-20",
            marginRight: 5,
          }}
        >
          {prefix}
        </Text>
      )}
      <Text style={{ textDecoration: disabled ? "line-through" : "none" }}>
        {instruction && instruction + " "}
        {typeof guidance === "string" ? (
          <Text style={{ fontStyle: "italic" }}>{guidance}</Text>
        ) : (
          ""
        )}
      </Text>
    </View>
  );
};
