import { FC, memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { useDebounce } from "use-debounce";
import { saveAs } from "file-saver";

// Material UI
import { Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from "@mui/icons-material/History";
import EditIcon from "@mui/icons-material/Edit";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DownloadIcon from "@mui/icons-material/Download";

// Custom Imports
import {
  StatForm,
  ListStatFormsFilterInput,
  StatFormEmail,
} from "@s12solutions/types";
import { Methods } from "api";
import { FormType } from "common/types/pdf";
import {
  FormState,
  FormStatus,
  ListStatFormsResponse,
} from "common/types/statForms";
import { useAPI, usePopups, useAuth, useDeviceType } from "hooks";
import { formatStatus } from "common/libs/string";
import { StyledInput } from "components/uiElements";
import Table from "components/table";
import StatFormActivity from "components/forms/activity/statFormActivity";
import { convertPDFToBlob } from "utils/pdf";
import generateFormProps from "utils/pdf/generateFormProps";
import { v4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(2),
    overflow: "none",
  },
}));

type FormListProps = {
  handleView: (id: string, type: FormType, data: FormState["data"]) => void;
};

const FormList: FC<FormListProps> = ({ handleView }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { isMobile } = useDeviceType();
  const { handleDialog } = usePopups();
  const { user } = useAuth();

  const [searchString, setSearchString] = useState<string>("");
  const [debouncedSearchString] = useDebounce(searchString, 1000);

  const {
    data,
    loading,
    trigger: refetch,
  } = useAPI<ListStatFormsResponse, { filter: ListStatFormsFilterInput }>({
    method: Methods.GET,
    fieldName: "listStatForms",
    args: {
      filter: {
        patientName: debouncedSearchString,
      },
    },
    manual: true,
  });

  const { trigger: createStatFormActivity } = useAPI({
    method: Methods.POST,
    fieldName: "createStatFormActivity",
  });

  const updateForm = useCallback(
    (
      id: StatForm["id"],
      type: FormType,
      emailLink: StatFormEmail["emailLink"]
    ) => {
      navigate(
        emailLink
          ? emailLink.replace(process.env.REACT_APP_ORIGIN!, "")
          : `/${type}/${id}/1`
      );
    },
    [navigate]
  );

  const handleActivityDialog = useCallback(
    (id: StatForm["id"]) => {
      handleDialog(<StatFormActivity formId={id} refetch={refetch} />, {
        title: "Activity Log",
        maxHeight: "55vh",
      });
    },
    [handleDialog, refetch]
  );

  const handleDownload = useCallback(
    async (id: StatForm["id"], type: FormType, data: FormState["data"]) => {
      const formProps = generateFormProps(id, type, data);

      const fileName = `s12-${formProps?.form}-${id}-${dayjs().format(
        "DD-MM-YYYY"
      )}.pdf`;

      const blob = await convertPDFToBlob(formProps);
      saveAs(blob, fileName);

      // // Save download in form activity log
      createStatFormActivity({
        input: {
          id: v4(),
          form: id,
          // actor: JSON.stringify(actor) || "",
          action: "download",
          createdAt: new Date().toISOString(),
        },
      });
    },
    [createStatFormActivity]
  );

  // Handle Search
  useEffect(() => {
    async function handleSearch() {
      refetch();
    }
    if (
      // If the user clears the search string
      debouncedSearchString.length === 0 ||
      // If the user types more than 2 characters
      debouncedSearchString.length > 2
    ) {
      handleSearch();
    }
  }, [debouncedSearchString, refetch]);

  return (
    <Stack spacing={2}>
      <StyledInput
        id="searchString"
        name="searchString"
        label="Search Patient Name"
        value={searchString}
        onChange={(value: any) => setSearchString(value)}
        startAdornment={<SearchIcon />}
      />
      <div className={classes.table} data-test-id="formlist-table">
        <Table
          loading={loading}
          pageSize={isMobile ? 5 : 10}
          columns={[
            {
              field: "emailLink",
              headerName: "",
              width: 50,
              disableColumnMenu: true,
              hideSortIcons: true,
              renderCell: (params: any) => {
                const { userId, emailLink, disabled } = params.row;

                return (
                  // Determine email link
                  // Is the user not the creator of the form?
                  userId !== user?.username ||
                    // Is the user the creator of the form but it has been emailed back
                    (userId === user?.username && emailLink) ? (
                    // is email link disabled or already downloaded?
                    disabled ? (
                      <Tooltip title="Form requires no further action from you">
                        <FiberManualRecordIcon
                          color="disabled"
                          style={{ height: 12, width: 12 }}
                        />
                      </Tooltip>
                    ) : (
                      // does email link require action?
                      <Tooltip title="Form requires further action from you">
                        <FiberManualRecordIcon
                          color="primary"
                          style={{ height: 12, width: 12 }}
                        />
                      </Tooltip>
                    )
                  ) : (
                    <></>
                  )
                );
              },
            },
            {
              field: "patientName",
              headerName: "Patient",
              width: isMobile ? 180 : 280,
              renderCell: (params: any) =>
                params.row.patientName || (
                  <span className="italic">Unspecified</span>
                ),
            },
            {
              field: "status",
              headerName: "Status",
              valueGetter: (params: any) => formatStatus(params.row.status),
              width: 160,
            },
            {
              field: "type",
              headerName: "Type",
              width: 80,
              valueGetter: (params: any) => `${params.row.type.toUpperCase()}`,
            },
            {
              field: "updatedAt",
              headerName: "Updated at",
              width: 190,
              valueGetter: (params: any) =>
                `${
                  params.row.updatedAt
                    ? dayjs(params.row.updatedAt).format(
                        "MMMM D YYYY [at] HH:mm"
                      )
                    : "-"
                }`,
            },
            {
              field: "createdAt",
              headerName: "Created at",
              width: 190,
              valueGetter: (params: any) =>
                `${dayjs(params.row.createdAt).format(
                  "MMMM D YYYY [at] HH:mm"
                )}`,
            },
            {
              field: "actions",
              headerName: "Actions",
              width: 180,
              disableColumnMenu: true,
              hideSortIcons: true,
              renderCell: (params: any) => {
                const { id, type, data, disabled, emailLink, status } =
                  params.row;

                return (
                  <Grid container flexDirection="row" alignItems="center">
                    <Grid item>
                      <Tooltip title="View Form Activity">
                        <IconButton
                          data-test-id=""
                          onClick={() => handleActivityDialog(id)}
                        >
                          <HistoryIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="View Form">
                        <IconButton
                          data-test-id="forms-view"
                          onClick={() =>
                            handleView(id, type as FormType, JSON.parse(data))
                          }
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      {status === FormStatus.s52_signed_and_pdf_sent ? (
                        <Tooltip title="Download Form">
                          <IconButton
                            data-test-id="forms-update"
                            onClick={async () =>
                              await handleDownload(
                                id,
                                type as FormType,
                                JSON.parse(data)
                              )
                            }
                            disabled={
                              // if the form has been emailed to the user
                              // and the link is disabled
                              disabled
                            }
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Update Form">
                          <IconButton
                            data-test-id="forms-update"
                            onClick={() =>
                              updateForm(id, type as FormType, emailLink)
                            }
                            disabled={
                              // if the form has been emailed to the user
                              // and the link is disabled
                              disabled
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                );
              },
            },
          ]}
          rows={data}
          card
        />
      </div>
      <Stack spacing={1} mt={1}>
        <Stack flexDirection="row" alignItems="center">
          <FiberManualRecordIcon
            color="primary"
            style={{ height: 12, width: 12 }}
          />
          <Typography variant="body2" sx={{ ml: 1 }}>
            Form has been emailed to your account and needs action
          </Typography>
        </Stack>
        <Stack flexDirection="row" alignItems="center">
          <FiberManualRecordIcon
            color="disabled"
            style={{ height: 12, width: 12 }}
          />
          <Typography variant="body2" sx={{ ml: 1 }}>
            Form has been emailed to your account and requires no further action
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(FormList);
