import { isNull } from "lodash";
import { FormErrors } from "store/forms/types";

/**
 * @description validates whether a value is is present
 * @param {{string}} value - value
 * @param {{string, number}} message - message
 * @returns {string | undefined}
 */
const required = (value: string | null = null, message = "Required") =>
  !isNull(value) && value !== "" && value ? undefined : message;

/**
 * @description validates whether a value is is present
 * @param {{string}} value - value
 * @returns {string | undefined}
 */
export const validateRequired = (value: string) => required(value);

/**
 * @description public validates whether a value more than a min number
 * @param {{number}} min - value
 * @returns {string | undefined}
 */
export const validateMinLength = (min: number) => (value: string) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const phoneRegExp =
  // /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  /^\+(?:[0-9] ?){6,14}[0-9]$/;

export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

export const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const validatePhoneNumber = (value: string) =>
  value && !phoneRegExp.test(value) ? "Invalid phone number" : undefined;

/**
 * @description validates whether a valid email has been entered
 * @param {{string}} value - value
 * @returns {string | undefined}
 */
export const validateEmail = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

/**
 * @description validates if email is present
 * @param {string} value
 * @returns {string | undefined}
 */
export const validateRequiredEmail = (value: string) =>
  required(value, "Please enter an email address");

/**
 * @description validates whether a password has been entered
 * @param {{string}} value - value
 * @returns {string | undefined}
 */
export const validateRequiredPassword = (value: string) =>
  required(value, "Please enter a password");

// /**
//  * @description Validate whether passwords match
//  * @param {{string}} value - value
//  * @returns {string | undefined}
//  */
// export const passwordsMustMatch = (value, allValues) =>
//   value !== allValues.password ? 'Passwords do not match' : null;

export const validatePassword = (value: string) => {
  const checkPassword = (password: string) => {
    const RegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return RegEx.test(password);
  };

  if (checkPassword(value)) {
    return undefined;
  } else {
    return "Passwords must contain a capital letter, a number, a special character and be at least 12 characters long";
  }
};

/**
 * isSectionErrored
 * @param {FormErrors} formErrors - form errors object from the useForm hook
 * @param {string} errorKey - the error key of the section
 * @returns {boolean}
 */
export const isSectionErrored = (
  formErrors: FormErrors,
  errorKey?: string
): boolean => {
  if (!errorKey) return false;
  const errorKeys = Object.keys(formErrors).map((key) => key.split(".")[0]);

  return errorKeys.includes(errorKey);
};
