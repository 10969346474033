import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import GenericPatientDetails, {
  PatientDetailsProps,
} from "components/formSections/common/patientDetails";
import { PatientDetails as PatientDetailsType } from "common/types/cto7";
import Paper from "components/paper";
import { StyledDatePicker } from "components/uiElements";
import Tooltip from "components/tooltip";
import { FormStateActions } from "common/types/cto7";

const PatientDetails: React.FC<PatientDetailsProps<PatientDetailsType>> = ({
  testIdKey,
  patientDetails,
  patientDetails: { ctoCreated, examined },
  dispatchFormState,
  disabled,
}) => (
  <>
    <GenericPatientDetails
      testIdKey={testIdKey}
      patientDetails={patientDetails}
      dispatchFormState={dispatchFormState}
      disabled={disabled}
    />
    <Paper>
      <Stack spacing={3} mt={3}>
        <Typography variant="h4" display="inline">
          The patient is currently subject to a community treatment order made
          on (enter date).
        </Typography>
        <Grid container>
          <Grid item xs={12} md={4}>
            <StyledDatePicker
              testId={`${testIdKey}-idate`}
              value={ctoCreated}
              label="Date First Imposed"
              onChange={(value: any) =>
                dispatchFormState({
                  type: FormStateActions.SET_PATIENT_CTO_CREATED,
                  payload: value,
                })
              }
              errorKey="patientDetails.ctoCreated"
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Stack flexDirection="row" alignItems="center">
          <Typography variant="h4" display="inline">
            I examined the patient on{" "}
          </Typography>
          <Tooltip text="Examination date must be within the period of two months ending on the day on which the order would cease to be in force." />
        </Stack>
        <Grid container>
          <Grid item xs={12} md={4}>
            <StyledDatePicker
              testId={`${testIdKey}-edate`}
              value={examined}
              label="Examination Date"
              onChange={(value: any) =>
                dispatchFormState({
                  type: FormStateActions.SET_PATIENT_EXAMINED,
                  payload: value,
                })
              }
              errorKey="patientDetails.examined"
              disabled={disabled}
              disableFuture
            />
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  </>
);

export default React.memo(PatientDetails);
