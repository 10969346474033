import React, { useState, useCallback } from "react";

// Material UI
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Custom imports
import Action from "common/types/action";
import { CtoFor } from "common/types/cto";
import { FormStateActions, Opinion as OpinionType } from "common/types/cto1";
import { useAuth, useForm } from "hooks";
import { formatUserName } from "common/libs/formatUserName";
import Paper from "components/paper";
import { StyledTextArea, StyledCheckboxes } from "components/uiElements";
import SignatureSection from "components/formSections/common/signatureSection";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

const OpinionTemplate = () => (
  <Stack spacing={2}>
    <Typography variant="body2">
      [PATIENT] has a mental disorder (named if possible) characterised by the
      following symptoms...
    </Typography>
    <Typography variant="body2">
      The nature of their disorder is demonstrated by... AND/OR The degree of
      their disorder is demonstrated by...
    </Typography>
    <Typography variant="body2">
      Community treatment is not possible because... and this makes 24-hour
      specialist hospital admission necessary.
    </Typography>
    <Typography variant="body2">
      Hospital admission is necessary in the interests of [PATIENT]'s health
      because... AND/OR in the interests of [PATIENT]’s safety, because...
      AND/OR, there is a view to protect others in that...
    </Typography>
  </Stack>
);

const checkboxValues = [
  {
    key: CtoFor.PATIENTS_HEALTH,
    value: "(i) the patient's health",
  },
  {
    key: CtoFor.PATIENTS_SAFETY,
    value: "(ii) the patient's safety",
  },
  {
    key: CtoFor.SAFETY_OF_OTHERS,
    value: "(iii) the protection of other persons",
  },
];

interface OpinionProps {
  testIdKey: string;
  opinion: OpinionType;
  dispatchFormState: React.Dispatch<Action>;
}

const Opinion: React.FC<OpinionProps> = ({
  testIdKey,
  opinion: { for: forValue, details, signature, date },
  dispatchFormState,
}) => {
  const { user } = useAuth();
  const { handleEmail } = useForm();
  const [signedStatus, setSignedStatus] = useState<boolean>(!!signature);

  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed ? formatUserName(user) : "";

      dispatchFormState({
        type: FormStateActions.SET_OPINION_SIGNATURE,
        payload: signedValue,
      });
    },
    [dispatchFormState, user]
  );
  return (
    <Paper>
      <Stack spacing={4} mb={4}>
        <Typography variant="h4" display="inline">
          In my opinion,
        </Typography>
        <Stack spacing={2}>
          <Typography variant="h4" display="inline" sx={{ marginLeft: "1rem" }}>
            (a) this patient is suffering from mental disorder of a nature or
            degree which makes it appropriate for the patient to receive medical
            treatment in a hospital,
          </Typography>
          <Typography variant="h4" display="inline">
            AND
          </Typography>
          <div style={{ marginLeft: "1rem" }}>
            <Typography variant="h4" display="inline">
              (b) it is necessary for
            </Typography>
            <Stack spacing={2} sx={{ paddingLeft: "2rem" }}>
              <StyledCheckboxes
                testIdKey={`${testIdKey}-for`}
                value={forValue}
                onChange={(value: any) =>
                  dispatchFormState({
                    type: FormStateActions.SET_OPINION_FOR,
                    payload: value,
                  })
                }
                items={checkboxValues}
                disabled={signedStatus}
                errorKey="opinion.for"
              />
              <Typography variant="h4" display="inline" className="italic">
                (check each that is applicable)
              </Typography>
              <Typography variant="h4" display="inline" sx={{ marginTop: 20 }}>
                that the patient should receive treatment in hospital,
              </Typography>
            </Stack>
          </div>
          <Typography variant="h4" display="inline">
            AND
          </Typography>
          <Typography variant="h4" display="inline" sx={{ marginLeft: "1rem" }}>
            (c) such treatment cannot be provided unless the patient is detained
            for medical treatment under the Act,
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Typography variant="h4" display="inline">
            because—{" "}
            <span className="italic">
              (Your reasons should cover (a), (b) and (c) above. As part of
              them: describe the patient’s symptoms and behaviour and explain
              how those symptoms and behaviour lead you to your opinion; say
              whether other methods of treatment or care (eg out-patient
              treatment or social services) are available and, if so, why they
              are not appropriate; indicate why informal admission is not
              appropriate.)
            </span>
          </Typography>
          <StyledTextArea
            testId={`${testIdKey}-details`}
            value={details}
            onChange={(value: any) =>
              dispatchFormState({
                type: FormStateActions.SET_OPINION_DETAILS,
                payload: value,
              })
            }
            label="Reasons"
            disabled={signedStatus}
            errorKey="opinion.details"
            templateProps={{
              template: OpinionTemplate,
              name: "Reasons Template",
            }}
          />
          <Typography variant="h4" display="inline">
            I am also of the opinion that taking into account the nature and
            degree of the mental disorder from which the patient is suffering
            and all other circumstances of the case, appropriate medical
            treatment is available to the patient at the hospital named above.
          </Typography>
        </Stack>
      </Stack>
      <SignatureSection
        name={signature}
        date={date}
        signedStatus={signedStatus}
        setSignedStatus={handleSetSignedStatus}
      />
      <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
        <Button
          data-test-id="cto5-email-part-1"
          variant="contained"
          onClick={() => handleEmail()}
          disabled={!signedStatus}
          size="large"
          sx={{ mr: 1 }}
        >
          Send to AMHP
        </Button>
        <PdfDownloadButton disabled={!signedStatus} />
      </Stack>
    </Paper>
  );
};

export default React.memo(Opinion);
