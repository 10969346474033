import { RenderedPDFProps } from "components/pdf";

export enum FormType {
  // AUTHORITY_TO_CONVEY = "AUTHORITY_TO_CONVEY",
  H1 = "h1",
  H3 = "h3",
  CTO1 = "cto1",
  CTO3 = "cto3",
  CTO4 = "cto4",
  CTO5 = "cto5",
  CTO7 = "cto7",
  CTO12 = "cto12",
  T2 = "t2",
}

export type FormPDFConfigData = Omit<
  RenderedPDFProps,
  "id" | "form" | "formProps"
>;
