import * as Yup from "yup";
import {
  Actors,
  FormStatus,
  FormState,
  FormItemType,
  PatientDetails,
  ClinicianDetailsType,
} from "common/types/statForms";
import { Opinion, CTODetails } from "common/types/cto";
import { AMHPDetails, CTO5Form, HospitalDetails } from "common/types/cto5";
import {
  amhpDetailsValidation,
  clinicianDetailsValidation,
  ctoDetailsValidation,
  hospitalDetailsValidation,
  opinionValidation,
  patientDetailsFullValidation,
} from "common/libs/validation";
import { CognitoUserExt } from "common/types/auth";
import { isAmhp, isDoctor, isWard } from "utils/auth";

export const handleStatus = (formState: FormState<CTO5Form>): FormStatus => {
  const { opinion } = formState.data;

  if (!opinion.signature) {
    return FormStatus.s10_draft;
  }

  return FormStatus.s40_signed;
};

/**
 * getActor
 * - if the user is a doctor, and part 2 is not signed -> stage 1 index 0
 * - if the user is an amhp, part 1 is signed and part 3 is not signed -> stage 2 index 4
 * - if the user is a doctor and part 2 is signed -> stage 3 index 5
 */
export const getActor = (
  formState: FormState<CTO5Form>,
  user: CognitoUserExt
): Actors | false => {
  const { opinion, amhpDetails } = formState.data;

  if (isDoctor(user) && !amhpDetails.signature) {
    return Actors.Originator;
  }

  if (isAmhp(user) && opinion.signature) {
    return Actors.AMHP;
  }

  if (isDoctor(user) && amhpDetails.signature) {
    return Actors.Clinician;
  }

  if (isWard(user)) {
    return Actors.MHAAdmin;
  }

  return false;
};

export const generateFormItems = (actor: Actors): FormItemType[] => [
  /** TO BE COMPLETED BY: Responsible Clinician */
  {
    index: 0,
    itemText: "1. Clinician Details",
    disabled: actor !== Actors.Originator,
    errorKey: "clinicianDetails",
  },
  {
    index: 1,
    itemText: "2. Patient Details",
    disabled: actor !== Actors.Originator,
    errorKey: "section1",
  },
  {
    index: 2,
    itemText: "3. Opinion",
    disabled: actor !== Actors.Originator,
    errorKey: "opinion",
  },
  /** TO BE COMPLETED BY: AMHP */
  {
    index: 3,
    itemText: "4. AMHP Details",
    disabled: actor !== Actors.AMHP,
    errorKey: "amhpDetails",
  },
  /** TO BE COMPLETED BY: Responsible Clinician */
  {
    index: 4,
    itemText: "5. CTO Details",
    disabled: actor !== Actors.Clinician && actor !== Actors.MHAAdmin,
    errorKey: "ctoDetails",
  },
];

export const validationSchema = [
  Yup.object().shape(clinicianDetailsValidation),
  Yup.object().shape({
    patientDetails: Yup.object().shape(patientDetailsFullValidation),
    hospitalDetails: Yup.object().shape(hospitalDetailsValidation),
  }),
  Yup.object().shape(opinionValidation),
  Yup.object().shape({
    ...amhpDetailsValidation,
    email: Yup.string()
      .email("Invalid email address")
      .required("AMHP email address is required"),
  }),
  Yup.object().shape(ctoDetailsValidation),
];

export const hasErrorsSection1 = (props: ClinicianDetailsType): boolean => {
  const {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    email,
  } = props;
  let error = false;

  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;
  if (!email) error = true;

  return error;
};

export const hasErrorsSection2 = (
  props: PatientDetails | HospitalDetails
): boolean => {
  const { name, address, manualAddress1, manualAddress2, manualPostcode } =
    props;
  let error = false;

  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;

  return error;
};

export const hasErrorsSection3 = (props: Opinion): boolean => {
  const { for: forValue, details } = props;
  let error = false;
  if (!forValue) error = true;
  if (!details) error = true;

  return error;
};
export const hasErrorsSection4 = (
  props: AMHPDetails,
  requireSignature: boolean = true
): boolean => {
  const {
    name,
    address,
    email,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    authorityName,
    signature,
  } = props;
  let error = false;
  if (!name) error = true;
  if (!address && !(manualAddress1 && manualAddress2 && manualPostcode))
    error = true;
  if (!email) error = true;
  if (!authorityName) error = true;
  if (requireSignature && !signature) error = true;

  return error;
};

export const hasErrorsSection5 = (
  props: CTODetails,
  requireSignature: boolean = true
): boolean => {
  const { effectiveFromDate, effectiveFromTime, signature } = props;
  let error = false;
  if (!effectiveFromDate) error = true;
  if (!effectiveFromTime) error = true;
  if (requireSignature && !signature) error = true;

  return error;
};

export const shouldDisableNext = (
  currentIndex: number,
  actor: Actors
): boolean => {
  switch (currentIndex) {
    case 2:
      return actor === Actors.Originator;
    case 3:
      return actor === Actors.AMHP;
    default:
      return false;
  }
};

export const shouldDisablePrev = (
  actor: Actors,
  currentIndex: number
): boolean => {
  if (actor === Actors.AMHP && currentIndex === 3) {
    return true;
  }
  if (actor === Actors.Clinician && currentIndex === 4) {
    return true;
  }

  return false;
};

export const shouldEnableDownload = (data: CTO5Form): boolean => {
  const {
    clinicianDetails,
    patientDetails,
    hospitalDetails,
    opinion,
    amhpDetails,
    ctoDetails,
  } = data;

  if (
    hasErrorsSection1(clinicianDetails) ||
    hasErrorsSection2(patientDetails) ||
    hasErrorsSection2(hospitalDetails) ||
    hasErrorsSection3(opinion) ||
    hasErrorsSection4(amhpDetails) ||
    hasErrorsSection5(ctoDetails)
  ) {
    return false;
  }

  return true;
};
