import React from "react";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

interface SideMenuTextProps {
  testId?: string;
  text: string;
}

const SideMenuText: React.FC<SideMenuTextProps> = ({ testId, text }) => {
  return (
    <ListItem data-test-id={testId}>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default React.memo(SideMenuText);
