import React, { useMemo } from "react";

// Form Components
import FormContainer from "components/forms/formContainer";

import H1Header from "components/formSections/h1/H1Header";
import HospitalDetails from "components/formSections/common/hospitalDetails";
import YourDetails from "components/formSections/h1/yourDetails";
import PatientDetails from "components/formSections/h1/patientDetails";
import WardSignature from "components/formSections/h1/wardSignature";
import NextActorDialogContent from "components/forms/nextActorDialogContent/NextActorDialogContent";
import FormLoading from "components/forms/formLoading";

// Custom Imports
import { useStyles } from "common/styles/form";
import {
  useActorTypeStageChange,
  useAuth,
  useForm,
  useFormAccess,
  useFormValidation,
  usePopups,
  useUI,
} from "hooks";
import { Actors } from "common/types/statForms";
import { FormType } from "common/types/pdf";
import { FormStateActions } from "common/types/h1";
import { DialogType } from "store/popups/types";
import { isWard } from "utils/auth";
import {
  generateFormItems,
  shouldDisablePrev,
  validationSchema,
} from "./helpers";
import { LoadingCircle } from "@s12solutions/ui";

/**
 * ------------------------------------------------------------
 * * WORKFLOW FOR H1 FORM
 * ------------------------------------------------------------
 * Stages
 * 1/ Started by doctor (who belongs to mht with feature enabled for h1)
 * 2/ Signed by ward
 * 3/ Emailed to mha admin
 * ------------------------------------------------------------
 */

const H1Form: React.FC = () => {
  const classes = useStyles();
  const { actor, user } = useAuth();
  const {
    loading,
    formState,
    dispatchFormState,
    actorType,
    setActorType,
    handleSave,
  } = useForm();
  const { currentSectionIndex } = useUI();
  const { handleDialog } = usePopups();

  // Section index functions will be executed when the user tries to go to the next section
  // sectionIndex => callback function
  const sectionIndexFunctions = useMemo(
    () => ({
      2: () => {
        handleDialog(
          <NextActorDialogContent
            formType={FormType.H1}
            onSuccess={() => {
              // Save form
              handleSave({ shouldNavigate: false });

              setActorType(Actors.MHAAdmin);
            }}
            actorType="MHA Admin"
          />,
          {
            title: "Actor Change",
            type: DialogType.Error,
            size: "sm",
          }
        );
      },
    }),
    [handleDialog, handleSave, setActorType]
  );

  // Destructure
  const {
    hospitalDetails,
    hospitalDetails: {
      name: hospitalName,
      address: hospitalAddress,
      manualAddress1,
      manualAddress2,
      manualPostcode,
    },
    yourDetails,
    patientDetails,
    furnishingReport,
    wardSignature,
  } = formState.data;

  // * Start Component lifecycle methods ------------------------

  useFormAccess("Hospital");

  useActorTypeStageChange(actorType, {
    [Actors.Originator]: 0,
    [Actors.MHAAdmin]: 3,
  });

  useFormValidation(validationSchema, [
    { hospitalDetails },
    { yourDetails },
    { patientDetails },
    { wardSignature },
  ]);

  // * End Component lifecycle methods ------------------------

  const formItems = generateFormItems(actorType);
  const part1Disabled = !!furnishingReport.signature;
  const shouldDisableNext =
    currentSectionIndex === 2 && !furnishingReport.signature ? true : false;

  // Render
  if (loading) {
    return <LoadingCircle />;
  }

  if (isWard(user) && !actor) {
    return <FormLoading />;
  }

  return (
    <FormContainer
      sections={[
        <>
          <div className={classes.sectionContainer}>
            <H1Header />
          </div>
          <div className={classes.sectionContainer}>
            <HospitalDetails
              testIdKey="h1"
              name={hospitalName}
              address={hospitalAddress}
              manualAddress1={manualAddress1}
              manualAddress2={manualAddress2}
              manualPostcode={manualPostcode}
              setHospital={(value: any) => {
                dispatchFormState({
                  type: FormStateActions.SET_HOSPITAL,
                  payload: value,
                });
              }}
              label="To the managers of (name and address of hospital)"
              italicLabel={false}
              disabled={part1Disabled}
            />
          </div>
        </>,
        <div className={classes.sectionContainer}>
          <YourDetails
            yourDetails={yourDetails}
            dispatchFormState={dispatchFormState}
            disabled={part1Disabled}
          />
        </div>,
        <div className={classes.sectionContainer}>
          <PatientDetails
            patientDetails={patientDetails}
            furnishingReport={furnishingReport}
            dispatchFormState={dispatchFormState}
            disabled={part1Disabled}
          />
        </div>,
        <div className={classes.sectionContainer}>
          <WardSignature
            wardSignature={wardSignature}
            dispatchFormState={dispatchFormState}
          />
        </div>,
      ]}
      formPartSelectorProps={{
        formItems,
      }}
      formNavButtonProps={{
        formItems,
        lastSectionIndex: 3,
        sectionIndexFunctions,
        disablePrev: shouldDisablePrev(actorType, currentSectionIndex),
        disableNext: shouldDisableNext,
      }}
    />
  );
};

export default React.memo(H1Form);
