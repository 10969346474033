import React, { useState, useCallback } from "react";

// Material UI
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Custom imports
import Action from "common/types/action";
import { FormStateActions, Opinion as OpinionType } from "common/types/cto1";
import { useAuth } from "hooks";
import { formatUserName } from "common/libs/formatUserName";
import Paper from "components/paper";
import { StyledTextArea, StyledCheckboxes } from "components/uiElements";
import SignatureSection from "components/formSections/common/signatureSection";
import { CtoFor } from "common/types/cto";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

const OpinionTemplate = () => (
  <Stack spacing={2}>
    <Typography variant="body2">
      [PATIENT] has a mental disorder (named if possible) characterised by the
      following symptoms... The nature of their disorder is demonstrated by...
      AND/OR The degree of their disorder is demonstrated by...
    </Typography>
    <Typography variant="body2">
      Treatment for their mental disorder can be provided without the need for
      continued detention in hospital, provided they are liable to recall to
      hospital, because (include details of the patient’s capacity to consent to
      treatment, and their attitude towards the treatment)…….
    </Typography>
    <Typography variant="body2">
      The power of recall is necessary, because……
    </Typography>
    <Typography variant="body2">
      The appropriate treatment available to the patient is……..
    </Typography>
  </Stack>
);

const checkboxValues = [
  {
    key: CtoFor.PATIENTS_HEALTH,
    value: "(i) the patient's health",
  },
  {
    key: CtoFor.PATIENTS_SAFETY,
    value: "(ii) the patient's safety",
  },
  {
    key: CtoFor.SAFETY_OF_OTHERS,
    value: "(iii) the protection of other persons",
  },
];

interface OpinionProps {
  testIdKey?: string;
  opinion: OpinionType;
  dispatchFormState: React.Dispatch<Action>;
  handleEmail?: () => void;
  checkString?: string;
  pointASemiColon?: boolean;
  disabled?: boolean;
}

const Opinion: React.FC<OpinionProps> = ({
  testIdKey,
  opinion: { for: forValue, details, signature, date },
  dispatchFormState,
  checkString = "(check each that is applicable)",
  handleEmail,
  pointASemiColon = false,
  disabled,
}) => {
  const { user } = useAuth();
  const [signedStatus, setSignedStatus] = useState<boolean>(!!signature);

  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed ? formatUserName(user) : "";

      dispatchFormState({
        type: FormStateActions.SET_OPINION_SIGNATURE,
        payload: signedValue,
      });
    },
    [dispatchFormState, user]
  );

  return (
    <Paper>
      <Stack spacing={4} mb={handleEmail ? 3 : undefined}>
        <Typography variant="h4" display="inline">
          In my opinion,
        </Typography>
        <Stack spacing={4} sx={{ paddingLeft: "1rem" }}>
          <Typography variant="h4" display="inline">
            (a) this patient is suffering from mental disorder of a nature or
            degree which makes it appropriate for the patient to receive medical
            treatment{pointASemiColon ? ";" : ","}
          </Typography>
          <Stack spacing={1}>
            <Typography variant="h4" display="inline">
              (b) it is necessary for
            </Typography>
            <Stack spacing={1} sx={{ paddingLeft: "2rem" }}>
              <StyledCheckboxes
                testIdKey={`${testIdKey}-for`}
                value={forValue}
                onChange={(value: any) =>
                  dispatchFormState({
                    type: FormStateActions.SET_OPINION_FOR,
                    payload: value,
                  })
                }
                items={checkboxValues}
                disabled={disabled}
                errorKey="opinion.for"
              />
              <Typography variant="h4" display="inline" className="italic">
                {checkString}
              </Typography>
            </Stack>
          </Stack>
          <div style={{ margin: "20px 0 0" }}>
            <Typography variant="h4" display="inline">
              that the patient should receive such treatment;
            </Typography>
          </div>
          <Typography variant="h4" display="inline">
            (c) such treatment can be provided without the patient continuing to
            be detained in a hospital provided the patient is liable to being
            recalled to hospital for medical treatment;
          </Typography>
          <Typography variant="h4" display="inline">
            (d) it is necessary that the responsible clinician should be able to
            exercise the power under section 17E(1) to recall the patient to
            hospital;
          </Typography>
          <Typography variant="h4" display="inline">
            (e) taking into account the nature and degree of the mental disorder
            from which the patient is suffering and all other circumstances of
            the case, appropriate medical treatment is available to the patient.
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Typography variant="h4" display="inline">
            My opinion is founded on the following grounds —
          </Typography>
          <StyledTextArea
            testId={`${testIdKey}-details`}
            value={details}
            onChange={(value: any) =>
              dispatchFormState({
                type: FormStateActions.SET_OPINION_DETAILS,
                payload: value,
              })
            }
            label="Opinion"
            disabled={disabled}
            errorKey="opinion.details"
            templateProps={{
              template: OpinionTemplate,
              name: "Reasons Template",
            }}
          />
          <Typography variant="h4" display="inline">
            I confirm that in determining whether the criterion at (d) above is
            met, I have considered what risk there would be of deterioration of
            the patient’s condition if the patient were to continue not to be
            detained in hospital, with regard to the patient’s history of mental
            disorder and any other relevant factors.
          </Typography>
        </Stack>
      </Stack>
      {handleEmail && (
        <>
          <SignatureSection
            name={signature}
            date={date}
            signedStatus={signedStatus}
            setSignedStatus={handleSetSignedStatus}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            mt={2}
          >
            <Button
              data-test-id={`${testIdKey}-email`}
              variant="contained"
              onClick={() => handleEmail()}
              disabled={!signedStatus}
              size="large"
              sx={{ mr: 1 }}
            >
              Send to AMHP
            </Button>
            <PdfDownloadButton disabled={!signedStatus} />
          </Stack>
        </>
      )}
    </Paper>
  );
};

export default React.memo(Opinion);
