import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography, RadioGroup, FormControlLabel, Radio } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  inputDisabled: {
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    borderRadius: ".5rem",
  },
  errorText: {
    marginTop: ".5rem",
    color: theme.palette.error.dark,
    fontWeight: "bold",
  },
}));

interface StyledRadioGroupItemProps {
  key: any;
  testId?: string;
  value: string;
}
interface StyledRadioGroupProps {
  testId?: string;
  disabled?: boolean;
  label: string;
  value: any;
  onChange: any;
  error?: boolean;
  errorText?: string;
  items: StyledRadioGroupItemProps[];
  itemPadding?: string | number;
}

const StyledRadioGroup: React.FC<StyledRadioGroupProps> = ({
  testId,
  disabled,
  label,
  value,
  onChange,
  error,
  errorText,
  items,
  itemPadding,
}) => {
  const classes = useStyles();

  return (
    <div
      style={{
        borderRadius: ".5rem",
        background: "white",
        marginTop: 8,
        marginBottom: 4,
        paddingTop: 16,
        paddingBottom: 8,
        paddingLeft: 32,
        border: "1px solid rgba(0, 0, 0, 0.23)",
      }}
    >
      {label && (
        <Typography variant="h4" display="inline">
          {label}
        </Typography>
      )}
      <RadioGroup
        data-test-id={testId}
        aria-label={label}
        name={label}
        value={value}
        onChange={onChange}
      >
        {items.map((item, index) => (
          <FormControlLabel
            data-test-id={item.testId}
            key={index}
            value={item.key}
            control={
              <Radio
                sx={{
                  padding: "0px 10px",
                  margin: itemPadding || "0 0 16px 0",
                }}
                disabled={disabled}
              />
            }
            label={item.value}
            labelPlacement="end"
            sx={{ fontWeight: 400, alignItems: "flex-start" }}
          />
        ))}
      </RadioGroup>
      {error && errorText && (
        <div className={classes.errorText}>
          <Typography variant="h5">{errorText}</Typography>
        </div>
      )}
    </div>
  );
};

export default React.memo(StyledRadioGroup);
