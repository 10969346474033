enum Itemisation {
  ROMAN = "Roman",
  ALPHABETICAL = "Alphabetical",
  BULLETS = "Bullets",
}

/**
 * getItemisation
 * Returns alternate itemisation from index or other numeric input
 * @param {Itemisation} itemisation - IV, V, VI, X etc or a,b,c,d,e...
 * @param {number} number - the value to be converted
 * @returns {JSX.Element}
 */
export const getItemisation = (itemisation: Itemisation, number: number) => {
  switch (itemisation) {
    case Itemisation.ROMAN:
      return createRomanNumeralFromIndex(number + 1);
    case Itemisation.ALPHABETICAL:
      return createAlphabeticalFromIndex(number);
    case Itemisation.BULLETS:
      return "";
    default:
      throw new Error("must supply itemisation type");
  }
};

/**
 * createRomanNumeralFromIndex
 * Converts roman numerals from a number
 * @param {number} number - the value to be converted
 * @returns {string} - numeral
 */
const createRomanNumeralFromIndex = (number: number) => {
  const numeralCodes = [
    ["", "i", "ii", "iii", "iv", "v", "vi", "vii", "viii", "ix"],
    ["", "x", "xx"],
  ];
  let numeral = "";
  const digits = number.toString().split("").reverse();
  for (let i = 0; i < digits.length; i++) {
    numeral = numeralCodes[i][parseInt(digits[i], 10)] + numeral;
  }
  return `(${numeral})`;
};

/**
 * createAlphabeticalFromIndex
 * Converts alphabetical iteratives from a number
 * @param {number} number - the value to be converted
 * @returns {string} - the iterative (a,b,c,d etc)
 */
export const createAlphabeticalFromIndex = (number: number) => {
  if (number > 25) {
    // Justification: Creating either a series of checkboxes, or radios this long is a UX nightmare
    throw new Error("Unable to create list this long");
  }
  const letters = "abcdefghijklmnopqrstuvwxyz";
  return `(${letters.slice(number, number + 1)})`;
};
