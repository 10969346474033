import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { authService } from "store/auth/authService";

const env = process.env;
const baseURL = env.REACT_APP_STAT_FORMS_EP as string;
const Origin = env.REACT_APP_ORIGIN as string;

const injectToken = async (config: AxiosRequestConfig) => {
  try {
    const session = await authService.currentSession();
    const token = session ? `Bearer ${session.getIdToken().getJwtToken()}` : "";

    if (token) {
      config.headers ||= {};
      config.headers.Authorization = token;
    }
  } catch (err) {
  } finally {
    return config;
  }
};

class API {
  private instance: AxiosInstance;

  constructor() {
    const instance = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Access-Control-Allow-Origin": Origin,
      },
    });

    // Add a request interceptor to inject token
    instance.interceptors.request.use(injectToken, (error) =>
      Promise.reject(error)
    );

    this.instance = instance;
  }

  get<TData = any>(config?: AxiosRequestConfig): Promise<AxiosResponse<TData>> {
    return this.instance.get<TData, AxiosResponse<TData>>("", config);
  }

  post<TData = any, TFormData = any>(
    data?: TFormData,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TData>> {
    return this.instance.post<TData, AxiosResponse<TData>>("", data, config);
  }

  put<TData = any, TFormData = any>(
    data?: TFormData,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TData>> {
    return this.instance.put<TData, AxiosResponse<TData>>("", data, config);
  }

  delete<TData = any>(
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TData>> {
    return this.instance.delete<TData, AxiosResponse<TData>>("", config);
  }
}

const api = new API();

export default api;
