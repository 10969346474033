import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";

// Material UI
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

// Material UI Icons
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LogoutIcon from "@mui/icons-material/Logout";

// Custom
import { useAuth } from "hooks";
import { useStyles } from "./styles";

import SideMenuItem from "./SideMenuItem";
import SideMenuHeading from "./SideMenuHeading";
import { isDoctor } from "utils/auth";
import { RouteConstants } from "common/constants/routes";

interface SideMenuProps {
  handleDrawerClose: () => void;
  open: boolean;
}

const SideMenu: React.FC<SideMenuProps> = ({
  open,
  handleDrawerClose,
  children,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, formGroups, authService, clearCredentials } = useAuth();
  const isUserDoctor = isDoctor(user!);

  const handleResetAfterNavigate = useCallback(() => {
    handleDrawerClose();
  }, [handleDrawerClose]);

  const handleNavigateHome = useCallback(() => {
    navigate(RouteConstants.DASHBOARD);
    handleResetAfterNavigate();
  }, [handleResetAfterNavigate, navigate]);

  const handleNavigateToForm = useCallback(
    (href: string) => {
      navigate(`${href}/${uuidv4()}/1`);
      handleResetAfterNavigate();
    },
    [handleResetAfterNavigate, navigate]
  );

  const handleSignOut = useCallback(async () => {
    await authService.signout();
    clearCredentials();
    handleDrawerClose();
  }, [authService, clearCredentials, handleDrawerClose]);

  return (
    <div className={classes.root}>
      <Drawer
        data-test-id="side-menu"
        className={classes.drawer}
        transitionDuration={{ enter: 100, exit: 100 }}
        closeAfterTransition={true}
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          onBackdropClick: handleDrawerClose, // close on outside click
        }}
      >
        <>
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose} size="large">
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <SideMenuItem
              testId="sidemenu-home"
              icon={<HomeIcon />}
              onClick={handleNavigateHome}
              selected={window.location.pathname === "/"}
            >
              Dashboard
            </SideMenuItem>
            {formGroups.includes("Hospital") && (
              <>
                <SideMenuHeading text="Hospital" />
                <SideMenuItem
                  testId="sidemenu-h1"
                  icon={<AssignmentIcon />}
                  onClick={() => handleNavigateToForm("/h1")}
                  selected={window.location.pathname.includes("/h1")}
                  secondary="report on hospital in-patient"
                >
                  Section 5(2) - H1
                </SideMenuItem>
                <SideMenuItem
                  testId="sidemenu-h3"
                  icon={<AssignmentIcon />}
                  onClick={() => handleNavigateToForm("/h3")}
                  selected={window.location.pathname.includes("/h3")}
                  secondary="record of detention in hospital"
                >
                  H3 Form
                </SideMenuItem>
              </>
            )}
            {formGroups.includes("CTO") && isUserDoctor && (
              <>
                <SideMenuHeading text="CTO" />
                <SideMenuItem
                  testId="sidemenu-cto1"
                  icon={<AssignmentIcon />}
                  onClick={() => handleNavigateToForm("/cto1")}
                  selected={window.location.pathname.includes("/cto1")}
                  secondary="community treatment order"
                >
                  CTO1
                </SideMenuItem>
                <SideMenuItem
                  testId="sidemenu-cto3"
                  icon={<AssignmentIcon />}
                  onClick={() => handleNavigateToForm("/cto3")}
                  selected={window.location.pathname.includes("/cto3")}
                  secondary="notice of recall to hospital"
                >
                  CTO3
                </SideMenuItem>
                <SideMenuItem
                  testId="sidemenu-cto4"
                  icon={<AssignmentIcon />}
                  onClick={() => handleNavigateToForm("/cto4")}
                  selected={window.location.pathname.includes("/cto4")}
                  secondary="record of patient’s detention in hospital after recall"
                >
                  CTO4
                </SideMenuItem>
                <SideMenuItem
                  testId="sidemenu-cto5"
                  icon={<AssignmentIcon />}
                  onClick={() => handleNavigateToForm("/cto5")}
                  selected={window.location.pathname.includes("/cto5")}
                  secondary="revocation of community treatment order"
                >
                  CTO5
                </SideMenuItem>
                <SideMenuItem
                  testId="sidemenu-cto7"
                  icon={<AssignmentIcon />}
                  onClick={() => handleNavigateToForm("/cto7")}
                  selected={window.location.pathname.includes("/cto7")}
                  secondary="report extending the community treatment period"
                >
                  CTO7
                </SideMenuItem>
                <SideMenuItem
                  testId="sidemenu-cto12"
                  icon={<AssignmentIcon />}
                  onClick={() => handleNavigateToForm("/cto12")}
                  selected={window.location.pathname.includes("/cto12")}
                  secondary="Certificate that community patient has capacity to consent"
                >
                  CTO12
                </SideMenuItem>
              </>
            )}
            {formGroups.includes("Treatment") && isUserDoctor && (
              <>
                <SideMenuHeading text="Treatment" />
                <SideMenuItem
                  testId="sidemenu-t2"
                  icon={<AssignmentIcon />}
                  onClick={() => handleNavigateToForm("/t2")}
                  selected={window.location.pathname === "/t2"}
                  secondary="certificate of consent to treatment"
                >
                  T2
                </SideMenuItem>
              </>
            )}
            <>
              <SideMenuHeading text="Coming Soon" />
              <SideMenuItem
                disabled
                testId="sidemenu-h5s20"
                icon={<AssignmentIcon />}
                secondary="renewal of authority for detention"
              >
                H5 s20
              </SideMenuItem>
              <SideMenuItem
                disabled
                testId="sidemenu-h2s54"
                icon={<AssignmentIcon />}
                secondary="record of hospital in-patient"
              >
                H2 s5(4)
              </SideMenuItem>
              <SideMenuItem
                disabled
                testId="sidemenu-h4s19"
                icon={<AssignmentIcon />}
                secondary="authority for transfer from one hospital to another under different managers"
              >
                H4 s19
              </SideMenuItem>
              <SideMenuItem
                disabled
                testId="sidemenu-m2s25"
                icon={<AssignmentIcon />}
                secondary="report barring discharge by nearest relative"
              >
                M2 s25
              </SideMenuItem>
              <SideMenuItem
                disabled
                testId="sidemenu-cto2"
                icon={<AssignmentIcon />}
                secondary="variation of conditions of a community treatment order"
              >
                CTO2
              </SideMenuItem>
              <SideMenuItem
                disabled
                testId="sidemenu-h6s21b"
                icon={<AssignmentIcon />}
                secondary="authority for detention after absence without leave for more than 28 days"
              >
                H6 s21B
              </SideMenuItem>
              <SideMenuItem
                disabled
                testId="sidemenu-t4"
                icon={<AssignmentIcon />}
                secondary="certificate of consent to treatment (patients at least 18 years old)"
              >
                T4
              </SideMenuItem>
            </>
          </List>
          <Divider className="grow" />
          <List>
            <SideMenuItem
              testId="side-menu-logout"
              onClick={handleSignOut}
              icon={<LogoutIcon />}
            >
              Sign out
            </SideMenuItem>
          </List>
        </>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
};

export default React.memo(SideMenu);
