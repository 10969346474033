import dayjs from "dayjs";
import { CommonStateActions, FormState } from "common/types/statForms";
import { H3Form, FormStateActions } from "common/types/h3";
import Action from "common/types/action";
import { signedFormHashed } from "common/libs/forms";
import { h3InitialState } from "../initialState";

export const h3Reducer = (state: FormState<H3Form>, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case CommonStateActions.RESET_FORM:
      return { ...h3InitialState };
    case CommonStateActions.LOAD_FORM:
      return {
        ...state,
        ...payload,
      };
    case CommonStateActions.SET_INITIAL_DATA:
      return {
        ...state,
        initialData: state.data,
      };
    case FormStateActions.SET_HOSPITAL:
      return {
        ...state,
        data: {
          ...state.data,
          hospitalDetails: payload,
        },
      };
    case FormStateActions.SET_PATIENT_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          patientDetails: {
            ...state.data.patientDetails,
            name: payload,
          },
        },
      };
    case FormStateActions.SET_DATE: {
      return {
        ...state,
        data: {
          ...state.data,
          admissionDetails: {
            ...state.data.admissionDetails,
            admissionDate: payload,
          },
        },
      };
    }
    case FormStateActions.SET_TIME: {
      return {
        ...state,
        data: {
          ...state.data,
          admissionDetails: {
            ...state.data.admissionDetails,
            admissionTime: payload,
          },
        },
      };
    }
    case FormStateActions.SET_SECTION: {
      return {
        ...state,
        data: {
          ...state.data,
          admissionDetails: {
            ...state.data.admissionDetails,
            section: payload,
          },
        },
      };
    }
    case FormStateActions.SET_IN_PATIENT: {
      return {
        ...state,
        data: {
          ...state.data,
          admissionDetails: {
            ...state.data.admissionDetails,
            inPatient: payload,
          },
        },
      };
    }
    case FormStateActions.SET_SIGNATURE_PART1: {
      return {
        ...state,
        data: {
          ...state.data,
          admissionDetails: {
            ...state.data.admissionDetails,
            signaturePart1: payload,
            namePart1: payload,
            signatureDatePart1: payload ? dayjs().toISOString() : "",
            trackingIdPart1: payload ? signedFormHashed(state.data) : "",
          },
        },
      };
    }
    case FormStateActions.SET_DATE_PART2: {
      return {
        ...state,
        data: {
          ...state.data,
          admissionDetails: {
            ...state.data.admissionDetails,
            admissionDatePart2: payload,
          },
        },
      };
    }
    case FormStateActions.SET_TIME_PART2: {
      return {
        ...state,
        data: {
          ...state.data,
          admissionDetails: {
            ...state.data.admissionDetails,
            admissionTimePart2: payload,
          },
        },
      };
    }
    case FormStateActions.SET_SIGNATURE_PART2: {
      return {
        ...state,
        data: {
          ...state.data,
          admissionDetails: {
            ...state.data.admissionDetails,
            signaturePart2: payload,
            namePart2: payload,
            signatureDatePart2: payload ? dayjs().toISOString() : "",
            trackingIdPart2: payload ? signedFormHashed(state.data) : "",
          },
        },
      };
    }
    default:
      return state;
  }
};
