import { useEffect } from "react";
import { useUI } from "hooks";
import { Actors } from "common/types/statForms";

type ActorTypeStageMapping = {
  [key in Actors]?: number;
};

/**
 * useActorTypeStageChange()
 * @param {Actors} actorType the actorType of the person filling out the form
 * @param {ActorTypeStageMapping} actorTypeStageMapping actorType => index
 */
export const useActorTypeStageChange = (
  actorType: Actors,
  actorTypeStageMapping: ActorTypeStageMapping
) => {
  const { setCurrentSectionIndex } = useUI();
  const navigateToStage = actorTypeStageMapping[actorType];

  useEffect(() => {
    if (!navigateToStage) return;

    setCurrentSectionIndex(navigateToStage);
  }, [navigateToStage, setCurrentSectionIndex]);
};
