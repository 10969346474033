import React from "react";
import dayjs from "dayjs";

// Material UI
import { makeStyles } from "@mui/styles";
import { Theme, useTheme } from "@mui/material/styles";
import { Box, Stack, Typography } from "@mui/material";

// Custom imports
import { StrikeThrough } from "components/uiElements/StrikeThrough";

interface ThemeProps {
  strikeThrough: boolean;
}

const useStyles = makeStyles<Theme, ThemeProps>((theme) => ({
  inputContainer: {
    padding: ".6rem",
    width: "12rem",
    backgroundColor: theme.palette.common.white,
  },
  contentBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "2.5rem",
    height: "2rem",
  },
}));

// We are saving dates in Redux in ISO format, so this component receives dates
// as strings and extracts the date accordingly.
interface OutputDateProps {
  iSODate?: string;
  strikeThrough?: boolean;
  noBackground?: boolean;
}

const OutputDate: React.FC<OutputDateProps> = ({
  iSODate,
  strikeThrough = false,
  noBackground = false,
}) => {
  const theme = useTheme();
  const classes = useStyles({ strikeThrough });
  const date = dayjs(iSODate).toJSON();
  return (
    <>
      <Box className={classes.inputContainer}>
        <Stack
          spacing={1}
          direction="row"
          style={{ position: "relative" }}
          justifyContent="space-between"
          alignItems="center"
        >
          {strikeThrough && <StrikeThrough width={20} />}
          <Box
            className={classes.contentBox}
            sx={{
              backgroundColor: noBackground
                ? undefined
                : theme.palette.grey.A200,
            }}
          >
            {date && (
              <Typography variant="h4">{date.substring(8, 10)}</Typography>
            )}
          </Box>
          <Box>/</Box>
          <Box
            className={classes.contentBox}
            sx={{
              backgroundColor: noBackground
                ? undefined
                : theme.palette.grey.A200,
            }}
          >
            {date && (
              <Typography variant="h4">{date.substring(5, 7)}</Typography>
            )}
          </Box>
          <Box>/</Box>
          <Box
            className={classes.contentBox}
            sx={{
              backgroundColor: noBackground
                ? undefined
                : theme.palette.grey.A200,
            }}
          >
            {date && (
              <Typography variant="h4">{date.substring(0, 4)}</Typography>
            )}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export { OutputDate };
