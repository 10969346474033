/**
 * -------------------------------------
 * * Timeline component
 * -------------------------------------
 *
 * This component is a abstracted MUI timeline component, it takes in an array of items which can
 * either be text or custom components
 *
 * ? Usage:
 *
 *  <>
 *      <Timeline
 *       items={[
 *        { text: "Item 1" },
 *        { component: (<CustomComponent />) },
 *        { text: "Item 2" },
 *        { text: "Item 3" },
 *       ]}
 *      />
 *  </>
 *
 * * Note: to see what options are available see the types below (TimelineProps)
 * * Note: another example of this in use is the StatFormActivity.tsx component (it uses custom components)
 * -------------------------------------
 */

import React from "react";

import { Typography as Text, TypographyTypeMap } from "@mui/material";
import {
  Timeline as MuiTimeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";

type TimelineItemType = {
  /**
   * @param {string | undefined} text
   * The text to display in the timeline item.
   */
  text?: string;
  /**
   * @default {"body1"}
   * @param {TypographyTypeMap["props"]["variant"] | undefined} variant
   * The variant of the typography to use for the text.
   */
  variant?: TypographyTypeMap["props"]["variant"];
  /**
   * @param {React.ReactNode | undefined} component
   * The component to display in the content, this is for extra flexibility and will overwrite
   * the text prop.
   */
  component?: React.ReactNode;
};

interface TimelineProps {
  /**
   * @param {TimelineItemType} item
   * The items to display in the timeline.
   * will either display text or a custom component inside the timeline content
   */
  items: TimelineItemType[];
}

const Timeline: React.FC<TimelineProps> = ({ items }) => (
  <MuiTimeline position="right">
    {items.map(({ text, variant, component }, index) => (
      <TimelineItem
        key={index}
        sx={{
          "&:before": {
            content: "''",
            display: "block",
            padding: 0,
            flex: 0,
          },
        }}
      >
        <TimelineSeparator>
          <TimelineDot color="secondary" variant="outlined" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          {component || <Text variant={variant || "body1"}>{text}</Text>}
        </TimelineContent>
      </TimelineItem>
    ))}
  </MuiTimeline>
);

export default React.memo(Timeline);
