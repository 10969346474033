import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";

// Material UI
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { CommonStateActions, FormItemType } from "common/types/statForms";
import { useForm, useUI } from "hooks";
import { isSectionErrored } from "utils/validationHelpers";
import { RouteConstants } from "common/constants/routes";
import { getHeading } from "common/constants/headings";
import { FormType } from "common/types/pdf";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    padding: "2.0rem 1.6rem 1.6rem",
  },
  info: {
    backgroundColor: theme.palette.info.dark,
    padding: "1rem",
  },
  header: {
    padding: "0 1rem",
  },
  hover: {
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.dark,
    },
  },
  selected: {
    color: theme.palette.primary.main,
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  errored: {
    color: theme.palette.error.main,
  },
  typography: {
    fontWeight: "bold",
  },
}));

export interface FormPartSelectorProps {
  formItems: FormItemType[];
  enableDownload?: boolean;
}

const FormPartSelector: React.FC<FormPartSelectorProps> = ({ formItems }) => {
  const { formType } = useParams();
  const castedFormType = formType as FormType;
  const classes = useStyles();
  const navigate = useNavigate();
  const { currentSectionIndex, setCurrentSectionIndex } = useUI();
  const { handleSave, formErrors, sectionIndexValidation, dispatchFormState } =
    useForm();

  // We validate from the current index to the index the user wants to go jump to
  // If there is an error we highlight the error and keep them on the section instead
  const handleSetCurrentSectionIndex = useCallback(
    async (jumpToIndex: number) => {
      const promiseArr = [];
      if (sectionIndexValidation) {
        for (let i = currentSectionIndex; i < jumpToIndex; i++) {
          promiseArr.push(await sectionIndexValidation[i]());
        }
      }
      if (promiseArr) {
        await Promise.all(promiseArr);
      }
      setCurrentSectionIndex(jumpToIndex);
    },
    [currentSectionIndex, sectionIndexValidation, setCurrentSectionIndex]
  );

  const renderItems = (selected: number) => {
    return formItems.map((item: FormItemType, index) => {
      return (
        <Typography
          onClick={async () =>
            item.disabled
              ? undefined
              : await handleSetCurrentSectionIndex(index)
          }
          variant="h4"
          noWrap
          key={index}
          className={clsx({
            [classes.selected]: selected === index,
            [classes.disabled]: item.disabled,
            [classes.hover]: !item.disabled,
            [classes.errored]: isSectionErrored(formErrors, item?.errorKey),
            [classes.typography]: true,
          })}
        >
          {item.itemText}
        </Typography>
      );
    });
  };

  const handleExit = useCallback(() => {
    dispatchFormState({
      type: CommonStateActions.RESET_FORM,
      payload: undefined,
    });
    navigate(RouteConstants.DASHBOARD);
  }, [dispatchFormState, navigate]);

  return (
    <Paper
      elevation={0}
      classes={{
        root: classes.paper,
      }}
      square
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Stack>
          <Typography variant="h3" sx={{ mb: 3.2 }}>
            {getHeading(castedFormType)} Form
          </Typography>
          <Stack spacing={3}>{renderItems(currentSectionIndex)}</Stack>
        </Stack>
        <Stack spacing={1}>
          <Button
            data-test-id="form-cancel"
            color="error"
            variant="outlined"
            onClick={handleExit}
            fullWidth
          >
            Exit
          </Button>
          <Button
            data-test-id="form-save-and-exit"
            color="primary"
            variant="contained"
            onClick={() =>
              handleSave({ shouldNavigate: true, shouldResetForm: true })
            }
            fullWidth
          >
            Save and Exit
          </Button>
        </Stack>
      </div>
    </Paper>
  );
};

export default React.memo(FormPartSelector);
