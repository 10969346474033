import React, { useState, useEffect, useCallback } from "react";

import { Stack, Typography } from "@mui/material";

// Custom Imports
import {
  StyledActorInput,
  StyledClinicianInput,
  StyledRadioGroup,
} from "components/uiElements";
import Paper from "components/paper";
import Action from "common/types/action";
import { FormStateActions, YourDetailsType } from "common/types/h1";
import { isWard } from "utils/auth";
import { useAuth } from "hooks";

interface YourDetailsProps {
  yourDetails: YourDetailsType;
  dispatchFormState: React.Dispatch<Action>;
  disabled: boolean;
}

const YourDetails: React.FC<YourDetailsProps> = ({
  yourDetails: { practitionerName, isNominee, isMedicalPractitioner },
  dispatchFormState,
  disabled,
}) => {
  const { user } = useAuth();
  const PrefilledComponent = isWard(user)
    ? StyledActorInput
    : StyledClinicianInput;

  const getValue = useCallback(() => {
    if (!isNominee) {
      if (isMedicalPractitioner) {
        return 1;
      } else {
        return 2;
      }
    } else {
      if (isMedicalPractitioner) {
        return 3;
      } else {
        return 4;
      }
    }
  }, [isNominee, isMedicalPractitioner]);

  const [value, setValue] = useState(getValue());

  useEffect(() => {
    switch (Number(value)) {
      case 1:
        dispatchFormState({
          type: FormStateActions.SET_IS_NOMINEE,
          payload: false,
        });

        dispatchFormState({
          type: FormStateActions.SET_IS_MEDICAL_PRACTITIONER,
          payload: true,
        });
        break;
      case 2:
        dispatchFormState({
          type: FormStateActions.SET_IS_NOMINEE,
          payload: false,
        });

        dispatchFormState({
          type: FormStateActions.SET_IS_MEDICAL_PRACTITIONER,
          payload: false,
        });
        break;
      case 3:
        dispatchFormState({
          type: FormStateActions.SET_IS_NOMINEE,
          payload: true,
        });

        dispatchFormState({
          type: FormStateActions.SET_IS_MEDICAL_PRACTITIONER,
          payload: true,
        });
        break;
      case 4:
        dispatchFormState({
          type: FormStateActions.SET_IS_NOMINEE,
          payload: true,
        });

        dispatchFormState({
          type: FormStateActions.SET_IS_MEDICAL_PRACTITIONER,
          payload: false,
        });
        break;
    }
  }, [value, dispatchFormState]);

  return (
    <Paper>
      <div style={{ padding: "1rem" }}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Typography variant="h4" display="inline">
              I am (PRINT full name)
            </Typography>
            <PrefilledComponent
              testId="h1-practitioner-name"
              label="Name"
              name="name"
              type="text"
              variant="outlined"
              fullWidth
              onChange={(value: any) => {
                dispatchFormState({
                  type: FormStateActions.SET_PRACTITIONER_NAME,
                  payload: value,
                });
              }}
              value={practitionerName}
              errorKey="yourDetails.practitionerName"
            />
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h4" display="inline">
              and I am{" "}
              <span className="italic">
                (select ONE of the following options from below)
              </span>
            </Typography>
            <StyledRadioGroup
              label=""
              value={value}
              onChange={(e: any) => setValue(e.target.value)}
              items={[
                {
                  key: 1,
                  testId: "h1-iam-option-1",
                  value: "the registered medical practitioner",
                },
                {
                  key: 2,
                  testId: "h1-iam-option-2",
                  value:
                    "the approved clinician (who is not a registered medical practitioner)",
                },
                {
                  key: 3,
                  testId: "h1-iam-option-3",
                  value:
                    "a registered medical practitioner who is the nominee of the registered medical practitioner or approved clinician (who is not a registered medical practitioner)",
                },
                {
                  key: 4,
                  testId: "h1-iam-option-4",
                  value:
                    "an approved clinician (who is not a registered medical practitioner) who is the nominee of the registered medical practitioner or approved clinician (who is not a registered medical practitioner)",
                },
              ]}
              disabled={disabled}
            />
          </Stack>
        </Stack>
      </div>
    </Paper>
  );
};

export default React.memo(YourDetails);
