import React from "react";
import { Paper as MuiPaper } from "@mui/material";
import { useStyles } from "./styles";

interface PaperProps {
  children: React.ReactNode;
}

const Paper: React.FC<PaperProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <MuiPaper elevation={0} classes={{ root: classes.paper }}>
      {children}
    </MuiPaper>
  );
};

export default React.memo(Paper);
