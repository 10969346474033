import React, { useState, useCallback } from "react";

// Material UI Imports
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { WardSignature as WardSignatureType } from "common/types/cto7";

// Custom Imports
import Action from "common/types/action";
import { FormStateActions } from "common/types/cto7";
import { useAuth, useForm } from "hooks";
import SignatureSection from "components/formSections/common/signatureSection";
import Paper from "components/paper";
import { OutputDate } from "components/uiElements";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

interface WardSignatureProps {
  wardSignature: WardSignatureType;
  dispatchFormState: React.Dispatch<Action>;
}
const WardSignature: React.FC<WardSignatureProps> = ({
  wardSignature: { signature, date },
  dispatchFormState,
}) => {
  const { actor } = useAuth();
  const { handleEmail } = useForm();
  const [signedStatus, setSignedStatus] = useState<boolean>(!!signature);

  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed ? actor?.name : "";

      dispatchFormState({
        type: FormStateActions.SET_WARD_SIGNATURE,
        payload: signedValue,
      });
    },
    [dispatchFormState, actor]
  );

  return (
    <Paper>
      <Stack spacing={3} mb={3}>
        <Typography variant="h4" display="inline">
          This report was{" "}
          <span className="italic">
            (Delete the phrases which do not apply)
          </span>
        </Typography>
        <Stack sx={{ paddingLeft: "2rem" }}>
          <Typography variant="h4" className="strikeThrough">
            furnished to the hospital managers through their internal mail
            system.
          </Typography>
          <Typography variant="h4">
            furnished to the hospital managers, or a person authorised by them
            to receive it, by means of electronic communication.
          </Typography>
          <Typography variant="h4" className="strikeThrough">
            received by me on behalf of the hospital managers on
          </Typography>
          <Stack flexDirection="row" alignItems="center">
            <OutputDate iSODate={""} strikeThrough noBackground />
            <Typography
              variant="h4"
              className="strikeThrough italic"
              sx={{ marginLeft: 2 }}
            >
              (date)
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <SignatureSection
        name={signature}
        date={date}
        signedStatus={signedStatus}
        setSignedStatus={handleSetSignedStatus}
      />
      <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
        <Button
          data-test-id="cto7-email-final"
          variant="contained"
          onClick={() => handleEmail({ includePDFAttachment: true })}
          disabled={!signedStatus}
          size="large"
          sx={{ mr: 1 }}
        >
          Send to MHA Admin
        </Button>
        <PdfDownloadButton disabled={!signedStatus} />
      </Stack>
    </Paper>
  );
};

export default React.memo(WardSignature);
