import React from "react";

// Material UI
import { makeStyles } from "@mui/styles";
import { Theme, useTheme } from "@mui/material/styles";
import { Box, Stack, Typography } from "@mui/material";

// Custom imports
import { StrikeThrough } from "components/uiElements/StrikeThrough";

interface ThemeProps {
  strikeThrough: boolean;
}

const useStyles = makeStyles<Theme, ThemeProps>((theme) => ({
  inputContainer: {
    padding: ".6rem",
    width: "12rem",
    backgroundColor: theme.palette.common.white,
  },
  contentBox: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "2.5rem",
    height: "2rem",
  },
}));

interface OutputTimeProps {
  time?: string;
  strikeThrough?: boolean;
  noBackground?: boolean;
}
// You should either send a time string (e.g. 10:26 in 24hr format) or an indication that the date is crossed through
const OutputTime: React.FC<OutputTimeProps> = ({
  time,
  strikeThrough = false,
  noBackground = false,
}) => {
  const theme = useTheme();
  const classes = useStyles({ strikeThrough });
  return (
    <Box className={classes.inputContainer}>
      <Stack
        spacing={1}
        direction="row"
        style={{ position: "relative" }}
        justifyContent="space-between"
        alignItems="center"
      >
        {strikeThrough && <StrikeThrough width={20} />}
        <Box
          className={classes.contentBox}
          sx={{
            backgroundColor: noBackground ? undefined : theme.palette.grey.A200,
          }}
        >
          {time && <Typography variant="h4">{time.substring(0, 2)}</Typography>}
        </Box>
        <Box>:</Box>
        <Box
          className={classes.contentBox}
          sx={{
            backgroundColor: noBackground ? undefined : theme.palette.grey.A200,
          }}
        >
          {time && <Typography variant="h4">{time.substring(3, 5)}</Typography>}
        </Box>
      </Stack>
    </Box>
  );
};

export { OutputTime };
