import React from "react";
import { View } from "@react-pdf/renderer";
import { PDFSubHeader } from "../formComponents/baseComponents/PDFSubHeader";
import {
  StyledDateOrTime,
  DateOrTime,
} from "../formComponents/StyledDateOrTime";
import { StyledInformationBox } from "../formComponents/StyledInformationBox";
import { StyledText } from "../formComponents/StyledText";
import { StyledCheckbox } from "../formComponents/StyledCheckbox";
import { PDFSignatures } from "../formComponents/baseComponents/PDFSignatures";
import { CtoFor } from "common/types/cto";
import { CTO7Form } from "common/types/cto7";
import { formatNameAndAddressText } from "utils/pdf";

/**
 * CTO7Form
 * @param {CTO7Form} CTO7Form
 * @returns {JSX.Element}
 */
export const CTO7Body: React.FC<CTO7Form> = ({
  hospitalDetails,
  clinicianDetails,
  patientDetails,
  opinion,
  amhpDetails,
  furnishingReport,
  wardSignature,
}) => {
  // Hospital Details
  const hospitalNameAndAddress = formatNameAndAddressText(
    hospitalDetails.name,
    hospitalDetails.address,
    hospitalDetails.manualAddress1,
    hospitalDetails.manualAddress2,
    hospitalDetails.manualPostcode
  );

  // Clinician Details
  const clinicianNameAndAddress = formatNameAndAddressText(
    clinicianDetails.name,
    clinicianDetails.address,
    clinicianDetails.manualAddress1,
    clinicianDetails.manualAddress2,
    clinicianDetails.manualPostcode,
    clinicianDetails.email
  );

  // Patient Details
  const patientNameAndAddress = formatNameAndAddressText(
    patientDetails.name,
    patientDetails.address,
    patientDetails.manualAddress1,
    patientDetails.manualAddress2,
    patientDetails.manualPostcode
  );

  // Amhp Details
  const amhpNameAndAddress = formatNameAndAddressText(
    amhpDetails.name,
    amhpDetails.address,
    amhpDetails.manualAddress1,
    amhpDetails.manualAddress2,
    amhpDetails.manualPostcode,
    amhpDetails.email
  );

  return (
    <View>
      {/* Part 1 -> Hospital Details */}
      <PDFSubHeader
        text="Parts 1 and 3 of this form are to be completed by the responsible clinician and Part 2 by an approved mental health professional. Part 4 is to be completed by or on behalf of the managers of the responsible hospital. "
        style={{ maxWidth: "97%", margin: "9px auto 18px" }}
        fontStyle={{ fontSize: 11.5 }}
      />
      <PDFSubHeader subsubheading="PART 1" />
      <StyledText
        instruction="To the managers of"
        noMargin
        style={{ marginBottom: 3 }}
      />
      <StyledText
        instruction=""
        guidance="(name and address of responsible hospital)"
        noMargin
      />
      <StyledInformationBox
        value={hospitalNameAndAddress}
        minHeight={50}
        style={{ margin: 0 }}
      />

      <StyledText
        instruction="I am"
        guidance="(PRINT full name, address and, if sending by means of electronic communication, email address of the responsible clinician)"
        noMargin
        style={{ fontSize: 10.5, maxWidth: "76%" }}
      />
      <StyledInformationBox value={clinicianNameAndAddress} minHeight={50} />
      <StyledText
        instruction="the responsible clinician for"
        noMargin
        style={{ marginBottom: 6, marginTop: 2 }}
      />

      <StyledText
        instruction=""
        guidance="(PRINT full name and address of patient)"
        noMargin
        style={{ fontSize: 12 }}
      />
      <StyledInformationBox
        value={patientNameAndAddress}
        minHeight={50}
        style={{ margin: 0 }}
      />

      <StyledText
        instruction="The patient is currently subject to a community treatment order made on"
        guidance="(enter date)"
        style={{ fontSize: 12 }}
      />

      <StyledDateOrTime
        dateOrTime={DateOrTime.date}
        value={patientDetails.ctoCreated}
        empty={!!!patientDetails.ctoCreated}
        label=""
      />
      <StyledText
        instruction="I examined the patient on"
        noMargin
        style={{ fontSize: 12 }}
      />

      <StyledDateOrTime
        dateOrTime={DateOrTime.date}
        value={patientDetails.examined}
        label="(date)"
        empty={!!!patientDetails.examined}
      />

      {/* Opinion */}
      <StyledText
        instruction="In my opinion,"
        style={{ marginTop: 6, marginBottom: 0 }}
      />
      <View style={{ marginLeft: 20 }}>
        <StyledCheckbox
          itemisation="Alphabetical"
          itemPadding={10}
          display
          options={[
            {
              component: (
                <View style={{ maxWidth: "86%" }}>
                  <StyledText
                    instruction="this patient is suffering from mental disorder of a nature
                    or degree which makes it appropriate for the patient to
                    receive medical treatment in a hospital;"
                    noMargin
                  />
                </View>
              ),
            },
            {
              component: (
                <View>
                  <StyledText
                    instruction="it is necessary for"
                    noMargin
                    style={{ marginBottom: 8 }}
                  />
                  <StyledCheckbox
                    value={opinion.for}
                    itemisation="Roman"
                    itemPadding={6}
                    noMargin
                    options={[
                      {
                        value: CtoFor.PATIENTS_HEALTH,
                        label: "the patient's own health",
                      },
                      {
                        value: CtoFor.PATIENTS_SAFETY,
                        label: "the patient's own safety",
                      },
                      {
                        value: CtoFor.SAFETY_OF_OTHERS,
                        label: "the protection of other persons",
                      },
                    ]}
                  />
                  <StyledText
                    instruction="(delete any indent which is not applicable)"
                    style={{ fontStyle: "italic", marginTop: 4 }}
                  />
                  <StyledText
                    instruction="that the patient should receive such treatment;"
                    style={{ marginTop: 12, marginBottom: 0 }}
                  />
                </View>
              ),
            },
            {
              label:
                "such treatment can be provided without the patient continuing to be detained in a hospital provided the patient is liable to being recalled to hospital for medical treatment;",
            },
            {
              component: (
                <View style={{ maxWidth: "92%" }}>
                  <StyledText
                    instruction="it is necessary that the responsible clinician should continue to be able to exercise the power under section 17E(1) to recall the patient to hospital;"
                    noMargin
                  />
                </View>
              ),
            },
            {
              component: (
                <View style={{ maxWidth: "94%" }}>
                  <StyledText
                    instruction="taking into account the nature and degree of the mental disorder from which the patient is suffering and all other circumstances of the case, appropriate medical treatment is available to the patient."
                    noMargin
                  />
                </View>
              ),
            },
          ]}
        />
      </View>
      <StyledText instruction="My opinion is founded on the following grounds— " />
      <StyledInformationBox
        value={opinion.details}
        minHeight={420}
        style={{ margin: 0, marginTop: 4 }}
      />
      <StyledText
        instruction="I confirm that in determining whether the criterion at (d) above is met, I have considered what risk there would be of deterioration of the patient’s condition if the patient were to continue not to be detained in hospital, with regard to the patient’s history of mental disorder and any other relevant factors."
        style={{ maxWidth: "96%" }}
      />
      <PDFSignatures
        signed={opinion.signature}
        date={opinion.date}
        sha256={opinion.trackingId}
        after={<StyledText instruction="Responsible clinician" noMargin />}
      />
      {/* AMHP Details */}
      <PDFSubHeader subsubheading="PART 2" style={{ margin: "6px 0" }} />
      <StyledText
        instruction="I"
        guidance="(PRINT full name, address and, if sending by means of electronic communication, email address)"
        style={{ fontSize: 12 }}
      />
      <StyledInformationBox value={amhpNameAndAddress} minHeight={50} />
      <StyledText
        instruction="am acting on behalf of"
        guidance="(name of local social services authority)"
        style={{ margin: "4px 0px 0px" }}
      />
      <StyledInformationBox value={amhpDetails.authorityName} minHeight={40} />
      <StyledText
        instruction="and am approved to act as an approved mental health professional for the purposes of the"
        noMargin
      />
      <StyledText
        instruction="Act by, "
        guidance="(delete as appropriate)"
        noMargin
        style={{ marginBottom: 6 }}
      />
      <StyledCheckbox
        value={
          typeof amhpDetails.otherAuthority === "undefined" &&
          !amhpDetails.onBehalfOf
            ? undefined // user hasn't filled out yet (initial state)
            : typeof amhpDetails.otherAuthority === "undefined" &&
              amhpDetails.onBehalfOf // user has filled out without altering initial state
            ? [true]
            : [amhpDetails.otherAuthority]
        }
        itemPadding={3}
        noMargin
        options={[
          {
            value: false,
            label: "that authority",
          },
          {
            value: true,
            label:
              "(name of local social services authority that approved you, if different).",
            labelStyle: "italic",
          },
        ]}
      />
      <View style={{ paddingLeft: 22, margin: 0 }}>
        <StyledInformationBox
          value={
            typeof amhpDetails.onBehalfOf === "undefined"
              ? ""
              : amhpDetails.onBehalfOf || "N/A"
          }
          minHeight={40}
        />
      </View>
      <StyledText
        instruction="I agree that:"
        noMargin
        style={{ marginTop: 2 }}
      />
      <View
        style={{
          maxWidth: "90%",
          paddingLeft: 20,
          margin: 0,
          marginBottom: 10,
        }}
      >
        <StyledCheckbox
          value={""}
          display
          itemPadding={4}
          noMargin
          itemisation="Roman"
          options={[
            {
              label:
                "the patient meets the criteria for the extension of the community treatment period and",
            },
            {
              label:
                "it is appropriate to extend the community treatment period.",
            },
          ]}
        />
      </View>
      <PDFSignatures
        signed={amhpDetails.signature}
        date={amhpDetails.date}
        sha256={amhpDetails.trackingId}
        after={
          <StyledText
            instruction="Approved mental health professional"
            noMargin
          />
        }
        noMargin
      />
      {/* Part 3 */}
      <PDFSubHeader subsubheading="PART 3" style={{ margin: "4px 0 6px" }} />
      <StyledText
        instruction="Before furnishing this report, I consulted"
        noMargin
        style={{ marginBottom: 2 }}
      />
      <StyledText
        instruction=""
        guidance="(PRINT full name and profession of person consulted)"
        noMargin
      />
      <StyledInformationBox
        value={`${furnishingReport.consultedName}\n${furnishingReport.consultedProfession}`}
        minHeight={40}
      />
      <StyledText
        instruction="who has been professionally concerned with the patient's treatment."
        noMargin
        style={{ marginBottom: 6 }}
      />
      <StyledText
        instruction="I am furnishing this report by:"
        guidance="(Delete the phrase which does not apply)"
        noMargin
      />
      <View style={{ paddingLeft: 20, marginBottom: 10 }}>
        <StyledText
          instruction="today consigning it to the hospital managers' internal mail system."
          noMargin
          disabled
        />
        <StyledText
          instruction="today sending it to the hospital managers, or a person authorised by them to receive it,"
          noMargin
        />
        <StyledText
          instruction="by means of electronic communication."
          noMargin
        />
        <StyledText
          instruction="sending or delivering it without using the hospital managers' internal mail system."
          noMargin
          disabled
        />
      </View>
      <PDFSignatures
        signed={furnishingReport.signature}
        date={furnishingReport.date}
        sha256={furnishingReport.trackingId}
        after={<StyledText instruction="Responsible clinician" noMargin />}
        noMargin
      />
      <PDFSubHeader
        subsubheading="THIS REPORT IS NOT VALID UNLESS PARTS 1, 2 & 3 ARE COMPLETED AND SIGNED"
        fontStyle={{ fontSize: 12 }}
        style={{ margin: "10px auto 0", maxWidth: "98%" }}
      />
      {/* Part 4 */}
      <PDFSubHeader subsubheading="PART 4" pageBreak />
      <StyledText
        instruction="This report was"
        guidance="(Delete the phrase which does not apply)"
        style={{ marginBottom: 6 }}
      />
      <View style={{ paddingLeft: 22 }}>
        <StyledText
          instruction="furnished to the hospital managers through their internal mail system."
          disabled
          noMargin
        />
        <StyledText
          instruction="furnished to the hospital managers, or a person authorised by them to receive it,"
          noMargin
        />
        <StyledText
          instruction="by means of electronic communication."
          noMargin
        />
        <StyledText
          instruction="received by me on behalf of the hospital managers on"
          disabled
          noMargin
        />
        <StyledDateOrTime
          dateOrTime={DateOrTime.date}
          value={null}
          label="(date)"
          strikethrough
        />
      </View>
      <PDFSignatures
        signed={wardSignature.signature}
        date={wardSignature.date}
        sha256={wardSignature.trackingId}
        withPrintName
        onBehalfOf="the managers of the responsible hospital"
      />
    </View>
  );
};
