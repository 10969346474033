import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import { RouteConstants } from "common/constants/routes";
import { ReviewingType } from "store/ui/uiContext";
import { usePDF } from "@react-pdf/renderer";
import { useDeviceType, useUI } from "hooks";
import { Typography } from "@mui/material";

/**
 * PDFPreviewDrawer
 * @returns {React.Element}
 */
export default function PDFPreviewDrawer() {
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();
  const { reviewing, clearReviewing } = useUI();

  const { document, type, disableSign, callback } = reviewing || {};

  const [instance, updateInstance] = usePDF({
    document: document as React.ReactElement,
  });

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    clearReviewing();
  };

  const handleContinue = () => {
    callback?.();
    clearReviewing();
  };

  useEffect(() => {
    if (!reviewing) return;

    updateInstance();
  }, [reviewing, updateInstance]);

  const loading: boolean = instance.loading;

  if (!reviewing) return <></>;

  return (
    <Drawer anchor="bottom" open={!!reviewing} onClose={toggleDrawer}>
      <Box
        height={isMobile ? "100vh" : "85vh"}
        display="flex"
        flexDirection="column"
        onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
      >
        <iframe
          src={`${instance.url}#toolbar=0&navpanes=0` || undefined}
          style={{ width: "100%", height: "100vh" }}
          title="StatForm_Preview"
        />
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
            padding: 0.5,
          }}
        >
          {type === ReviewingType.EMAIL_LINK ? (
            <>
              <Button
                data-test-id="pdfReview-sign"
                endIcon={<ArrowForwardIcon />}
                color="primary"
                variant="contained"
                onClick={handleContinue}
                style={{ width: 170, margin: 5 }}
                disabled={loading}
              >
                Continue
              </Button>
              <Button
                data-test-id="pdfReview-close"
                endIcon={<CloseIcon />}
                color="primary"
                variant="outlined"
                onClick={(e: React.MouseEvent) => {
                  toggleDrawer(e);
                  navigate(RouteConstants.DASHBOARD);
                }}
                style={{ width: 150, margin: 5 }}
                disabled={loading}
              >
                Close
              </Button>
            </>
          ) : type === ReviewingType.SIGNATURE ? (
            <>
              {disableSign && (
                <Box display="flex" flexDirection="row" alignItems="center">
                  <ReportProblemIcon color="error" />
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ ml: 1, mr: 2 }}
                  >
                    Please complete all required fields
                  </Typography>
                </Box>
              )}
              <Button
                data-test-id="pdfReview-sign"
                endIcon={<CheckCircleIcon />}
                color="primary"
                variant="contained"
                onClick={handleContinue}
                style={{ width: 170, margin: 5 }}
                disabled={loading || disableSign}
              >
                Sign
              </Button>
              <Button
                data-test-id="pdfReview-close"
                endIcon={<CloseIcon />}
                color="primary"
                variant="outlined"
                onClick={toggleDrawer}
                style={{ width: 150, margin: 5 }}
                disabled={loading}
              >
                Close
              </Button>
            </>
          ) : type === ReviewingType.VIEW ? (
            <Button
              data-test-id="pdfReview-close"
              endIcon={<CloseIcon />}
              color="primary"
              variant="outlined"
              onClick={toggleDrawer}
              style={{ width: 150, margin: 5 }}
              disabled={loading}
            >
              Close
            </Button>
          ) : (
            <></>
          )}
        </Container>
      </Box>
    </Drawer>
  );
}
