import React, { Fragment } from "react";
import { Text, View } from "@react-pdf/renderer";
import { getItemisation } from "common/libs/getItemisation";

/**
 * StyledCheckbox
 * A set of text values that may or may now be crossed out depending on a selected value
 * NB: this may not be needed but will keep for now. Needs thought and tidying up if ever used,
 * but has a lot of overlap with RadioOptionsList, just simplified.
 * @param {any[]} options - The list of values
 * @param {any} value - The chosen value
 * @param {any} [itemisation] - Any itemisation styling
 * @returns {JSX.Element}
 */

export const StyledCheckbox = ({
  options,
  value,
  itemisation,
  noMargin,
  itemPadding,
  display,
}: {
  options: any[];
  value?: any | Array<any>;
  itemisation?: any;
  noMargin?: boolean;
  itemPadding?: number | string;
  display?: boolean;
}) => {
  return (
    <View
      style={{
        marginTop: !noMargin ? 10 : 0,
      }}
    >
      {options.map((option, i: number) => {
        return (
          <Fragment key={i}>
            <View
              wrap
              style={[
                {
                  textDecoration:
                    display ||
                    value?.includes?.(option?.value) ||
                    typeof value === "undefined" ||
                    (Array.isArray(value) && value.length === 0)
                      ? "none"
                      : "line-through",
                  flexDirection: "row",
                  marginVertical:
                    typeof itemPadding === "number" ? itemPadding : undefined,
                  margin:
                    typeof itemPadding !== "number" ? itemPadding : undefined,
                },
              ]}
            >
              {(!!option.prefix || itemisation) && (
                <Text
                  wrap
                  style={{
                    minWidth: 16,
                    marginRight: 3,
                  }}
                >
                  {option.prefix ||
                    (itemisation && getItemisation(itemisation, i)) ||
                    ""}
                </Text>
              )}
              {option?.component ||
                (option.label && (
                  <Text style={{ fontStyle: option?.labelStyle || undefined }}>
                    {itemisation && itemisation === "Bullets" && "\u2022 "}
                    {option.label}
                  </Text>
                ))}
            </View>
            {option.after && <View>{option.after}</View>}
          </Fragment>
        );
      })}
    </View>
  );
};
