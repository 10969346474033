import { H3Form } from "common/types/h3";
import { formatInitialFormState } from "../helpers";

export const data: H3Form = {
  hospitalDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
  },
  patientDetails: {
    name: "",
  },
  admissionDetails: {
    inPatient: false,
    admissionDate: "",
    admissionTime: "",
    section: "",
    signaturePart1: "",
    trackingIdPart1: "",
    namePart1: "",
    signatureDatePart1: null,
    admissionDatePart2: null,
    admissionTimePart2: null,
    signaturePart2: "",
    trackingIdPart2: "",
    namePart2: "",
    signatureDatePart2: null,
  },
};

export const h3InitialState = formatInitialFormState(data);
