import {
  AddressType,
  PatientDetails,
  ClinicianDetailsType,
} from "common/types/statForms";
import {
  AMHPDetails as GenericAMHPDetails,
  CTODetails,
  Opinion,
} from "common/types/cto";
import { FormPDFConfigData } from "./pdf";

export type HospitalDetails = AddressType & {
  name: string;
};

export type AMHPDetails = GenericAMHPDetails & {
  email: string;
};

export interface CTO5Form {
  clinicianDetails: ClinicianDetailsType;
  patientDetails: PatientDetails;
  hospitalDetails: HospitalDetails;
  opinion: Opinion;
  amhpDetails: AMHPDetails;
  ctoDetails: CTODetails;
}

export const pdfConfig: FormPDFConfigData = {
  fontSize: 11,
  longTitle: "Section 17F(4) - revocation of community treatment order",
  regulation: "6(8)(a) and (b)",
  footerText: "Form amended by SI 2020/1072 for use from 1 December 2020",
  enablePagination: true,
  pagePadding: "12px 22px 2px",
};

export enum FormStateActions {
  LOAD_FORM = "LOAD_FORM",
  SET_CLINICIAN_NAME = "SET_CLINICIAN_NAME",
  SET_CLINICIAN_ADDRESS = "SET_CLINICIAN_ADDRESS",
  SET_CLINICIAN_EMAIL = "SET_CLINICIAN_EMAIL",
  SET_PATIENT_NAME = "SET_PATIENT_NAME",
  SET_PATIENT_ADDRESS = "SET_PATIENT_ADDRESS",
  SET_HOSPITAL = "SET_HOSPITAL",
  SET_OPINION_FOR = "SET_OPINION_FOR",
  SET_OPINION_DETAILS = "SET_OPINION_DETAILS",
  SET_OPINION_SIGNATURE = "SET_OPINION_SIGNATURE",
  SET_AMHP_NAME = "SET_AMHP_NAME",
  SET_AMHP_ADDRESS = "SET_AMHP_ADDRESS",
  SET_AMHP_EMAIL = "SET_AMHP_EMAIL",
  SET_AMHP_AUTHORITY_NAME = "SET_AMHP_AUTHORITY_NAME",
  SET_AMHP_OTHER_AUTHORITY = "SET_AMHP_OTHER_AUTHORITY",
  SET_AMHP_ON_BEHALF_OF = "SET_AMHP_ON_BEHALF_OF",
  SET_AMHP_SIGNATURE = "SET_AMHP_SIGNATURE",
  SET_CTO_DATE = "SET_CTO_DATE",
  SET_CTO_TIME = "SET_CTO_TIME",
  SET_CTO_SIGNATURE = "SET_CTO_SIGNATURE",
}
