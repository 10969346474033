import React from "react";

// Material UI
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Custom imports
import Paper from "components/paper";
import { StyledInput } from "components/uiElements";
import HospitalDetails from "components/formSections/common/hospitalDetails";
import Action from "common/types/action";
import { HospitalDetailsType } from "common/types/statForms";
import { PatientDetails as PatientDetailsType } from "common/types/cto3";
import { FormStateActions } from "common/types/cto3";

export interface PatientDetailsProps {
  testIdKey: string;
  patientDetails: PatientDetailsType;
  hospitalDetails: HospitalDetailsType;
  dispatchFormState: React.Dispatch<Action>;
  disabled: boolean;
}

const PatientDetails: React.FC<PatientDetailsProps> = ({
  testIdKey,
  patientDetails: { name: patientName },
  hospitalDetails: {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
  },
  dispatchFormState,
  disabled,
}) => (
  <Paper>
    <Stack spacing={3}>
      <Stack spacing={2}>
        {/* Name */}
        <Typography variant="h4" display="inline">
          I notify you,
        </Typography>
        <Typography variant="h4" display="inline" className="italic">
          (PRINT name of community patient),
        </Typography>
        <StyledInput
          testId={`${testIdKey}-name`}
          label="Patient Name"
          variant="outlined"
          value={patientName}
          errorKey="section1.patientDetails.name"
          onChange={(value: any) =>
            dispatchFormState({
              type: FormStateActions.SET_PATIENT_NAME,
              payload: value,
            })
          }
          disabled={disabled}
        />
        {/* Address */}
        <HospitalDetails
          testIdKey={testIdKey}
          label="that you are recalled to"
          guidance="(PRINT full name and address of the hospital)"
          name={name}
          address={address}
          manualAddress1={manualAddress1}
          manualAddress2={manualAddress2}
          manualPostcode={manualPostcode}
          setHospital={(value: any) =>
            dispatchFormState({
              type: FormStateActions.SET_HOSPITAL,
              payload: value,
            })
          }
          disabled={disabled}
          errorKey="section1.hospitalDetails"
        />
        <Typography variant="h4" display="inline">
          under section 17E of the Mental Health Act 1983.
        </Typography>
      </Stack>
    </Stack>
  </Paper>
);

export default React.memo(PatientDetails);
