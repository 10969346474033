import React from "react";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";

interface SideMenuItemProps {
  testId?: string;
  href?: string;
  onClick?: () => void;
  icon: JSX.Element;
  children: any; // will probably just be text
  secondary?: string;
  selected?: boolean;
  disabled?: boolean;
}

const SideMenuItem: React.FC<SideMenuItemProps> = ({
  testId,
  href,
  onClick,
  icon,
  selected,
  secondary,
  disabled,
  children,
}) => {
  return (
    <ListItemButton
      data-test-id={testId}
      href={href}
      onClick={onClick}
      component={Link}
      selected={selected}
      disabled={disabled}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={children} {...(secondary ? { secondary } : {})} />
    </ListItemButton>
  );
};

export default React.memo(SideMenuItem);
