import { FormType } from "common/types/pdf";

type FormTypeToHeadings = {
  [key in FormType]: string;
};
const formTypeToHeadings: FormTypeToHeadings = {
  [FormType.H1]: "H1",
  [FormType.H3]: "H3",
  [FormType.CTO1]: "CTO1",
  [FormType.CTO3]: "CTO3",
  [FormType.CTO4]: "CTO4",
  [FormType.CTO5]: "CTO5",
  [FormType.CTO7]: "CTO7",
  [FormType.CTO12]: "CTO12",
  [FormType.T2]: "T2",
};

export const getHeading = (formType: FormType) => formTypeToHeadings[formType];
