import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import GenericClinicianDetails, {
  ClinicianDetailsProps,
} from "components/formSections/common/clinicianDetails";
import Paper from "components/paper";
import { StyledRadioGroup } from "components/uiElements";
import { FormStateActions, ClinicianDetailsType } from "common/types/t2";
import { useForm } from "hooks";

const ClinicianDetails: React.FC<
  ClinicianDetailsProps<ClinicianDetailsType>
> = ({
  testIdKey,
  clinicianDetails,
  clinicianDetails: { approvedClinician },
  dispatchFormState,
  disabled,
}) => {
  const { formErrors } = useForm();

  return (
    <>
      <GenericClinicianDetails
        testIdKey={testIdKey}
        clinicianDetails={clinicianDetails}
        dispatchFormState={dispatchFormState}
        disabled={disabled}
      />
      <Paper>
        <Stack spacing={3} mt={3}>
          <Typography variant="h4">Select your role</Typography>
          <StyledRadioGroup
            label=""
            value={approvedClinician}
            items={[
              {
                testId: `${testIdKey}-role-1`,
                key: true,
                value:
                  "the approved clinician in charge of the treatment described below ",
              },
              {
                testId: `${testIdKey}-role-2`,
                key: false,
                value:
                  "a registered medical practitioner appointed for the purposes of Part 4 of the Act (a SOAD) ",
              },
            ]}
            onChange={(e: any) =>
              dispatchFormState({
                type: FormStateActions.SET_CLINICIAN_ROLE,
                payload: e.target.value,
              })
            }
            error={!!formErrors?.["clinicianDetails.approvedClinician"]}
            errorText={formErrors?.["clinicianDetails.approvedClinician"]}
          />
        </Stack>
      </Paper>
    </>
  );
};

export default React.memo(ClinicianDetails);
