import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { PDFSubHeader } from "../formComponents/baseComponents/PDFSubHeader";
import { StyledInformationBox } from "../formComponents/StyledInformationBox";
import { StyledText } from "../formComponents/StyledText";
import { PDFSignatures } from "../formComponents/baseComponents/PDFSignatures";
import { CTO12Form } from "common/types/cto12";
import { formatNameAndAddressText } from "utils/pdf";
import { PDFHeader } from "../formComponents/baseComponents/PDFHeader";
import { FormType } from "common/types/pdf";

/**
 * CTO12Form
 * @param {CTO12Form} CTO12Form
 * @returns {JSX.Element}
 */
export const CTO12Body: React.FC<CTO12Form> = ({
  clinicianDetails,
  patientDetails,
  patientDetails: { capacity, description },
  clinicianSignature: { signature, date, trackingId },
}) => {
  // Clinician Details
  const clinicianNameAndAddress = formatNameAndAddressText(
    clinicianDetails.name,
    clinicianDetails.address,
    clinicianDetails.manualAddress1,
    clinicianDetails.manualAddress2,
    clinicianDetails.manualPostcode,
    clinicianDetails.email
  );

  // Patient Details
  const patientNameAndAddress = formatNameAndAddressText(
    patientDetails.name,
    patientDetails.address,
    patientDetails.manualAddress1,
    patientDetails.manualAddress2,
    patientDetails.manualPostcode
  );

  const { regulation, longTitle, longTitleFontSize, longTitleCenter } = {
    regulation: "28(1A)",
    longTitle: `Section 64C(4A) – Certificate that community patient has capacity to consent
    (or if under 16 is competent to consent) to treatment and has done so
    (Part 4A consent certificate)`,
    longTitleFontSize: 11,
    longTitleCenter: true,
  };

  return (
    <View
      style={{
        backgroundColor: "#FFEAF0",
        margin: 40,
        marginBottom: 80,
        padding: 15,
      }}
    >
      <PDFHeader
        form={FormType.CTO12}
        regulation={regulation}
        longTitle={longTitle}
        longTitleFontSize={longTitleFontSize}
        longTitleCenter={longTitleCenter}
      />
      <PDFSubHeader
        text="(To be completed on behalf of the responsible hospital) "
        fontStyle={{ fontSize: 11.5 }}
      />
      <StyledText
        instruction="I"
        guidance="(PRINT full name, address and, if sending by means of electronic communication, email address)"
        style={{ fontSize: 9.5 }}
      />
      <StyledInformationBox value={clinicianNameAndAddress} minHeight={50} />
      <StyledText
        instruction="am the approved clinician in charge of the treatment of"
        noMargin
        style={{ marginBottom: 4 }}
      />
      <StyledText
        instruction=""
        guidance="(PRINT full name and address of patient)"
        noMargin
      />
      <StyledInformationBox
        value={patientNameAndAddress}
        minHeight={50}
        style={{ margin: 0 }}
      />
      <StyledText
        instruction="who is subject to a community treatment order."
        noMargin
        style={{ marginBottom: 6 }}
      />

      <Text>
        I certify that this patient{" "}
        <Text
          style={{
            textDecoration:
              capacity !== "HAS_CAPACITY" ? "line-through" : undefined,
          }}
        >
          has the capacity
        </Text>
        /
        <Text
          style={{
            textDecoration:
              capacity !== "IS_COMPETENT" ? "line-through" : undefined,
          }}
        >
          is competent
        </Text>{" "}
        to consent <Text style={{ fontStyle: "italic" }}>(delete the one</Text>
      </Text>
      <Text>
        <Text style={{ fontStyle: "italic" }}>that is not appropriate)</Text>{" "}
        and has consented to the following treatment. The
      </Text>
      <Text>treatment is:</Text>
      <StyledText
        instruction=""
        guidance="(Give description of treatment or plan of treatment.)"
        noMargin
      />
      <StyledInformationBox
        value={description}
        minHeight={200}
        style={{ marginBottom: 10 }}
      />
      <PDFSignatures
        signed={signature}
        date={date}
        sha256={trackingId}
        noMargin
      />
    </View>
  );
};
