import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { RadioOptionsList } from "../formComponents/RadioOptionsList";

interface PractitionerOrClinicianProps {
  /**
   * @param {boolean} isNominee is optional here so typescript doesn't throw errors
   * as the initial value is undefined in the form. At this point it will be true or false.
   */
  isNominee?: boolean;
  /**
   * @param {boolean} isMedicalPractitioner is optional here so typescript doesn't throw errors
   * as the initial value is undefined in the form. At this point it will be true or false.
   */
  isMedicalPractitioner?: boolean;
}

const Container = (props: { children: React.ReactNode }) => (
  <View wrap style={{ maxWidth: "96%", marginBottom: 6 }}>
    {props.children}
  </View>
);

/**
 * The reason this component is written this way is that each bullet can have two different options based on
 * the value of isMedicalPractitioner.
 * @returns {React.ReactNode}
 */
export const PractitionerOrClinician: React.FC<
  PractitionerOrClinicianProps
> = ({ isNominee, isMedicalPractitioner }) => {
  if (!isNominee) {
    return (
      <Container>
        <RadioOptionsList
          value={0}
          options={[
            {
              value: 0,
              prefix: "(a)",
              component: (
                <Text>
                  <Text
                    style={{
                      textDecoration: isMedicalPractitioner
                        ? "none"
                        : "line-through",
                    }}
                  >
                    the registered medical practitioner
                  </Text>
                  <Text>/</Text>
                  <Text
                    style={{
                      textDecoration: !isMedicalPractitioner
                        ? "none"
                        : "line-through",
                    }}
                  >
                    the approved clinician (who is not a registered medical
                    practitioner)
                  </Text>
                  <Text style={{ fontStyle: "italic" }}>
                    {" "}
                    (delete the phrase which does not apply)
                  </Text>
                </Text>
              ),
            },
            {
              value: 1,
              prefix: "(b)",
              label:
                "a registered medical practitioner/an approved clinician (who is not a registered medical practitioner)* who is the nominee of the registered medical practitioner or approved \nclinician (who is not a registered medical practitioner)",
              guidance: "(*delete the phrase which does \nnot apply)",
            },
          ]}
          itemPadding={12}
        />
      </Container>
    );
  } else if (isNominee) {
    return (
      <Container>
        <RadioOptionsList
          value={1}
          options={[
            {
              value: 0,
              prefix: "(a)",
              label:
                "the registered medical practitioner/the approved clinician (who is not a registered medical practitioner)",
              guidance: "(delete the phrase which does not apply)",
            },
            {
              value: 1,
              prefix: "(b)",
              component: (
                <Text>
                  <Text
                    style={{
                      textDecoration: isMedicalPractitioner
                        ? "none"
                        : "line-through",
                    }}
                  >
                    a registered medical practitioner
                  </Text>
                  <Text>/</Text>
                  <Text
                    style={{
                      textDecoration: !isMedicalPractitioner
                        ? "none"
                        : "line-through",
                    }}
                  >
                    an approved clinician (who is not a registered medical
                    practitioner)
                  </Text>
                  <View>
                    <Text>
                      * who is the nominee of the registered medical
                      practitioner or approved {"\n"}clinician (who is not a
                      registered medical practitioner)
                      <Text style={{ fontStyle: "italic" }}>
                        (*delete the phrase which does {"\n"}not apply)
                      </Text>
                    </Text>
                  </View>
                </Text>
              ),
            },
          ]}
          itemPadding={12}
        />
      </Container>
    );
  } else {
    return <></>;
  }
};
