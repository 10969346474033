import React, { useState, useCallback } from "react";

// Material UI
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  Checkbox,
} from "@mui/material";

// Custom imports
import Action from "common/types/action";
import { FormStateActions } from "common/types/cto1";
import { AMHPDetails as AMHPDetailsType } from "common/types/cto";
import { useAuth, useForm } from "hooks";
import { formatUserName } from "common/libs/formatUserName";
import Paper from "components/paper";
import { StyledInput } from "components/uiElements";
import AddressDetails from "components/formSections/common/addressDetails";
import SignatureSection from "components/formSections/common/signatureSection";
import PdfDownloadButton from "components/pdf/pdfDownloadButton";

interface AMHPDetailsProps {
  amhpDetails: AMHPDetailsType;
  dispatchFormState: React.Dispatch<Action>;
  handleEmail: () => void;
}

const AMHPDetails: React.FC<AMHPDetailsProps> = ({
  amhpDetails: {
    name,
    address,
    manualAddress1,
    manualAddress2,
    manualPostcode,
    authorityName,
    otherAuthority,
    onBehalfOf,
    signature,
    trackingId,
    date,
  },
  dispatchFormState,
  handleEmail,
}) => {
  const { user } = useAuth();
  const { handleValidateValue } = useForm();
  const [signedStatus, setSignedStatus] = useState<boolean>(!!signature);

  const handleSetSignedStatus = useCallback(
    (signed: boolean) => {
      // Handle button
      setSignedStatus(signed);

      // Handle signature input value and name input value accordingly
      const signedValue = signed ? formatUserName(user) : "";

      dispatchFormState({
        type: FormStateActions.SET_AMHP_SIGNATURE,
        payload: signedValue,
      });
    },
    [dispatchFormState, user]
  );

  return (
    <Paper>
      <Stack spacing={3} sx={{ marginBottom: 3 }}>
        <Typography variant="h2" style={{ alignSelf: "center" }}>
          PART 2
        </Typography>
        <Typography variant="h4" display="inline">
          I <span className="italic">(PRINT full name and address)</span>
        </Typography>
        <StyledInput
          testId="cto1-amhp-name"
          label="AMHP name"
          variant="outlined"
          value={name}
          errorKey="amhpDetails.name"
          onChange={(value: any) =>
            dispatchFormState({
              type: FormStateActions.SET_AMHP_NAME,
              payload: value,
            })
          }
          disabled={signedStatus}
        />
        <AddressDetails
          testIdKey="cto1-amhp"
          address={address}
          addressLabel="AMHP address"
          manualAddress1={manualAddress1}
          manualAddress2={manualAddress2}
          manualPostcode={manualPostcode}
          setAddress={(value: any) =>
            dispatchFormState({
              type: FormStateActions.SET_AMHP_ADDRESS,
              payload: value,
            })
          }
          disabled={signedStatus}
          errorKey="amhpDetails.address"
        />
        <Typography variant="h4" display="inline">
          am acting on behalf of{" "}
          <span className="italic">
            (name of local social services authority)
          </span>
        </Typography>
        <StyledInput
          testId="cto1-amhp-authority-name"
          label="Local Social Services Authority Name"
          variant="outlined"
          value={authorityName}
          errorKey="amhpDetails.authorityName"
          onChange={(value: any) =>
            dispatchFormState({
              type: FormStateActions.SET_AMHP_AUTHORITY_NAME,
              payload: value,
            })
          }
          disabled={signedStatus}
        />
        <Typography variant="h4" display="inline">
          and am approved to act as an approved mental health professional for
          the purposes of the Act by{" "}
          <span className="italic">(check as appropriate)</span>
        </Typography>
        <Stack spacing={1} style={{ paddingLeft: "2rem", marginTop: 8 }}>
          <FormControl>
            <FormGroup
              onChange={async () => {
                const newValue =
                  typeof otherAuthority === "undefined"
                    ? false
                    : !otherAuthority;

                // Validate
                await handleValidateValue("amhpDetails.onBehalfOf", {
                  otherAuthority: newValue, // invert as it hasn't been updated yet
                  onBehalfOf,
                });

                dispatchFormState({
                  type: FormStateActions.SET_AMHP_OTHER_AUTHORITY,
                  payload: newValue,
                });
              }}
            >
              <FormControlLabel
                value={otherAuthority}
                control={
                  <Checkbox
                    data-test-id="cto1-amhp-other-authority"
                    checked={
                      !otherAuthority && typeof otherAuthority === "boolean"
                    }
                    disabled={signedStatus}
                  />
                }
                label={<Typography variant="h4">that authority</Typography>}
                style={{
                  borderRadius: ".5rem",
                  background: "white",
                  margin: 4,
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 4,
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  width: "100%",
                }}
              />
            </FormGroup>
          </FormControl>
          <Typography variant="h4" className="italic">
            (name of the local social services authority that approved you, if
            different)
          </Typography>
          <Typography variant="h4" className="italic"></Typography>
          <StyledInput
            testId="cto1-amhp-other-authority-name"
            label="Approved by Local Social Services Authority Name"
            variant="outlined"
            value={onBehalfOf}
            errorKey="amhpDetails.onBehalfOf"
            validateWith={{ otherAuthority }}
            onChange={(value: any) =>
              dispatchFormState({
                type: FormStateActions.SET_AMHP_ON_BEHALF_OF,
                payload: value,
              })
            }
            disabled={
              signedStatus ||
              // NOTE: Shouldn't be disabled for undefined (initial state)
              (!otherAuthority && typeof otherAuthority === "boolean")
            }
          />
        </Stack>
        <Typography variant="h4" display="inline">
          I agree that:
        </Typography>
        <Stack spacing={2} sx={{ paddingLeft: "1rem" }}>
          <Typography variant="h4" display="inline">
            (i) the above patient meets the criteria for a community treatment
            order to be made
          </Typography>
          <Typography variant="h4" display="inline">
            (ii) it is appropriate to make a community treatment order, and
          </Typography>
          <Typography variant="h4" display="inline">
            (iii) the conditions made above under section 17B(2) are necessary
            or appropriate for one or more of the purposes specified.
          </Typography>
        </Stack>
      </Stack>
      <SignatureSection
        name={signature}
        date={date}
        description="Approved mental health professional"
        signedStatus={signedStatus}
        setSignedStatus={handleSetSignedStatus}
      />
      <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
        <Button
          data-test-id="cto1-email-back"
          variant="contained"
          onClick={handleEmail}
          disabled={!signedStatus}
          size="large"
          sx={{ mr: 1 }}
        >
          Send back to Responsible Clinician
        </Button>
        <PdfDownloadButton disabled={!signedStatus} />
      </Stack>
    </Paper>
  );
};

export default React.memo(AMHPDetails);
