import dayjs from "dayjs";
import { CommonStateActions, FormState } from "common/types/statForms";
import { H1Form, FormStateActions } from "common/types/h1";
import Action from "common/types/action";
import { signedFormHashed } from "common/libs/forms";
import { h1InitialState } from "../initialState";

export const h1Reducer = (state: FormState<H1Form>, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case CommonStateActions.RESET_FORM:
      return { ...h1InitialState };
    case CommonStateActions.LOAD_FORM:
      return {
        ...state,
        ...payload,
      };
    case CommonStateActions.SET_INITIAL_DATA:
      return {
        ...state,
        initialData: state.data,
      };
    case FormStateActions.SET_HOSPITAL:
      return {
        ...state,
        data: {
          ...state.data,
          hospitalDetails: payload,
        },
      };
    case FormStateActions.SET_PRACTITIONER_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          yourDetails: {
            ...state.data.yourDetails,
            practitionerName: payload,
          },
        },
      };
    case FormStateActions.SET_IS_NOMINEE:
      return {
        ...state,
        data: {
          ...state.data,
          yourDetails: {
            ...state.data.yourDetails,
            isNominee: payload,
          },
        },
      };
    case FormStateActions.SET_IS_MEDICAL_PRACTITIONER:
      return {
        ...state,
        data: {
          ...state.data,
          yourDetails: {
            ...state.data.yourDetails,
            isMedicalPractitioner: payload,
          },
        },
      };
    case FormStateActions.SET_PATIENT_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          patientDetails: {
            ...state.data.patientDetails,
            name: payload,
          },
        },
      };
    case FormStateActions.SET_DETAILED_REASONS:
      return {
        ...state,
        data: {
          ...state.data,
          patientDetails: {
            ...state.data.patientDetails,
            detailedReasons: payload,
          },
        },
      };
    case FormStateActions.SET_PRACTITIONER_SIGNATURE:
      return {
        ...state,
        data: {
          ...state.data,
          furnishingReport: {
            ...state.data.furnishingReport,
            signature: payload,
            date: payload ? dayjs().toISOString() : "",
            trackingId: payload ? signedFormHashed(state.data) : "",
          },
        },
      };
    case FormStateActions.SET_WARD_SIGNATURE:
      return {
        ...state,
        data: {
          ...state.data,
          wardSignature: {
            ...state.data.wardSignature,
            signature: payload,
            date: payload ? dayjs().toISOString() : "",
            trackingId: payload ? signedFormHashed(state.data) : "",
          },
        },
      };
    default:
      return state;
  }
};
