import { FC, memo, ReactNode, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Chip, Container, Grid, Typography } from "@mui/material";
import {
  FormPartSelector,
  FormPartSelectorProps,
  FormNavButtons,
  FormNavButtonsProps,
} from "components/uiElements";
import { useDeviceType, useUI } from "hooks";
import { useStyles } from "common/styles/form";

interface FormContainerProps {
  sections: ReactNode[];
  formPartSelectorProps: FormPartSelectorProps;
  formNavButtonProps: FormNavButtonsProps;
}

const getSectionText = (
  formPartSelectorProps: FormPartSelectorProps,
  currentSectionIndex: number
): string =>
  formPartSelectorProps.formItems[currentSectionIndex].itemText || "";

const FormContainer: FC<FormContainerProps> = ({
  sections,
  formPartSelectorProps,
  formNavButtonProps,
}) => {
  const navigate = useNavigate();
  const { formType } = useParams();
  const classes = useStyles();
  const { isMobile, isTablet } = useDeviceType();
  const { currentSectionIndex } = useUI();

  /**
   * handleBackButton useEffect
   * this is required as the url changes from navigating between sections
   * create records in the browser history. So instead of the browser back button
   * acting like another previous button, we will navigate them to the dashboard.
   */
  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      event.preventDefault();
      navigate("/");
    };

    window.onpopstate = handleBackButton;

    return () => {
      window.onpopstate = null;
    };
  }, [navigate]);

  return (
    <Container maxWidth="lg">
      <div className={classes.root}>
        <Grid container spacing={2}>
          {isMobile || isTablet ? (
            <Grid
              item
              xs={12}
              display="flex"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Chip
                label={formType?.toUpperCase()}
                color="primary"
                variant="outlined"
                size="small"
              />
              <Typography variant="h4" fontWeight={400} component="div">
                {getSectionText(formPartSelectorProps, currentSectionIndex)}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={3}>
              <div className={classes.formPartSelector}>
                <FormPartSelector {...formPartSelectorProps} />
              </div>
            </Grid>
          )}
          <Grid item xs={12} md={9}>
            {/* Render Sections Here By Index */}
            {sections[currentSectionIndex]}
            <Grid item xs={12} className={classes.buttonContainer}>
              <FormNavButtons {...formNavButtonProps} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default memo(FormContainer);
