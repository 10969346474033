import {
  PatientDetails as GenericPatientDetails,
  HospitalDetailsType as HospitalDetails,
} from "common/types/statForms";
import {
  AMHPDetails as GenericAMHPDetails,
  Opinion as GenericOpinion,
} from "common/types/cto";
import { FormPDFConfigData } from "./pdf";

export enum CtoFor {
  REQUIRES_TREATMENT = "REQUIRES_TREATMENT",
  FAILED_TO_COMPLY = "FAILED_TO_COMPLY",
}

export enum CtoPurpose {
  CONSIDERATION = "CONSIDERATION",
  ENABLE_PART_4A = "ENABLE_PART_4A",
}

export type PatientDetails = Pick<GenericPatientDetails, "name">;

export type AMHPDetails = GenericAMHPDetails & {
  email: string;
};

export type Opinion = GenericOpinion<CtoFor | undefined> & {
  purpose: CtoPurpose[];
};

export interface CTO3Form {
  patientDetails: PatientDetails;
  hospitalDetails: HospitalDetails;
  opinion: Opinion;
}

export const pdfConfig: FormPDFConfigData = {
  longTitle:
    "Section 17E— Community treatment order: notice of recall to hospital",
  regulation: "6(3)(a)",
  pagePadding: "12px 22px 2px",
};

export enum FormStateActions {
  LOAD_FORM = "LOAD_FORM",
  SET_PATIENT_NAME = "SET_PATIENT_NAME",
  SET_HOSPITAL = "SET_HOSPITAL",
  SET_OPINION_FOR = "SET_OPINION_FOR",
  SET_OPINION_DETAILS = "SET_OPINION_DETAILS",
  SET_OPINION_PURPOSE = "SET_OPINION_PURPOSE",
  SET_OPINION_SIGNATURE = "SET_OPINION_SIGNATURE",
}
