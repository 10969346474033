// Main application layout, setting up style, routes and manus
import React from "react";
import * as Sentry from "@sentry/browser";

// Material UI
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Custom Imports
import theme from "theme";
import Layout from "layout";
import { PopupsProvider } from "store/popups/popupsProvider";

import "react-datepicker/dist/react-datepicker.css";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

if (process.env.REACT_APP_SENTRY_KEY) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_KEY });
}

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <PopupsProvider>
            <Layout />
          </PopupsProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default React.memo(App);
