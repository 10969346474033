import React from "react";
import { Tooltip as MuiTooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

interface TooltipProps {
  text: string;
  icon?: React.ReactElement;
}
const Tooltip: React.FC<TooltipProps> = ({ text, icon }) => {
  return (
    <MuiTooltip title={text} placement="right-end">
      {icon ? icon : <InfoIcon />}
    </MuiTooltip>
  );
};

export default React.memo(Tooltip);
