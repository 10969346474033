import { createContext } from "react";

/*
    UI CONTEXT
    - Do a comment
*/
export enum ReviewingType {
  EMAIL_LINK = "EMAIL_LINK",
  SIGNATURE = "SIGNATURE",
  VIEW = "VIEW",
}
export interface Reviewing {
  document: React.ReactElement;
  type: ReviewingType;
  callback?: Function;
  disableSign?: boolean;
}
export type ReviewingParams = Omit<Reviewing, "document">;
export interface UIState {
  formSummaryMode: boolean;
  currentSectionIndex: number;
  reviewing?: Reviewing;
  navigateForward: () => void;
  navigateBack: () => void;
  setCurrentSectionIndex: (state: number) => void;
  setFormSummaryMode: (state: boolean) => void;
  setReviewing: (document: React.ReactElement, params: ReviewingParams) => void;
  clearReviewing: () => void;
}

export const initialState: UIState = {
  formSummaryMode: false,
  currentSectionIndex: 0,
  reviewing: undefined,
  navigateForward: () => {},
  navigateBack: () => {},
  setCurrentSectionIndex: () => {},
  setFormSummaryMode: () => {},
  setReviewing: () => {},
  clearReviewing: () => {},
};

export const UIContext = createContext(initialState);
