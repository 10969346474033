import React from "react";
import { Card } from "@s12solutions/ui";
import Box from "@mui/material/Box";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";

type CustomTableProps = Omit<DataGridProProps, "rows"> & {
  rows?: any[];
  card?: boolean;
  pageSize?: number;
};

/**
 * material-ui-x-data-grid how to use
 * @see https://mui.com/x/api/data-grid/data-grid/
 */
const Table: React.FC<CustomTableProps> = ({
  columns,
  rows = [],
  card,
  autoHeight = true,
  loading,
  pageSize,
  pageSizeOptions = [5, 10, 25, 50],
  ...props
}) => {
  const Wrapper = card ? Card : React.Fragment;
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: pageSize || 10,
    page: 0,
  });

  return (
    <Wrapper>
      <Box sx={{ flexGrow: 1, height: "100%" }}>
        <DataGridPro
          loading={loading}
          autoHeight={autoHeight}
          columns={columns}
          rows={rows}
          disableRowSelectionOnClick
          pageSizeOptions={pageSizeOptions}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          classes={{
            columnHeaderTitle: "fw500",
          }}
          pagination
          {...props}
        />
      </Box>
    </Wrapper>
  );
};

export default React.memo(Table);
