import React, { useCallback } from "react";
import dayjs from "dayjs";
import { Button, Grid, Link, Stack, Typography as Text } from "@mui/material";
import { LoadingCircle } from "@s12solutions/ui";
import { Methods } from "api";
import { useAPI, useAuth, usePopups } from "hooks";
import {
  ListStatFormsFilterInput,
  StatForm,
  StatFormEmail,
  StatFormsActivity,
} from "@s12solutions/types";
import Timeline from "components/timeline";
import DisableEmailLink from "components/forms/email/disableEmailLink";
import { formatUserName } from "common/libs/formatUserName";
import {
  ListStatFormEmailsResponse,
  ListStatFormsResponse,
} from "common/types/statForms";

interface StatFormActivityProps {
  formId: string;
  refetch: (args?: {
    filter: ListStatFormsFilterInput;
  }) => Promise<
    ListStatFormsResponse | ListStatFormEmailsResponse | null | undefined
  >;
}

const StatFormActivity: React.FC<StatFormActivityProps> = ({
  formId,
  refetch: refetchForms,
}) => {
  const { user: signedInUser } = useAuth();
  const { handleDialog } = usePopups();

  const {
    data,
    loading,
    trigger: refetch,
  } = useAPI<StatFormsActivity[], { id: StatForm["id"] }>({
    method: Methods.GET,
    fieldName: "statFormActivity",
    args: {
      id: formId,
    },
  });

  const handleRefetch = useCallback(async () => {
    await refetch();
    await refetchForms();
  }, [refetch, refetchForms]);

  const handleDisableEmail = useCallback(
    (emailLinkId: StatFormEmail["id"], emailLink: StatFormEmail["emailLink"]) =>
      handleDialog(
        <DisableEmailLink
          emailLinkId={emailLinkId}
          emailLink={emailLink}
          onSuccess={handleRefetch}
        />,
        {
          title: "Disable email link",
        }
      ),
    [handleDialog, handleRefetch]
  );

  if (loading) {
    return <LoadingCircle />;
  }

  if (data?.length === 0) {
    return <Text variant="body2">No activity found</Text>;
  }

  return (
    <Stack spacing={2}>
      <Timeline
        items={
          data?.map((item, itemIdx) => {
            const user = item.user;
            const actor = item.actor ? JSON.parse(item.actor) : "";
            const actorText = actor ? `${actor.name} (${actor.email})` : "";
            const email = item.email;
            const emailContainsEmailLink = !!email?.emailLink;

            return {
              component: (
                <Stack key={itemIdx}>
                  <Text variant="body2">
                    {formatUserName(item.userFullName)}
                  </Text>
                  <Text variant="body2">{actorText}</Text>
                  <Grid
                    container
                    flexDirection="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Text variant="body2" component="div">
                        {item.action}{" "}
                        {/* If form has been emailed but there is no email link present this means the final pdf was sent */}
                        {/* If form has an email link and that email link was sent by the user logged in there will be a disabled button */}
                        {email && [
                          <span>
                            {`${
                              !emailContainsEmailLink ? " final form " : ""
                            } to `}
                          </span>,
                          <Link href={`mailto:${email.toEmail}`}>
                            {email.toEmail}
                          </Link>,
                          ...(emailContainsEmailLink
                            ? [
                                <span> • </span>,
                                <span
                                  className={
                                    email.disabled
                                      ? "text-error"
                                      : "text-success"
                                  }
                                >
                                  {email.disabled ? "Inactive" : "Active"}
                                </span>,
                                ...(!email.disabled &&
                                signedInUser!.username === user
                                  ? [
                                      <span> • </span>,
                                      <Button
                                        onClick={() =>
                                          handleDisableEmail(
                                            email.id,
                                            email.emailLink
                                          )
                                        }
                                        size="small"
                                        sx={{ padding: 0 }}
                                      >
                                        Disable link
                                      </Button>,
                                    ]
                                  : []),
                              ]
                            : []),
                        ]}
                      </Text>
                      <Text variant="body2">
                        {dayjs(item.createdAt).format("HH:mm [on] DD/MM/YYYY")}
                      </Text>
                    </Grid>
                  </Grid>
                </Stack>
              ),
            };
          }) || []
        }
      />
    </Stack>
  );
};

export default React.memo(StatFormActivity);
