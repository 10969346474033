import React from "react";
import { pdf } from "@react-pdf/renderer";
import { RenderedPDF, RenderedPDFProps } from "components/pdf";

export const formatNameAndAddressText = (
  name: string,
  address?: string,
  manualAddress1?: string,
  manualAddress2?: string,
  manualPostcode?: string,
  email?: string
): string => {
  if (
    !name &&
    !address &&
    !manualAddress1 &&
    !manualAddress2 &&
    !manualPostcode
  )
    return "";

  const addressText =
    address || `${manualAddress1}, ${manualAddress2}, ${manualPostcode}`;

  return `${name}\n${addressText}${email ? `\n${email}` : ""}`;
};

const convertBlobToBase64 = async (blob: Blob): Promise<string> => {
  // blob data
  const base64 = (await blobToBase64(blob)).replace(
    "data:application/pdf;base64,",
    ""
  );

  return base64;
};

const blobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve((reader?.result || "") as string);
    reader.onerror = (error) => reject(error);
  });

export const convertPDFToBlob = async (formProps: RenderedPDFProps) => {
  const blob = await pdf(<RenderedPDF {...formProps} />).toBlob();
  return blob;
};

export async function createPdfAsBase64String(
  formProps: RenderedPDFProps
): Promise<string> {
  try {
    const blob = await convertPDFToBlob(formProps);
    const pdfDocument = await convertBlobToBase64(blob);
    if (!pdfDocument) {
      throw new Error("Could not generate PDF document");
    }
    return pdfDocument;
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
}
