/*
  The purpose of this stylesheet is to create a common set of "classes"
  the React PDF components can use to keep things as uniform as possible,
  and to reduce bulk by declaring common style patterns here.

  If you wish to use a class, import this file and simply either:

    <MyComponent style={styles.myClass}/>

  or

    <MyComponent style={{...styles.myClass}}/>


  If there is any CSS rule applied you wish overwritten, simply declare
  the class using the second method above, and include the rule after
  spreading the class. Doing it before will result in your change
  being overwritten by the class.

  Please keep classes here as general and reusable as possible, leave
  any custom styling "inline" so to speak.
*/

import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  // Font styling
  largeBoldFont: { fontSize: 16, fontWeight: "bold" },
  mediumBoldFont: { fontSize: 14, fontWeight: "bold" },
  mediumItalicFont: { fontSize: 12, fontStyle: "italic" },
  smallBoldFont: { fontSize: 12, fontWeight: "bold" },
  smallItalicFont: { fontSize: 12, fontStyle: "italic" },

  // Flex box patterns
  flexRowSpaceBetween: {
    justifyContent: "space-between",
    flexDirection: "row",
  },
  flexColCenter: {
    flexDirection: "column",
    alignItems: "center",
  },

  // Signature patterns
  signatureTextInput: {
    backgroundColor: "white",
    paddingVertical: 5,
    paddingHorizontal: 10,
    minHeight: 25,
  },
  signatureValueWrapper: {
    flexDirection: "column",
    flex: 3,
    marginRight: 10,
  },
  signatureRowWrapper: {
    flexDirection: "row",
  },
  signaturePanel: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 2,
    alignItems: "flex-end",
  },

  // Time and date patterns
  timeDateBoxWrapper: {
    textAlign: "center",
    width: 150,
    height: 35,
    marginRight: 10,
    backgroundColor: "white",
    padding: 5,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
