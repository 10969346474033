import {
  AddressType,
  PatientDetails as GenericPatientDetails,
  ClinicianDetailsType,
  SignatureType,
} from "common/types/statForms";
import { AMHPDetails as GenericAMHPDetails, Opinion } from "common/types/cto";
import { FormPDFConfigData } from "./pdf";

export type HospitalDetails = AddressType & {
  name: string;
};

export type PatientDetails = GenericPatientDetails & {
  ctoCreated: string;
  examined: string;
};

export type AMHPDetails = GenericAMHPDetails & {
  email: string;
};

export type FurnishingReport = SignatureType & {
  consultedName: string;
  consultedProfession: string;
};

export type WardSignature = SignatureType;

export interface CTO7Form {
  hospitalDetails: HospitalDetails;
  clinicianDetails: ClinicianDetailsType;
  patientDetails: PatientDetails;
  opinion: Opinion;
  amhpDetails: AMHPDetails;
  furnishingReport: FurnishingReport;
  wardSignature: WardSignature;
}

export const pdfConfig: FormPDFConfigData = {
  longTitle:
    "Section 20A - community treatment order: report extending the community treatment period",
  longTitleFontSize: 11.5,
  regulation: "13(6)(a) and (b), and 13(7)",
  footerText: "Form amended by SI 2020/1072 for use from 1 December 2020",
  enablePagination: true,
  pagePadding: "12px 22px 2px",
};

export enum FormStateActions {
  LOAD_FORM = "LOAD_FORM",
  SET_HOSPITAL = "SET_HOSPITAL",
  SET_CLINICIAN_NAME = "SET_CLINICIAN_NAME",
  SET_CLINICIAN_ADDRESS = "SET_CLINICIAN_ADDRESS",
  SET_CLINICIAN_EMAIL = "SET_CLINICIAN_EMAIL",
  SET_PATIENT_NAME = "SET_PATIENT_NAME",
  SET_PATIENT_ADDRESS = "SET_PATIENT_ADDRESS",
  SET_PATIENT_CTO_CREATED = "SET_PATIENT_CTO_CREATED",
  SET_PATIENT_EXAMINED = "SET_PATIENT_EXAMINED",
  SET_OPINION_FOR = "SET_OPINION_FOR",
  SET_OPINION_DETAILS = "SET_OPINION_DETAILS",
  SET_OPINION_SIGNATURE = "SET_OPINION_SIGNATURE",
  SET_AMHP_NAME = "SET_AMHP_NAME",
  SET_AMHP_ADDRESS = "SET_AMHP_ADDRESS",
  SET_AMHP_EMAIL = "SET_AMHP_EMAIL",
  SET_AMHP_AUTHORITY_NAME = "SET_AMHP_AUTHORITY_NAME",
  SET_AMHP_OTHER_AUTHORITY = "SET_AMHP_OTHER_AUTHORITY",
  SET_AMHP_ON_BEHALF_OF = "SET_AMHP_ON_BEHALF_OF",
  SET_AMHP_SIGNATURE = "SET_AMHP_SIGNATURE",
  SET_FURNISHING_CONSULTED_NAME = "SET_FURNISHING_CONSULTED_NAME",
  SET_FURNISHING_CONSULTED_PROFESSION = "SET_FURNISHING_CONSULTED_PROFESSION",
  SET_FURNISHING_SIGNATURE = "SET_FURNISHING_SIGNATURE",
  SET_WARD_SIGNATURE = "SET_WARD_SIGNATURE",
}
