import { FC, memo } from "react";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useDeviceType } from "hooks";

interface HeaderProps {
  regulation: string;
  title: string;
}
const Header: FC<HeaderProps> = ({ regulation, title }) => {
  const { formType } = useParams();
  const { isMobile } = useDeviceType();
  const castedFormType = formType as string;

  return (
    <>
      <Stack
        spacing={2}
        {...(isMobile
          ? {}
          : {
              direction: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            })}
      >
        <Stack
          {...(isMobile
            ? {}
            : {
                direction: "row",
                alignItems: "baseline",
              })}
          spacing={1}
        >
          <Typography variant="h1">
            Form {castedFormType.toUpperCase()}
          </Typography>
          <Typography
            variant="h3"
            style={{ fontStyle: "italic", fontWeight: 500 }}
          >
            {regulation}
          </Typography>
        </Stack>
        <Typography variant="h2">Mental Health Act 1983</Typography>
      </Stack>
      <Typography variant="h3" style={{ fontWeight: 600 }}>
        {title}
      </Typography>
    </>
  );
};

export default memo(Header);
