import { H1Form } from "common/types/h1";
import { formatInitialFormState } from "../helpers/formatInitialFormState";

const data: H1Form = {
  hospitalDetails: {
    name: "",
    address: "",
    manualAddress1: "",
    manualAddress2: "",
    manualPostcode: "",
  },
  yourDetails: {
    practitionerName: "",
    isNominee: false,
    isMedicalPractitioner: true,
  },
  patientDetails: {
    name: "",
    detailedReasons: "",
  },
  furnishingReport: {
    signature: "",
    trackingId: "",
    date: "",
  },
  wardSignature: {
    signature: "",
    trackingId: "",
    date: "",
  },
};

export const h1InitialState = formatInitialFormState(data);
