import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { formatShortDate, formatShortDateTime } from "common/libs/dateFormat";
import { styles } from "common/styles/pdf";

/**
 * createJoinedSha
 * A helper function that breaks up a sha256 encrypted signature into something
 * a little more readable.
 * @param {string} sha256 - encrypted signature
 * @returns {string} - structured sub signature
 */
const createJoinedSha = (sha256?: string) => {
  if (!sha256) return;

  const shaSubstring = sha256.substring(0, 16);
  const shaSections = shaSubstring.match(new RegExp(".{1,4}", "g"));
  const joinedSha = shaSections?.join(" - ");

  return joinedSha;
};

/**
 * SignatureWithDates
 * Signature sub-component only used below
 * @param {string} signed - signature data
 * @param {boolean} withPrintName - flag controlling alternate format with PRINTED name
 * @param {string} onBehalfOf - e.g: the hospital managers
 * @param {string} joinedSha - formatted sha256 signature
 * @param {boolean} [empty] - to be left blank
 * @returns {JSX.Element}
 */
const SignaturesWithDates = (props: {
  signed?: string;
  signedBy?: string;
  date?: string;
  withPrintName?: boolean;
  onBehalfOf?: string;
  joinedSha?: string;
  empty?: boolean;
  showTime?: boolean;
}) => {
  const [signature, date] = [props.signed, props.date];
  const formattedDate =
    date && !props.showTime
      ? formatShortDate(date)
      : date && props.showTime
      ? formatShortDateTime(date)
      : undefined;
  const empty = !signature && !date;

  return props.withPrintName ? (
    <View style={{ flexDirection: "column" }}>
      <View style={{ ...styles.signatureRowWrapper, marginBottom: 10 }}>
        <View style={{ flexDirection: "column", flex: 1 }}>
          <View style={styles.signaturePanel}>
            <Text>Signed</Text>
            {props.joinedSha && (
              <Text style={{ fontSize: 8, fontStyle: "italic" }}>
                Tracking ID: {props.joinedSha}
              </Text>
            )}
          </View>
          <Text style={styles.signatureTextInput}>{!empty && signature}</Text>
          {props.onBehalfOf && <Text>on behalf of {props.onBehalfOf}</Text>}
          {props.signedBy && <Text>{props.signedBy}</Text>}
        </View>
      </View>
      <View style={styles.signatureRowWrapper}>
        <View style={styles.signatureValueWrapper}>
          <Text style={{ marginBottom: 2 }}>PRINT NAME</Text>
          <Text style={styles.signatureTextInput}>
            {!empty && signature?.toUpperCase()}
          </Text>
        </View>
        <View style={{ flexDirection: "column", width: 120 }}>
          <Text style={{ marginBottom: 2 }}>Date</Text>
          <Text style={{ ...styles.signatureTextInput, textAlign: "center" }}>
            {!empty ? formattedDate : "/          /"}
          </Text>
        </View>
      </View>
    </View>
  ) : (
    <View style={styles.signatureRowWrapper}>
      <View style={styles.signatureValueWrapper}>
        <View style={styles.signaturePanel}>
          <Text>Signed</Text>
          {props.joinedSha && (
            <Text style={{ fontSize: 8, fontStyle: "italic" }}>
              Tracking ID: {props.joinedSha}
            </Text>
          )}
        </View>
        <Text style={styles.signatureTextInput}>{!empty && signature}</Text>
      </View>
      <View style={{ flexDirection: "column", width: 120 }}>
        <Text style={{ marginBottom: 2 }}>Date</Text>
        <Text style={{ ...styles.signatureTextInput, textAlign: "center" }}>
          {!empty ? formattedDate : "/          /"}
        </Text>
      </View>
    </View>
  );
};

/**
 * PDFSignatures
 * Signatures with tracking ID and time generated appended
 * @param {string} sha256 - encrypted signature
 * @param {string} signed - signature data
 * @param {boolean} [withPrintName] - flag controlling alternate format with PRINTED name
 * @param {string} [onBehalfOf] - e.g: the hospital managers
 * @param {boolean} [empty] - to be left blank
 * @returns {JSX.Element}
 */
export const PDFSignatures = (props: {
  sha256?: string;
  signed?: string;
  signedBy?: string;
  date?: string;
  withPrintName?: boolean;
  onBehalfOf?: string;
  empty?: boolean;
  after?: React.ReactNode;
  noMargin?: boolean;
  showTime?: boolean;
}) => {
  const {
    sha256,
    signed,
    signedBy,
    date,
    withPrintName,
    onBehalfOf,
    empty,
    after,
    noMargin,
    showTime,
  } = props;
  const joinedSha = createJoinedSha(sha256);

  return (
    <View
      wrap={false}
      style={{
        flexDirection: "column",
        marginTop: noMargin ? undefined : 20,
        marginBottom: 10,
      }}
    >
      <SignaturesWithDates
        signed={signed}
        signedBy={signedBy}
        date={date}
        withPrintName={withPrintName}
        onBehalfOf={onBehalfOf}
        joinedSha={joinedSha}
        empty={empty}
        showTime={showTime}
      />
      {after ? <View>{after}</View> : <></>}
    </View>
  );
};
