import { FormType } from "common/types/pdf";
import { FormState } from "common/types/statForms";
import { RenderedPDFProps } from "components/pdf";

const generateFormProps = (
  formId: string | null | undefined,
  formType: FormType | null,
  formData: FormState["data"]
): RenderedPDFProps => {
  const pdfConfig = formType
    ? require(`common/types/${formType}`).pdfConfig
    : {};

  const formProps: RenderedPDFProps = {
    id: formId,
    form: formType,
    formProps: formData,
    ...pdfConfig,
  };

  return formProps;
};

export default generateFormProps;
