import { Reducer } from "react";
import { FormType } from "common/types/pdf";
import { FormState } from "common/types/statForms";
import Action from "common/types/action";
import * as reducers from "store/formState/reducers";
import * as initialStates from "store/formState/initialState";

type ReducerConfig = {
  reducer: Reducer<FormState<any>, Action>;
  initialState: FormState;
};
type FormTypeToReducerConfig = {
  [key in FormType]: ReducerConfig;
};
export const formTypeToReducerConfig: FormTypeToReducerConfig = {
  // Hospital
  [FormType.H1]: {
    reducer: reducers.h1Reducer,
    initialState: initialStates.h1InitialState,
  },
  [FormType.H3]: {
    reducer: reducers.h3Reducer,
    initialState: initialStates.h3InitialState,
  },
  // CTO
  [FormType.CTO1]: {
    reducer: reducers.cto1Reducer,
    initialState: initialStates.cto1InitialState,
  },
  [FormType.CTO3]: {
    reducer: reducers.cto3Reducer,
    initialState: initialStates.cto3InitialState,
  },
  [FormType.CTO4]: {
    reducer: reducers.cto4Reducer,
    initialState: initialStates.cto4InitialState,
  },
  [FormType.CTO5]: {
    reducer: reducers.cto5Reducer,
    initialState: initialStates.cto5InitialState,
  },
  [FormType.CTO7]: {
    reducer: reducers.cto7Reducer,
    initialState: initialStates.cto7InitialState,
  },
  [FormType.CTO12]: {
    reducer: reducers.cto12Reducer,
    initialState: initialStates.cto12InitialState,
  },
  // Treatment
  [FormType.T2]: {
    reducer: reducers.t2Reducer,
    initialState: initialStates.t2InitialState,
  },
};
