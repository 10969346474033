import React, { useEffect, useRef } from "react";
import { useAuth } from "hooks";
import { StyledInput, StyledInputProps } from "./StyledInput";

const StyledActorInput: React.FC<StyledInputProps> = ({
  onChange,
  ...props
}) => {
  const prefilledActorRef = useRef<boolean>(false);
  const { actor } = useAuth();

  useEffect(() => {
    if (prefilledActorRef.current || !actor) return;

    onChange(actor.name);

    prefilledActorRef.current = true;
  }, [actor, onChange]);

  return <StyledInput onChange={onChange} {...props} />;
};

export { StyledActorInput };
