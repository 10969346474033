import { FC, memo } from "react";
import { useStyles as useCommonFormStyles } from "common/styles/form";
import { Backdrop, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useRequireActor } from "hooks";
import CTO1Header from "components/formSections/cto1/CTO1Header";
import HospitalDetails from "components/formSections/common/hospitalDetails/HospitalDetails";
import FormContainer from "../formContainer/FormContainer";

const useStyles = makeStyles((theme) => ({
  overlay: {
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backdropFilter: "blur(6px)",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
}));

// ℹ️ This is all dummy data and will be blurred out when the form is loading
// Basically a makeshift skeleton loader for the forms

const dummyFormItems = [
  {
    index: 0,
    itemText: "1. Clinician Details",
  },
  {
    index: 1,
    itemText: "2. Patient Details",
  },
  {
    index: 2,
    itemText: "3. Opinion",
  },
  {
    index: 3,
    itemText: "4. Further Conditions",
  },
  {
    index: 4,
    itemText: "5. AMHP Details",
  },
];

const loremIpsumParagraph = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
aliquip ex ea commodo consequat. `;

const FormLoading: FC = () => {
  const formClasses = useCommonFormStyles();
  const classes = useStyles();

  useRequireActor();

  return (
    <>
      <FormContainer
        // Sections is an array as this dummy form component will be reacting to the
        // stage of the form that's been loaded in the url. FYI: to make this more realistic
        // the first section has a form header and the other sections look like sections to complete
        sections={[
          <div className={formClasses.sectionContainer}>
            <CTO1Header />
            <Typography variant="body1" mt={3} mb={3}>
              {loremIpsumParagraph}
            </Typography>
            <HospitalDetails name="" address="" />
            <Typography variant="body1" mt={3} mb={3}>
              {loremIpsumParagraph}
            </Typography>
          </div>,
          <div className={formClasses.sectionContainer}>
            <Typography variant="body1" mb={3}>
              {loremIpsumParagraph}
            </Typography>
            <HospitalDetails name="" address="" />
            <Typography variant="body1" mt={3} mb={3}>
              {loremIpsumParagraph}
            </Typography>
          </div>,
          <div className={formClasses.sectionContainer}>
            <Typography variant="body1" mb={3}>
              {loremIpsumParagraph}
            </Typography>
            <HospitalDetails name="" address="" />
            <Typography variant="body1" mt={3} mb={3}>
              {loremIpsumParagraph}
            </Typography>
          </div>,
          <div className={formClasses.sectionContainer}>
            <Typography variant="body1" mb={3}>
              {loremIpsumParagraph}
            </Typography>
            <HospitalDetails name="" address="" />
            <Typography variant="body1" mt={3} mb={3}>
              {loremIpsumParagraph}
            </Typography>
          </div>,
          <div className={formClasses.sectionContainer}>
            <Typography variant="body1" mb={3}>
              {loremIpsumParagraph}
            </Typography>
            <HospitalDetails name="" address="" />
            <Typography variant="body1" mt={3} mb={3}>
              {loremIpsumParagraph}
            </Typography>
          </div>,
          <div className={formClasses.sectionContainer}>
            <Typography variant="body1" mb={3}>
              {loremIpsumParagraph}
            </Typography>
            <HospitalDetails name="" address="" />
            <Typography variant="body1" mt={3} mb={3}>
              {loremIpsumParagraph}
            </Typography>
          </div>,
          <div className={formClasses.sectionContainer}>
            <Typography variant="body1" mb={3}>
              {loremIpsumParagraph}
            </Typography>
            <HospitalDetails name="" address="" />
            <Typography variant="body1" mt={3} mb={3}>
              {loremIpsumParagraph}
            </Typography>
          </div>,
          <div className={formClasses.sectionContainer}>
            <Typography variant="body1" mb={3}>
              {loremIpsumParagraph}
            </Typography>
            <HospitalDetails name="" address="" />
            <Typography variant="body1" mt={3} mb={3}>
              {loremIpsumParagraph}
            </Typography>
          </div>,
        ]}
        formPartSelectorProps={{
          formItems: dummyFormItems,
        }}
        formNavButtonProps={{
          formItems: dummyFormItems,
          lastSectionIndex: 0,
        }}
      />
      <Backdrop open={true} className={classes.overlay} />
    </>
  );
};

export default memo(FormLoading);
