import React from "react";
import { View } from "@react-pdf/renderer";
import { PDFSubHeader } from "../formComponents/baseComponents/PDFSubHeader";
import {
  StyledDateOrTime,
  DateOrTime,
} from "../formComponents/StyledDateOrTime";
import { StyledInformationBox } from "../formComponents/StyledInformationBox";
import { StyledText } from "../formComponents/StyledText";
import { PDFSignatures } from "../formComponents/baseComponents/PDFSignatures";

/**
 * H3Body
 * The pdf H3 form
 * @param {any} props - Get a drink, this is going to be long
 *  @param {any} props.patientDetails - patient object
 *    @param {string} props.patientDetails.name - Patient name
 *  @param {any} props.hospitalDetails - hospital object
 *    @param {any} props.hospitalDetails.address - hospital address from autocomplete or constructed from manual data
 *    @param {any} [props.hospitalDetails.manualAddress1] - address line 1
 *    @param {any} [props.hospitalDetails.manualAddress2] - address line 2
 *    @param {any} [props.hospitalDetails.manualPostcode] - postcode
 *    @param {any} props.hospitalDetails.name - hospital name
 *  @param {any} props.≈ - MAIN FORM DATA
 *    @param {any} [props.admissionDetails.admissionDate] - admission date
 *    @param {any} props.admissionDetails.admissionDatePart2 - PART 2 admission date
 *    @param {any} [props.admissionDetails.admissionTime] - admission time
 *    @param {any} props.admissionDetails.admissionTimePart2 - PART 2 admission time
 *    @param {any} props.admissionDetails.inPatient - is patient already an inpatient (admitted)
 *    @param {any} props.admissionDetails.namePart1 - *printed name for PART 1 (not sure)
 *    @param {any} props.admissionDetails.namePart2 - *printed name for PART 2 (not sure)
 *    @param {any} [props.admissionDetails.section] - section
 *    @param {any} props.admissionDetails.signatureDatePart1 - signature date for PART 1
 *    @param {any} props.admissionDetails.signatureDatePart2 - signature date for PART 2
 *    @param {any} props.admissionDetails.signaturePart1 - signature for PART 1
 *    @param {any} props.admissionDetails.signaturePart2 - signature for PART 2
 * @returns {JSX.Element}
 */
export const H3Body = (props: {
  hospitalDetails: any;
  patientDetails: any;
  admissionDetails: any;
}) => {
  if (
    !props ||
    !props.hospitalDetails ||
    !props.patientDetails ||
    !props.admissionDetails
  )
    return null;
  const hospitalLocation = `${props?.hospitalDetails?.name}\n${props?.hospitalDetails?.address}`;
  const patientName = props?.patientDetails?.name?.toUpperCase();

  const sectionADisabled = props.admissionDetails.inPatient;
  const sectionBDisabled = !props.admissionDetails.inPatient;

  const {
    admissionDate,
    admissionTime,
    section,
    admissionDatePart2,
    admissionTimePart2,
    signaturePart1,
    signatureDatePart1,
    trackingIdPart1,
    signaturePart2,
    signatureDatePart2,
    trackingIdPart2,
  } = props?.admissionDetails;

  return (
    <View>
      {/* Intro Section -> Part 1 */}
      <PDFSubHeader
        text="(To be attached to the application for admission)"
        style={{ fontSize: 10 }}
      />
      <PDFSubHeader subsubheading="PART 1" />

      <StyledText
        style={{ fontStyle: "italic" }}
        instruction="(Name and address of hospital)"
      />
      <StyledInformationBox value={hospitalLocation} />

      <StyledText
        style={{ fontStyle: "italic", marginTop: 0 }}
        instruction="(PRINT full name of patient)"
      />
      <StyledInformationBox value={patientName} />

      <StyledText
        style={{ fontStyle: "italic" }}
        instruction="Complete (a) if the patient is not already an in-patient in the hospital."
      />
      <StyledText
        style={{ fontStyle: "italic", marginTop: 5 }}
        instruction="Complete (b) if the patient is already an in-patient."
      />
      <StyledText
        style={{ fontStyle: "italic", marginTop: 5 }}
        instruction="Delete the one which does not apply."
      />

      {/* Section A */}
      <View style={{ marginLeft: 40, flexDirection: "column" }}>
        <StyledText
          style={{ marginTop: 5, marginBottom: 5 }}
          instruction="The above named patient was admitted to this hospital on"
          prefix="(a)"
          disabled={sectionADisabled}
        />
        <StyledDateOrTime
          dateOrTime={DateOrTime.date}
          value={admissionDate || ""}
          label="(Date of admission to hospital)"
          strikethrough={sectionADisabled}
        />
        <StyledDateOrTime
          dateOrTime={DateOrTime.time}
          value={admissionTime}
          label="(time)"
          prefix="at "
          strikethrough={sectionADisabled}
        />
        <StyledText
          style={{ marginTop: 5 }}
          instruction="In pursuance of an application for admission under section (state section)"
          disabled={sectionADisabled}
        />
        <StyledInformationBox
          value={section ? `Section ${section.toString()}` : ""}
          disabled={sectionADisabled}
        />
        <StyledText
          style={{ marginBottom: 5, marginTop: 0 }}
          instruction="of the Mental Health Act 1983."
          disabled={sectionADisabled}
        />
      </View>

      {/* Section B */}
      <View style={{ marginLeft: 40, flexDirection: "column" }}>
        <StyledText
          style={{ marginTop: 5, marginBottom: 0 }}
          instruction="An application for the admission of the above named patient (who had already been admitted to this hospital) under section"
          guidance="(state section)"
          prefix="(b)"
          disabled={sectionBDisabled}
        />
        <StyledInformationBox
          value={section ? `Section ${section}` : ""}
          disabled={sectionBDisabled}
        />
        <StyledText
          style={{ marginTop: 0, marginBottom: 5 }}
          instruction="of the Mental Health Act 1983 was received by me on behalf of the hospital managers on"
          disabled={sectionBDisabled}
        />

        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <StyledDateOrTime
            dateOrTime={DateOrTime.date}
            value={admissionDate}
            label="(date)"
            strikethrough={sectionBDisabled}
          />
          <StyledDateOrTime
            dateOrTime={DateOrTime.time}
            value={admissionTime}
            label="(time)"
            prefix="at "
            strikethrough={sectionBDisabled}
          />
        </View>
        <StyledText
          style={{ marginTop: 0 }}
          instruction="and the patient was accordingly treated as admitted for the purposes of the Act from that time."
          disabled={sectionBDisabled}
        />
      </View>
      <PDFSignatures
        signed={signaturePart1}
        date={signatureDatePart1}
        withPrintName
        onBehalfOf="the hospital managers"
        sha256={trackingIdPart1}
      />

      {/* Final Section -> Part 2 */}
      <PDFSubHeader subheading="PART 2" pageBreak />
      <PDFSubHeader text="(To be completed only if the patient was admitted in pursuance of an emergency application under section 4 of the Act)" />
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: 450,
          marginTop: 20,
        }}
      >
        <StyledDateOrTime
          dateOrTime={DateOrTime.date}
          value={admissionDatePart2}
          label="(Date)"
          prefix="On"
          empty={section !== "4" || !admissionDatePart2}
        />
        <StyledDateOrTime
          dateOrTime={DateOrTime.time}
          value={admissionTimePart2}
          label="(time)"
          prefix="at "
          empty={section !== "4" || !admissionTimePart2}
        />
      </View>
      <StyledText
        instruction="I received, on behalf of the hospital managers, the second medical recommendation in support of the application for the admission of the above named patient."
        disabled={false}
      />
      <PDFSignatures
        signed={signaturePart2}
        date={signatureDatePart2}
        withPrintName
        onBehalfOf="the hospital managers"
        empty={section !== "4" || !signaturePart2}
        sha256={trackingIdPart2}
      />
      <PDFSubHeader
        style={{ padding: 10, marginTop: 3 }}
        subsubheading="NOTE: IF THE PATIENT IS BEING DETAINED AS A RESULT OF A TRANSFER FROM GUARDIANSHIP, THE PATIENT'S ADMISSION SHOULD BE RECORDED IN PART 2 OF THE FORM G8 WHICH AUTHORISED THE TRANSFER."
      />
    </View>
  );
};
