import { memo, FC, useState, useEffect, useRef } from "react";
import { useDebounce } from "use-debounce";
import { makeStyles } from "@mui/styles";
import { TextField, BaseTextFieldProps, FormHelperText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import { useAPI, useForm } from "hooks";
import { StyledAddressFinderProps, AddressAPIResponseItem } from "./types";
import ErrorLabel from "../ErrorLabel";
import { Methods } from "api";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  errorText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.error.dark,
    fontWeight: "bold",
  },
}));

type Option = {
  id: string | undefined | null;
  label: string;
  expandsTo?: number | null;
};

const StyledAddressFinder: FC<StyledAddressFinderProps & BaseTextFieldProps> =
  memo(
    ({
      testId,
      err,
      errorText,
      errorKey,
      value = "",
      label,
      onChange,
      disabled,
      noFixedAbode,
    }) => {
      const classes = useStyles();
      const { formErrors, handleValidateValue } = useForm();
      const [open, setOpen] = useState(false);
      const [options, setOptions] = useState<Option[]>([]);
      const [inputValue, setInputValue] = useState(value);
      const [debouncedInputValue] = useDebounce(inputValue, 600);
      const [addressIdValue, setAddressIdValue] = useState<string>("");
      const [debouncedAddressIdValue] = useDebounce(addressIdValue, 1000);

      const inputChangedRef = useRef<boolean>(false);

      const [formattingOptions, setFormattingOptions] =
        useState<boolean>(false);

      const { loading: dataLoading, trigger: refetch } = useAPI<
        AddressAPIResponseItem[],
        { term: string; addressId: string }
      >({
        method: Methods.GET,
        fieldName: "addressLookup",
        args: {
          term: debouncedAddressIdValue ? "" : debouncedInputValue,
          addressId: debouncedAddressIdValue,
        },
        skip: true,
        onCompleted: (data) => {
          if (!data) return;
          setFormattingOptions(true);

          const newOptions = data
            ? data.map((el) => ({
              id: el.id,
              label: el.itemText,
              expandsTo: el.expandsTo,
            }))
            : [];
          setOptions([...newOptions]);

          setFormattingOptions(false);

          // Handle set postcode if down to last item and reset
          if (
            data.length === 1 &&
            !data[0].isExpandable &&
            debouncedAddressIdValue
          ) {
            onChange(data[0].itemText);
            setOpen(false);
          }
        },
      });

      useEffect(() => {
        async function handleRefetch() {
          if (inputChangedRef.current) {
            if (errorKey) {
              await handleValidateValue(errorKey, debouncedInputValue);
            }
            refetch();
          }
        }
        handleRefetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [debouncedInputValue, debouncedAddressIdValue]);

      // Handle reset
      useEffect(() => {
        if (!open) {
          setOptions([]);
          setAddressIdValue("");
        }
      }, [open]);

      const loading = dataLoading || formattingOptions;

      const valueObject: { label: string; expandsTo?: number | null } = {
        label: value || "",
      };

      return (
        <Autocomplete
          noOptionsText={inputValue ? "No results found" : "Start typing..."}
          filterOptions={(options) => options}
          disabled={disabled}
          value={valueObject}
          open={open}
          onOpen={() => {
            setOpen(true);
            if (inputValue) {
              refetch();
            }
          }}
          onClose={() => {
            setOpen(false);
          }}
          onInputChange={(_, newInputValue) => {
            if (!inputChangedRef.current) {
              inputChangedRef.current = true;
            }
            setInputValue(newInputValue);
            onChange(newInputValue);
          }}
          onChange={(_, value: any) => {
            if (value) {
              if (value.id) {
                setAddressIdValue(value.id);
                onChange(value.label);
              }
            }
          }}
          disableCloseOnSelect
          options={[...options]}
          loading={loading}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option.label}{" "}
              {option.expandsTo && `(${option.expandsTo} matches)`}
            </Box>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                data-test-id={testId}
                className={classes.input}
                variant="outlined"
                label={label}
                error={(errorKey && !!formErrors?.[errorKey]) || err}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
              {noFixedAbode && (
                <FormHelperText sx={{ float: "left", marginTop: 0.5 }}>
                  Enter the postcode if known, otherwise enter Unknown or N/A
                </FormHelperText>
              )}
              <ErrorLabel
                error={err}
                errorText={errorText}
                errorKey={errorKey}
              />
            </>
          )}
        />
      );
    }
  );

export { StyledAddressFinder };
